import { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Enums } from 'utils';
import ImageView from 'ui/ImageView';

const AttachmentsFormSummary = ({
  taskId,
  header,
}: {
  taskId?: number;
  header?: string;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const attachments =
    task?.encounterTaskAttachmentsList &&
    task?.encounterTaskAttachmentsList.length > 0 &&
    task?.encounterTaskAttachmentsList.filter(
      (attachment) => attachment.encounterTaskId === taskId,
    );

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // // Attachments may not exist on this encounter task (defensive)
  if (attachments === null || attachments === undefined || !attachments)
    return null;

  return (
    <div>
      <h2>{header ? header : task?.taskName}</h2>
      <Grid>
        {attachments &&
          attachments.map((attachment) => {
            return (
              <div key={attachment.encounterTaskAttachmentId}>
                <strong>
                  {header
                    ? attachment.description
                    : Enums.humanize(attachment.type)}{' '}
                  Image:
                </strong>
                <ImageView url={attachment.url as string} />
              </div>
            );
          })}
      </Grid>
    </div>
  );
};

export default AttachmentsFormSummary;
