import { useEffect } from 'react';

import {
  IdentifierTypeEnum,
  useGetDoseSpotUrlMutation,
} from 'generated/graphql';
import { useUserContext } from 'hooks';

type TQueryArgs = {
  encounterId?: number;
  marketId: number;
  patientId: number;
};

type TProps = {
  open: boolean;
  urlQueryArgs?: TQueryArgs;
};

type TReturnValues = {
  errorMessage: string | null;
  doseSpotUrl: string | null;
  loading: boolean;
};

const useDoseSpotModal = ({ open, urlQueryArgs }: TProps): TReturnValues => {
  const { user } = useUserContext() ?? {};
  const dsId = user?.userIdentifiersList?.filter(
    (type) => type?.identifierType === IdentifierTypeEnum.DOSESPOT,
  )[0];
  const dsIdError =
    !dsId || !dsId.identifier
      ? 'This functionality is not configured for your account. Please contact support@getready.com if this should be enabled.'
      : null;

  const [getDoseSpotUrl, { data, error, loading }] = useGetDoseSpotUrlMutation({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (open && urlQueryArgs) {
      const { encounterId, marketId, patientId } = urlQueryArgs;
      const doseSpotVariables: TQueryArgs = {
        marketId,
        patientId,
      };

      if (encounterId) {
        doseSpotVariables.encounterId = encounterId;
      }

      getDoseSpotUrl({
        variables: doseSpotVariables,
      });
    }
  }, [open, urlQueryArgs]);

  if (open && dsIdError) {
    return {
      errorMessage: dsIdError,
      doseSpotUrl: null,
      loading: false,
    };
  }
  return {
    errorMessage: error
      ? error.graphQLErrors.map((err) => err.message).join(' ')
      : null,
    doseSpotUrl: data?.generateDoseSpotURL ?? null,
    loading,
  };
};

export default useDoseSpotModal;
