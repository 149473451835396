import React, { useEffect } from 'react';

import { useGetEncounterTaskByEncounterTaskIdLazyQuery } from 'generated/graphql';
import { EncounterTaskStatusEnum } from 'generated/graphql';

import UI from 'ui';
import { humanize } from 'utils/enums/enums';
import { Grid } from '../StyledEncounterPrint/StyledEncounterPrint';

const VaccineSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const vaccine = task?.vaccine;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isVaccineTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Vaccine may not exist on this encounter task (defensive)
  if (vaccine === null || vaccine === undefined) return null;

  const lookup = task?.vaccineLookups?.find(
    (vlookup) => vlookup?.vaccineLookupId === vaccine.vaccineLookupId,
  );
  if (lookup === null || lookup === undefined) return null;

  return (
    <>
      <h2>{task?.taskName}</h2>
      <Grid>
        <div>
          <strong>Drug Name:</strong>
        </div>
        <div>{lookup.drug?.drugName ?? '-'}</div>
        <div>
          <strong>Manufacturer:</strong>
        </div>
        <div>{lookup.drug?.manufacturerName ?? '-'}</div>
        <div>
          <strong>Route:</strong>
        </div>
        <div>{lookup.drug?.routeName ?? '-'}</div>
        <div></div>
        <div></div>

        <div>
          <strong>Dose Quantity:</strong>
        </div>
        <div>{lookup.doseQuantity ?? '-'}</div>
        <div>
          <strong>Dose Units:</strong>
        </div>
        <div>{lookup.doseUnits ?? '-'}</div>

        <div>
          <strong>Lot #:</strong>
        </div>
        <div>{vaccine.lotNumber ?? '-'}</div>
        <div>
          <strong>Lot Expiration:</strong>
        </div>
        <div>{vaccine.lotExpiration ?? '-'}</div>

        <div>
          <strong>Vaccine Information Statement Edition:</strong>
        </div>
        <div>{vaccine.visEdition ?? '-'}</div>
        <div></div>
        <div></div>

        <div>
          <strong>Location:</strong>
        </div>
        <div>{humanize(vaccine.location || '') ?? '-'}</div>
        <div></div>
        <div></div>

        <div>
          <strong>Dose Number:</strong>
        </div>
        <div>{vaccine.doseNumber ?? '-'}</div>
        <div>
          <strong>Total Doses:</strong>
        </div>
        <div>{vaccine.totalDoses ?? '-'}</div>

        <div>
          <strong>Adverse Reaction:</strong>
        </div>
        <div>{vaccine.hadAdverseReaction ? 'Yes' : 'No'}</div>
        <div></div>
        <div></div>
      </Grid>
      <div>Vaccine Lot Image (TODO)</div>
      <div>Vaccination Letter Image (TODO)</div>
    </>
  );
};

export default VaccineSummary;
