import { yup } from 'forms';

const alternateRequired = (
  operatingAsClinician: boolean,
  isAlternateConnectMethod: boolean,
) => !operatingAsClinician && isAlternateConnectMethod;

const FormSchema = yup.object({
  isAlternateConnectMethod: yup.boolean(),

  // This value will always be empty, and is set in getDefaultValues.
  // We use $isMeetingComplete context to determine whether there
  // should be a validating error.
  isMeetingComplete: yup
    .boolean()
    .when(
      [
        '$isMeetingComplete',
        'isAlternateConnectMethod',
        'alternateConnectionMethod',
        'alternateConnectionReason',
      ],
      {
        is: (
          isMeetingComplete: boolean,
          isAlternateConnectMethod: boolean,
          method: string,
          reason: string,
        ) =>
          (!isMeetingComplete && !isAlternateConnectMethod) ||
          (isMeetingComplete && isAlternateConnectMethod && !method && !reason),
        then: yup
          .boolean()
          .required(
            'Please complete telehealth meeting or provide an alternative connection method',
          ),
      },
    ),

  alternateConnectionMethod: yup
    .string()
    .nullable(true)
    .when(['$operatingAsClinician', 'isAlternateConnectMethod'], {
      is: alternateRequired,
      then: yup
        .string()
        .nullable(true)
        .required('Please select a connection method'),
    }),

  alternateConnectionReason: yup
    .string()
    .when(['$operatingAsClinician', 'isAlternateConnectMethod'], {
      is: alternateRequired,
      then: yup.string().required('Please enter a reason'),
    }),
});

export type TInputs = yup.InferType<typeof FormSchema>;

export default FormSchema;
