import styled from 'styled-components';

import { Tokens } from 'config';
import TableHeadContext from './TableHeadContext';

const StyledTableHead = styled.thead<
  React.HTMLAttributes<HTMLTableSectionElement>
>`
  th {
    padding: calc(${Tokens.rhythm} * 2);
  }
`;

const TableHead = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>): JSX.Element => (
  <StyledTableHead {...props}>
    <TableHeadContext.Provider value={{ variant: 'head' }}>
      {children}
    </TableHeadContext.Provider>
  </StyledTableHead>
);

export default TableHead;
