import { Warning, Check } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';

import { Tokens } from 'config';
import { createLocalUrl } from 'hooks/useFileUpload/utils';
import Dropdown from 'ui/Dropdown/Dropdown';
import ListItem from 'ui/ListItem/ListItem';
import Spinner from 'ui/Spinner/Spinner';

type ThumbnailProps = {
  downloadFailed: boolean;
  file: File | Blob | null;
  isLoading: boolean;
  localUrl: string | null;
  onDelete: () => void;
  onRetryDownload: (() => void) | undefined;
  uploadFailed: boolean;
  uploadComplete: boolean;
};

const Thumbnail = ({
  downloadFailed,
  file,
  isLoading = false,
  localUrl: existingObjectUrl,
  onDelete,
  uploadFailed,
  uploadComplete,
}: ThumbnailProps): JSX.Element => {
  const menuContainerRef = useRef(null);
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [localUrl, setLocalUrl] = useState(existingObjectUrl);

  useEffect(() => {
    if (!localUrl && file) {
      const url = createLocalUrl(file);
      setLocalUrl(url);
    }
  }, [file, localUrl, setLocalUrl]);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      ref={menuContainerRef}
    >
      <div
        className="thumbnail"
        style={{
          backgroundImage: `url(${localUrl})`,
          cursor: 'pointer',
        }}
        onClick={() => setEditMenuOpen((state) => !state)}
      />
      {isLoading && (
        <div className="thumbnail-overlay">
          <Spinner className="spinner" />
        </div>
      )}
      {(downloadFailed || uploadFailed) && !isLoading && (
        <div
          className="thumbnail-overlay error"
          onClick={() => setEditMenuOpen((state) => !state)}
          style={{ cursor: 'pointer' }}
        >
          <Warning
            size={24}
            color={Tokens.color.ui.error.base}
            style={{
              marginLeft: 'calc(50% - 12px)',
              marginTop: 'calc(50% - 12px)',
            }}
          />
        </div>
      )}
      {uploadComplete && (
        <div className="thumbnail-overlay upload-complete">
          <Check
            size={24}
            color={Tokens.color.ui.success.base}
            style={{
              marginLeft: 'calc(50% - 12px)',
              marginTop: 'calc(50% - 12px)',
            }}
          />
        </div>
      )}
      <Dropdown
        role="menu"
        open={editMenuOpen}
        setOpen={setEditMenuOpen}
        anchorRef={menuContainerRef}
        position="right"
      >
        <ListItem label="Delete" role="menuitem" onClick={onDelete} />
      </Dropdown>
    </div>
  );
};

export default Thumbnail;
