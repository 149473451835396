import styled from 'styled-components';

import { Tokens } from 'config';

export const StyledEditSection = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  line-height: ${Tokens.lineHeight.paragraph.base};
  width: 100%;

  .body {
    background-color: ${Tokens.color.neutral.grey[250]};
    margin: calc(${Tokens.rhythm} * 2);
    padding-left: calc(${Tokens.rhythm} * 2);
    padding-right: calc(${Tokens.rhythm} * 2);
    padding-bottom: calc(${Tokens.rhythm} * 2);
    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: calc(${Tokens.rhythm} * 4);
      .closeButton {
        margin-right: calc(${Tokens.rhythm} * 4);
      }
    }
    .fields {
      display: flex;
      flex-direction: column;
    }
    p {
      padding-top: calc(${Tokens.rhythm} * 2);
      margin-bottom: calc(${Tokens.rhythm} * 2);
      color: ${Tokens.color.ui.charcoal.base};
    }
  }
  .heading {
    display: flex;
    border-bottom: ${Tokens.border.size} solid
      ${Tokens.color.neutral.black.transparent[2]};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    color: ${Tokens.color.ui.charcoal.base};
    padding-left: calc(${Tokens.rhythm} * 2);
    padding-right: calc(${Tokens.rhythm} * 2);
    align-items: center;
    p {
      font-weight: ${Tokens.font.weight.semiBold};
    }
  }

  .inputWrapper {
    margin-bottom: calc(${Tokens.rhythm} * 2);
  }
`;
