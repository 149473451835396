import * as yup from 'yup';

import { MultiPhotoSchema } from 'forms';
import { TMultiInputValue } from 'ui/ImageInput/types';

export type TInputs = {
  attachments: Array<TMultiInputValue & { openOnRender?: boolean }>;
};

const FormSchema = yup.object({
  attachments: yup.array().of(MultiPhotoSchema),
});

export default FormSchema;
