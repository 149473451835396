import { useEffect, useState } from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';

import { RoutePaths, Tokens } from 'config';
import {
  useGetPatientByGlobalPatientIdQuery,
  usePatientCreateEmrAuditLogsMutation,
} from 'generated/graphql';
import { Layout } from 'modules';
import UI from 'ui';
import PatientRoutes, { PatientRoutePaths } from './PatientRoutes';
import PatientHeader from './components/PatientHeader';

import { RouteParams, PatientProps, PatientType } from './types';

export const Patient = ({
  patient,
  referrerState,
}: PatientProps): JSX.Element => {
  return (
    <UI.Container
      backgroundColor={Tokens.color.ui.steel.base}
      padding={`0 calc(${Tokens.rhythm} * 2)`}
      style={{
        height: 'calc(100vh - 64px)', // Subtract height of nav header
        overflow: 'hidden',
        maxHeight: '100%',
      }}
    >
      <PatientHeader referrerState={referrerState} patient={patient} />
      <PatientRoutes patient={patient} />
    </UI.Container>
  );
};

const PatientContainer = (): JSX.Element => {
  const { id } = useParams<RouteParams>();
  const location = useLocation();
  const [patient, setPatient] = useState<PatientType>();
  const { data, error } = useGetPatientByGlobalPatientIdQuery({
    variables: { id },
  });
  const patientId = patient?.patientId;
  const [createEmrAuditLogPatientMutation] =
    usePatientCreateEmrAuditLogsMutation();

  useEffect(() => {
    if (data?.patientByGlobalPatientId) {
      setPatient(data.patientByGlobalPatientId as PatientType);
    }
  }, [data]);

  useEffect(() => {
    if (!patientId) return;
    createEmrAuditLogPatientMutation({
      variables: {
        logs: [
          {
            patientId,
            action: 'View Patient Profile',
          },
        ],
      },
    });
  }, [patientId]);

  if (error) return <UI.ErrorFallback error={error} />;

  const referrerState = location.state?.referrer;
  const pageTitle = patient?.fullName ? `${patient?.fullName}'s Chart` : '';
  const breadcrumbsProps = [
    ...(referrerState
      ? [
          {
            title: referrerState.pageTitle,
            to: {
              pathname: referrerState.pathname,
              search: referrerState.search ?? null,
              state: {
                ...referrerState,
              },
            },
          },
        ]
      : [
          {
            title: 'My Appointments',
            href: RoutePaths.appointments,
          },
        ]),
    {
      title: pageTitle,
      to: {
        pathname: generatePath(PatientRoutePaths.profile, { id }),
        state: referrerState
          ? {
              referrer: referrerState,
            }
          : undefined,
      },
    },
  ];

  return (
    <Layout title="Patient Chart" breadcrumbsProps={breadcrumbsProps}>
      {patient ? (
        <Patient referrerState={referrerState} patient={patient} />
      ) : (
        <UI.Spinner testId="spinner" centered />
      )}
    </Layout>
  );
};

export default PatientContainer;
