import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format as formatDate } from 'date-fns';

import {
  EncounterTask,
  useGetEncounterTasksByEncounterIdLazyQuery,
  useCreateClinicianEncounterSignatureMutation,
  useCreateResponderEncounterSignatureMutation,
  DispositionEnum,
  GetEncountersByPatientIdQuery,
  UserRoleEnum,
  EncounterSignature,
} from 'generated/graphql';

import { Enums, Logger } from 'utils';
import { Tokens } from 'config';
import { useEncounter, useToastMessage, useUserContext } from 'hooks';
import UI from 'ui';
import EncounterTaskComponent from './EncounterTask';
import {
  getRoleSpecificTaskList,
  getTaskGroups,
  getTaskMetrics,
} from './utils';

const StyledEncounterTasks = styled.div`
  background-color: ${Tokens.color.neutral.grey[250]};
  display: flex;
  flex-direction: column;
  position: relative;

  .finishEncounter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(${Tokens.rhythm} * 2);

    & > p {
      color: ${Tokens.color.neutral.grey[82]};
      margin: 0 0 ${Tokens.rhythm} ${Tokens.rhythm};
      padding: 0;
      line-height: 1;
    }
  }

  .signatures {
    padding-left: ${Tokens.rhythm};
    padding-top: calc(${Tokens.rhythm} * 2);
    font-style: italic;
    color: ${Tokens.color.neutral.grey[82]};
  }

  .group {
    & > p {
      color: ${Tokens.color.neutral.grey[82]};
      margin: 0 0 ${Tokens.rhythm} ${Tokens.rhythm};
      padding: 0;
      line-height: 1;
    }
  }

  .group + .group {
    padding-top: calc(${Tokens.rhythm} * 2);
  }

  .noTasks {
    padding-left: ${Tokens.rhythm};
  }
`;

const EncounterTasks = ({
  disposition,
  encounter,
  moduleLength,
  serviceLine,
  subType,
}: Readonly<{
  disposition?: DispositionEnum | null;
  encounter: NonNullable<
    GetEncountersByPatientIdQuery['encountersList']
  >[number];
  moduleLength?: number;
  serviceLine?: string;
  subType?: string;
}>): JSX.Element => {
  const [getEncounterTasks, { data, loading, error, refetch }] =
    useGetEncounterTasksByEncounterIdLazyQuery();
  const [finishClinicianEncounter] =
    useCreateClinicianEncounterSignatureMutation();
  const [finishResponderOrSupervisorEncounter] =
    useCreateResponderEncounterSignatureMutation();
  const { setToastMessage } = useToastMessage();
  const {
    user,
    operatingAs,
    operatingAsClinician,
    operatingAsResponder,
    operatingAsSupervisor,
  } = useUserContext() ?? {};
  const { encounterIsComplete, encounterIsInProgress } =
    useEncounter(encounter);

  const signaturesPresent = encounter?.encounterSignatures?.nodes?.length > 0;
  const signatures = encounter?.encounterSignatures?.nodes;
  const [modalOpen, setModalOpen] = useState(false);

  const taskList = getRoleSpecificTaskList({
    role: operatingAs,
    list: data?.encounterTasksList,
  });
  const taskGroups = getTaskGroups({ list: taskList });
  const {
    allTasksAreComplete,
    completeTasks,
    incompleteTasks,
    roleSpecificTaskCount,
    skippedTasks,
  } = getTaskMetrics({ list: taskList, role: operatingAs });

  const approvedDispositionList = [
    DispositionEnum.DISCHARGED_WITH_ACTIVATION,
    DispositionEnum.DISCHARGED_WITH_SERVICE_REFUSAL,
    DispositionEnum.PATIENT_DECLINED_SERVICES,
    DispositionEnum.RESPONDER_INITIATED_ACTIVATION,
  ];
  const dispositionIsApproved =
    completeTasks?.some((task) => task.dataType === 'disposition') &&
    disposition &&
    approvedDispositionList.includes(disposition);
  const encounterCanBeFinished =
    allTasksAreComplete ||
    ((operatingAsResponder || operatingAsSupervisor) && dispositionIsApproved);

  const getModalBody = () => (
    <>
      {incompleteTasks && incompleteTasks.length > 0 ? (
        <>
          <div>
            <b>
              The following incomplete tasks will be marked as skipped upon
              finishing this encounter:
            </b>
          </div>
          <ul>
            {incompleteTasks
              .filter((task) => {
                if (!operatingAsClinician && encounter.clinicianIncluded) {
                  return !task.taskRoles.includes(UserRoleEnum.CLINICIAN);
                } else {
                  return task;
                }
              })
              .map((task) => (
                <li key={task.encounterTaskId}>{task.taskName}</li>
              ))}
          </ul>
        </>
      ) : null}

      <div>
        Please confirm that this encounter is finished. You will not be able to
        make any edits to this encounter after completion.
      </div>
    </>
  );

  const getSignatureBody = (signature: EncounterSignature) => {
    const action =
      signature.operatingAsRole === UserRoleEnum.RESPONDER
        ? 'completed'
        : 'signed';

    const prettyDate = formatDate(
      new Date(signature.createdAt),
      'MMMM dd, yyyy @ h:mmaaa',
    );

    const key = `${signature.operatingAsRole}${signature.user?.userId}`;
    const signatureText = `${Enums.humanize(signature.operatingAsRole || '')} ${
      signature.user?.fullName
    } ${action} on ${prettyDate}`;

    return <div key={key}>{signatureText}</div>;
  };

  const renderSignatures = (encounterSignatures: Array<EncounterSignature>) => {
    // resilience for testing. Generated mock creates encounterSignatures as [{}] which breaks code
    if (
      encounterSignatures.length === 1 &&
      encounterSignatures[0].operatingAsRole === undefined
    ) {
      return null;
    }

    return (
      <div className="signatures" data-testid="signatures">
        {encounterSignatures
          ? encounterSignatures.map((signature) =>
              getSignatureBody(signature as EncounterSignature),
            )
          : null}
      </div>
    );
  };

  const handleFinishEncounter = async () => {
    if (!user?.userId) return;

    try {
      const mutationInput = {
        variables: {
          userId: user?.userId,
          encounterId: encounter?.encounterId,
        },
      };

      if (operatingAsClinician) {
        await finishClinicianEncounter(mutationInput);
      } else if (operatingAsResponder || operatingAsSupervisor) {
        await finishResponderOrSupervisorEncounter(mutationInput);
      }

      setToastMessage(
        `Successfully finished encounter ${serviceLine} - ${subType}`,
        UI.Toast.SeverityLevel.Success,
      );
    } catch (e) {
      Logger.error(e);

      setToastMessage(
        `Failed to finish encounter ${serviceLine} - ${subType}`,
        UI.Toast.SeverityLevel.Error,
      );
    }

    if (modalOpen) setModalOpen(false);
  };

  useEffect(() => {
    getEncounterTasks({
      variables: {
        id: encounter?.encounterId,
      },
    });
  }, []);

  useEffect(() => {
    if (refetch) refetch();
  }, [moduleLength]);

  if (error) return <UI.ErrorFallback error={error} />;

  return loading ? (
    <UI.Container
      backgroundColor={Tokens.color.ui.steel.base}
      padding={`calc(${Tokens.rhythm} * 2)`}
    >
      <UI.Spinner data-testid="spinner" />
    </UI.Container>
  ) : (
    <StyledEncounterTasks>
      <UI.Container
        backgroundColor={Tokens.color.ui.steel.base}
        padding={`${Tokens.rhythm} ${Tokens.rhythm} calc(${Tokens.rhythm} * 2)`}
      >
        {taskList && taskList.length < 1 ? (
          <div className="noTasks">No tasks scheduled yet!</div>
        ) : (
          <div className="finishEncounter">
            {!encounterIsComplete ? (
              <>
                <UI.Button
                  data-testid="finishEncounterButton"
                  onClick={() => setModalOpen(true)}
                  style={{ margin: 0 }}
                  disabled={!encounterIsInProgress || !encounterCanBeFinished}
                >
                  {operatingAsClinician ? 'Sign Encounter' : 'Finish Encounter'}
                </UI.Button>

                <p data-testid="taskCounter">{`${
                  (completeTasks?.length ?? 0) + (skippedTasks?.length ?? 0)
                }/${roleSpecificTaskCount} Tasks Complete`}</p>
              </>
            ) : null}
          </div>
        )}

        {taskGroups
          ?.filter((group) => group === 'Patient Intake')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {taskGroups
          ?.filter((group) => group === 'Basic Health Information')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {taskGroups
          ?.filter((group) => group === 'Approved Modules')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {taskGroups
          ?.filter((group) => group === 'Clinician Teleconference')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {taskGroups
          ?.filter((group) => group === 'Ordered Modules')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {taskGroups
          ?.filter((group) => group === 'Closing Tasks')
          .map((group) => (
            <div className="group" key={group}>
              <p>{group}</p>

              {taskList
                ?.filter((task) => task.group === group)
                .map((task) => (
                  <EncounterTaskComponent
                    key={task.encounterTaskId + task.status!}
                    encounterTask={task as EncounterTask}
                  />
                ))}
            </div>
          ))}

        {signaturesPresent
          ? renderSignatures(signatures as Array<EncounterSignature>)
          : null}
      </UI.Container>

      <UI.Modal
        body={getModalBody()}
        closeButtonLabel="Cancel"
        onClose={() => setModalOpen(false)}
        title={
          operatingAsClinician
            ? 'Sign this Encounter?'
            : 'Finish this encounter?'
        }
        size="small"
        open={modalOpen}
        footerButtons={[
          {
            children: operatingAsClinician
              ? 'Yes, Sign Encounter'
              : 'Yes, Finish Encounter',
            variant: 'primary',
            size: 'small',
            onClick: () => handleFinishEncounter(),
          },
        ]}
      />
    </StyledEncounterTasks>
  );
};

export default EncounterTasks;
