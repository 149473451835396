import { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Tokens } from 'config';

const MedicalConditionsSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const filteredMedicalConditionsList = task?.patientProblemList?.filter(
    (item) => !item?.isDeleted,
  );

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isMedicalConditionTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Medical Conditions may not exist on this encounter task (defensive)
  if (
    filteredMedicalConditionsList === null ||
    filteredMedicalConditionsList === undefined ||
    filteredMedicalConditionsList.length === 0
  )
    return null;

  return (
    <div>
      <Grid style={{ marginBottom: Tokens.rhythm }}>
        <div>
          <strong>Past Medical/Surgery History:</strong>
        </div>
      </Grid>

      <div>
        {filteredMedicalConditionsList.map((condition) => {
          return (
            <Grid key={condition?.startedAt}>
              <div>{condition?.problemText}</div>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default MedicalConditionsSummary;
