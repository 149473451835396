import * as yup from 'yup';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
} from 'generated/graphql';

import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type LeaveBehindInputs = {
  leaveBehindAttachment?: Maybe<string>;
  workOrSchoolNoteAttachment?: Maybe<string>;
};

export type TransferOfCareInputs = {
  transferOfCareAttachment?: Maybe<string>;
};

export const LeaveBehindSchema: yup.SchemaOf<LeaveBehindInputs> = yup.object({
  leaveBehindAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid leave behind'),
  workOrSchoolNoteAttachment: yup.string().nullable(),
});

export const TransferOfCareSchema: yup.SchemaOf<TransferOfCareInputs> =
  yup.object({
    transferOfCareAttachment: yup.string().nullable().required(' '),
  });

export const getDefaultValues = (
  task: TTask,
): LeaveBehindInputs & TransferOfCareInputs => ({
  leaveBehindAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.LEAVE_BEHIND,
  ).attachmentUrl,
  transferOfCareAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.TRANSFER_OF_CARE,
  ).attachmentUrl,
  workOrSchoolNoteAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.WORK_SCHOOL_NOTE,
  ).attachmentUrl,
});
