import { Plus } from 'phosphor-react';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import UI from 'ui';
import { useAttachmentsForm } from './hooks';
import { TInputs } from './utils';

type Props = {
  encounterTaskId: number;
  defaultValues: TInputs;
  disabled: boolean;
  fetchAttachments: () => void;
  setAttachmentsSaving: (isSaving: boolean) => void;
};

const AttachmentsForm = ({
  defaultValues,
  disabled = false,
  encounterTaskId,
  fetchAttachments,
  setAttachmentsSaving,
}: Props): JSX.Element => {
  const {
    appendAttachment,
    buttonIsDisabled,
    attachmentFields,
    getAvailableTypeOptions,
    control,
  } = useAttachmentsForm({
    setAttachmentsSaving,
    defaultValues,
    fetchAttachments,
    encounterTaskId,
  });

  return (
    <>
      {attachmentFields.map((attachment, index) => (
        <div key={attachment.key} className="field">
          <Controlled.MultiImageInput
            control={control}
            disabled={disabled}
            folder={`task-${encounterTaskId}-attachments`}
            name={`attachments.${index}`}
            defaultDescription="Additional Attachment"
            typeOptions={getAvailableTypeOptions(attachment?.type)}
            openEditOnRender={attachment.openOnRender}
            fullWidth
          />
        </div>
      ))}
      <UI.Button
        disabled={disabled || buttonIsDisabled}
        onClick={appendAttachment}
        size="icon"
        style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}
        variant="secondary"
      >
        <Plus />
      </UI.Button>
    </>
  );
};

export default AttachmentsForm;
