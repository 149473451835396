import * as yup from 'yup';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
} from 'generated/graphql';

import { ResponseData, EmployerAddress, PatientName } from './types';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TInputs = {
  patientConsentForm?: Maybe<boolean>;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  patientConsentForm: yup.boolean().required(),
});

export const parseResponseData = (data: ResponseData): string[][] =>
  Object.entries(data)
    .map(([key, value]) => {
      if (key === 'employer_address') {
        const address = value as EmployerAddress;

        const address1 = address?.address ?? '';
        const address2 = address?.address2 ?? '';
        const addressLine = `${address1}${address2 ? ` ${address2}` : ''}`;
        const city = address.city ? ` ${address.city},` : '';
        const state = address.state ? ` ${address.state}` : '';
        const zip = address.zip ? ` ${address.zip}` : '';

        return [
          key,
          `${addressLine ? `${addressLine},` : ''}${city}${state}${zip}`,
        ];
      }

      if (key === 'patient_name') {
        const name = value as PatientName;
        const first = name.first ?? '';
        const last = name.last ?? '';
        return [key, `${first}${first && last ? ' ' : ''}${last}`];
      }

      return [key, value?.toString() ?? ''];
    })
    .filter(
      ([key]) =>
        ![
          'datetime',
          'FormID',
          'UniqueID',
          'HandshakeKey',
          'visitid',
          'encountertaskid',
          'patientformlookupid',
        ].includes(key),
    )
    .sort((a, b) => (a[0] > b[0] && 1) || -1);

export const humanizeKey = (key: string): string => {
  const words = key.split('_');
  return [
    ...[words[0].charAt(0).toUpperCase() + words[0].slice(1)],
    ...words.slice(1),
  ].join(' ');
};
