import * as yup from 'yup';

import {
  ActivationEnum,
  DispositionEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  ModuleNameEnum,
  PatientInstructionTypeEnum,
  UpsertEncounterTaskInput,
} from 'generated/graphql';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TInputs = {
  activation?: ActivationEnum | null;
  clinicianIncluded?: boolean | null;
  disposition?: DispositionEnum | null;
  patientInstructionId?: number | null;
  careInstructions?: string;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  activation: yup
    .mixed<ActivationEnum>()
    .when('disposition', {
      is: (dispositionValue: DispositionEnum) =>
        [
          DispositionEnum.RESPONDER_INITIATED_ACTIVATION,
          DispositionEnum.DISCHARGED_WITH_ACTIVATION,
        ].includes(dispositionValue),
      then: yup
        .string()
        .required('Please select a valid activation type')
        .nullable(),
    })
    .test('no ems module type', (value, testContext) => {
      const {
        options: { context },
        createError,
      } = testContext;
      if (!context?.hasEMSModuleType && value === ActivationEnum.EMS) {
        return createError({
          path: 'activation',
          message: ' ', // message type is a JSX.Element that controls modal state and is located in DispositionForm.tsx
        });
      } else return true;
    }),
  clinicianIncluded: yup
    .boolean()
    .required('Please select a valid answer')
    .nullable(),
  disposition: yup.mixed<DispositionEnum>().when('clinicianIncluded', {
    is: (value: boolean) => value !== null,
    then: yup.string().required('Please select a valid disposition').nullable(),
  }),
  patientInstructionId: yup.number().nullable(true),
  careInstructions: yup.string(),
});

const fieldIsNotTrue = (task: TTask) => {
  const isClinicianModuleType =
    task?.encounter?.modules?.includes(ModuleNameEnum.CLINICIAN) ||
    task?.encounter?.modules?.includes(ModuleNameEnum.CLINICIAN_ACTIVATION);

  if (task?.encounter?.clinicianIncluded === null && isClinicianModuleType) {
    return null;
  }
  return false;
};

export const getDefaultValues = (task: TTask): TInputs => {
  const patientInstructions = (task?.patientInstructions ?? []).filter(
    (item) => !item?.isDeleted,
  );

  const getClinicianIncluded = () => {
    if (task?.encounter?.clinician) {
      return true;
    }
    return task?.encounter?.clinicianIncluded
      ? task?.encounter?.clinicianIncluded
      : fieldIsNotTrue(task);
  };

  return {
    activation: task?.encounter?.activation ?? null,
    clinicianIncluded: getClinicianIncluded(),
    disposition: task?.encounter?.disposition ?? null,
    patientInstructionId: patientInstructions[0]?.patientInstructionId,
    careInstructions: patientInstructions[0]?.careInstructions ?? '',
  };
};

export const getPatch = (
  formData: TInputs,
): Partial<UpsertEncounterTaskInput> => {
  const {
    activation,
    clinicianIncluded,
    disposition,
    careInstructions,
    patientInstructionId,
  } = formData;

  const patientInstructions =
    !careInstructions && !patientInstructionId
      ? []
      : [
          {
            patientInstructionId,
            careInstructions,
            type: PatientInstructionTypeEnum.GENERAL_CARE_INSTRUCTIONS,
          },
        ];

  const patch: Partial<UpsertEncounterTaskInput> = {
    disposition: {
      activation,
      clinicianIncluded,
      disposition: disposition ?? null,
      patientInstructions,
    },
  };

  return patch;
};

export type Option = {
  label: string;
  value: string | number | Record<string, unknown>;
};

export const selectOptions = (
  isClinicianModuleType: boolean | undefined,
  formState: TInputs,
): Option[] => {
  switch (true) {
    case isClinicianModuleType && formState.clinicianIncluded:
      return [
        { label: 'Discharged', value: DispositionEnum.DISCHARGED },
        {
          label: 'Discharged with activation',
          value: DispositionEnum.DISCHARGED_WITH_ACTIVATION,
        },
        {
          label: 'Discharged with service refusal',
          value: DispositionEnum.DISCHARGED_WITH_SERVICE_REFUSAL,
        },
      ];
    case (isClinicianModuleType && !formState.clinicianIncluded) ||
      !isClinicianModuleType:
      return [
        { label: 'Completed', value: DispositionEnum.COMPLETED },
        {
          label: 'Patient declined services',
          value: DispositionEnum.PATIENT_DECLINED_SERVICES,
        },
        {
          label: 'Responder initiated activation',
          value: DispositionEnum.RESPONDER_INITIATED_ACTIVATION,
        },
      ];
    default:
      return [{ label: 'Unknown', value: 'unknown' }];
  }
};

export const activationSelectOptions = [
  { label: 'EMS', value: ActivationEnum.EMS },
  {
    label: 'Police',
    value: ActivationEnum.POLICE,
  },
];
