import { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import PatientFormResponse from './FormstackFormResponse';
import { Tokens } from 'config';

const FormstackFormResponseSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isPatientFormTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Formstack response data may not exist on this encounter task
  if (!task?.patientForm?.fieldResponseJson) return null;

  return (
    <div>
      <h3>Consent Form Response</h3>

      <div style={{ marginLeft: `calc(${Tokens.rhythm} * 2)` }}>
        <PatientFormResponse
          responseData={task?.patientForm?.fieldResponseJson}
        />
      </div>
    </div>
  );
};

export default FormstackFormResponseSummary;
