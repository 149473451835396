import * as yup from 'yup';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  UpsertEncounterTaskInput,
} from 'generated/graphql';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TInputs = {
  note?: string;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  note: yup.string(),
});

export const getDefaultValues = (task: TTask): TInputs => ({
  note: task?.progressNote?.note ?? undefined,
});

export const getPatch = (
  formData: TInputs,
  task: TTask,
): Partial<UpsertEncounterTaskInput> => {
  const { note } = formData;

  const patch = {
    progressNote: {
      encounterTaskId: task?.encounterTaskId as number,
      note: note?.trim() ?? '',
      progressNoteId: task?.progressNote?.progressNoteId ?? undefined,
    },
  };

  return patch;
};
