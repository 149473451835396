import { FieldValues } from 'react-hook-form';

import { UpsertEncounterTaskInput } from 'generated/graphql';

import { TTask } from './getDefaultValues';

const getPatch = (
  formData: FieldValues,
  task: TTask,
): Partial<UpsertEncounterTaskInput> => {
  const { note } = formData;

  const patch = {
    physicalExam: {
      encounterTaskId: task?.encounterTaskId as number,
      note: note?.trim() ?? '',
      physicalExamId: task?.physicalExam?.physicalExamId ?? undefined,
    },
  };

  return patch;
};

export default getPatch;
