import {
  useController,
  Control,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import { useMultiFileUpload } from 'hooks';
import MultiImageInput, {
  MultiImageInputProps,
} from 'ui/ImageInput/MultiImageInput';
import { TMultiInputValue } from 'ui/ImageInput/types';

/**
 * Controlled Multi Image Input Props
 * @extends {UI.MultiImageInput}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<
  MultiImageInputProps,
  | 'name'
  | 'errorText'
  | 'id'
  | 'anyUploadFailed'
  | 'anyIsUploading'
  | 'appendImage'
  | 'clearAllUploadFailed'
  | 'imageFiles'
  | 'onDelete'
  | 'onInputChange'
  | 'value'
> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;

  /**
   * If provided, overrides the default delete method
   */
  onDelete?: () => void;

  /**
   * A folder name for where you want to store the image in the S3 bucket.
   */
  folder: string;
};

const ControlledMultiImageInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  folder,
  name,
  onDelete,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => {
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  const {
    files,
    anyUploadFailed,
    anyIsUploading,
    append,
    clearAllUploadFailed,
  } = useMultiFileUpload({
    folder,
    fileUrls: (value as TMultiInputValue)?.images.map((image) => image.url),
  });

  const handleInputChange = (data: TMultiInputValue) => {
    onChange({ ...data });
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
      return;
    }

    onChange({
      ...(value as TMultiInputValue),
      images: [
        ...((value as TMultiInputValue)?.images ?? []).map((image) => ({
          ...image,
          isDeleted: true,
        })),
      ],
    });
  };

  return (
    <MultiImageInput
      id={name}
      anyIsUploading={anyIsUploading}
      anyUploadFailed={anyUploadFailed}
      appendImage={append}
      clearAllUploadFailed={clearAllUploadFailed}
      errorText={error?.message}
      imageFiles={files}
      onDelete={handleDelete}
      onInputChange={handleInputChange}
      value={value}
      {...field}
      {...props}
    />
  );
};

export default ControlledMultiImageInput;
