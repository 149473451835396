import { CommunicationNeedEnum, LanguagesEnum } from 'generated/graphql';
import { Enums } from 'utils';
import { Option } from 'ui/Select/Select';

export const CommunicationNeedOptions: Option[] = Object.values(
  CommunicationNeedEnum,
).map((option) => ({
  label:
    option === 'TRANSLATOR_INTERPRETER'
      ? Enums.addForwardSlash(option)
      : Enums.humanize(option),
  value: option,
}));

const humanizedLanguageOptions = (option: LanguagesEnum) => {
  switch (option) {
    case LanguagesEnum.KRU:
      return 'Kru (Bassa)';
    case LanguagesEnum.MONKHMER_CAMBODIAN:
      return 'Mon-Khmer, Cambodian';
    case LanguagesEnum.PERSIAN:
      return 'Persian (Farsi)';
    default:
      return Enums.humanize(option);
  }
};

export const LanguageOptions: { label: string; value: LanguagesEnum }[] =
  Object.values(LanguagesEnum).map((option) => ({
    label: humanizedLanguageOptions(option),
    value: option,
  }));
