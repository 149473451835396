import { memo } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

import { Tokens } from 'config';

export type HeaderProps = {
  showRank: boolean;
  columns: string[];
};

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: ${Tokens.font.size.paragraph.base};
  color: ${Tokens.color.neutral.grey[82]};
  margin-bottom: ${Tokens.rhythm};
  margin-left: calc(${Tokens.rhythm} * 2);
  padding: 0 calc(${Tokens.rhythm} * 5) 0 calc(${Tokens.rhythm} * 2);

  .rank {
    flex: 0 calc(${Tokens.rhythm} * 6);
  }

  .col {
    flex-grow: 1;
    flex-basis: calc(${Tokens.rhythm} * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Header = ({
  columns,
  showRank = false,
  ...props
}: HeaderProps): JSX.Element => (
  <StyledHeader {...props}>
    {showRank && <span className="rank">Rank</span>}
    {columns.map((col, idx) => (
      <span key={shortid.generate()} className={`col col${idx + 1}`}>
        {col}
      </span>
    ))}
  </StyledHeader>
);

export default memo(Header);
