import styled from 'styled-components';

import { Tokens } from 'config';

const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  display: inline-flex;
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  .rightLabel {
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: ${Tokens.lineHeight.paragraph.base};
    padding: 0 ${Tokens.rhythm};
    position: absolute;
    right: 0;
    top: 10px;
  }
`;

export default InputWrapper;
