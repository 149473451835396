import React from 'react';
import styled from 'styled-components';
import { Tokens } from 'config';
import UI from 'ui';
import { RadioButtonGroupProps } from './types';

const StyledRadioButtonGroup = styled.div<{
  fullWidth?: boolean;
}>(
  ({ fullWidth }) => `
  display: flex;
  flex-direction: row;
  justify-content: ${fullWidth ? 'space-between' : 'flex-start'};
  width: 100%;
  margin: 0;
  padding: 0;

  & > *:not(:first-child) {
    margin-left: calc(${Tokens.rhythm} * 2);
  }
`,
);

export const StyledErrorText = styled.div<{ errorText?: string }>`
  .errorTextWrapper {
    margin-top: ${Tokens.rhythm};
    span {
      color: ${Tokens.color.ui.error.base};
      font-family: ${Tokens.font.family};
      font-size: ${Tokens.font.size.paragraph.base};
      line-height: ${Tokens.lineHeight.paragraph.small};
    }
  }
`;
const RadioButtonGroup = ({
  children,
  disabled = false,
  errorText,
  instructionText,
  id,
  label,
  name,
  onClickRadioButton,
  optional = false,
  required = false,
  selectedValue,
  fullWidth,
}: RadioButtonGroupProps): JSX.Element => (
  <>
    {label && (
      <UI.Label
        style={{
          marginBottom: instructionText ? '4px' : Tokens.rhythm,
        }}
        htmlFor={id}
        required={required}
      >
        {optional ? (
          <span>
            {label} <i>(optional)</i>
          </span>
        ) : (
          label
        )}
      </UI.Label>
    )}
    <StyledRadioButtonGroup id={id} fullWidth={fullWidth}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          checked: selectedValue === child.props.id,
          onChange: () => onClickRadioButton(child.props.id),
          name,
          fullWidth,
          disabled,
          errorText,
        }),
      )}
    </StyledRadioButtonGroup>

    {errorText && (
      <StyledErrorText>
        <div className="errorTextWrapper">
          <span>{errorText}</span>
        </div>
      </StyledErrorText>
    )}
  </>
);

export default RadioButtonGroup;
