import { Tokens } from 'config';

type Props = {
  color?: string;
};

const RxIcon = ({ color }: Props): JSX.Element => {
  const iconColor = color ?? Tokens.color.ui.charcoal.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/2000/xlink"
      fill={iconColor}
      viewBox="0 0 256 256"
      width="256"
      height="256"
    >
      <path
        d="M72,128h48a44,44,0,0,0,0-88H72V192"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      ></path>
      <line
        x1="112"
        y1="128"
        x2="200"
        y2="216"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      ></line>
      <line
        x1="200"
        y1="160"
        x2="144"
        y2="216"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      ></line>
    </svg>
  );
};

export default RxIcon;
