import { useEffect } from 'react';
import { format as formatDate } from 'date-fns';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { getDefaultValues } from './utils';

const VitalsSummary = ({ taskId }: { taskId?: number }): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const isFirstSet = task?.dataCategory === 'first_set';
  const vitals = getDefaultValues(task?.vitalsList ?? [], isFirstSet);

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isVitalTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Vitals may not exist on this encounter task (defensive)
  if (
    task?.vitalsList === null ||
    task?.vitalsList === undefined ||
    task?.vitalsList.length === 0
  )
    return null;

  return (
    <div>
      <h2>Vitals</h2>
      <Grid>
        <div>
          <strong>Height:</strong>
        </div>
        <div>
          {`${vitals.height} ft ${vitals.heightInches} in`}:{' '}
          {vitals.measuredHeight ? 'Measured' : 'Patient-Stated'}
        </div>
        <div>
          <strong>Weight:</strong>
        </div>
        <div>
          {`${vitals.weight} lbs`}:{' '}
          {vitals.measuredWeight ? 'Measured' : 'Patient-Stated'}
        </div>
        <div>
          <strong>Vitals Taken At:</strong>
        </div>
        <div>
          {vitals.measuredAt &&
            `${formatDate(
              new Date(vitals.measuredAt),
              'M/dd/yyyy',
            )} ${formatDate(new Date(vitals.measuredAt), '@ h:mmaaa')}`}
        </div>
        <div>
          <strong>Body Position:</strong>
        </div>
        <div>{vitals.bodyPosition}</div>
        <div>
          <strong>Systolic Blood Pressure:</strong>
        </div>
        <div>{vitals.systolicBloodPressure} mmHg</div>
        <div>
          <strong>Diastolic Blood Pressure:</strong>
        </div>
        <div>{vitals.diastolicBloodPressure} mmHg</div>
        <div>
          <strong>Blood Pressure Location:</strong>
        </div>
        <div>{vitals.bloodPressureLocation}</div>
        <div>
          <strong>Heart Rate:</strong>
        </div>
        <div>{vitals.heartRate} bpm</div>
        <div>
          <strong>Oxygen Saturation:</strong>
        </div>
        <div>{vitals.oxygenSaturation} %</div>
        <div>
          <strong>Temperature:</strong>
        </div>
        <div>{vitals.bodyTemperature} &deg;F</div>
        <div>
          <strong>Respiratory Rate:</strong>
        </div>
        <div>{vitals.respiratoryRate} breath/min</div>
        <div>
          <strong>Notes:</strong>
        </div>
        <div>{vitals.note && vitals.note.length > 0 ? vitals.note : '-'}</div>
      </Grid>
    </div>
  );
};

export default VitalsSummary;
