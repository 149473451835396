import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import MedicalDecisionMakingForm from './MedicalDecisionMakingForm';
import FormSchema, { FormInputs, DiagnosisCodeReferenceInput } from './schema';
import { useEffect } from 'react';

export type TTask = Readonly<
  GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
>;

type Props = {
  task: TTask;
};

const MedicalDecisionMakingContainer = ({ task }: Props): JSX.Element => {
  const diagnosisCodeRefs = (task?.patientDiagnosesList ?? [])
    .filter((diagnosisCode) => !diagnosisCode.isDeleted)
    .sort((a, b) => (a.order < b.order ? -1 : 1));

  const initialFormValues: FormInputs = {
    note: task?.medicalDecisionMaking?.note ?? '',
    diagnosisCodeReferences: diagnosisCodeRefs.map((item) => {
      const { code, name, diagnosisCodeReferenceId } =
        item!.diagnosisCodeReference!;
      return { code, name, diagnosisCodeReferenceId };
    }),
  };

  const {
    control,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
    handleSubmit,
    handleSave,
    reset,
    trigger,
    watch,
    setValue,
  } = useForm<FormInputs>(FormSchema, {
    defaultValues: initialFormValues,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setValue('note', task?.medicalDecisionMaking?.note ?? '');
  }, [task]);

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => {
        const { note, diagnosisCodeReferences } = data;
        return {
          medicalDecisionMaking: {
            note,
            diagnosisCodeReferenceIds: diagnosisCodeReferences.map(
              (item: DiagnosisCodeReferenceInput) =>
                item.diagnosisCodeReferenceId,
            ),
          },
        };
      },
      onPersistTask: () => reset({}, { keepValues: true }),
    },
  );

  const appointmentDate = task?.encounter?.appointment?.scheduledFor;

  return (
    <MedicalDecisionMakingForm
      key={`MedicalDecisionMakingForm__${task?.updatedAt}`}
      appointmentDate={appointmentDate}
      control={control}
      inputsAreDisabled={inputsAreDisabled}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
      trigger={trigger}
    />
  );
};

export default MedicalDecisionMakingContainer;
