import * as yup from 'yup';

export const MultiPhotoSchema = yup
  .object()
  .shape({
    description: yup.string().required('Please enter a valid title'),
    type: yup.string().required('Please select a valid type'),
    images: yup.array().of(
      yup.object({
        id: yup.number().nullable(true),
        url: yup.string().required(),
        isDeleted: yup.boolean().required(),
      }),
    ),
  })
  .nullable(true);
