import { TypePolicy, FieldPolicy } from '@apollo/client';

import { CredentialsEnum, User, UserRoleEnum } from 'generated/graphql';

const UserTypePolicy: TypePolicy = {
  keyFields: ['userId'],
  fields: {
    // Adds @client field roles
    roles: {
      read(value, { readField }) {
        const userRoles = [];

        if (readField<User['isResponder']>('isResponder')) {
          userRoles.push(UserRoleEnum.RESPONDER);
        }
        if (readField<User['isClinician']>('isClinician')) {
          userRoles.push(UserRoleEnum.CLINICIAN);
        }
        if (readField<User['isSupervisor']>('isSupervisor')) {
          userRoles.push(UserRoleEnum.SUPERVISOR);
        }
        if (readField<User['isIt']>('isIt')) {
          userRoles.push(UserRoleEnum.IT);
        }
        return userRoles;
      },
    } as FieldPolicy,

    // Adds @client field fullName
    fullName: {
      read(value, { readField }) {
        const firstName = readField<User['firstName']>('firstName');
        const lastName = readField<User['lastName']>('lastName');

        if (!firstName && !lastName) {
          return null;
        }

        const names = [
          ...(firstName ? [firstName] : []),
          ...(lastName ? [lastName] : []),
        ];

        return names.join(' ').trim();
      },
    } as FieldPolicy,

    // Adds @client humanizedCredentials
    humanizedCredentials: {
      read(value, { readField }) {
        const credentials = readField<User['credentials']>('credentials');

        if (credentials && credentials.length > 0) {
          return credentials.map((credential: CredentialsEnum | null) => {
            if (credential === CredentialsEnum.RESPONDER) {
              credential.toLowerCase();
            } else {
              return credential;
            }
          });
        } else {
          return [];
        }
      },
    } as FieldPolicy,
  },
};

export default UserTypePolicy;
