import { Plus } from 'phosphor-react';
import { MutableRefObject, useRef, useState } from 'react';

import { Tokens } from 'config';
import { Market, ServiceLine } from 'generated/graphql';
import UI from 'ui';
import { TPatient } from '../types';
import PatientSearchBox from './PatientSearchBox';

export type MarketOption = Pick<Market, '__typename' | 'name' | 'marketId'> & {
  selected: boolean;
};

export type ListItemOption = {
  label: string;
  value: string;
  selected: boolean;
};

export type ServiceLineOption = Pick<
  ServiceLine,
  '__typename' | 'name' | 'serviceLineId' | 'subType'
> & { selected: boolean };

export type FilterMenuProps = {
  /**
   * If `true`, the filters are loading
   */
  isLoading: boolean;

  /**
   * The chart status options
   */
  chartStatusOptions: ListItemOption[];

  /**
   * Handles the chart status option click
   */
  onChartStatusClick: (chartStatus: ListItemOption) => void;

  /**
   * The appointment status options
   */
  appointmentStatusOptions: ListItemOption[];

  /**
   * Handles the appointment status option click
   */
  onAppointmentStatusClick: (appointmentStatus: ListItemOption) => void;

  /**
   * The market options
   */
  marketOptions: MarketOption[];

  /**
   * Handles a market option click
   */
  onMarketClick: (market: MarketOption) => void;

  /**
   * The payment mode options
   */
  paymentModeOptions: ListItemOption[];

  /**
   * Handles the payment mode option click
   */
  onPaymentModeClick: (paymentMode: ListItemOption) => void;

  /**
   * The service line options
   */
  serviceLineOptions: ServiceLineOption[];

  /**
   * Handles the service line option click
   */
  onServiceLineClick: (serviceLine: ServiceLineOption) => void;

  /**
   * The selected patient in the patient typeahead
   */
  selectedPatient: TPatient;

  /**
   * Handles the patient option click
   */
  onPatientOptionClick: (patient: TPatient) => void;

  /**
   * The Patient Filter Ref
   */
  patientFilterRef: MutableRefObject<{ reset: () => void }>;
};

const FilterMenu = ({
  isLoading,
  chartStatusOptions,
  onChartStatusClick,
  appointmentStatusOptions,
  onAppointmentStatusClick,
  marketOptions,
  onMarketClick,
  paymentModeOptions,
  onPaymentModeClick,
  serviceLineOptions,
  onServiceLineClick,
  selectedPatient,
  onPatientOptionClick,
  patientFilterRef,
}: FilterMenuProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClearPatient = () => {
    onPatientOptionClick(null);
  };

  return (
    <div ref={containerRef} style={{ display: 'inline-block' }}>
      <UI.Button
        isLoading={isLoading}
        variant="tertiary"
        leftIcon={<Plus />}
        style={{ fontWeight: 600 }}
        size="small"
        onClick={() => setOpen((state) => !state)}
      >
        Add Filter
      </UI.Button>
      <UI.Dropdown
        anchorRef={containerRef}
        position="bottomRight"
        open={open}
        setOpen={setOpen}
      >
        <UI.SubMenu parentOpen={open} label="Patient">
          <div
            style={{
              width: '528px',
              padding: `0 ${Tokens.rhythm}`,
            }}
          >
            <PatientSearchBox
              initialValue={selectedPatient?.globalHumanId ?? undefined}
              onOptionClick={(arg) => onPatientOptionClick(arg as TPatient)}
              onReset={handleClearPatient}
              patientFilterRef={patientFilterRef}
            />
          </div>
        </UI.SubMenu>
        <UI.SubMenu
          parentOpen={open}
          label="Market"
          submenuStyle={{
            maxHeight: 390,
            overflowY: 'scroll',
          }}
        >
          {marketOptions.map((option) => (
            <UI.ListItem
              key={option.marketId}
              label={`${option.name}`}
              showCheckbox
              selected={option.selected}
              onClick={() => onMarketClick(option)}
            />
          ))}
        </UI.SubMenu>
        <UI.SubMenu parentOpen={open} label="Chart Status">
          {chartStatusOptions.map((option) => (
            <UI.ListItem
              key={option.label}
              label={option.label}
              showCheckbox
              selected={option.selected}
              onClick={() => onChartStatusClick(option)}
            />
          ))}
        </UI.SubMenu>
        <UI.SubMenu parentOpen={open} label="Appointment Status">
          {appointmentStatusOptions.map((option) => (
            <UI.ListItem
              key={option.label}
              label={option.label}
              showCheckbox
              selected={option.selected}
              onClick={() => onAppointmentStatusClick(option)}
            />
          ))}
        </UI.SubMenu>
        <UI.SubMenu
          parentOpen={open}
          label="Service Line"
          style={{ minWidth: 250 }}
        >
          {serviceLineOptions.map((option) => (
            <UI.ListItem
              key={option.serviceLineId}
              label={`${option.name} - ${option.subType}`}
              showCheckbox
              selected={option.selected}
              onClick={() => onServiceLineClick(option)}
            />
          ))}
        </UI.SubMenu>
        <UI.SubMenu
          parentOpen={open}
          label="Payment Mode"
          style={{ minWidth: 250 }}
        >
          {paymentModeOptions.map((option) => (
            <UI.ListItem
              key={option.label}
              label={option.label}
              showCheckbox
              selected={option.selected}
              onClick={() => onPaymentModeClick(option)}
            />
          ))}
        </UI.SubMenu>
      </UI.Dropdown>
    </div>
  );
};

export default FilterMenu;
