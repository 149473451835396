import styled from 'styled-components';

declare interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  /*
   * if `true`, set width to 100%
   */
  fullWidth?: boolean;
  /*
   * if `true`, suppress the nowrap property
   */
  textwrap?: boolean;
}

const StyledTable = styled.table<TableProps>(({ fullWidth, textwrap }) => {
  const width = fullWidth ? 'width: 100%;' : '';
  const whitespace = textwrap ? '' : 'white-space: nowrap;';
  return `
    margin: 0 auto;
    overflow-x: auto;
    ${whitespace}
    border-collapse: collapse;
    ${width}
  `;
});

const Table = ({ children, ...props }: TableProps): JSX.Element => (
  <StyledTable {...props}>{children}</StyledTable>
);

export default Table;
