import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import { useAdditionalPhotoDocSection } from './hooks';
import AttachmentsForm from './AttachmentsForm';

const StyledContainer = styled.div`
  margin-top: calc(${Tokens.rhythm} * 2);

  .field {
    margin-bottom: ${Tokens.rhythm};
  }

  .heading {
    display: block;
    font-weight: ${Tokens.font.weight.bold};
    margin-bottom: calc(${Tokens.rhythm} * 2);
  }

  .loading {
    display: flex;

    span {
      margin-left: ${Tokens.rhythm};
    }
  }
`;

type Props = {
  encounterTaskId: number;
  disabled: boolean;
};

const AdditionalPhotoDocSection = ({
  disabled = false,
  encounterTaskId,
}: Props): JSX.Element => {
  const {
    attachmentsLoading,
    attachmentsSaving,
    fetchAttachments,
    setAttachmentsSaving,
    defaultFormValues,
  } = useAdditionalPhotoDocSection({
    encounterTaskId,
  });

  const renderContent = (children: JSX.Element) => (
    <StyledContainer>
      <span className="heading">Additional Photo Documentation</span>
      {children}
    </StyledContainer>
  );

  if (attachmentsLoading || attachmentsSaving) {
    const action = attachmentsLoading ? 'Loading' : 'Saving';
    return renderContent(
      <div className="loading">
        <UI.Spinner />
        <span>{action} Attachments...</span>
      </div>,
    );
  }

  return renderContent(
    <AttachmentsForm
      encounterTaskId={encounterTaskId}
      defaultValues={defaultFormValues}
      fetchAttachments={fetchAttachments}
      disabled={disabled}
      setAttachmentsSaving={setAttachmentsSaving}
    />,
  );
};

export default AdditionalPhotoDocSection;
