import {
  EncounterTaskStatusEnum,
  EncounterTask,
  CredentialsEnum,
  Maybe,
} from 'generated/graphql';
import styled from 'styled-components';
import { Tokens } from 'config';
import { getStateFromTask } from 'cache/reactiveVars/TaskDrawer';

export const StyledClinicianAttestation = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  padding-left: ${Tokens.rhythm};
  padding-right: ${Tokens.rhythm};
`;

const getCredentials = (credentials: Maybe<CredentialsEnum>[]) =>
  credentials && credentials.length > 0 ? `, ${credentials.join(', ')}` : '';

const getClinicianName = (task: EncounterTask) =>
  `${task?.encounter?.clinician?.firstName} ${
    task?.encounter?.clinician?.lastName
  }${`${getCredentials(task.encounter?.clinician?.credentials ?? [])},`}`;

const getRespondersNames = (task: EncounterTask) =>
  task?.modifiedByResponders?.map(
    (responder) =>
      `${responder?.firstName} ${responder?.lastName}${getCredentials(
        responder?.credentials ?? [],
      )}`,
  );

const getCategoryFromTaskDrawerState = (task: EncounterTask) => {
  const taskDrawerState = getStateFromTask(task);
  switch (!!taskDrawerState) {
    case taskDrawerState.isAllergiesTask:
      return 'non-drug allergies';
    case taskDrawerState.isDoseSpotTask:
      return 'medications and drug allergies';
    case taskDrawerState.isMedicalConditionTask:
      return 'medical conditions';
    case taskDrawerState.isPatientFormTask:
      return 'assessment';
    case taskDrawerState.isPhysicalExamTask:
      return 'physical exam';
    case taskDrawerState.isPointOfCareTestTask:
      return 'point of care test results';
    case taskDrawerState.isTelehealthTask:
      return 'meeting details';
    case taskDrawerState.isVitalTask:
      return 'vitals';
  }
};

export const renderClinicianAttestation = (
  task: EncounterTask,
): JSX.Element | null => {
  if (!task) return null;
  const clinicianReviewed = Boolean(
    task?.status === EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
  );
  const modifiedByResponders = Boolean(
    task?.modifiedByResponders && task?.modifiedByResponders.length > 0,
  );
  const category = getCategoryFromTaskDrawerState(task);

  if (clinicianReviewed) {
    return (
      <div>
        <p>
          {`I, ${getClinicianName(task)} reviewed and updated the`}{' '}
          <strong>{`${category}`}</strong>{' '}
          {`${
            !modifiedByResponders
              ? 'verbally reported by the Responder'
              : `inputted by Responder(s) ${getRespondersNames(task)}`
          }`}
        </p>
      </div>
    );
  }
  return null;
};
