import styled from 'styled-components';
import React, { useContext } from 'react';

import { Tokens } from 'config';
import TableHeadContext from './TableHeadContext';

declare interface TableHeaderCellProps
  extends React.HTMLAttributes<HTMLTableHeaderCellElement> {
  scope?: string;
}

const sharedStyles = `
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: ${Tokens.lineHeight.paragraph.small};
`;

const StyledTableHeaderCell = styled.th<TableHeaderCellProps>`
  color: ${Tokens.color.ui.slate.base};
  font-weight: ${Tokens.font.weight.semiBold};
  text-align: left;
  ${sharedStyles};
`;

const StyledTableCell = styled.td<React.HTMLAttributes<HTMLTableCellElement>>`
  border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  color: ${Tokens.color.ui.charcoal.base};
  height: calc(${Tokens.rhythm} * 7);
  padding: 0 calc(${Tokens.rhythm} * 2);
  ${sharedStyles};

  a {
    color: ${Tokens.color.ui.charcoal.base};
  }
`;

const TableCell = ({
  children,
  ...props
}:
  | React.HTMLAttributes<HTMLTableCellElement>
  | TableHeaderCellProps): JSX.Element => {
  const { variant } = useContext(TableHeadContext);

  if (variant === 'head' || 'scope' in props) {
    return <StyledTableHeaderCell {...props}>{children}</StyledTableHeaderCell>;
  }

  return <StyledTableCell {...props}>{children}</StyledTableCell>;
};

export default TableCell;
