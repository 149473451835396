import styled from 'styled-components';
import { Tokens } from 'config';

export const StyledNotesSection = styled.div`
  background-color: ${Tokens.color.neutral.grey[250]};
  padding: calc(${Tokens.rhythm} * 3) 0 0 calc(${Tokens.rhythm} * 2);
  width: 40%;
  height: calc(
    100vh - 173px
  ); // Subtract height of nav header and profile header
  overflow: scroll;
  --webkit-overflow-scrolling: touch;

  .heading {
    border-bottom: ${Tokens.border.size} solid
      ${Tokens.color.neutral.black.transparent[2]};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    font-weight: ${Tokens.font.weight.semiBold};
    padding-left: ${Tokens.rhythm};
    padding-bottom: ${Tokens.rhythm};
  }

  .newNoteButton {
    margin-top: calc(${Tokens.rhythm} * 3);
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    &:last-child::after {
      content: '';
      display: block;
      height: calc(${Tokens.rhythm} * 8); // 64px
      width: 100%;
    }
  } //iPad
`;

export const StyledNotes = styled.div<{
  isHighPriority?: boolean | null;
}>(
  ({ isHighPriority }) => `
  background-color: ${Tokens.color.neutral.white.base};
  border-radius: 2px;
  margin-top: calc(${Tokens.rhythm} * 2);
  padding: calc(${Tokens.rhythm} * 2);
  ${isHighPriority && `border-left: 2px solid ${Tokens.color.ui.error.base};`}

  .highPriority {
    display: flex;
    align-items: center;
    margin-left: ${Tokens.rhythm};
    span {
      margin-left: ${Tokens.rhythm};
      color: ${Tokens.color.ui.error.base};
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc(${Tokens.rhythm} * 3);

    .buttonsRight {
      display: flex;
      justify-content: flex-end;

      .saveButton {
        margin-left: calc(${Tokens.rhythm} * 2);
      }
    }
  }

  .noteBody {
    margin: 0 ${Tokens.rhythm};
  }

  .fields {
    display: flex;
    flex-direction: column;
    margin-top: ${Tokens.rhythm};
  }

  .subHeadingWrapper {
    display: flex;
    justify-content: space-between;
    align-items: top;
    border-bottom: ${Tokens.border.size} solid
      ${Tokens.color.neutral.black.transparent[2]};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

    padding-left: ${Tokens.rhythm};
    padding-right: ${Tokens.rhythm};

    .subHeadingBold {
      margin: ${Tokens.rhythm} 0 0 0;
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: 1;
    }

    .subHeadingDate {
      margin: ${Tokens.rhythm} 0 ${Tokens.rhythm} 0;
      line-height: 1;
    }

    span.subHeading {
      line-height: 1;
      margin: 0;
      padding-bottom: ${Tokens.rhythm};
      padding-top: ${Tokens.rhythm};
    }

    .tripleDotMenu {
      position: relative
    }
  }
`,
);
