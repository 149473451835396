type AddressValues = {
  address1: string;
  city: string;
  state: string;
  zip: string;
};

const normalizePlaceDetails = (
  data: google.maps.places.PlaceResult,
  autocompleteResult: string,
): AddressValues => {
  let streetNumber;
  let street;

  const address: AddressValues = {
    address1: '',
    city: '',
    state: '',
    zip: '',
  };

  data?.address_components?.forEach((component) => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    } else if (component.types.indexOf('route') >= 0) {
      street = component.long_name;
    } else if (
      component.types.includes('locality') ||
      component.types.includes('sublocality')
    ) {
      address.city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      address.state = component.short_name;
    } else if (component.types.includes('postal_code')) {
      address.zip = component.long_name;
    }
  });

  if (!streetNumber) {
    // Parse the street number from the autocomplete result
    const groups = autocompleteResult.match(/^(\d+)(\s.*)$/i);
    streetNumber = groups && groups.length > 0 ? groups[1] : '';
  }

  address.address1 = `${streetNumber} ${street}`;

  return address;
};

export default normalizePlaceDetails;
