// Docs located at https://sanusart.github.io/react-dropdown-select/api
import ReactSelect, { SelectProps } from 'react-dropdown-select';
import { ReactNode } from 'react';

import { Tokens } from 'config';
import UI from 'ui';
import InstructionText from '../Input/InstructionText';
import StyledSelect from './StyledSelect';

export type Option = {
  label: string;
  value: string | number | Record<string, unknown>;
};

export type Props = {
  /**
   * If `true`, the input will be disabled.
   */
  disabled?: boolean;
  /**
   * Error displayed below the input.
   */
  errorText?: string | ReactNode;
  /**
   * The input id.
   */
  id: string;
  /**
   * Instruction text displayed above the input.
   */
  instructionText?: string | React.ReactNode;
  /**
   * Renders a label with the provided text.
   */
  label?: string;
  /**
   * Allow multiple selections.
   */
  multi?: boolean;
  /**
   * The `name` attribute of the input.
   */
  name?: string;
  /**
   * If `true`, the label will have italicized `optional` text.
   */
  optional?: boolean;
  /**
   * Options array for Select.
   */
  options?: SelectProps<Option>['options'];
  /**
   * Placeholder text.
   */
  placeholder?: string;
  /**
   * Option for setting required on input.
   */
  required?: boolean;
  /**
   * If `true`, options will sort by label.
   */
  sortedOptions?: boolean;
  /**
   * Values is an array of selected Options.
   */
  values?: Option[];
  /**
   * The `onChange` event handler callback fires with an array of Option values as args.
   */
  onChange?: (arg?: Option[]) => void;
};

const Select = ({
  disabled = false,
  errorText,
  id = 'Select',
  instructionText,
  label,
  multi = false,
  name = 'Select',
  onChange = () => {},
  optional = false,
  options = [],
  placeholder = 'Select...',
  required = false,
  sortedOptions = true,
  values = [],
}: Props): JSX.Element => {
  const renderTags: SelectProps<Option>['optionRenderer'] = ({
    item,
    methods,
  }) => (
    <UI.Tag
      cancellable
      label={item?.label}
      onCancel={() => methods.removeItem(null, item, false)}
    />
  );

  const renderOptions: SelectProps<Option>['optionRenderer'] = ({
    item,
    methods,
  }) => (
    <div style={{ lineHeight: `${Tokens.lineHeight.paragraph.base}` }}>
      <UI.ListItem
        label={item?.label}
        onClick={() => methods.addItem(item)}
        selected={methods.isSelected(item)}
        showCheckbox={multi}
      />
    </div>
  );

  const classes = [];
  if (disabled) classes.push('disabled');
  if (multi) classes.push('multi');

  return (
    <>
      {label && (
        <UI.Label
          style={{
            marginBottom: instructionText ? '4px' : Tokens.rhythm,
          }}
          htmlFor={id}
          required={required}
        >
          {optional ? (
            <div>
              {label} <i>(optional)</i>
            </div>
          ) : (
            label
          )}
        </UI.Label>
      )}

      {instructionText && <InstructionText>{instructionText}</InstructionText>}

      <StyledSelect errorText={errorText}>
        <ReactSelect
          additionalProps={{ id }}
          color={Tokens.color.ui.charcoal.base}
          className={classes.join(' ')}
          disabled={disabled}
          dropdownGap={0}
          itemRenderer={renderOptions}
          multi={multi}
          name={name}
          onChange={onChange}
          options={options}
          optionRenderer={multi ? renderTags : undefined}
          placeholder={placeholder}
          required={required}
          searchable={false}
          sortBy={sortedOptions ? 'label' : undefined}
          values={values}
        />

        {errorText && (
          <div className="errorTextWrapper">
            <span>{errorText}</span>
          </div>
        )}
      </StyledSelect>
    </>
  );
};

export default Select;
