import { FormEvent } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';

import {
  PatientPatchRecordInput,
  usePatientUpdatePatientMutation,
} from 'generated/graphql';

import UI from 'ui';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import { EditInfoSectionsProps } from '../../../types';
import * as Options from './enumsToOptions';
import { StyledEditSection } from '../StyledEditSection';

const EditCommunications = ({
  patient,
  setIsEditable,
}: EditInfoSectionsProps): JSX.Element => {
  const [updatePatientMutation] = usePatientUpdatePatientMutation();
  const { setToastMessage } = useToastMessage();

  const { formState, getData, setData, getErrors, setErrors } = useForm({
    preferredPhoneNumber: {
      errorMessage: `Please provide a valid phone number, e.g. 555-867-5309`,
      field: 'preferredPhoneNumber',
      hasError: false,
      required: true,
      value: patient?.preferredPhoneNumber ?? '',
    },
    email: {
      field: 'email',
      value: patient?.email ?? undefined,
    },
    communicationNeed: {
      field: 'communicationNeed',
      value: (patient?.communicationNeed as string[]) ?? undefined,
    },
    languages: {
      field: 'languages',
      value: (patient?.languages as string[]) ?? undefined,
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = getErrors();
    const data = getData<PatientPatchRecordInput>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
      return;
    }

    if (
      data?.preferredPhoneNumber &&
      !isValidPhoneNumber(data.preferredPhoneNumber)
    ) {
      setErrors('preferredPhoneNumber', true);
      return;
    } // Check for phone number validity before saving

    if (!patient?.patientId) return;

    const { patientId } = patient;
    const patch = {
      preferredPhoneNumber: data?.preferredPhoneNumber,
      email: data?.email,
      communicationNeed: data?.communicationNeed
        ? data?.communicationNeed
        : null,
      languages: data?.languages ? data?.languages : null,
    };

    try {
      const updatedPatient = await updatePatientMutation({
        variables: {
          input: {
            patientId,
            patch,
          },
        },
      });

      if (updatedPatient) {
        setToastMessage(
          `Successfully updated the patient's information`,
          UI.Toast.SeverityLevel.Success,
        );
      }
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update the patient's information`,
        UI.Toast.SeverityLevel.Error,
      );
    } finally {
      if (setIsEditable) setIsEditable(false);
    }
  };

  return (
    <StyledEditSection>
      <div className="heading">
        <p>Communications</p>
      </div>

      <form className="body" onSubmit={handleSubmit} autoComplete="off">
        <p>All fields below, unless otherwise noted, are required.</p>

        <div className="fields">
          <div className="inputWrapper">
            <UI.PhoneNumber
              id="preferredPhoneNumber"
              name="preferredPhoneNumber"
              label="Preferred Phone Number"
              fullWidth
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              title={formState.preferredPhoneNumber.errorMessage}
              errorText={
                formState.preferredPhoneNumber.hasError
                  ? formState.preferredPhoneNumber.errorMessage
                  : undefined
              }
              onUpdate={(value) => {
                setData('preferredPhoneNumber', value);
                if (value.length === 2) {
                  setData('preferredPhoneNumber', '');
                }
              }}
              phoneNumber={patient?.preferredPhoneNumber as string}
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="email"
              name="email"
              label="Email Address"
              fullWidth
              optional
              onChange={(event) => setData('email', event.target.value)}
              value={(formState.email.value as string) ?? ''}
            />
          </div>
          <div className="inputWrapper">
            <UI.Select
              id="languages"
              label="Preferred Language"
              name="languages"
              optional
              options={Options.LanguageOptions}
              multi
              onChange={(values) => {
                if (values && values.length > 0) {
                  setData(
                    'languages',
                    values?.map((value) => value.value) as string[],
                  );
                } else {
                  setData('languages', undefined);
                }
              }}
              values={Options.LanguageOptions.filter((option) =>
                (formState.languages.value as string)?.includes(
                  option.value as string,
                ),
              )}
            />
          </div>

          <UI.Select
            id="communicationNeed"
            label="Special Communication Needs"
            name="communicationNeed"
            optional
            options={Options.CommunicationNeedOptions}
            multi
            onChange={(values) => {
              if (values && values.length > 0) {
                setData(
                  'communicationNeed',
                  values?.map((value) => value.value) as string[],
                );
              } else {
                setData('communicationNeed', undefined);
              }
            }}
            values={Options.CommunicationNeedOptions.filter((option) =>
              (formState.communicationNeed.value as string)?.includes(
                option.value as string,
              ),
            )}
          />
        </div>

        <div className="buttonContainer">
          <UI.Button
            variant="tertiary"
            className="closeButton"
            onClick={() => setIsEditable && setIsEditable(false)}
          >
            Close
          </UI.Button>
          <UI.Button variant="primary" className="saveButton" type="submit">
            Save
          </UI.Button>
        </div>
      </form>
    </StyledEditSection>
  );
};

export default EditCommunications;
