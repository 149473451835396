import * as yup from 'yup';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
  UpsertEncounterTaskInput,
  VaccineLocationEnum,
  VaccineLookupFieldsFragment,
} from 'generated/graphql';

import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import { getDrugIsExpired } from 'utils';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TInputs = {
  doseNumber?: Maybe<string>;
  drugId?: Maybe<number>;
  contraindication?: Maybe<boolean>;
  hadAdverseReaction?: Maybe<boolean>;
  location?: Maybe<VaccineLocationEnum>;
  lotNumber?: Maybe<string>;
  lotNumberAttachment?: Maybe<string>;
  lotExpiration?: Maybe<Date>;
  standingOrderAddedAt?: Maybe<string>;
  standingOrderId?: Maybe<number>;
  totalDoses?: Maybe<string>;
  vaccinationLetterAttachment?: Maybe<string>;
  visEdition?: Maybe<Date>;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  doseNumber: yup
    .string()
    .test(
      'test-doseNumber',
      'Please enter a valid number',
      (value, context) =>
        Boolean(context?.options?.context?.overrideRequired) ||
        Boolean(value && /^[1-9][0-9]?$|^100$/.test(value)),
    ),
  drugId: yup.number().required('Please select a vaccine'),
  contraindication: yup.boolean().required('Please select a valid answer'),
  hadAdverseReaction: yup
    .boolean()
    .required('Please confirm if there was an adverse reaction'),
  location: yup
    .mixed<VaccineLocationEnum>()
    .required('Please select a valid location'),
  lotNumber: yup.string().required('Please enter a valid vaccine lot #'),
  lotNumberAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid vaccine lot #'),
  lotExpiration: yup
    .date()
    .nullable()
    .test(
      'test-lotExpiration',
      'Please enter a valid lot expiration date',
      (value, context) => {
        return (
          (value &&
            Boolean(
              !getDrugIsExpired(context?.options?.context?.task, value),
            )) ||
          Boolean(context?.options?.context?.overrideRequired)
        );
      },
    ),
  standingOrderAddedAt: yup.string(),
  standingOrderId: yup
    .number()
    .nullable()
    .required('Please select a valid standing order'),
  totalDoses: yup
    .string()
    .test(
      'test-totalDoses',
      'Please enter a valid number',
      (value, context) =>
        Boolean(context?.options?.context?.overrideRequired) ||
        Boolean(value && /^[1-9][0-9]?$|^100$/.test(value)),
    ),
  vaccinationLetterAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid vaccination letter'),
  visEdition: yup
    .date()
    .nullable()
    .required('Please enter a valid VIS edition date'),
});

export const getExistingDrugId = (task: TTask): number | undefined =>
  task?.vaccineLookups?.find(
    (lookup) => lookup?.vaccineLookupId === task.vaccine?.vaccineLookupId,
  )?.drug?.drugId;

export const getSelectedDrugVaccineLookup = (
  formData: TInputs,
  task: TTask,
): VaccineLookupFieldsFragment | undefined | null =>
  task?.vaccineLookups?.find(
    (vaccine) => vaccine?.drug?.drugId === formData.drugId,
  );

export const getDefaultValues = (task: TTask): TInputs => ({
  doseNumber: task?.vaccine?.doseNumber?.toString() ?? undefined,
  drugId: getExistingDrugId(task),
  contraindication: task?.vaccine?.contraindication ?? undefined,
  hadAdverseReaction: task?.vaccine?.hadAdverseReaction ?? undefined,
  location: task?.vaccine?.location ?? undefined,
  lotExpiration: task?.vaccine?.lotExpiration ?? undefined,
  lotNumber: task?.vaccine?.lotNumber ?? undefined,
  lotNumberAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.LOT_NUMBER,
  ).attachmentUrl,
  visEdition: task?.vaccine?.visEdition ?? undefined,
  standingOrderAddedAt: task?.vaccine?.standingOrderAddedAt ?? undefined,
  standingOrderId: task?.vaccine?.standingOrder?.standingOrderId ?? undefined,
  totalDoses: task?.vaccine?.totalDoses?.toString() ?? undefined,
  vaccinationLetterAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.VACCINATION_LETTER,
  ).attachmentUrl,
});

export const getPatchValues = (
  formData: TInputs,
  task: TTask,
): Partial<UpsertEncounterTaskInput> | undefined => {
  const {
    contraindication,
    doseNumber,
    hadAdverseReaction,
    location,
    lotExpiration,
    lotNumber,
    standingOrderAddedAt,
    standingOrderId,
    totalDoses,
    visEdition,
  } = formData;

  let patch;

  const vaccineLookupId = getSelectedDrugVaccineLookup(
    formData,
    task,
  )?.vaccineLookupId;

  if (vaccineLookupId) {
    patch = {
      vaccine: {
        contraindication,
        doseNumber: doseNumber ? Number(doseNumber) : null,
        hadAdverseReaction,
        location: location ?? null,
        lotExpiration: lotExpiration
          ? new Date(lotExpiration).toISOString().split('T')[0]
          : null,
        lotNumber: lotNumber && lotNumber.length > 0 ? lotNumber : null,
        standingOrderAddedAt: standingOrderAddedAt ?? null,
        standingOrderId: standingOrderId ?? null,
        totalDoses: totalDoses ? Number(totalDoses) : null,
        vaccineLookupId,
        visEdition: visEdition ?? null,
        visReceived: Boolean(visEdition),
      },
    };
  }

  return patch;
};
