import styled from 'styled-components';
import { X, IconProps } from 'phosphor-react';

import { Tokens } from 'config';
import Button from 'ui/Button/Button';

type ToastLayoutProps = {
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  iconColor?: string;
  message?: string;
  actionLabel?: string;
  onActionClick?: () => void;
  onHide?: () => void;
  className?: string;
  testId?: string;
};

const StyledToast = styled.div`
  animation-iteration-count: 1;
  animation-duration: ${Tokens.transition.duration[3]};
  background-color: ${Tokens.color.neutral.white.base};
  bottom: calc(${Tokens.rhythm} * 2);
  color: ${Tokens.color.ui.charcoal.base};
  font-family: ${Tokens.font.family};
  left: calc(${Tokens.rhythm} * 2);
  position: absolute;

  &.show {
    animation-name: fadeIn;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  &.hide {
    animation-name: fadeOut;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .wrapper {
    align-items: center;
    box-shadow: 0px 1px 8px 0px ${Tokens.color.neutral.black.transparent[25]};
    display: flex;
    flex-direction: row;
    padding: calc(${Tokens.rhythm} * 2);

    .icon {
      display: flex;
      margin-right: calc(${Tokens.rhythm} * 2);
    }

    .message {
      font-size: ${Tokens.font.size.paragraph.base};
      line-height: ${Tokens.lineHeight.paragraph.small};
      max-width: 393px;
      margin-right: ${Tokens.rhythm};
    }

    .action {
      margin-right: ${Tokens.rhythm};
    }

    .separator {
      background-color: ${Tokens.color.neutral.grey[240]};
      margin: 6px ${Tokens.rhythm};
      height: 20px;
      width: 1px;
    }

    .close {
      display: flex;
      align-items: center;
    }
  }
`;

const ToastLayout = ({
  message,
  actionLabel,
  icon: IconComponent,
  iconColor,
  onActionClick,
  className,
  onHide,
  testId,
}: ToastLayoutProps): JSX.Element => (
  <StyledToast data-testid={testId} className={className}>
    <div className="wrapper">
      {IconComponent && (
        <div className="icon">
          <IconComponent color={iconColor} size={20} weight="fill" />
        </div>
      )}
      <div className="message">{message}</div>
      {actionLabel && onActionClick && (
        <Button
          as="button"
          variant="link"
          onClick={onActionClick}
          className="action"
        >
          {actionLabel}
        </Button>
      )}
      <div className="separator" />
      <div className="close">
        <Button onClick={onHide} size="smallIcon" variant="tertiary">
          <X />
        </Button>
      </div>
    </div>
  </StyledToast>
);

export default ToastLayout;
