import {
  CommunicationNeedEnum,
  GenderIdentityEnum,
  GenderPronounEnum,
  LanguagesEnum,
  SexEnum,
  RaceEnum,
  PatientSuffixEnum,
} from 'generated/graphql';
import { aPatient, aPatientNote, aUser } from 'generated/mocks';

import { InfoSectionsProps } from '../types';

const PatientMockData: InfoSectionsProps['patient'] = aPatient({
  address1: '172143 Los Tablos Ave',
  address2: 'address 2',
  addressLine1: '172143 Los Tablos Ave, address 2, unit 2',
  addressLine2: 'Los Angeles, CA 91108',
  age: 36,
  cannotProvideSsn: false,
  city: 'Los Angeles',
  communicationNeed: [
    CommunicationNeedEnum.DEAF,
    CommunicationNeedEnum.TRANSLATOR_INTERPRETER,
  ],
  dob: '12/31/1984',
  email: 'SpanishJoe@gmail.com',
  firstName: 'Joseph',
  fullName: 'Dr. Joseph Jolly Smith-Johnson III',
  genderIdentity: GenderIdentityEnum.NON_BINARY,
  genderPronoun: GenderPronounEnum.HE_HIM,
  globalHumanId: '48fd535',
  hasDifferentGenderIdentity: true,
  humanizedCommunicationNeed: ['Deaf, Translator/Interpreter'],
  humanizedGenderIdentity: 'Non-binary',
  humanizedGenderPronoun: 'He/Him',
  humanizedGlobalHumanId: '48FD535',
  humanizedLanguages: ['Albanian', 'Chinese'],
  humanizedRaces: ['American Indian', 'Native Alaskan', 'Asian'],
  humanizedSex: 'Female',
  humanizedNoSsnReason: ['Refused'],
  languages: [LanguagesEnum.ALBANIAN, LanguagesEnum.CHINESE],
  lastName: 'Smith-Johnson',
  middleName: 'Jolly',
  patientId: 111,
  patientNotesList: [
    aPatientNote({
      createdAt: '2021-04-30 20:24:36.964+00',
      formattedCreatedAt: 'April 30, 2021 @ 8:24pm',
      isDeleted: false,
      isHighPriority: false,
      migrationSource: 'RH1.0',
      note: 'Need to add mint',
      patientId: 1234,
      patientNoteId: 10,
    }),
    aPatientNote({
      createdAt: '2021-02-30 18:24:36.964+00',
      formattedCreatedAt: 'February 30, 2021 @ 6:24pm',
      isDeleted: false,
      isHighPriority: true,
      migrationSource: null,
      note: 'Looking for more cheese',
      patientId: 1234,
      patientNoteId: 9,
      userByCreatedBy: aUser({
        firstName: 'Falafel',
        lastName: 'Pizza',
      }),
    }),
  ],

  preferredName: 'Joe',
  preferredPhoneNumber: '626-333-2131',
  prefix: 'Dr.',
  races: [RaceEnum.AMERICAN_INDIAN, RaceEnum.NATIVE_ALASKAN, RaceEnum.ASIAN],
  sex: SexEnum.FEMALE,
  ssnSerial: '1234',
  state: 'CA',
  suffix: PatientSuffixEnum.III,
  teladocPass: '12345!',
  teladocUser: 'Joejoe@gmail.com',
  unit: 'unit 2',
  zip: '91108',
});

export default PatientMockData;
