import _ from 'lodash';
import { format as formatDate } from 'date-fns';
import { useEffect } from 'react';
import { Control } from 'react-hook-form';

import { useForm } from 'forms';
import {
  GetEncounterTaskByEncounterTaskIdQuery,
  useGetEncounterTaskByEncounterTaskIdQuery,
  useJoinTelehealthMeetingMutation,
} from 'generated/graphql';
import {
  usePersistTask,
  useTask,
  useToastMessage,
  useUserContext,
} from 'hooks';
import UI from 'ui';
import { Logger } from 'utils';
import { TaskHeaderProps } from '../../TaskHeader';
import {
  FormSchema,
  TInputs,
  getPatchValues,
  getDefaultValues,
} from '../utils';

type Props = {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
};

type MeetingDetails = {
  host: string;
  url: string;
  meetingId: string;
  startTime: string | null;
  endTime: string | null;
};

type ReturnValues = {
  control: Control<TInputs>;
  defaultValues: TInputs;
  handleJoinQueue: () => void;
  inputsAreDisabled: boolean;
  isAlternateConnectMethod: boolean;
  isCompleteErrorText: string | undefined;
  isInQueue: boolean;
  hasInQueueAt: boolean; // TODO: remove after teledoc logic is sorted out
  meetingDetails: MeetingDetails | null;
  taskHeaderProps: TaskHeaderProps;
};

const useTelehealthForm = ({ task }: Props): ReturnValues => {
  const { setToastMessage } = useToastMessage();

  const defaultValues = getDefaultValues({ task });

  const { clinician, inQueueAt, videoConference, clinicianClaimedAt } =
    task?.telehealthMeeting ?? {};

  const { meetingStartTime, meetingEndTime } = videoConference ?? {};

  const isMeetingComplete = !!meetingStartTime && !!meetingEndTime;

  const { operatingAsClinician } = useUserContext() ?? {};

  const {
    control,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
    handleSave,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<TInputs>(FormSchema, {
    defaultValues,
    context: { operatingAsClinician, isMeetingComplete },
  });

  const formState = watch();
  const { isAlternateConnectMethod } = formState;
  const { inputsAreDisabled } = useTask(task);
  const isCompleteErrorText = _.get(errors, 'isMeetingComplete.message');

  const [joinTelehealthMeeting] = useJoinTelehealthMeetingMutation();

  const { loading, refetch, startPolling, stopPolling } =
    useGetEncounterTaskByEncounterTaskIdQuery({
      variables: {
        id: task?.encounterTaskId ?? 0,
        isTelehealthTask: true,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  const isInQueue = (!!inQueueAt && clinicianClaimedAt === null) || loading;

  const shouldStartPolling = isInQueue || clinicianClaimedAt;

  const handleJoinQueue = async () => {
    if (task?.encounterTaskId) {
      try {
        await joinTelehealthMeeting({
          variables: { encounterTaskId: task?.encounterTaskId },
        });

        // Also refetch right away to get updated queue data for visual update
        await refetch();
      } catch (error) {
        Logger.error(error);
        setToastMessage(
          'An error occurred while joining queue.',
          UI.Toast.SeverityLevel.Error,
        );
      }
    }
  };

  useEffect(() => {
    if (shouldStartPolling) {
      // Start polling for updates to telehealth queue
      startPolling(3000);
    }
    // Cleanup and stop polling for updates to telehealth queue
    return () => {
      stopPolling();
    };
  }, [shouldStartPolling]);

  useEffect(() => {
    if (isAlternateConnectMethod === false) {
      setValue('alternateConnectionMethod', null);
      setValue('alternateConnectionReason', '');
    }
  }, [isAlternateConnectMethod]);

  const getMeetingDetails = (): MeetingDetails | null => {
    if (videoConference) {
      const { meetingId, hostUrl, participantUrl } = videoConference;

      const startTime = meetingStartTime
        ? formatDate(new Date(meetingStartTime), 'h:mm aaa')
        : null;

      const endTime = meetingEndTime
        ? formatDate(new Date(meetingEndTime), 'h:mm aaa')
        : null;

      return {
        host: `${clinician?.firstName} ${clinician?.lastName}`,
        startTime,
        endTime,
        meetingId,
        url: operatingAsClinician ? hostUrl ?? '' : participantUrl ?? '',
      };
    }

    return null;
  };

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatchValues(data as TInputs),
      onPersistTask: (updatedTask) =>
        reset(getDefaultValues({ task: updatedTask })),
    },
  );

  const meetingDetails = getMeetingDetails();

  return {
    control,
    defaultValues,
    handleJoinQueue,
    inputsAreDisabled,
    isAlternateConnectMethod: !!isAlternateConnectMethod,
    isCompleteErrorText,
    isInQueue,
    hasInQueueAt: !!inQueueAt,
    meetingDetails,
    taskHeaderProps: {
      task,
      isDirty,
      isSaving,
      isValidating,
      onTaskComplete: handleCompleteTask,
      onTaskSkip: handleSkipTask,
      onTaskSave: handleSaveTask,
    },
  };
};

export default useTelehealthForm;
