import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import Header from './Header';
import Row from './Row';
import { move as defaultMove, remove as defaultRemove } from './utils';

export type DragAndDropProps<T extends Record<string, unknown>> = {
  columnKeys: Array<keyof T>;
  disabled?: boolean;
  header: string[];
  keyName: keyof T;
  move?: (from: number, to: number) => void;
  remove?: (index: number) => void;
  rows: T[];
  setRows?: (rows: T[]) => void;
  showRank?: boolean;
  testId?: string;
  toolTipColumnKeys?: Array<keyof T>;
};

const DragAndDrop = <T extends Record<string, unknown>>({
  columnKeys,
  disabled = false,
  header,
  keyName,
  move,
  remove,
  rows,
  setRows,
  showRank = false,
  testId,
  toolTipColumnKeys = [],
}: DragAndDropProps<T>): JSX.Element => {
  const onDragEnd: OnDragEndResponder = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    if (move) {
      move(source.index, destination.index);
      return;
    }

    if (setRows) {
      setRows(defaultMove(rows, source.index, destination.index));
    }
  };

  const onRemove = (index: number) => {
    if (remove) {
      remove(index);
      return;
    }

    if (setRows) {
      setRows(defaultRemove(rows, index));
    }
  };

  return (
    <>
      <Header
        data-testid={testId ? `${testId}__header` : undefined}
        columns={header}
        showRank={showRank}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={disabled} droppableId="list">
          {(provided) => (
            <div
              data-testid={testId ? `${testId}__rows` : undefined}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {rows.map((row, index) => {
                const key = `${row[keyName]}`;
                const rank = showRank ? index + 1 : undefined;
                const columns = columnKeys.map((columnKey) => ({
                  label: `${row[columnKey]}`,
                  showTooltip: toolTipColumnKeys?.includes(columnKey),
                }));
                return (
                  <Row
                    key={key}
                    id={key}
                    index={index}
                    rank={rank}
                    columns={columns}
                    disabled={disabled}
                    onRemove={() => onRemove(index)}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DragAndDrop;
