import { useState } from 'react';

import {
  GetMarketsQuery,
  GetUsersQuery,
  useUpdateUserMutation,
  useUserGrantRoleMutation,
  useUserRevokeRoleMutation,
  UserRoleEnum,
  IdentifierTypeEnum,
} from 'generated/graphql';

import { useForm, Controlled } from 'forms';
import Row from './Row';
import UI from 'ui';
import {
  TInputs,
  Schema,
  getDefaultValues,
  getPatch,
  TRoleState,
  CredentialsOptions,
} from './utils';
import { SeverityLevel } from 'ui/Toast/Toast';
import { useToastMessage } from 'hooks';
import { Enums, Logger } from 'utils';
import styled from 'styled-components';
import { Tokens } from 'config';

const ModalBody = styled.div`
  div + label {
    margin-top: calc(${Tokens.rhythm} * 2);
  }
  .field {
    margin-top: calc(${Tokens.rhythm} * 2);
  }
`;

const RowContainer = ({
  user = undefined,
  markets = [],
}: {
  user?: NonNullable<GetUsersQuery['usersList']>[number];
  markets?: GetMarketsQuery['marketsList'];
}): JSX.Element => {
  const defaultValues = getDefaultValues(user);
  const [updateUserMutation] = useUpdateUserMutation();
  const [revokeRoleMutation] = useUserRevokeRoleMutation();
  const [grantRoleMutation] = useUserGrantRoleMutation();

  const { setToastMessage } = useToastMessage();
  const [modalOpen, setModalOpen] = useState(false);
  const [roleState, setRoleState] = useState<TRoleState>({
    userRole: null,
    addRole: false,
  });
  const fullName = [user?.firstName, user?.lastName].join(' ');
  const npiIdentifierType = user?.userIdentifiersList?.filter(
    (type) => type?.identifierType === IdentifierTypeEnum.NPI,
  );
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<TInputs>(Schema, {
    context: { roleState, npiIdentifierType },
    defaultValues,
    reValidateMode: 'onChange',
  });
  const formState = watch();

  const handleRevokeRole = async (role: UserRoleEnum) => {
    const patch = { role };
    if (!user) return;

    try {
      await revokeRoleMutation({
        variables: {
          input: {
            patch,
            userId: user?.userId,
          },
        },
      });

      setToastMessage(
        `Removed ${fullName} from ${Enums.humanize(role)} role`,
        SeverityLevel.Success,
      );
    } catch (err) {
      Logger.error(err);
      setToastMessage(
        `An error occurred while saving ${Enums.humanize(role)}`,
        SeverityLevel.Error,
      );
    }

    setModalOpen(false);
  };

  const onChangeRole = async (userRole: UserRoleEnum, addRole?: boolean) => {
    if (addRole) {
      setRoleState({ userRole, addRole });
      setModalOpen(true);
      return;
    } else {
      await handleRevokeRole(userRole);
    }
  };

  const handleAdd = handleSubmit(async (data: TInputs) => {
    const patch = getPatch(data);
    if (!user) return;

    switch (roleState.addRole) {
      case roleState.userRole === UserRoleEnum.CLINICIAN:
        patch.role = UserRoleEnum.CLINICIAN;
        break;
      case roleState.userRole === UserRoleEnum.RESPONDER:
        patch.role = UserRoleEnum.RESPONDER;
        break;
      case roleState.userRole === UserRoleEnum.SUPERVISOR:
        patch.role = UserRoleEnum.SUPERVISOR;
        break;
      default:
        patch.role = null;
        break;
    }

    try {
      await grantRoleMutation({
        variables: {
          input: {
            patch,
            userId: user?.userId,
          },
        },
      });

      setToastMessage(
        `${fullName} was successfully updated`,
        SeverityLevel.Success,
      );
    } catch (err) {
      Logger.error(err);
      setToastMessage(
        `An error occurred while saving ${
          roleState.userRole ? Enums.humanize(roleState.userRole) : `user's`
        } role`,
        SeverityLevel.Error,
      );
    }

    reset({ ...formState, clinicianNpi: defaultValues.clinicianNpi });
    setModalOpen(false);
  });

  const handleUpdate = handleSubmit(async (data: TInputs) => {
    const patch = getPatch(data);
    if (!user) return;

    try {
      await updateUserMutation({
        variables: {
          input: {
            patch,
            userId: user?.userId,
          },
        },
      });

      setToastMessage(
        `${fullName} was successfully updated`,
        SeverityLevel.Success,
      );
    } catch (err) {
      Logger.error(err);
      setToastMessage(
        `An error occurred while saving ${
          roleState.userRole ? Enums.humanize(roleState.userRole) : `user's`
        } role`,
        SeverityLevel.Error,
      );
    }

    reset({ ...formState, clinicianNpi: defaultValues.clinicianNpi });
    setModalOpen(false);
  });

  const getModalTitle = () => {
    switch (roleState.userRole) {
      case UserRoleEnum.CLINICIAN:
        return 'Verify Clinician Information';
      case UserRoleEnum.RESPONDER:
      case UserRoleEnum.SUPERVISOR:
        return 'Verify Responder/Supervisor Information';
      default:
        return 'Verify User Information';
    }
  };

  return (
    <>
      <Row
        control={control}
        fullName={fullName}
        onChangeRole={onChangeRole}
        setModalOpen={setModalOpen}
        setRoleState={setRoleState}
        user={user}
      />

      <UI.Modal
        body={
          <ModalBody>
            <Controlled.Input
              control={control}
              defaultValue=""
              fullWidth
              label="Registered First Name"
              name="registeredFirstName"
            />
            <div className="field">
              <Controlled.Input
                control={control}
                defaultValue=""
                fullWidth
                label="Registered Last Name"
                name="registeredLastName"
              />
            </div>
            <div className="field">
              <Controlled.Datepicker
                control={control}
                fullWidth
                label="Date of Birth"
                name="dob"
                filterFutureDateAndTime
                format="MM/dd/yyyy"
                timeZone="UTC"
              />
            </div>
            {roleState.userRole === UserRoleEnum.CLINICIAN ? (
              <Controlled.Input
                control={control}
                defaultValue=""
                fullWidth
                label="Clinician NPI"
                name="clinicianNpi"
              />
            ) : null}
            <div className="field">
              <Controlled.Select
                control={control}
                label="Credentials"
                multi
                name="credentials"
                options={CredentialsOptions}
                sortedOptions={false}
              />
            </div>
            <div className="field">
              <Controlled.Select
                control={control}
                label="Market(s)"
                multi
                name="clinicianMarkets"
                options={markets?.map((market) => ({
                  label: market?.name ?? '',
                  value: market?.marketId,
                }))}
                sortedOptions={false}
              />
            </div>
          </ModalBody>
        }
        footerButtons={[
          {
            className: 'saveButton',
            children: `${isSubmitting ? 'Saving...' : 'Save'}`,
            variant: 'primary',
            onClick: roleState.addRole ? handleAdd : handleUpdate,
          },
        ]}
        onClose={() => {
          reset({ ...formState, clinicianNpi: defaultValues.clinicianNpi });
          setModalOpen(false);
        }}
        open={modalOpen}
        size="medium"
        title={getModalTitle()}
      />
    </>
  );
};

export default RowContainer;
