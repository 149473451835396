import { Redirect, Route } from 'react-router-dom';

import { UserRoleEnum } from 'generated/graphql';
import { useUserContext } from 'hooks';

type Props = {
  children: JSX.Element;
  exact?: boolean;
  path: string;
  redirectTo: string;
  roles: UserRoleEnum[];
};

const ProtectedRoute = ({
  children,
  roles,
  redirectTo,
  ...rest
}: Props): JSX.Element => {
  const userContext = useUserContext();
  const operatingAs = userContext?.operatingAs;

  return (
    <Route {...rest}>
      {operatingAs && roles.includes(operatingAs) ? (
        <>{children}</>
      ) : (
        <Redirect to={redirectTo} />
      )}
    </Route>
  );
};

export default ProtectedRoute;
