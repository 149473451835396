import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import Input from '../Input/Input';
import { InputProps } from '../Input/types';

type Props = Omit<InputProps, 'value' | 'onChange' | 'placeholder'> & {
  /* Function that returns the transformed SSN as a 9 digit string. */
  onUpdate?: (value: string) => void;
  /* Initial last 4 digits of SSN if available */
  ssnSerial?: string;
};

const SSNInput = (props: Props): JSX.Element => {
  const { ssnSerial = '', onUpdate = () => {}, ...rest } = props;
  const [internalValue, setInternalValue] = useState<string>('');

  const manipulateValue = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    value = value.substr(0, 9);

    if (value.length >= 4) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    }
    if (value.length >= 7) {
      value = `${value.slice(0, 6)}-${value.slice(6)}`;
    }

    setInternalValue(value);
  };

  const handleDelete = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key && (event.key === 'Backspace' || event.key === 'Delete')) {
      setInternalValue('');
    }
  };

  useEffect(() => {
    if (ssnSerial) setInternalValue(`***-**-${ssnSerial}`);
  }, [ssnSerial]);

  useEffect(() => {
    onUpdate(internalValue.replace(/[-,*]/g, ''));
  }, [internalValue]);

  return (
    <Input
      {...rest}
      onChange={manipulateValue}
      onKeyDown={handleDelete}
      placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-"
      value={internalValue}
      maxLength={11}
    />
  );
};

export default SSNInput;
