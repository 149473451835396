import styled from 'styled-components';
import { Tokens } from 'config';

import { RadioButtonProps } from './types';

const StyledRadioButtonLabel = styled.label<{
  fullWidth?: boolean;
  disabled?: boolean;
  checked?: boolean;
  errorText?: string;
}>(
  ({ fullWidth, disabled, checked, errorText }) =>
    `
align-items: center;
display: flex;
font-family: ${Tokens.font.family};
font-size: ${Tokens.font.size.paragraph.base};
font-weight: ${Tokens.font.weight.normal};
line-height: ${Tokens.lineHeight.paragraph.small};
padding: 11px calc(${Tokens.rhythm} * 1.5);
position: relative;
width: ${fullWidth ? '100%' : 'fit-content'};

${
  checked
    ? `
    border: 1px solid ${Tokens.color.neutral.grey[102]};
    border-radius: 2px;

    .innerCircle {
      background: ${
        disabled
          ? Tokens.color.neutral.grey[240]
          : Tokens.color.neutral.white.base
      };
      border-radius: 50%;
      height: 6px;
      margin-left: 5px;
      position: absolute;
      width: 6px;
    }
  `
    : `
    border: 1px solid ${
      errorText
        ? Tokens.color.ui.error.base
        : Tokens.color.neutral.black.transparent[25]
    };
    border-radius: 2px;`
};

${
  disabled
    ? `
      background-color: ${Tokens.color.neutral.grey[240]};
      border: 1px solid ${Tokens.color.neutral.black.transparent[25]};
      border-radius: 2px;
      color: ${Tokens.color.neutral.grey[82]};
      `
    : `
      background-color: ${Tokens.color.neutral.white.base};
      color: ${Tokens.color.ui.charcoal.base};
      cursor: pointer;

      &:focus {
        border-color: ${Tokens.color.ui.charcoal.base};
        outline: ${Tokens.outline};
      };

      &:hover {
        .outerCircle {
          border: 1px solid ${Tokens.color.neutral.grey[102]};
        };
      };`
};

.outerCircle {
  background: ${checked ? Tokens.color.ui.charcoal.base : 'white'};
  border: 1px solid ${Tokens.color.neutral.black.transparent[25]};
  border-radius: 50%;
  height: calc(${Tokens.rhythm} * 2);
  margin-right: calc(${Tokens.rhythm} * 1.5);
  width: calc(${Tokens.rhythm} * 2);

}`,
);

const StyledRadioButton = styled.input`
  left: -99999px;
  position: absolute;
`;

const RadioButton = ({
  checked,
  disabled,
  errorText,
  fullWidth,
  id,
  labelText,
  name,
  onChange,
}: RadioButtonProps): JSX.Element => (
  <div
    className="radioButtonWrapper"
    style={{
      width: fullWidth ? `calc(100% - ${Tokens.rhythm})` : 'auto',
    }}
    tabIndex={-1}
  >
    <StyledRadioButton
      checked={checked}
      disabled={disabled}
      name={name}
      id={id}
      onChange={onChange}
      type="radio"
      tabIndex={-1}
    />

    <StyledRadioButtonLabel
      checked={checked}
      disabled={disabled}
      errorText={errorText}
      fullWidth={fullWidth}
      htmlFor={id}
      tabIndex={disabled ? -1 : 0}
    >
      <span className="outerCircle"></span>
      <span className="innerCircle"></span>
      {labelText}
    </StyledRadioButtonLabel>
  </div>
);

export default RadioButton;
