import { useEffect, useState } from 'react';

import {
  GetRespondersByNameQuery,
  useGetRespondersByNameQueryLazyQuery,
} from 'generated/graphql';
import UI from 'ui';
import { Option } from 'ui/TypeAhead/TypeAhead';

type Props = {
  initialValue: string | undefined;
  onOptionClick: (arg?: string | number | Record<string, unknown>) => void;
  onReset: () => void;
};

const ResponderSearchBox = ({
  initialValue,
  onOptionClick,
  onReset,
}: Props): JSX.Element => {
  const [options, setOptions] = useState<Option[]>();
  const [queryResults, setQueryResults] =
    useState<GetRespondersByNameQuery | null>(null);

  // Query responders from type ahead search
  const [getResponders, { loading, data }] =
    useGetRespondersByNameQueryLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      setQueryResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (queryResults) {
      setOptions(
        (queryResults.searchResponders ?? []).map((_responder) => ({
          key: `${_responder.userId}`,
          label: `${_responder.firstName} ${_responder.lastName}`,
          value: _responder,
          hint: _responder.email ?? '',
        })),
      );
      return;
    }
    setOptions([]);
  }, [queryResults]);

  const handleInputChange = (query?: string) => {
    setQueryResults(null);
    if (!query) return;
    getResponders({ variables: { nameQuery: query } });
  };

  return (
    <UI.TypeAhead
      id="responder-search"
      className="responderSearch"
      name="responder-search"
      placeholder="Search encounters by Responder's name"
      onInputChange={handleInputChange}
      loading={loading}
      options={options}
      onOptionClick={onOptionClick}
      threshold={2}
      onReset={onReset}
      initialValue={initialValue}
    />
  );
};

export default ResponderSearchBox;
