import { SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { CheckSquare, PencilSimple, Square } from 'phosphor-react';
import { Control } from 'react-hook-form';

import { GetUsersQuery, UserRoleEnum } from 'generated/graphql';

import { Tokens } from 'config';
import UI from 'ui';
import { TInputs, TRoleState } from './utils';

type TProps = {
  control: Control<TInputs>;
  fullName: string;
  onChangeRole: (userRole: UserRoleEnum, addRole?: boolean) => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setRoleState: Dispatch<SetStateAction<TRoleState>>;
  user?: NonNullable<GetUsersQuery['usersList']>[number];
};

const StyledRow = styled.tr`
  transition: background-color 300ms linear;

  &:hover {
    background-color: ${Tokens.color.ui.steel.base};
  }

  .centered {
    .checkboxButton {
      display: inherit;
    }
  }
`;

const UserEmail = styled.div`
  align-items: center;
  display: inline-flex;

  span {
    align-items: center;
    display: inline-flex;
  }

  span + span {
    margin-left: ${Tokens.rhythm};
  }

  svg {
    cursor: pointer;
  }
`;

const Row = ({
  fullName,
  onChangeRole,
  setModalOpen,
  setRoleState,
  user = undefined,
}: Readonly<TProps>): JSX.Element => {
  return user ? (
    <StyledRow>
      <td>
        <UI.Avatar
          children={
            user?.profilePictureUrl ? (
              <img src={user?.profilePictureUrl} />
            ) : undefined
          }
          size="lg"
        />
      </td>
      <td>
        <UserEmail>
          <span>{user.email}</span>
          <span
            onClick={() => {
              setRoleState({
                userRole: user.isClinician ? UserRoleEnum.CLINICIAN : null,
                addRole: false,
              });
              setModalOpen(true);
            }}
          >
            <PencilSimple name="updateRole" color={Tokens.color.ui.link.base} />
          </span>
        </UserEmail>
      </td>
      <td>{fullName}</td>

      <td className="centered">
        <UI.Button
          className="checkboxButton"
          variant="tertiary"
          size="icon"
          onClick={() =>
            user?.isResponder
              ? onChangeRole(UserRoleEnum.RESPONDER)
              : onChangeRole(UserRoleEnum.RESPONDER, true)
          }
        >
          {user?.isResponder ? (
            <CheckSquare
              className="checkedIsResponder"
              color={Tokens.color.ui.charcoal.base}
              size={Tokens.icon.size.base}
              weight="fill"
            />
          ) : (
            <Square
              className="uncheckedIsResponder"
              color={Tokens.color.ui.charcoal.base}
              size={Tokens.icon.size.base}
              weight="light"
            />
          )}
        </UI.Button>
      </td>

      <td className="centered">
        <UI.Button
          className="checkboxButton"
          variant="tertiary"
          size="icon"
          onClick={() =>
            user?.isClinician
              ? onChangeRole(UserRoleEnum.CLINICIAN)
              : onChangeRole(UserRoleEnum.CLINICIAN, true)
          }
        >
          {user?.isClinician ? (
            <CheckSquare
              className="checkedIsClinician"
              color={Tokens.color.ui.charcoal.base}
              size={24}
              weight="fill"
            />
          ) : (
            <Square
              className="uncheckedIsClinician"
              color={Tokens.color.ui.charcoal.base}
              size={24}
              weight="light"
            />
          )}
        </UI.Button>
      </td>

      <td className="centered">
        <UI.Button
          className="checkboxButton"
          variant="tertiary"
          size="icon"
          onClick={() =>
            user?.isSupervisor
              ? onChangeRole(UserRoleEnum.SUPERVISOR)
              : onChangeRole(UserRoleEnum.SUPERVISOR, true)
          }
        >
          {user?.isSupervisor ? (
            <CheckSquare
              className="checkedIsSupervisor"
              color={Tokens.color.ui.charcoal.base}
              size={24}
              weight="fill"
            />
          ) : (
            <Square
              className="uncheckedIsSupervisor"
              color={Tokens.color.ui.charcoal.base}
              size={24}
              weight="light"
            />
          )}
        </UI.Button>
      </td>
    </StyledRow>
  ) : (
    <UI.Spinner />
  );
};

export default Row;
