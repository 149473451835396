import { endOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import {
  Encounter,
  EncounterClinicianStatusConditionEnum,
  EncounterResponderStatusConditionEnum,
  UserRoleEnum,
  useGetEncountersByUserIdQuery,
} from 'generated/graphql';
import { useUserContext, usePagination } from 'hooks';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';

const RESULTS_PER_PAGE = 10;

type ReturnValue = {
  currentPage: number;
  encounters: Encounter[] | undefined;
  handleEncounterClick: (encounter: Encounter) => void;
  handleNextPage: () => void;
  handlePageChange: (pageIndex: number) => void;
  handlePrevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  loading: boolean;
  operatingAs: UserRoleEnum | undefined;
  pageCount: number;
  pageSize: number;
  totalResults: number;
};

const RESPONDER_START_DATE = '2021-07-29';
const CLINICIAN_START_DATE = '2021-11-08';

type TaskListState = {
  encounters: Encounter[] | undefined;
  loading: boolean;
  totalResults: number;
};

const useMyTasks = (): ReturnValue => {
  const history = useHistory();
  const location = useLocation();
  const userContext = useUserContext();

  const operatingAs = userContext?.operatingAs;
  const userId = userContext?.user?.userId;

  const queryParams = new URLSearchParams(location.search);
  const [offset, setOffset] = useState<number>();
  const [taskListState, setTaskListState] = useState<TaskListState>({
    encounters: undefined,
    loading: true,
    totalResults: 0,
  });

  const { encounters, loading, totalResults } = taskListState;

  const maxEndDate = endOfDay(new Date()).toISOString();

  /**
   * Perform Encounter Search
   */
  const { data: encounterData, loading: encountersLoading } =
    useGetEncountersByUserIdQuery({
      variables: {
        ...(operatingAs === UserRoleEnum.CLINICIAN
          ? {
              clinicianIds: userId ? [userId] : undefined,
              clinicianStatus: [
                EncounterClinicianStatusConditionEnum.NOT_STARTED,
                EncounterClinicianStatusConditionEnum.IN_PROGRESS,
              ],
              scheduledForAfter: CLINICIAN_START_DATE,
            }
          : {
              responderId: userId,
              responderStatus: [
                EncounterResponderStatusConditionEnum.NOT_STARTED,
                EncounterResponderStatusConditionEnum.IN_PROGRESS,
              ],
              scheduledForAfter: RESPONDER_START_DATE,
            }),
        scheduledForBefore: maxEndDate,
        offset: offset ?? 0,
        limit: RESULTS_PER_PAGE,
        includeTaskStatuses: true,
      },
      skip: offset === undefined,
    });

  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    pageCount,
    resultsOffset,
    setPage,
  } = usePagination({
    queryParams,
    totalResults,
    resultsPerPage: RESULTS_PER_PAGE,
  });

  // Trigger a search by updating the window's location with search params
  const performSearch = (options?: { push: boolean }) => {
    const path = {
      pathname: location.pathname,
      search: queryParams.toString(),
    };
    if (options?.push) {
      history.push(path);
      return;
    }
    history.replace(path);
  };

  useEffect(() => {
    const items = encounterData?.encounters?.nodes as Encounter[];
    if (items) {
      setTaskListState((state) => ({
        ...state,
        encounters: items,
        totalResults: encounterData?.encountersCount?.totalCount ?? 0,
      }));
    }
  }, [encounterData]);

  useEffect(() => {
    setTaskListState((state) => ({
      ...state,
      loading: encountersLoading,
    }));
  }, [encountersLoading]);

  useEffect(() => {
    setOffset(resultsOffset);
  }, [resultsOffset]);

  const handleEncounterClick = (encounter: Encounter) => {
    const url = generatePath(PatientRoutePaths.encounters, {
      id: `${encounter.patient?.globalPatientId}`,
      encounterId: encounter.encounterId,
    });
    history.push(url, {
      referrer: {
        pageTitle: 'My Tasks',
        pathname: location.pathname,
        search: location.search,
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    performSearch({ push: true });
  };

  const handleNextPage = () => {
    nextPage();
    performSearch({ push: true });
  };

  const handlePrevPage = () => {
    prevPage();
    performSearch({ push: true });
  };

  return {
    currentPage,
    encounters,
    handleEncounterClick,
    handleNextPage,
    handlePageChange,
    handlePrevPage,
    hasNextPage,
    hasPrevPage,
    loading,
    operatingAs,
    pageCount,
    pageSize: RESULTS_PER_PAGE,
    totalResults,
  };
};

export default useMyTasks;
