import { useState, forwardRef } from 'react';

import { TFile } from 'hooks/useFileUpload/types';
import BaseImageInput from './components/BaseImageInput';
import MultiEditModal from './components/MultiEditModal';
import { TMultiInputValue, CompressionOptions } from './types';

export type MultiImageInputProps = {
  /**
   * If `true`, at least one image failed to upload
   */
  anyUploadFailed: boolean;

  /**
   * If `true`, at least one image is uploading
   */
  anyIsUploading: boolean;

  /**
   * Appends an image file
   */
  appendImage: () => void;

  /**
   * The class name
   */
  className?: string;

  /**
   * Clears all failed upload errors
   */
  clearAllUploadFailed: () => void;

  /**
   * The image compression options
   */
  compressOptions?: CompressionOptions;

  /**
   * If `true`, the input is disabled
   */
  disabled?: boolean;

  /**
   * The default description for when there is no image group title
   */
  defaultDescription: string;

  /**
   * An error message
   */
  errorText?: string;

  /**
   * If `true`, the input's width expands to 100% of its container width
   */
  fullWidth?: boolean;

  /**
   * The input's id
   */
  id: string;

  /**
   * An array of image objects
   */
  imageFiles: TFile[];

  /**
   * An input label
   */
  label?: string;

  /**
   * Handles the delete input action
   */
  onDelete: () => void;

  /**
   * Handles the input change event
   */
  onInputChange: (value: TMultiInputValue) => void;

  /**
   * Opens the edit modal on component render
   */
  openEditOnRender?: boolean;

  /**
   * If `true` the field is not required
   */
  optional?: boolean;

  /**
   * A `data-testid` string
   */
  testId?: string;

  /**
   * An array of type options
   */
  typeOptions: Array<{
    label: string;
    value: string;
  }>;

  /**
   * The input value
   */
  value: TMultiInputValue | null;
};

const MultiImageInput = forwardRef<HTMLInputElement, MultiImageInputProps>(
  (
    {
      anyIsUploading,
      anyUploadFailed,
      appendImage,
      className,
      clearAllUploadFailed,
      compressOptions,
      defaultDescription,
      disabled = false,
      errorText,
      fullWidth = false,
      id,
      imageFiles,
      label,
      onDelete,
      onInputChange,
      openEditOnRender = false,
      optional = false,
      testId,
      typeOptions,
      value,
    },
    inputRef,
  ): JSX.Element => {
    const [editModalOpen, setEditModalOpen] = useState(openEditOnRender);
    const description = value?.description
      ? value.description
      : defaultDescription;

    const defaultFormValues = value ?? {
      description: '',
      type: '',
      images: [],
    };

    const getTypeLabel = () => {
      if (value?.type) {
        const opt = (typeOptions ?? []).find(
          (option) => option.value === value?.type,
        );
        return opt?.label;
      }
      return undefined;
    };

    const filteredImageFiles = (value?.images ?? []).reduce(
      (prev, image, index) => {
        if (image.isDeleted) {
          return prev;
        }
        return [...prev, imageFiles[index]];
      },
      [] as TFile[],
    );

    const handleSave = (data: TMultiInputValue) => {
      onInputChange(data);
      setEditModalOpen(false);
    };

    return (
      <>
        <BaseImageInput
          className={className}
          description={description}
          disabled={disabled}
          errorText={errorText}
          fullWidth={fullWidth}
          id={id}
          images={filteredImageFiles}
          imageCount={filteredImageFiles.length}
          imageType={getTypeLabel()}
          inputRef={inputRef}
          isUploading={anyIsUploading}
          label={label}
          onDelete={onDelete}
          onMultiUploadButtonClick={() => setEditModalOpen(true)}
          optional={optional}
          testId={testId}
          type="multi"
          uploadFailed={anyUploadFailed}
          value={value}
        />
        <MultiEditModal
          anyUploadFailed={anyUploadFailed}
          anyIsUploading={anyIsUploading}
          appendFile={appendImage}
          clearAllUploadFailed={clearAllUploadFailed}
          compressOptions={compressOptions}
          defaultValues={defaultFormValues}
          files={imageFiles}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSave}
          testId={testId ? `${testId}__editModal` : undefined}
          typeOptions={typeOptions}
        />
      </>
    );
  },
);

export default MultiImageInput;
