import { TypePolicy, FieldPolicy } from '@apollo/client';
import { format as formatDate, differenceInYears } from 'date-fns';
import { formatDateString } from 'utils';

import PhoneUtils from 'utils/phone';
import { Enums } from 'utils';
import {
  CommunicationNeedEnum,
  GenderIdentityEnum,
  LanguagesEnum,
  Patient,
  PatientSuffixEnum,
} from 'generated/graphql';

const PatientTypePolicy: TypePolicy = {
  keyFields: ['patientId'],
  fields: {
    // Adds new @client field `addressLine1`
    addressLine1: {
      read(value, { readField }) {
        const address1 = readField<Patient['address1']>('address1');
        const address2 = readField<Patient['address2']>('address2');
        const unit = readField<Patient['unit']>('unit');

        return `${address1 ?? '-'}${address2 ? `, ${address2}` : ''}${
          unit ? `, ${unit}` : ''
        }`;
      },
    } as FieldPolicy,

    // Adds new @client field `addressLine2`
    addressLine2: {
      read(value, { readField }) {
        const city = readField<Patient['city']>('city');
        const state = readField<Patient['state']>('state');
        const zip = readField<Patient['zip']>('zip');

        return `${city ? `${city},` : ''} ${state ?? ''} ${zip ?? ''}`;
      },
    } as FieldPolicy,

    // Adds new @client field `age`
    age: {
      read(value, { readField }) {
        const dob = readField<Patient['dob']>('dob');

        if (dob) {
          return differenceInYears(new Date(), new Date(dob));
        }

        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `patientNotesCreatedAt` with date/time formatting and timezone offset
    patientNotesCreatedAt: {
      read(value, { readField }) {
        const patientNotesList =
          readField<Patient['patientNotesList']>('patientNotesList');

        if (patientNotesList && patientNotesList?.length > 0) {
          const date = new Date(patientNotesList[0].createdAt);

          return formatDate(date, 'MMMM dd, yyyy @ h:mmaaa');
        }

        return null;
      },
    } as FieldPolicy,

    // Formats DOB as MM/DD/YYYY
    dob: {
      read(value) {
        if (value) {
          return formatDateString(value);
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `fullName`
    fullName: {
      read(value, { readField }) {
        const prefix = readField<Patient['prefix']>('prefix');
        const first = readField<Patient['firstName']>('firstName');
        const middleName = readField<Patient['middleName']>('middleName');
        const last = readField<Patient['lastName']>('lastName');
        const suffix = readField<Patient['suffix']>('suffix');

        return `${prefix ?? ''} ${first ?? ''} ${middleName ?? ''} ${
          last ?? ''
        } ${
          suffix &&
          [PatientSuffixEnum.JR, PatientSuffixEnum.SR].includes(suffix)
            ? Enums.titleCase(suffix)
            : suffix ?? ''
        }`;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedCommunicationNeeds` and humanizes existing field `communicationNeeds`
    humanizedCommunicationNeed: {
      read(value, { readField }) {
        const needs =
          readField<Patient['communicationNeed']>('communicationNeed');
        if (needs) {
          return needs?.map((need) => {
            if (need === CommunicationNeedEnum.TRANSLATOR_INTERPRETER) {
              return Enums.addForwardSlash(need);
            }
            return Enums.humanize(need ?? '');
          });
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedGenderIdentity` and humanizes existing field `genderIdentity`
    humanizedGenderIdentity: {
      read(value, { readField }) {
        const genderIdentity =
          readField<Patient['genderIdentity']>('genderIdentity');
        if (genderIdentity) {
          if (
            genderIdentity === GenderIdentityEnum.NON_DISCLOSE ||
            genderIdentity === GenderIdentityEnum.NON_BINARY
          ) {
            return Enums.addDash(genderIdentity);
          }
          return Enums.humanize(genderIdentity);
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedGenderPronoun` and humanizes existing field `genderPronoun`
    humanizedGenderPronoun: {
      read(value, { readField }) {
        const genderPronoun =
          readField<Patient['genderPronoun']>('genderPronoun');
        if (genderPronoun) {
          return Enums.addForwardSlash(genderPronoun);
        }
        return null;
      },
    } as FieldPolicy,

    // Formats existing field `preferredPhoneNumber`
    preferredPhoneNumber: {
      read(value) {
        if (value && value.length === 12) {
          return PhoneUtils.formatE164ToUSPhoneNumber(value);
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedRaces` and humanizes existing field `races`
    humanizedRaces: {
      read(value, { readField }) {
        const races = readField<Patient['races']>('races');
        if (races) {
          return races?.map((race) => Enums.humanize(race ?? ''));
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedSex` and humanizes existing field `sex`
    humanizedSex: {
      read(value, { readField }) {
        const sex = readField<Patient['sex']>('sex');
        if (sex) {
          return Enums.humanize(sex);
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedNoSsnReason` and humanizes existing field `noSsnReason`
    humanizedNoSsnReason: {
      read(value, { readField }) {
        const reasons = readField<Patient['noSsnReason']>('noSsnReason');
        if (reasons) {
          return reasons?.map((reason) => Enums.humanize(reason ?? ''));
        }
        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedLanguages` and humanizes existing field `languages`
    humanizedLanguages: {
      read(value, { readField }) {
        const languages = readField<Patient['languages']>('languages');

        const humanize = (language: LanguagesEnum) => {
          switch (language) {
            case LanguagesEnum.KRU:
              return 'Kru (Bassa)';
            case LanguagesEnum.MONKHMER_CAMBODIAN:
              return 'Mon-Khmer, Cambodian';
            case LanguagesEnum.PERSIAN:
              return 'Persian (Farsi)';
            case LanguagesEnum.HAITIAN_CREOLE:
              return Enums.humanize(language!);
            default:
              return Enums.titleCase(language!);
          }
        };

        return (languages ?? [])
          .filter((language) => !!language) // filter out null values
          .map((language) => humanize(language!));
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedGlobalHumanId` and humanizes existing field `sex`
    humanizedGlobalHumanId: {
      read(value, { readField }) {
        const globalHumanId =
          readField<Patient['globalHumanId']>('globalHumanId');
        if (globalHumanId) {
          return globalHumanId.toUpperCase();
        }
        return null;
      },
    } as FieldPolicy,
  },
};

export default PatientTypePolicy;
