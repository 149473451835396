import {
  useUnclaimEncounterMutation,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';

import UI from 'ui';
import {
  TModalState,
  TSetModalState,
} from '../Patient/components/Encounters/types';

const UnclaimEncounterModal = ({
  encounter,
  setModalState,
  modalState,
}: {
  encounter?: NonNullable<
    GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
  >['encounter'];
  setModalState: TSetModalState;
  modalState: TModalState;
}): JSX.Element => {
  const [unclaimEncounter] = useUnclaimEncounterMutation();

  const handleUnclaimClick = async () => {
    try {
      await unclaimEncounter({
        variables: { id: encounter?.encounterId as number },
      });
    } catch {
      return;
    }
    setModalState({
      selectedType: null,
      isOpen: false,
    });
  };

  return (
    <UI.Modal
      body="Are you sure you want to unclaim this encounter?"
      closeButtonLabel="Keep encounter"
      onClose={() => {
        setModalState({
          selectedType: null,
          isOpen: false,
        });
      }}
      title={'Unclaim encounter'}
      size="small"
      open={modalState.isOpen}
      footerButtons={[
        {
          name: 'unclaimEncounterButton',
          children: 'Unclaim encounter',
          variant: 'primary',
          size: 'small',
          onClick: handleUnclaimClick,
        },
      ]}
    />
  );
};

export default UnclaimEncounterModal;
