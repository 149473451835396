import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Tokens } from 'config';
import { useEffect } from 'react';

export type TooltipProps = {
  label: string;
};

export type TooltipRefProps = {
  show: () => void;
  hide: () => void;
};

const StyledTooltip = styled.div`
  background-color: ${Tokens.color.ui.charcoal.base};
  border-radius: ${Tokens.border.radius};
  color: ${Tokens.color.neutral.white.base};
  font-size: ${Tokens.font.size.paragraph.base};
  padding: 2px 4px;
  position: fixed;
  visibility: hidden;
`;

const Tooltip = ({ label }: TooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const mouseX = `${event.clientX}px`;
      const mouseY = `${event.clientY}px`;

      if (tooltipRef.current) {
        const tooltipWidth = tooltipRef.current.offsetWidth * 0.5;
        tooltipRef.current.style.top = `calc(${mouseY} + (2 * ${Tokens.rhythm}))`;
        tooltipRef.current.style.left = `calc(${mouseX} - ${tooltipWidth}px)`;
        tooltipRef.current.style.visibility = 'visible';
      }
    };

    window.addEventListener('mousemove', handler);

    return () => window.removeEventListener('mousemove', handler);
  }, []);

  return <StyledTooltip ref={tooltipRef}>{label}</StyledTooltip>;
};

const appRoot = document.getElementById('root');

const TooltipWrapper = forwardRef<TooltipRefProps, TooltipProps>(
  ({ label }, ref): JSX.Element | null => {
    const portalRef = useRef(document.createElement('div'));
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      appRoot?.appendChild(portalRef.current);

      return () => {
        if (appRoot?.children.length) {
          appRoot?.removeChild(portalRef.current);
        }
      };
    }, []);

    useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true);
      },
      hide: () => {
        setVisible(false);
      },
    }));

    return createPortal(
      visible ? <Tooltip label={label} /> : null,
      portalRef.current,
    );
  },
);

export default TooltipWrapper;
