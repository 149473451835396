import { useState } from 'react';
import shortid from 'shortid';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import MedicalConditionsForm from './MedicalConditionsForm';
import { TInputs, Schema, getDefaultValues, getPatch } from './utils';

const MedicalConditionsFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const [dateValue, setDateValue] = useState<Date | undefined>(undefined);
  const [titleIsEmpty, setTitleIsEmpty] = useState<boolean>(false);
  const [problemTextValue, setProblemTextValue] = useState('');

  const {
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    defaultValues: getDefaultValues(task),
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatch(data),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  const addMedicalCondition = () => {
    const updatedValues = [...(formState?.patientProblemList ?? [])];

    // If no problem text return error state
    if (problemTextValue.length < 1) {
      setTitleIsEmpty(true);
      return;
    }

    updatedValues.push({
      isDeleted: false,
      key: shortid.generate(),
      patientProblemId: undefined,
      problemText: problemTextValue,
      startedAt: dateValue,
    });

    setValue('patientProblemList', updatedValues, {
      shouldDirty: true,
    });

    // Reset form state
    setProblemTextValue('');
    setDateValue(undefined);
    setTitleIsEmpty(false);
  };

  const deleteMedicalCondition = (key?: string) => {
    if (!key) return;

    const index = formState?.patientProblemList?.findIndex(
      (item) => item.key === key,
    );

    if (typeof index === 'undefined') return;

    // Set existing item isDeleted to true
    setValue(`patientProblemList.${index}.isDeleted`, true, {
      shouldDirty: true,
    });
  };

  return (
    <MedicalConditionsForm
      addMedicalCondition={addMedicalCondition}
      deleteMedicalCondition={deleteMedicalCondition}
      titleIsEmpty={titleIsEmpty}
      formState={formState}
      dateValue={dateValue}
      inputsAreDisabled={inputsAreDisabled}
      problemTextValue={problemTextValue}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
      setDateValue={setDateValue}
      setProblemTextValue={setProblemTextValue}
    />
  );
};

export default MedicalConditionsFormContainer;
