import {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import {
  useGetCurrentUserQuery,
  GetCurrentUserQuery,
  UserRoleEnum,
} from 'generated/graphql';

import { Config } from 'config';
import { Analytics } from 'utils';

export type CurrentUser = GetCurrentUserQuery['getCurrentUser'];
export type UserContextWithRefetch = {
  user: CurrentUser;
  isLoading: boolean;
  hasError?: ApolloError;
  operatingAs?: UserRoleEnum;
  operatingAsClinician?: boolean;
  operatingAsResponder?: boolean;
  operatingAsSupervisor?: boolean;
  refetchUser: Dispatch<SetStateAction<boolean>>;
  setOperatingAs: Dispatch<SetStateAction<UserRoleEnum | undefined>>;
} | null;
type Props = { children: React.ReactNode };

export const UserContext = createContext<UserContextWithRefetch>(null);

export const LAST_SELECTED_ROLE_KEY = 'LAST_SELECTED_ROLE';

export const UserContextProvider = ({
  children,
}: Readonly<Props>): JSX.Element => {
  const [user, setUser] = useState<CurrentUser>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<ApolloError | undefined>(undefined);
  const [refetchUser, setRefetchUser] = useState<boolean>(false);
  const [operatingAs, setOperatingAs] = useState<UserRoleEnum | undefined>(
    undefined,
  );
  const [operatingAsClinician, setOperatingAsClinician] =
    useState<boolean>(false);
  const [operatingAsResponder, setOperatingAsResponder] =
    useState<boolean>(false);
  const [operatingAsSupervisor, setOperatingAsSupervisor] =
    useState<boolean>(false);

  const context = {
    user,
    hasError,
    isLoading,
    operatingAs,
    operatingAsClinician,
    operatingAsResponder,
    operatingAsSupervisor,
    setOperatingAs,
    setOperatingAsClinician,
    setOperatingAsResponder,
    setOperatingAsSupervisor,
    refetchUser: setRefetchUser,
  };

  const { data, error, loading, refetch } = useGetCurrentUserQuery();

  useEffect(() => {
    if (user?.email) {
      Sentry.setUser({ email: user.email });
      Analytics.identify(user.email);
      Analytics.people.set({
        $avatar: user?.profilePictureUrl,
        $email: user.email,
        $first_name: user?.firstName,
        $last_name: user?.lastName,
        'App Version': Config.version,
        Roles: user?.roles,
      });
      Analytics.register({ 'App Version': Config.version });
    }
  }, [user]);

  useEffect(() => {
    if (data && data.getCurrentUser) {
      const lastSelectedRole = localStorage.getItem(
        LAST_SELECTED_ROLE_KEY,
      ) as UserRoleEnum;

      setUser(data.getCurrentUser);
      setOperatingAs(
        (state) =>
          (lastSelectedRole && UserRoleEnum[lastSelectedRole]) ??
          state ??
          data?.getCurrentUser?.roles[0] ??
          undefined,
      );
    }
  }, [data]);

  useEffect(() => {
    Analytics.register({ Role: operatingAs });
    Analytics.people.set({ Role: operatingAs });

    setOperatingAsClinician(operatingAs === UserRoleEnum.CLINICIAN);
    setOperatingAsResponder(operatingAs === UserRoleEnum.RESPONDER);
    setOperatingAsSupervisor(operatingAs === UserRoleEnum.SUPERVISOR);
  }, [operatingAs]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  useEffect(() => {
    if (refetchUser) {
      refetch();
      setRefetchUser(false);
    }
  }, [refetchUser]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
