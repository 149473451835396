/**
 * Converts a date string "YYYY-DD-MM" to "MM/DD/YYYY"
 * @param dateString string
 */
const formatDateString = (dateString: string): string => {
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  const year = dateString.substring(0, 4);
  return `${month}/${day}/${year}`;
};

export default formatDateString;
