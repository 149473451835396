import {
  useController,
  Control,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import RadioButtonGroup from 'ui/RadioButton/RadioButtonGroup';
import RadioButton from 'ui/RadioButton/RadioButton';
import { RadioButtonGroupProps } from 'ui/RadioButton/types';

/**
 * Controlled RadioButtonGroup Props
 * @extends {UI.RadioButtonGroup}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<
  RadioButtonGroupProps,
  'name' | 'id' | 'children' | 'value' | 'onClickRadioButton'
> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;

  /**
   * Array of 2 strings that represent [true, false] values.
   */
  options: string[];
};

const ControlledRadioButtonGroup = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  name,
  options,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  const handleChange = (selectedValue?: string) =>
    selectedValue?.includes(options[0]) ? onChange(true) : onChange(false);

  const getValueString = (booleanValue: boolean): string | undefined => {
    if (typeof booleanValue !== 'boolean') return undefined;
    return booleanValue === true ? name + options[0] : name + options[1];
  };

  return (
    <RadioButtonGroup
      errorText={error ? error.message : undefined}
      id={name}
      selectedValue={getValueString(value)}
      fullWidth
      onClickRadioButton={handleChange}
      {...props}
    >
      {options.map((option, i) => (
        <RadioButton
          key={option}
          labelText={option}
          name={name}
          id={name + option}
          checked={(i === 0 && value === true) || (i === 1 && value === false)}
        />
      ))}
    </RadioButtonGroup>
  );
};

export default ControlledRadioButtonGroup;
