import { TypePolicy, FieldPolicy } from '@apollo/client';
import { format as formatDate } from 'date-fns';
import { PatientNote } from 'generated/graphql';

const PatientNoteTypePolicy: TypePolicy = {
  keyFields: ['patientNoteId'],
  fields: {
    // Adds new @client field `formattedCreatedAt` with date/time formatting and timezone offset
    formattedCreatedAt: {
      read(value, { readField }) {
        const createdAt = readField<PatientNote['createdAt']>('createdAt');

        if (createdAt) {
          const date = new Date(createdAt);
          return formatDate(date, 'MMMM dd, yyyy @ h:mmaaa');
        }

        return null;
      },
    } as FieldPolicy,
  },
};

export default PatientNoteTypePolicy;
