import { SetStateAction, useEffect, useState } from 'react';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  ModuleNameEnum,
} from 'generated/graphql';
import {
  useDidMountEffect,
  usePersistTask,
  useTask,
  useUserContext,
} from 'hooks';
import { useForm } from 'forms';
import DispositionForm from './DispositionForm';
import { TInputs, Schema, getDefaultValues, getPatch } from './utils';
import { TModalState } from '../Encounters/types';

const DispositionFormContainer = ({
  task,
}: {
  task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>;
}): JSX.Element => {
  const userContext = useUserContext();
  const defaultValues = getDefaultValues(task);
  const [hasEMSModuleType, setHasEMSModuleType] =
    useState<SetStateAction<undefined | boolean>>(undefined);
  const [modalState, setModalState] = useState<TModalState>({
    selectedType: null,
    isOpen: false,
  });

  const formTitle = task?.taskName;
  const isClinicianModuleType =
    task?.encounter?.modules?.includes(ModuleNameEnum.CLINICIAN) ||
    task?.encounter?.modules?.includes(ModuleNameEnum.CLINICIAN_ACTIVATION);

  const {
    control,
    clearErrors,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    defaultValues,
    reValidateMode: 'onChange',
    context: { hasEMSModuleType },
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatch(data),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  useEffect(() => {
    setHasEMSModuleType(
      task?.encounter?.modules?.includes(ModuleNameEnum.EMS_ACTIVATION),
    );
  }, [task?.encounter?.modules]);

  useEffect(() => {
    if (hasEMSModuleType) {
      clearErrors(); //Clear EMS activation error
    }
  }, [hasEMSModuleType]);

  useEffect(() => {
    if (!isClinicianModuleType && !formState.clinicianIncluded) {
      setValue('clinicianIncluded', false);
    }
  }, [isClinicianModuleType]);

  useDidMountEffect(() => {
    if (isClinicianModuleType && formState.clinicianIncluded === false) {
      setValue('clinicianIncluded', null);
    }
  }, [isClinicianModuleType]);

  useDidMountEffect(() => {
    setValue('disposition', null);
    clearErrors();
  }, [formState.clinicianIncluded]);

  useDidMountEffect(() => {
    setValue('activation', null);
    clearErrors();
  }, [formState.disposition]);

  const patientInstructionsDisabled = !userContext?.operatingAsClinician;

  return (
    <>
      <DispositionForm
        control={control}
        errors={errors}
        encounter={task?.encounter}
        formState={formState}
        formTitle={formTitle}
        inputsAreDisabled={inputsAreDisabled}
        isClinicianModuleType={isClinicianModuleType}
        modalState={modalState}
        operatingAsClinician={!!userContext?.operatingAsClinician}
        patientInstructionsDisabled={patientInstructionsDisabled}
        setModalState={setModalState}
        setValue={setValue}
        taskHeaderProps={{
          task,
          isDirty,
          isSaving,
          isValidating,
          onTaskSave: handleSaveTask,
          onTaskSkip: handleSkipTask,
          onTaskComplete: handleCompleteTask,
        }}
      />
    </>
  );
};

export default DispositionFormContainer;
