import mixpanel from 'mixpanel-browser';
import { Config, Environment } from 'config';

const enabled = [
  Environment.Development,
  Environment.Staging,
  Environment.Production,
].includes(Config.environment);

if (enabled) {
  mixpanel.init(Config.mixpanelToken);
}

const analytics = {
  /**
   * If `true`, Analytics are enabled.
   */
  enabled,
  /**
   * The alias method creates an alias which Mixpanel will use
   * to remap one id to another
   * @param alias A unique identifier that you want to use for this
   * user in the future.
   * @param original The current identifier being used for this user
   */
  alias: (alias: string, original?: string): void => {
    if (enabled) mixpanel.alias(alias, original);
  },
  /**
   * Identify a user with a unique ID to track user activity
   * across devices, tie a user to their events, and create
   * a user profile.
   * @param id A string that uniquely identifies a user
   */
  identify: (id: string): void => {
    if (enabled) mixpanel.identify(id);
  },
  /**
   * Register a set of super properties, which are included
   * with all events.
   * @param props An associative array of properties to store
   * about the user
   */
  register: (props: Record<string, unknown>): void => {
    if (enabled) mixpanel.register(props);
  },
  /**
   * Clears super properties and generates a new random
   * distinct_id for this instance
   */
  reset: (): void => {
    if (enabled) mixpanel.reset();
  },
  /**
   * Time an event by including the time between this
   * call and a later 'track' call for the same event in the
   * properties sent with the event. Note that each call to
   * time_event for the same event name restarts the start timer.
   * @param event The name of the event
   */
  timeEvent: (event: string): void => {
    if (enabled) mixpanel.time_event(event);
  },
  /**
   * Track an event.
   * @param event The name of the event
   * @param props A set of properties to include with the event
   * @param callback If provided, the callback function will be
   * called after tracking the event.
   */
  track: (
    event: string,
    props?: Record<string, unknown>,
    callback?: () => void,
  ): void => {
    if (enabled) mixpanel.track(event, props, callback);
  },
  people: {
    /**
     * Append a value to a list-valued people analytics property.
     * @param prop The name of the property.
     * @param value An item to append to the list
     */
    append: (
      prop: string,
      value: string | number | Record<string, unknown>,
    ): void => {
      if (enabled) mixpanel.people.append(prop, value);
    },
    /**
     * Increment/decrement numeric people analytics properties.
     * @param prop The name of the property.
     * @param by An amount to increment the given property
     */
    increment: (prop: string, by: number): void => {
      if (enabled) mixpanel.people.increment(prop, by);
    },
    /**
     * Set properties on a user record.
     * @param props An associative array of names and values
     */
    set: (props: Record<string, unknown>): void => {
      if (enabled) mixpanel.people.set(props);
    },
  },
};

export default analytics;
