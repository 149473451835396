import styled from 'styled-components';

import { Tokens } from 'config';

const StyledTableRow = styled.tr<React.HTMLAttributes<HTMLTableRowElement>>`
  &.hoverable {
    &:hover {
      background-color: ${Tokens.color.neutral.grey[250]};
      cursor: pointer;
    }
  }
`;

const TableRow = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLTableRowElement>): JSX.Element => {
  const classNames = [
    ...(props.onClick ? ['hoverable'] : []),
    ...(props.className ? [props.className] : []),
  ];
  return (
    <StyledTableRow {...props} className={classNames.join(' ')}>
      {children}
    </StyledTableRow>
  );
};

export default TableRow;
