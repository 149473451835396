import styled from 'styled-components';

import { Tokens } from 'config';

const StyledLayout = styled.div`
  font-family: ${Tokens.font.family};

  h2 {
    color: ${Tokens.color.ui.charcoal.base};
  }

  & > .sidebar {
    --width: 244px;

    background-color: ${Tokens.color.ui.charcoal.base};
    bottom: 0;
    left: calc(-1 * var(--width));
    position: fixed;
    right: 100%;
    top: 0;
    transition-property: left right;
    transition-duration: ${Tokens.transition.duration[3]};
    transition-timing-function: ${Tokens.transition.timing.easeIn};
    width: var(--width);
    z-index: ${Tokens.zIndex.sidebar};

    &.visible {
      left: 0;
      right: calc(100% - var(--width));
      transition-timing-function: ${Tokens.transition.timing.easeOut};
    }
  }

  & > .overlay {
    background-color: ${Tokens.color.neutral.black.transparent[25]};
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    width: var(--width);
    z-index: ${Tokens.zIndex.overlay};

    &.visible {
      visibility: visible;
    }
  }

  & > main {
    & > header {
      background-color: ${Tokens.color.ui.steel.base};
      color: ${Tokens.color.ui.charcoal.base};
      display: flex;
      font-size: ${Tokens.font.size.paragraph.small};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: 1;
      padding: calc(${Tokens.rhythm} * 2);
      align-items: center;

      & > * + * {
        margin-left: calc(${Tokens.rhythm} * 2);
      }

      .grow {
        flex-grow: 1;
      }
    }

    & > .content {
      background-color: ${Tokens.color.ui.steel.base};
      display: flex;
      flex-direction: column;
      font-size: ${Tokens.font.size.paragraph.base};
      font-weight: ${Tokens.font.weight.normal};
      height: calc(100vh - 64px); // Subtract height of nav header
      max-height: 100%;
      line-height: ${Tokens.lineHeight.paragraph.base};

      & > * {
        flex-grow: 1;
      }
    }
  }
`;

export default StyledLayout;
