export const bodyPositionOptions = [
  {
    label: 'Sitting',
    value: 'Sitting',
  },
  {
    label: 'Laying',
    value: 'Laying',
  },
  {
    label: 'Standing',
    value: 'Standing',
  },
];

export const bloodPressureLocationOptions = [
  {
    label: 'Left Arm',
    value: 'Left Arm',
  },
  {
    label: 'Right Arm',
    value: 'Right Arm',
  },
  {
    label: 'Left Leg',
    value: 'Left Leg',
  },
  {
    label: 'Right Leg',
    value: 'Right Leg',
  },
];
