import { ChangeEvent, useState, useEffect } from 'react';
import Input from '../Input/Input';
import { InputProps } from '../Input/types';

type Props = Omit<InputProps, 'value' | 'onChange' | 'placeholder'> & {
  /* Function that returns the formatted phoneNumber with dashes. */
  onUpdate?: (value: string) => void;
  /* Existing value of phoneNumber to display in edit form */
  phoneNumber?: string;
};

const PhoneNumberInput = (props: Props): JSX.Element => {
  const { onUpdate = () => {}, phoneNumber, ...rest } = props;
  const [internalValue, setInternalValue] = useState<string>('');

  const formatValue = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    value = value.substr(0, 10);

    if (value.length >= 4) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    }
    if (value.length >= 8) {
      value = `${value.slice(0, 7)}-${value.slice(7)}`;
    }

    setInternalValue(value);
  };

  useEffect(() => {
    if (phoneNumber) setInternalValue(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    onUpdate(`+1${internalValue.replace(/[-]/g, '')}`);
  }, [internalValue]);

  return (
    <Input
      {...rest}
      onChange={formatValue}
      placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-"
      value={internalValue}
      maxLength={12}
    />
  );
};

export default PhoneNumberInput;
