import { memo, Dispatch, SetStateAction, useEffect } from 'react';
import { CaretDown, CaretUp } from 'phosphor-react';
import styled from 'styled-components';

import { UserRoleEnum } from 'generated/graphql';

import { Tokens, Config } from 'config';
import { Enums } from 'utils';
import {
  CurrentUser,
  LAST_SELECTED_ROLE_KEY,
} from 'providers/UserContextProvider';
import UI from 'ui';

export type Props = {
  logout: () => void;
  operatingAs?: UserRoleEnum;
  overlayIsVisible: boolean;
  setOperatingAs?: Dispatch<SetStateAction<UserRoleEnum | undefined>>;
  setOverlayIsVisible: Dispatch<SetStateAction<boolean>>;
  sidebarIsOpen?: boolean;
  user?: Partial<CurrentUser>;
};

const StyledFooter = styled.div`
  & > header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: calc(${Tokens.rhythm} * 2);
    top: ${Tokens.rhythm};

    &:hover {
      background-color: ${Tokens.color.neutral.black.transparent[10]};
    }

    .user {
      align-items: center;
      display: flex;

      .details {
        padding-bottom: 8px;
        p {
          font-weight: ${Tokens.font.weight.normal};
          margin: 0;
          padding: 0 0 0 calc(${Tokens.rhythm} * 2);
        }

        p.small {
          color: ${Tokens.color.neutral.grey[191]};
          font-size: ${Tokens.font.size.paragraph.small};
        }
      }
    }
  }

  & > .actions {
    & > ul {
      border-top: 1px solid ${Tokens.color.neutral.white.transparent[10]};
      list-style: none;
      margin: 0 ${Tokens.rhythm};
      padding: ${Tokens.rhythm} 0;

      li {
        border-radius: 2px;
        color: ${Tokens.color.neutral.white.base};
        cursor: pointer;
        display: block;
        font-size: ${Tokens.font.size.paragraph.small};
        padding: ${Tokens.rhythm};
        text-decoration: none;

        &:hover {
          background-color: ${Tokens.color.neutral.white.transparent[10]};
        }
      }
    }
  }

  .version {
    bottom: 0;
    color: ${Tokens.color.neutral.grey[191]};
    font-size: ${Tokens.font.size.paragraph.small};
    margin-bottom: ${Tokens.rhythm};
    opacity: 0.5;
    padding-left: calc(${Tokens.rhythm} * 8);
    position: absolute;
  }
`;

const Footer = ({
  logout = () => {},
  operatingAs = undefined,
  overlayIsVisible,
  setOperatingAs = () => {},
  setOverlayIsVisible,
  sidebarIsOpen = false,
  user = {},
}: Readonly<Props>): JSX.Element => {
  useEffect(() => {
    if (!sidebarIsOpen) setOverlayIsVisible(false);
  }, [sidebarIsOpen]);

  const handleRoleClick = (role: UserRoleEnum) => {
    setOperatingAs(role);
    localStorage.setItem(LAST_SELECTED_ROLE_KEY, role);
  };

  return (
    <StyledFooter>
      <header onClick={() => setOverlayIsVisible((state) => !state)}>
        <div className="user">
          <UI.Avatar
            children={
              user?.profilePictureUrl ? (
                <img src={user?.profilePictureUrl} />
              ) : undefined
            }
            size="lg"
          />

          <div className="details">
            <p>
              {user?.firstName ?? ''} {user?.lastName ?? ''}
            </p>
            <p className="small">
              {operatingAs ? Enums.humanize(operatingAs) : ''}
            </p>
          </div>
        </div>

        {overlayIsVisible ? (
          <CaretDown color={Tokens.color.neutral.white.base} />
        ) : (
          <CaretUp color={Tokens.color.neutral.white.base} />
        )}
      </header>

      {overlayIsVisible ? (
        <div className="actions">
          {user?.roles && user?.roles.length > 1 ? (
            <ul>
              {user?.roles?.map(
                (role) =>
                  role !== operatingAs && (
                    <li key={role} onClick={() => handleRoleClick(role)}>
                      Switch to {Enums.humanize(role)}
                    </li>
                  ),
              )}
            </ul>
          ) : null}

          <ul>
            <li onClick={logout}>Log out</li>
          </ul>
        </div>
      ) : null}
      {!overlayIsVisible && <div className="version">{Config.version}</div>}
    </StyledFooter>
  );
};

export default memo(Footer);
