import { X } from 'phosphor-react';
import shortid from 'shortid';
import styled from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Tokens } from 'config';
import Button from 'ui/Button/Button';
import { ButtonProps } from 'ui/Button/types';

export type ModalSize = 'small' | 'medium' | 'large' | 'fullscreen';

export type ModalProps = {
  /**
   * The modal body's content.
   */
  body: ReactNode;

  /**
   * A custom close button label
   */
  closeButtonLabel?: string;

  /**
   * An array of `<UI.Button />` props to render buttons in footer.
   */
  footerButtons?: ButtonProps[];

  /**
   * Handles the modal close function.
   */
  onClose: () => void;

  /**
   * If `true`, the modal is open.
   */
  open: boolean;

  /**
   * The modal size.
   */
  size: ModalSize;

  /**
   * The `data-testid`
   */
  testId?: string;

  /**
   * The modal title in the header.
   */
  title: string | ReactNode;
};

const ModalContainer = styled.div<{ size: ModalSize }>(({ size }) => {
  const maxWidth: number | string = (() => {
    switch (size) {
      case 'medium':
        return 768;
      case 'large':
        return 990;
      case 'fullscreen':
        return '100%';
      case 'small':
      default:
        return 512;
    }
  })();

  const isFullScreen = size === 'fullscreen';

  return `
    ${isFullScreen ? 'display: flex;' : ''}
    ${isFullScreen ? 'flex-direction: column;' : ''}
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: ${Tokens.zIndex.modal};

    @keyframes backgroundFadeIn {
      0% { background-color: ${Tokens.color.neutral.black.transparent[0]} };
      100% { background-color: ${Tokens.color.neutral.black.transparent[75]} };
    }

    @keyframes backgroundFadeOut {
      0% { background-color: ${Tokens.color.neutral.black.transparent[75]} };
      25% { background-color: ${Tokens.color.neutral.black.transparent[75]} };
      100% { background-color: ${Tokens.color.neutral.black.transparent[0]} };
    }

    @keyframes modalFadeIn {
      0% {
        opacity: 0;
        transform: scale(.8);
      }
      60% {
        opacity: 0;
        transform: scale(.8);
      }
      100% {
        opacity: 1
        transform: scale(1);
      }
    }

    @keyframes modalFadeOut {
      0% {
        opacity: 1
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(.8);
      }
    }

    .modalBackdrop {
      animation-name: backgroundFadeIn;
      animation-duration: ${Tokens.transition.duration[3]};
      animation-timing-function: ${Tokens.transition.timing.easeIn};
      background-color: ${Tokens.color.neutral.black.transparent[75]};
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .modal {
      ${!isFullScreen ? `margin: calc(${Tokens.rhythm} * 2) auto;` : ''}
      ${
        isFullScreen
          ? `
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: calc(${Tokens.rhythm} * 2);
      `
          : ''
      }
      border-radius: 2px 2px 0 0;
      box-shadow: 0 1px 0 ${Tokens.color.neutral.black.transparent[10]};
      background-color: ${Tokens.color.neutral.white.base};
      color: ${Tokens.color.ui.charcoal.base};
      max-width: ${maxWidth}px;
      position: relative;
      animation-name: modalFadeIn;
      animation-duration: calc(${Tokens.transition.duration[3]} * 2);
      animation-timing-function: ${Tokens.transition.timing.easeIn};
      overflow: visible;

      .header {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: ${Tokens.rhythm} calc(${Tokens.rhythm} * 2);
        border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
        line-height: ${Tokens.lineHeight.heading[4]};

        h3 {
          margin: 0;
          flex-grow: 1;
          font-size: ${Tokens.font.size.heading[3]};
          font-weight: ${Tokens.font.weight.semiBold};
        }
      }

      .body {
        ${
          isFullScreen
            ? `
        display: flex;
        flex-grow: 1;
        `
            : ''
        }
        background-color: ${Tokens.color.neutral.grey[250]};
        padding: calc(${Tokens.rhythm} * 2);
        font-size: ${Tokens.font.size.paragraph.base};
        line-height: ${Tokens.lineHeight.paragraph.base};
      }

      .footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: calc(${Tokens.rhythm} * 7);
        box-sizing: border-box;
        border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};
        padding: 0 calc(${Tokens.rhythm} * 2);
      }
    }

    &.closed {
      .modalBackdrop {
        background-color: ${Tokens.color.neutral.black.transparent[0]};
        animation-name: backgroundFadeOut;
        animation-duration: calc(${Tokens.transition.duration[3]} * 2);
        animation-timing-function: ${Tokens.transition.timing.easeOut};
      }

      .modal {
        opacity: 0;
        animation-name: modalFadeOut;
        animation-duration: ${Tokens.transition.duration[3]};
        animation-timing-function: ${Tokens.transition.timing.easeOut};
      }
    }
  `;
});

const Modal = ({
  body,
  closeButtonLabel = 'Close',
  footerButtons,
  onClose,
  open,
  size,
  testId,
  title,
}: ModalProps): JSX.Element | null => {
  const [internalOpen, setInternalOpen] = useState(open);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        if (internalOpen) setInternalOpen(false);
      }, 500);
    }

    if (open && !internalOpen) {
      setInternalOpen(true);
    }
  }, [open, setInternalOpen]);

  if (internalOpen) {
    return ReactDOM.createPortal(
      <ModalContainer
        data-testid={testId}
        size={size}
        className={!open ? 'closed' : undefined}
      >
        <div className="modalBackdrop" onClick={onClose} />
        <div className="modal">
          <div className="header">
            <h3>{title}</h3>
            <Button
              className="xIcon"
              size="smallIcon"
              variant="tertiary"
              onClick={onClose}
            >
              <X />
            </Button>
          </div>
          <div className="body">{body}</div>
          <div className="footer">
            {(footerButtons ?? []).map((props) => (
              <Button
                key={shortid.generate()}
                style={{ marginLeft: `calc(${Tokens.rhythm} * 2` }}
                {...props}
              />
            ))}
            <Button variant="tertiary" size="small" onClick={onClose}>
              {closeButtonLabel}
            </Button>
          </div>
        </div>
      </ModalContainer>,
      document.getElementById('modal')!,
    );
  }

  return null;
};

export default Modal;
