import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import { PatientRoutePaths } from '../../PatientRoutes';
import { PatientHeaderProps } from '../../types';
import { DoseSpotButton } from 'modules';

const StyledPatientHeader = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  display: flex;
  flex-direction: column;
  padding-top: calc(${Tokens.rhythm} * 2);
  padding-left: calc(${Tokens.rhythm} * 2);
  padding-right: calc(${Tokens.rhythm} * 2);

  .info {
    display: flex;

    .patient {
      flex-grow: 1;
      margin-bottom: calc(${Tokens.rhythm} * 2);
      min-width: 160px;

      .name {
        display: block;
      }

      .details {
        color: ${Tokens.color.ui.slate.base};
        display: block;
      }
    }
  }
`;

const PatientHeader = ({
  patient,
  referrerState,
}: PatientHeaderProps): JSX.Element => {
  const history = useHistory();

  const getSelectedTab = (path: string): number => {
    switch (true) {
      case path.includes('encounters'):
        return 1;
      case path.includes('history'):
        return 2;
      default:
        return 0;
    }
  };

  const locationState = referrerState ? { referrer: referrerState } : null;

  return (
    <StyledPatientHeader>
      <div className="info">
        <div className="patient">
          <span className="name">{patient?.fullName ?? ''}</span>
          <span className="details">
            {patient?.sex &&
              `${patient?.humanizedSex ?? ''}, ${patient?.age} years old`}
          </span>
        </div>
        <div className="actions">
          <DoseSpotButton patientId={patient?.patientId} />
        </div>
      </div>
      <div>
        <UI.Tabs
          selectedIndex={window ? getSelectedTab(window.location.pathname) : 0}
        >
          <UI.Tab
            onClick={() =>
              history.push(
                `${generatePath(PatientRoutePaths.profile, {
                  id: patient?.globalPatientId,
                })}`,
                locationState,
              )
            }
          >
            Profile
          </UI.Tab>
          <UI.Tab
            onClick={() =>
              history.push(
                `${generatePath(PatientRoutePaths.encounters, {
                  id: patient?.globalPatientId,
                })}`,
                locationState,
              )
            }
          >
            Encounters
          </UI.Tab>
        </UI.Tabs>
      </div>
    </StyledPatientHeader>
  );
};

export default PatientHeader;
