import React, { useEffect } from 'react';

import { useGetEncounterTaskByEncounterTaskIdLazyQuery } from 'generated/graphql';
import { useUserContext } from 'hooks';

import UI from 'ui';
import AllergiesForm from '../AllergiesForm';
import ChiefComplaintAndHpiFormContainer from '../ChiefComplaintAndHpiForm';
import ClinicianBillingForm from '../ClinicianBillingForm';
import DispositionFormContainer from '../DispositionForm';
import DoseSpotForm from '../DoseSpotForm';
import AttachmentsForm from '../AttachmentsForm';
import MedicalConditionsForm from '../MedicalConditionsForm';
import MedicalDecisionMakingForm from '../MedicalDecisionMakingForm';
import FormstackForm from '../FormstackForm';
import PaymentMethodForm from '../PaymentMethodForm';
import PhysicalExamForm from '../PhysicalExamForm';
import PointOfCareTestForm from '../PointOfCareTestForm';
import ProgressNoteFormContainer from '../ProgressNoteForm';
import TaskDrawerComponent from '../TaskDrawer';
import TeladocForm from '../TeladocForm';
import TelehealthForm from '../TelehealthForm';
import VerifyPatientDemographics from '../VerifyPatientDemographics';
import VaccineForm from '../VaccineForm';
import VitalsForm from '../VitalsForm';
import { TTaskDrawerState } from 'cache/reactiveVars/TaskDrawer';

const TaskForm = ({
  taskDrawerState,
}: {
  taskDrawerState?: TTaskDrawerState;
}): JSX.Element => {
  const [getEncounterTask, { data, loading, startPolling, stopPolling }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();
  const task = data?.encounterTask;

  const { operatingAsResponder } = useUserContext() ?? {};

  const {
    expanded,
    taskId,
    isAllergiesTask,
    isAttachmentTask,
    isChiefComplaintAndHpiTask,
    isTeladocTask,
    isClinicianBillingTask,
    isDispositionTask,
    isDoseSpotTask,
    isMedicalConditionTask,
    isMedicalDecisionMakingTask,
    isPatientDemographicsTask,
    isPatientFormTask,
    isPaymentMethodTask,
    isPhysicalExamTask,
    isPointOfCareTestTask,
    isProgressNoteTask,
    isTelehealthTask,
    isVaccineTask,
    isVitalTask,
  } = taskDrawerState ?? {};

  useEffect(() => {
    if (operatingAsResponder) {
      // Start polling for updates if one of these tasks
      if (startPolling && isMedicalDecisionMakingTask) {
        startPolling(3000);
      }
      // Cleanup and stop polling
      return () => {
        if (stopPolling) stopPolling();
      };
    }
  }, [startPolling, isMedicalDecisionMakingTask]);

  const renderForm = (): React.ReactNode => {
    switch (true) {
      case isAllergiesTask:
        return <AllergiesForm key={`${task?.encounterTaskId}`} task={task} />;
      case isAttachmentTask:
        return <AttachmentsForm key={`${task?.encounterTaskId}`} task={task} />;
      case isChiefComplaintAndHpiTask:
        return (
          <ChiefComplaintAndHpiFormContainer
            key={`${task?.encounterTaskId}`}
            task={task}
          />
        );
      case isTeladocTask:
        return <TeladocForm key={`${task?.encounterTaskId}`} task={task} />;
      case isClinicianBillingTask:
        return (
          <ClinicianBillingForm key={`${task?.encounterTaskId}`} task={task} />
        );
      case isDispositionTask:
        return (
          <DispositionFormContainer
            key={`${task?.encounterTaskId}`}
            task={task}
          />
        );
      case isDoseSpotTask:
        return <DoseSpotForm key={`${task?.encounterTaskId}`} task={task} />;
      case isMedicalConditionTask:
        return (
          <MedicalConditionsForm key={`${task?.encounterTaskId}`} task={task} />
        );
      case isMedicalDecisionMakingTask:
        return (
          <MedicalDecisionMakingForm
            key={`${task?.encounterTaskId}`}
            task={task}
          />
        );
      case isPatientDemographicsTask:
        return (
          <VerifyPatientDemographics
            key={`${task?.encounterTaskId}`}
            task={task}
          />
        );
      case isPatientFormTask:
        return <FormstackForm key={`${task?.encounterTaskId}`} task={task} />;
      case isPaymentMethodTask:
        return (
          <PaymentMethodForm key={`${task?.encounterTaskId}`} task={task} />
        );
      case isPhysicalExamTask:
        return (
          <PhysicalExamForm key={`${task?.encounterTaskId}`} task={task} />
        );
      case isPointOfCareTestTask:
        return (
          <PointOfCareTestForm key={`${task?.encounterTaskId}`} task={task} />
        );
      case isProgressNoteTask:
        return (
          <ProgressNoteFormContainer
            key={`${task?.encounterTaskId}`}
            task={task}
          />
        );
      case isTelehealthTask:
        return <TelehealthForm key={`${task?.encounterTaskId}`} task={task} />;
      case isVaccineTask:
        return <VaccineForm key={`${task?.encounterTaskId}`} task={task} />;
      case isVitalTask:
        return <VitalsForm key={`${task?.encounterTaskId}`} task={task} />;
      default:
        return <p>{`Task Name: ${task?.taskName ?? 'No task found.'}`}</p>;
    }
  };

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isAllergiesTask,
          isChiefComplaintAndHpiTask,
          isClinicianBillingTask,
          isDispositionTask,
          isMedicalConditionTask,
          isMedicalDecisionMakingTask,
          isPatientFormTask,
          isPaymentMethodTask,
          isPhysicalExamTask,
          isPointOfCareTestTask,
          isProgressNoteTask,
          isTelehealthTask,
          isVaccineTask,
          isVitalTask,
        },
      });
    } // Add task form bools that are necessary for data retrieval
  }, [taskId]);

  return (
    <TaskDrawerComponent expanded={expanded}>
      {loading ? <UI.Spinner centered /> : renderForm()}
    </TaskDrawerComponent>
  );
};

export default TaskForm;
