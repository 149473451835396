import { Control, useFieldArray, UseFormTrigger } from 'react-hook-form';
import styled from 'styled-components';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import DragAndDrop from 'ui/DragAndDrop';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { StyledTaskForm } from '../TaskForm';
import ICDCodeTypeAhead from './DiagnosisCodeRefTypeAhead';
import { FormInputs, DiagnosisCodeReferenceInput } from './schema';

type Props = {
  appointmentDate: string;
  control: Control<FormInputs>;
  inputsAreDisabled?: boolean;
  taskHeaderProps: TaskHeaderProps;
  trigger: UseFormTrigger<FormInputs>;
};

const StyledMedicalDecisionMakingForm = styled(StyledTaskForm)`
  .field {
    margin-bottom: 40px;
  }

  .dragAndDropContainer {
    margin-top: calc(${Tokens.rhythm} * 2);

    .col1 {
      flex: 1;
    }

    .col2 {
      flex: 2;
    }
  }
`;

const MedicalDecisionMakingForm = ({
  appointmentDate,
  control,
  inputsAreDisabled,
  taskHeaderProps,
  trigger,
}: Props): JSX.Element => {
  const { task } = taskHeaderProps;
  const {
    fields: diagnosisCodeRefs,
    append: appendDiagnosisCodeRef,
    remove: removeDiagnosisCodeRef,
    move: moveDiagnosisCodeRef,
  } = useFieldArray({
    control,
    keyName: 'diagnosisCodeReferenceId',
    name: 'diagnosisCodeReferences',
  });

  const handleICDCodeTypeAheadOptionClick = (
    diagnosisCodeRef: DiagnosisCodeReferenceInput,
  ) => {
    appendDiagnosisCodeRef(diagnosisCodeRef);
    trigger('diagnosisCodeReferences');
  };

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledMedicalDecisionMakingForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>{task?.taskName}</h3>
          <p>All fields below, unless otherwise noted, are required.</p>
        </header>

        <div className="field">
          <p className="formStep">
            1. Document your medical decision making (MDM)
          </p>
          <Controlled.Input
            name="note"
            label="Medical Decision Making (MDM)"
            control={control}
            multiline
            rows={7}
            disabled={inputsAreDisabled}
            fullWidth
          />
        </div>

        <div className="field">
          <p className="formStep">
            2. Enter diagnosis codes (ICD-10) for this encounter (max. 12)
          </p>

          <ICDCodeTypeAhead
            appointmentDate={appointmentDate}
            control={control}
            disabled={Boolean(inputsAreDisabled)}
            onOptionClick={handleICDCodeTypeAheadOptionClick}
          />

          {diagnosisCodeRefs.length > 0 && (
            <div className="dragAndDropContainer">
              <DragAndDrop<DiagnosisCodeReferenceInput>
                columnKeys={['code', 'name']}
                disabled={inputsAreDisabled}
                keyName="diagnosisCodeReferenceId"
                header={['Code', 'Diagnosis Description']}
                move={moveDiagnosisCodeRef}
                remove={removeDiagnosisCodeRef}
                rows={diagnosisCodeRefs}
                showRank
                toolTipColumnKeys={['name']}
              />
            </div>
          )}
        </div>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={!!inputsAreDisabled}
          />
        )}
      </StyledMedicalDecisionMakingForm>
    </>
  );
};

export default MedicalDecisionMakingForm;
