import { Tokens } from 'config';

type Props = {
  className?: string;
};

const Rib = ({ className }: Props): JSX.Element => {
  const color = Tokens.color.ui.charcoal.base;
  return (
    <span className={className}>
      <svg
        width="5"
        height="16"
        viewBox="0 0 5 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.78125 8H4.21875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.78125 4H4.21875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.78125 12H4.21875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default Rib;
