import * as yup from 'yup';

import {
  EncounterPaymentModeEnum,
  NoInsuranceReasonEnum,
  SexEnum,
} from 'generated/graphql';

export const InsuranceSchema = yup.object({
  insuranceRecordId: yup.number(),

  // Plan Type
  planType: yup.string().when('$insuranceRequired', {
    is: true,
    then: yup.string().required('Please select a valid Plan Type'),
  }),

  // Member ID
  memberId: yup.string().when('$insuranceRequired', {
    is: true,
    then: yup.string().required('Please enter a valid Member ID'),
  }),

  // Group Number
  groupNumber: yup.string().when('$insuranceRequired', {
    is: true,
    then: yup.string().required('Please enter a valid Group Number'),
  }),

  // Attachment Front URL
  noInsuranceCards: yup.boolean(),
  attachmentFrontUrl: yup
    .string()
    .when(['$insuranceRequired', 'noInsuranceCards'], {
      is: (insuranceRequired: boolean, noInsuranceCards: boolean) =>
        insuranceRequired && !noInsuranceCards,
      then: yup
        .string()
        .nullable()
        .required('Please upload a valid Insurance Card (Front)'),
      otherwise: yup.string().nullable(),
    }),

  // Attachment Back URL
  attachmentBackUrl: yup
    .string()
    .when(['$insuranceRequired', 'noInsuranceCards'], {
      is: (insuranceRequired: boolean, noInsuranceCards: boolean) =>
        insuranceRequired && !noInsuranceCards,
      then: yup
        .string()
        .nullable()
        .required('Please upload a valid Insurance Card (Back)'),
      otherwise: yup.string().nullable(),
    }),

  company: yup
    .object({
      name: yup.string(),
      packageId: yup.string().nullable(),
    })
    .when(['$insuranceRequired', 'companyNotFound'], {
      is: (insuranceRequired: boolean, companyNotFound: boolean) =>
        insuranceRequired && !companyNotFound,
      then: yup.object({
        name: yup.string().required('Please enter a valid Insurance Company'),
        packageId: yup.string().nullable(),
      }),
    }),

  // Unlisted Insurance Company
  companyNotFound: yup.boolean().default(false),
  unlistedCompany: yup
    .string()
    .when(['$insuranceRequired', 'companyNotFound'], {
      is: (insuranceRequired: boolean, companyNotFound: boolean) =>
        insuranceRequired && companyNotFound,
      then: yup.string().required('Please enter a valid Insurance Company'),
    }),

  // Relationship to Policy Holder
  relationshipToPolicyHolder: yup.string().when('$insuranceRequired', {
    is: true,
    then: yup
      .string()
      .required('Please select a valid relationship to policy holder'),
  }),

  // Policy Holder First Name
  policyHolderFirstName: yup
    .string()
    .when(['$insuranceRequired', 'relationshipToPolicyHolder'], {
      is: (required: boolean, relationship?: string) =>
        required && relationship && relationship !== 'self',
      then: yup.string().required('Please enter a valid First Name'),
    }),

  // Policy Holder Last Name
  policyHolderLastName: yup
    .string()
    .when(['$insuranceRequired', 'relationshipToPolicyHolder'], {
      is: (required: boolean, relationship?: string) =>
        required && relationship && relationship !== 'self',
      then: yup.string().required('Please enter a valid Last Name'),
    }),

  // Policy Holder Gender
  policyHolderGender: yup
    .mixed<SexEnum>()
    .when(['$insuranceRequired', 'relationshipToPolicyHolder'], {
      is: (required: boolean, relationship?: string) =>
        required && relationship && relationship !== 'self',
      then: yup
        .mixed<SexEnum>()
        .oneOf(Object.values(SexEnum))
        .required('Please enter a valid Sex at Birth'),
      otherwise: yup.mixed<SexEnum>().oneOf(Object.values(SexEnum)),
    }),

  // Policy Holder Date of Birth
  policyHolderDob: yup
    .date()
    .nullable()
    .when(['$insuranceRequired', 'relationshipToPolicyHolder'], {
      is: (required: boolean, relationship?: string) =>
        required && relationship && relationship !== 'self',
      then: yup
        .date()
        .nullable()
        .required('Please enter a valid Date of Birth'),
    }),
});

const FormSchema = yup.object({
  // Payment Mode
  paymentMode: yup
    .mixed<EncounterPaymentModeEnum>()
    .oneOf(Object.values(EncounterPaymentModeEnum))
    .required('Please provide a value'),

  // No Insurance Reason
  noInsuranceReason: yup
    .mixed<NoInsuranceReasonEnum>()
    .oneOf(Object.values(NoInsuranceReasonEnum))
    .when('paymentMode', {
      is: EncounterPaymentModeEnum.INVOICE,
      then: yup
        .mixed<NoInsuranceReasonEnum>()
        .oneOf(Object.values(NoInsuranceReasonEnum))
        .required('Please provide a value'),
    }),

  // Insurances
  insurances: yup.array().of(InsuranceSchema).max(2),
});

export declare type FormInputs = yup.InferType<typeof FormSchema>;
export declare type InsuranceInputs = yup.InferType<typeof InsuranceSchema>;

export default FormSchema;
