import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { useGetICDCodesBySearchTermLazyQuery } from 'generated/graphql';
import TypeAhead from 'ui/TypeAhead';
import { Option } from 'ui/TypeAhead/TypeAhead';
import { FormInputs, DiagnosisCodeReferenceInput } from './schema';

type Props = {
  appointmentDate: string;
  control: Control<FormInputs>;
  disabled: boolean;
  onOptionClick: (diagnosisCodeRef: DiagnosisCodeReferenceInput) => void;
};

const Container = styled.div`
  /* Override the typeahead result styles */
  .withHint {
    span {
      width: 75% !important;
      &:first-child {
        width: 25% !important;
      }
    }
  }
`;

const DiagnosisCodeRefTypeAhead = ({
  appointmentDate,
  control,
  disabled,
  onOptionClick,
}: Props): JSX.Element => {
  const typeAheadRef = useRef({ reset: () => {} });

  const diagnosisCodeReferences = useWatch({
    control,
    name: 'diagnosisCodeReferences',
  });

  const diagnosisCodeReferenceIds = diagnosisCodeReferences.map(
    (item) => item.diagnosisCodeReferenceId,
  );

  const { errors } = useFormState({ control });

  const [options, setOptions] = useState<Option[]>([]);

  const [getDiagnosisCodeReferences, { loading, data }] =
    useGetICDCodesBySearchTermLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const handleDiagnosisCodeRefInputChange = (searchTerm?: string) => {
    if (!searchTerm) return;
    setOptions([]);
    getDiagnosisCodeReferences({
      variables: { activeDate: appointmentDate, searchTerm },
    });
  };

  const handleOptionClick = (diagnosisCodeRef: DiagnosisCodeReferenceInput) => {
    onOptionClick(diagnosisCodeRef);
    setOptions([]);
    setTimeout(() => {
      typeAheadRef.current.reset();
    });
  };

  useEffect(() => {
    if (data) {
      setOptions(
        (data.searchICDCodes ?? [])
          .filter(
            (item) =>
              !diagnosisCodeReferenceIds.includes(
                item.diagnosisCodeReferenceId,
              ),
          )
          .map((item) => ({
            label: item.code,
            value: item,
            hint: item.name,
          })),
      );
    }
  }, [data]);

  const errorText = _.get(errors, 'diagnosisCodeReferences.message');

  return (
    <Container>
      <TypeAhead
        disabled={disabled || diagnosisCodeReferenceIds.length === 12}
        errorText={errorText}
        name="diagnosisCodeReferenceSearch"
        loading={loading}
        onInputChange={handleDiagnosisCodeRefInputChange}
        onOptionClick={(value) => {
          handleOptionClick(value as DiagnosisCodeReferenceInput);
        }}
        options={options}
        placeholder="Search diagnosis code by name or code"
        ref={typeAheadRef}
        threshold={2}
      />
    </Container>
  );
};

export default DiagnosisCodeRefTypeAhead;
