import {
  EncounterPaymentModeEnum,
  NoInsuranceReasonEnum,
  SexEnum,
} from 'generated/graphql';
import { Option } from 'ui/Select/Select';

const insurancePlanTypes = [
  ['ppo', 'PPO'],
  ['hmo', 'HMO'],
  ['medicareSupplement', 'Medicare Supplement'],
  ['pos', 'POS'],
  ['indemnity', 'Indemnity'],
  ['epo', 'EPO'],
  ['pffs', 'PFFS'],
  ['posII', 'POS II'],
  ['tricareSupplement', 'TRICARE Supplement'],
  ['ffs', 'FFS'],
  ['qpos', 'QPOS'],
  ['other', 'Other'],
  ['behavioralHealth', 'Behavioral health'],
  ['contract', 'Contracts'],
  ['legal', 'Legal'],
  ['mva', 'MVA'],
  ['radiology', 'Radiology'],
  ['vision', 'Vision'],
  ['workersComp', 'Workers Comp'],
];

const insuranceRelationshipTypes = [
  ['self', 'Self'],
  ['spouse', 'Spouse'],
  ['child', 'Child'],
  ['other', 'Other'],
  ['grandparent', 'Grandparent'],
  ['grandchild', 'Grandchild'],
  ['nephewOrNiece', 'Nephew or Niece'],
  ['adoptedChild', 'Adopted Child'],
  ['fosterChild', 'Foster Child'],
  ['ward', 'Ward'],
  ['stepsonOrStepDaughter', 'Stepson or Stepdaughter'],
  ['employee', 'Employee'],
  ['unknown', 'Unknown'],
  ['handicappedDependent', 'Handicapped Dependent'],
  ['sponsoredDependent', 'Sponsored Dependent'],
  ['dependentOfAMinorDependent', 'Dependent of a Minor Dependent'],
  ['significantOther', 'Significant Other'],
  ['mother', 'Mother'],
  ['father', 'Father'],
  ['otherAdult', 'Other Adult'],
  ['emancipatedMinor', 'Emancipated Minor'],
  ['organDonor', 'Organ Donor'],
  ['cadaverDonor', 'Cadaver Donor'],
  ['injuredPlaintiff', 'Injured Plaintiff'],
  [
    'childInsNotFinanciallyResponsible',
    'Child (Ins. not Financially Respons.)',
  ],
  ['lifePartner', 'Life Partner'],
  ['childMothersInsurance', "Child (Mother's Insurance)"],
  ['childFathersInsurance', "Child (Father's Insurance)"],
  [
    'childMothersInsuranceNotFinanciallyResponsible',
    "Child (Mother's Ins., Ins. not Financially Respons.)",
  ],
  [
    'childFathersInsuranceNotFinanciallyResponsible',
    "Child (Father's Ins., Ins. not Financially Respons.)",
  ],
  [
    'stepsonOrStepDaughterStepMothersInsurance',
    "Stepson or Stepdaughter (Stepmother's Insurance)",
  ],
  [
    'stepsonOrStepDaughterStepFathersInsurance',
    "Stepson or Stepdaughter (Stepfather's Insurance)",
  ],
];

export const PaymentOptions: Option[] = [
  {
    label: 'Insurance',
    value: EncounterPaymentModeEnum.INSURANCE,
  },
  {
    label: 'Invoice (out of pocket)',
    value: EncounterPaymentModeEnum.INVOICE,
  },
  {
    label: 'Partner Invoice (select cases only)',
    value: EncounterPaymentModeEnum.PARTNERSHIP,
  },
];

export const NoInsuranceReasonOptions: Option[] = [
  {
    label: 'Cannot Find Insurance Cards',
    value: NoInsuranceReasonEnum.CANNOT_FIND_INSURANCE_CARDS,
  },
  {
    label: 'Uninsured',
    value: NoInsuranceReasonEnum.UNINSURED,
  },
];

export const InsurancePlanTypeOptions: Option[] = insurancePlanTypes.map(
  (item) => ({
    label: item[1],
    value: item[0],
  }),
);

export const InsuranceRelationshipTypeOptions: Option[] =
  insuranceRelationshipTypes.map((item) => ({
    label: item[1],
    value: item[0],
  }));

export const InsurancePolicyHolderGenderOptions: Option[] = [
  {
    label: 'Female',
    value: SexEnum.FEMALE,
  },
  {
    label: 'Male',
    value: SexEnum.MALE,
  },
];
