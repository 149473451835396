import { ModuleNameEnum } from 'generated/graphql';
import { Option } from 'ui/Select/Select';

export const AddFormModuleOptions: Option[] = [
  {
    label: 'Advanced Beneficiary Notice of Noncoverage',
    value: ModuleNameEnum.FORM_ABN_NONCOVERAGE,
  },
  {
    label: 'Against Medical Advice',
    value: ModuleNameEnum.FORM_AMA,
  },
  {
    label: 'AUDIT-C',
    value: ModuleNameEnum.FORM_AUDIT_C,
  },
  {
    label: 'Community Care',
    value: ModuleNameEnum.FORM_COMMUNITY_CARE,
  },
  {
    label: 'DAST-10',
    value: ModuleNameEnum.FORM_DAST_10,
  },
  {
    label: 'GAD-7',
    value: ModuleNameEnum.FORM_GAD_7,
  },
  {
    label: 'Katz ADL',
    value: ModuleNameEnum.FORM_KATZ_ADL,
  },
  {
    label: 'Morisky Rx Adherence',
    value: ModuleNameEnum.FORM_MORISKY_RX_ADHERENCE,
  },
  {
    label: 'Morse Fall',
    value: ModuleNameEnum.FORM_MORSE_FALL,
  },
  {
    label: 'Pain',
    value: ModuleNameEnum.FORM_PAIN,
  },
  {
    label: 'PHQ',
    value: ModuleNameEnum.FORM_PHQ,
  },
  {
    label: 'Postpartum Depression',
    value: ModuleNameEnum.FORM_POSTPARTUM_DEPRESSION,
  },
  {
    label: 'RAD General Assessment',
    value: ModuleNameEnum.FORM_RAD_GENERAL,
  },
  {
    label: 'Release of Medical Records',
    value: ModuleNameEnum.FORM_RELEASE_MEDICAL,
  },
  {
    label: 'SDoH',
    value: ModuleNameEnum.FORM_SDOH,
  },
  {
    label: 'Technology',
    value: ModuleNameEnum.FORM_TECHNOLOGY,
  },
];

export const ClinicianOrderModuleOptions: Option[] = [
  {
    label: 'Test - COVID Antigen',
    value: ModuleNameEnum.TEST_COVID_ORDER,
  },
  {
    label: 'Test - A1C',
    value: ModuleNameEnum.TEST_A1C_ORDER,
  },
  {
    label: 'Test - Flu Antigen',
    value: ModuleNameEnum.TEST_FLU_ANTIGEN_ORDER,
  },
  {
    label: 'Test - Pregnancy',
    value: ModuleNameEnum.TEST_PREGNANCY_ORDER,
  },
  {
    label: 'Test - Glucose',
    value: ModuleNameEnum.TEST_GLUCOSE_ORDER,
  },
  {
    label: 'Test - RSV',
    value: ModuleNameEnum.TEST_RSV_ORDER,
  },
  {
    label: 'Test - Strep',
    value: ModuleNameEnum.TEST_STREP_ORDER,
  },
];

export const EscalationModuleOptions: Option[] = [
  {
    label: 'Clinician',
    value: ModuleNameEnum.CLINICIAN_ACTIVATION,
  },
  {
    label: 'EMS Activation',
    value: ModuleNameEnum.EMS_ACTIVATION,
  },
];
