import { Control, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form';

import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { format as formatDate } from 'date-fns';
import { ArrowSquareOut, Check, File } from 'phosphor-react';

import {
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
  EncounterTaskStatusEnum,
  EncounterTask,
} from 'generated/graphql';

import { Tokens } from 'config';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import PatientFormResponse from './FormstackFormResponse';
import { usePrevious } from 'hooks';
import { TInputs } from './utils';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';

const StyledTaskBox = styled.div<{ hasError: boolean }>`
  align-items: center;
  border: 1px solid;
  border-color: ${({ hasError }) =>
    hasError ? Tokens.color.ui.error.base : 'rgba(48, 53, 70, 0.25)'};
  border-radius: ${Tokens.border.radius};
  display: flex;
  min-height: 83px;
  margin: ${Tokens.rhythm} 0;
  padding: calc(${Tokens.rhythm} * 2) calc(${Tokens.rhythm} * 2);

  .fileIcon {
    justify-self: flex-start;
    flex-shrink: 1;
    width: 24px;
  }

  .viewButton {
    justify-self: flex-end;
    margin-left: auto;
  }

  .taskBoxInfo {
    justify-self: flex-start;
    max-width: 250px;
    padding: 0 calc(${Tokens.rhythm} * 2);

    h4 {
      font-size: ${Tokens.font.size.heading[3]};
      line-height: ${Tokens.lineHeight.heading[4]};
      margin: 0;
      padding: 0;
    }

    .signedInfo {
      align-items: center;
      display: flex;

      small {
        color: ${Tokens.color.ui.charcoal.base};
        font-size: ${Tokens.font.size.paragraph.small};
        line-height: ${Tokens.lineHeight.paragraph.small};
        padding-right: ${Tokens.rhythm};
      }
    }
  }
`;

const ErrorText = styled.p`
  color: ${Tokens.color.ui.error.base};
`;

const FormstackForm = ({
  errors,
  inputsAreDisabled,
  setValue,
  taskHeaderProps,
}: {
  control: Control<TInputs>;
  errors: DeepMap<TInputs, FieldError>;
  formState: TInputs;
  inputsAreDisabled: boolean;
  setValue: UseFormSetValue<TInputs>;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const [pollForFormResult, { startPolling, stopPolling }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();
  const [polling, setPolling] = useState(false);

  const formTitle = task?.taskName ?? 'Untitled Form';
  const formUrl = task?.patientFormLookup?.formUrl ?? '#';
  const formIsSigned = !!task?.patientForm?.formstackId;
  const prevFormIsSigned = usePrevious<boolean>(formIsSigned);

  const handleViewForm = () => {
    if (formIsSigned) {
      window.open(
        `https://www.formstack.com/admin/submission/view/${task?.patientForm?.formstackId}`,
        '_blank',
      );
    } else {
      window.open(
        `${formUrl}?encounterTaskId=${task?.encounterTaskId}&patientFormLookupId=${task?.patientFormLookup?.patientFormLookupId}`,
        '_blank',
      );
      if (startPolling) startPolling(2500);
      setPolling(true);
    }
  };

  useEffect(() => {
    if (prevFormIsSigned !== undefined) {
      if (formIsSigned && !prevFormIsSigned)
        setValue('patientConsentForm', true, {
          shouldDirty: true,
          shouldValidate: true,
        });

      if (formIsSigned && stopPolling) {
        stopPolling();
        setPolling(false);
      }
    }

    return () => stopPolling && stopPolling();
  }, [startPolling, stopPolling, formIsSigned, prevFormIsSigned]);

  useEffect(() => {
    if (task?.encounterTaskId && !formIsSigned) {
      pollForFormResult({
        variables: {
          id: task.encounterTaskId,
          isPatientFormTask: true,
        },
      });
    }
  }, [task?.encounterTaskId]);

  const showViewButton = Boolean(
    task?.status !== EncounterTaskStatusEnum.SKIPPED,
  );

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
        style={{ paddingBottom: `calc(${Tokens.rhythm} * 6)` }}
      >
        <header>
          <h3>{formTitle}</h3>

          <p className="formInstructionsLabel">
            1. Click ‘View’ to open the form in a new window.
          </p>
        </header>

        <section>
          <fieldset>
            <StyledTaskBox hasError={!!errors.patientConsentForm}>
              <File size={24} weight="fill" className="fileIcon" />

              <span className="taskBoxInfo">
                <h4>{formTitle}</h4>

                {task?.patientForm ? (
                  <span className="signedInfo">
                    <small>
                      Signed on:{' '}
                      {formatDate(
                        new Date(task.patientForm.signedAt),
                        'M/dd/yyyy @ h:mmaaa',
                      )}
                    </small>

                    <Check color={Tokens.color.ui.success.base} size={14} />
                  </span>
                ) : null}
              </span>

              {showViewButton && (
                <UI.Button
                  className="viewButton"
                  isLoading={polling}
                  leftIcon={<ArrowSquareOut />}
                  onClick={handleViewForm}
                  variant="secondary"
                >
                  View
                </UI.Button>
              )}
            </StyledTaskBox>
            {errors.patientConsentForm ? (
              <ErrorText>Please complete {formTitle}</ErrorText>
            ) : null}
          </fieldset>
        </section>
        {task?.patientForm?.fieldResponseJson && (
          <PatientFormResponse
            responseData={task?.patientForm?.fieldResponseJson}
          />
        )}
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default FormstackForm;
