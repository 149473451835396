import {
  Control,
  useController,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import Select, { Option, Props as SelectProps } from 'ui/Select/Select';

/**
 * Controlled Image Input Props
 * @extends {UI.Select}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<SelectProps, 'name' | 'errorText' | 'id' | 'onChange' | 'values'> & {
  /**
   * Unique name of your input.
   */
  name: TName;

  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;
};

const ControlledSelect = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  multi,
  name,
  options,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  const handleChange = (opts: Option[]) => {
    if (multi) {
      onChange(opts.map((opt) => opt.value));
      return;
    }
    onChange(opts.length > 0 ? opts[0].value : undefined);
  };

  const optionValues = (options ?? []).filter((option) =>
    multi && Array.isArray(value)
      ? (value as Array<unknown>).includes(option.value)
      : option.value === value,
  );

  return (
    <Select
      id={name}
      multi={multi}
      name={name}
      onChange={(opts) => handleChange(opts ?? [])}
      options={options}
      values={optionValues}
      errorText={error?.message}
      {...props}
    />
  );
};

export default ControlledSelect;
