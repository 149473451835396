import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { MagnifyingGlass, XCircle } from 'phosphor-react';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';

import { Tokens } from 'config';
import { useOutsideClickHandler, useDidMountEffect } from 'hooks';
import Container from '../Container/Container';
import Input from '../Input/Input';
import ListItem from '../ListItem/ListItem';
import Spinner from '../Spinner/Spinner';
import { StyledDropdown } from '../Dropdown/Dropdown';

const Dropdown = styled((props) => <StyledDropdown {...props} />)`
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  z-index: ${Tokens.zIndex.dropdown};
`;

const StyledTypeAhead = styled.div`
  position: relative;
`;

export type Option = {
  hint?: string;
  key?: string;
  label: string;
  value: string | number | Record<string, unknown>;
};

export type TypeAheadProps = {
  /**
   * A className for overriding styles.
   */
  className?: string;
  /**
   * Debounce rate in milliseconds
   */
  debounceRate?: number;
  /**
   * If `true`, the input will be disabled.
   */
  disabled?: boolean;
  /**
   * If `true`, the input value is invalid.
   */
  errorText?: string;
  /**
   * The initial value.
   */
  initialValue?: string;
  /**
   * ID prop of the Input
   */
  id?: string;
  /**
   * Renders a label with the provided text.
   */
  label?: string;
  /**
   * Loading
   */
  loading?: boolean;
  /**
   * The `name` attribute of the input. Use in cnjunction with the word `Search` to prevent Safari from auto-filling values. See story for example.
   */
  name?: string;
  /**
   * The `onChange` event handler.
   */
  onInputChange?: (arg?: string) => void;
  /**
   * The `onChange` event handler.
   */
  onOptionClick?: (arg?: Option['value']) => void;
  /**
   * The `onReset` event handler.
   */
  onReset?: () => void;
  /**
   * If `true`, the input is optional
   */
  optional?: boolean;
  /**
   * Options array for Select.
   */
  options?: Option[];
  /**
   * Placeholder text.
   */
  placeholder?: string;
  /**
   * Option for setting required on input.
   */
  required?: boolean;
  /**
   * The `data-testid`
   */
  testId?: string;
  /**
   * Number of characters required before onInputChange is called.
   */
  threshold?: number;
};

const TypeAhead = forwardRef<{ reset: () => void }, TypeAheadProps>(
  (
    {
      className,
      debounceRate = 850,
      disabled = false,
      errorText,
      initialValue = '',
      id = 'TypeAhead',
      label,
      loading = false,
      optional = false,
      name = 'TypeAheadSearch',
      onInputChange = () => {},
      onOptionClick = () => {},
      onReset = () => {},
      options = [],
      placeholder = 'Search...',
      required = false,
      testId,
      threshold = 1,
    },
    ref,
  ): JSX.Element => {
    const [inputValue, setInputValue] = useState<string>(initialValue);
    const [internalOptions, setInternalOptions] = useState<Option[]>(options);
    const [internalLoading, setInternalLoading] = useState<boolean>(loading);
    const [queryString, setQueryString] = useState<string>(initialValue);
    const [hideDropdown, setHideDropdown] = useState<boolean>(true);
    const [valueSelected, setValueSelected] = useState<boolean>(
      initialValue ? initialValue.length > 0 : false,
    );

    const typeaheadRef = useRef(null);
    useOutsideClickHandler(typeaheadRef, () => {
      setHideDropdown(true);
    });

    const debouncedSetQueryString = useCallback(
      _debounce((value: string) => {
        setQueryString(value.length >= threshold ? value : '');
      }, debounceRate),
      [],
    );

    const handleOptionClick = (option: Option) => {
      setInputValue(option.label);
      setHideDropdown(true);
      onOptionClick(option.value);
      setValueSelected(true);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event?.target?.value);
      setHideDropdown(false);
    };

    const handleReset = () => {
      setInputValue('');
      setHideDropdown(true);
      setValueSelected(false);
      onReset();
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;

      if (key === 'Tab') {
        return;
      }

      if (valueSelected) {
        event.stopPropagation();
        event.preventDefault();

        if (key === 'Backspace') {
          handleReset();
        }
      }
    };

    useImperativeHandle(ref, () => ({
      reset: () => {
        handleReset();
      },
    }));

    const loadingOrNoResult = internalLoading ? (
      <Container>
        <Spinner
          data-testid={testId ? `${testId}__Spinner` : undefined}
          size="small"
          centered
        />
      </Container>
    ) : (
      <ListItem label="No results found..." />
    );

    const results = (
      <Dropdown
        open={!hideDropdown}
        setOpen={(open: boolean) => setHideDropdown(!open)}
        data-testid={testId ? `${testId}__Dropdown` : undefined}
      >
        {internalOptions.length > 0
          ? internalOptions.map((option: Option) => (
              <ListItem
                data-testid={option.label}
                key={option.key ?? option.label}
                hint={option.hint}
                label={option.label}
                onClick={() => handleOptionClick(option)}
              />
            ))
          : loadingOrNoResult}
      </Dropdown>
    );

    useDidMountEffect(() => {
      if (inputValue !== queryString) {
        if (debounceRate > 0) {
          setInternalLoading(true);
          setInternalOptions([]);
        }
        debouncedSetQueryString(inputValue);
      }

      return () => debouncedSetQueryString.cancel();
    }, [inputValue, queryString]);

    useDidMountEffect(() => {
      if (queryString) {
        onInputChange(queryString);
      }
    }, [queryString]);

    useDidMountEffect(() => {
      if (initialValue) {
        setInputValue(initialValue);
        setValueSelected(true);
      }
    }, [initialValue]);

    useDidMountEffect(() => {
      setInternalLoading(loading);
    }, [loading]);

    useDidMountEffect(() => {
      setInternalOptions(options);
    }, [options]);

    return (
      <StyledTypeAhead
        className={className}
        data-testid={testId}
        ref={typeaheadRef}
      >
        <Input
          autoComplete="off"
          disabled={disabled}
          errorText={errorText}
          fullWidth
          id={id}
          label={label}
          leftIcon={<MagnifyingGlass />}
          name={name}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          optional={optional}
          placeholder={placeholder}
          required={required}
          rightIcon={
            valueSelected ? (
              <XCircle
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                data-testid={testId ? `${testId}__clearTypeAhead` : undefined}
                onClick={!disabled ? handleReset : undefined}
              />
            ) : undefined
          }
          type="text"
          value={inputValue}
        />
        {inputValue.length >= threshold && !hideDropdown ? results : null}
      </StyledTypeAhead>
    );
  },
);

export default TypeAhead;
