import * as yup from 'yup';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
} from 'generated/graphql';

import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type PatientIdInputs = {
  patientIdFrontAttachment?: Maybe<string>;
  patientIdBackAttachment?: Maybe<string>;
};

export const PatientIdSchema: yup.SchemaOf<PatientIdInputs> = yup.object({
  patientIdFrontAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid patient ID (Front)'),
  patientIdBackAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid patient ID (Back)'),
});

export const getDefaultValues = (task: TTask): PatientIdInputs => ({
  patientIdFrontAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.PATIENT_ID_FRONT,
  ).attachmentUrl,
  patientIdBackAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.PATIENT_ID_BACK,
  ).attachmentUrl,
});
