import { Plus } from 'phosphor-react';
import UI from 'ui';
import { useState } from 'react';

import { InfoSectionsProps } from '../../types';
import AddPatientNote from './AddPatientNote';
import PatientNote from './PatientNote';
import { StyledNotesSection } from './StyledPatientNotes';

const PatientNotes = ({ patient }: InfoSectionsProps): JSX.Element => {
  const { patientNotesList } = patient;

  const [noteInputArea, setNoteInputArea] = useState(false);

  const sortedPatientNotes =
    patientNotesList &&
    [...patientNotesList].sort(
      (a, b) => Number(b.isHighPriority) - Number(a.isHighPriority),
    );

  return (
    <StyledNotesSection>
      <div className="heading">Sticky Notes</div>

      {noteInputArea ? (
        <AddPatientNote patient={patient} setNoteInputArea={setNoteInputArea} />
      ) : (
        <UI.Button
          variant="tertiary"
          className="newNoteButton"
          onClick={() => {
            if (setNoteInputArea) setNoteInputArea(true);
          }}
          leftIcon={<Plus />}
        >
          Add a Note
        </UI.Button>
      )}

      {sortedPatientNotes?.map((patientNote) => (
        <PatientNote
          key={patientNote.patientNoteId}
          patientNote={patientNote}
        />
      ))}
    </StyledNotesSection>
  );
};

export default PatientNotes;
