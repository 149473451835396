import {
  Encounter,
  EncounterTask,
  GetEncounterTaskByEncounterTaskIdQuery,
  useGetEncounterByEncounterIdLazyQuery,
  useGetEncounterTasksByEncounterIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StyledEncounterPrint } from '../StyledEncounterPrint';

import AllergiesSummary from '../AllergiesForm/AllergiesSummary';
import AddendumListSummary from './AddendumListSummary';
import AppointmentsSummary from 'modules/Appointments/AppointmentsSummary';
import AttachmentsFormSummary from '../AttachmentsForm/AttachmentsFormSummary';
import ChiefComplaintAndHpiSummary from '../ChiefComplaintAndHpiForm/ChiefComplaintAndHpiSummary';
import ClinicianBillingSummary from '../ClinicianBillingForm/ClinicianBillingSummary';
import DispositionSummary from '../DispositionForm/DispositionSummary';
import EncounterSummary from './EncounterSummary';
import MedicalConditionsSummary from '../MedicalConditionsForm/MedicalConditionsSummary';
import MedicalDecisionMakingSummary from '../MedicalDecisionMakingForm/MedicalDecisionMakingSummary';
import PatientSummary from '../Profile/PatientSummary';
import PhysicalExamSummary from '../PhysicalExamForm/PhysicalExamSummary';
import PointOfCareTestSummary from '../PointOfCareTestForm/PointOfCareTestSummary';
import VaccineSummary from '../VaccineForm/VaccineSummary';
import VitalsSummary from '../VitalsForm/VitalsSummary';

const EncounterPrint = (): JSX.Element => {
  const [getEncounterTasks, { data, loading }] =
    useGetEncounterTasksByEncounterIdLazyQuery();
  const [getEncounter, { data: encounterData, loading: encounterLoading }] =
    useGetEncounterByEncounterIdLazyQuery();

  const { encounterId } = useParams<{ encounterId: string }>();

  useEffect(() => {
    if (encounterId) {
      getEncounterTasks({
        variables: {
          id: parseInt(encounterId),
        },
      });
      getEncounter({
        variables: {
          id: parseInt(encounterId),
        },
      });
    }
  }, [encounterId]);

  if (loading || encounterLoading)
    return <UI.Spinner centered data-testid="spinner" />;

  // Helper to get the correct task as we don't want to just print in the order the server returns
  const findTask = (taskName: string): number => {
    const taskFound = data?.encounterTasksList?.find(
      (task) => task.taskName === taskName,
    );
    return taskFound?.encounterTaskId ?? 0;
  };

  const orderedModulePOCTTasks =
    data?.encounterTasksList?.filter(
      (task) =>
        task.dataType === 'point_of_care_test' &&
        task.group === 'Ordered Modules',
    ) ?? [];

  const approvedModulePOCTTasks =
    data?.encounterTasksList?.filter(
      (task) =>
        task.dataType === 'point_of_care_test' &&
        task.group === 'Approved Modules',
    ) ?? [];

  const POCTComponent = (
    task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>,
    group?: string,
  ) =>
    task?.taskName === 'Administer glucose test' ? (
      <Fragment key={task.encounterTaskId}>
        <h2>{group ?? null}</h2>
        <PointOfCareTestSummary
          taskId={task.encounterTaskId}
          header="Glucose Test Results"
        />
      </Fragment>
    ) : (
      <Fragment key={task?.encounterTaskId}>
        <h2>{group ?? null}</h2>
        <PointOfCareTestSummary taskId={task?.encounterTaskId} />
      </Fragment>
    );

  const renderedOrderedModulePOCTTasks = () =>
    orderedModulePOCTTasks.map((task) =>
      POCTComponent(task, 'Ordered Modules'),
    );

  const renderedApprovedModulePOCTTasks = () =>
    approvedModulePOCTTasks.map((task) => POCTComponent(task));

  return (
    <StyledEncounterPrint>
      <AppointmentsSummary encounter={encounterData?.encounter as Encounter} />
      <PatientSummary encounter={encounterData?.encounter as Encounter} />
      <EncounterSummary encounter={encounterData?.encounter as Encounter} />
      <ChiefComplaintAndHpiSummary
        taskId={findTask('Document Chief Complaint, HPI, and ROS')}
      />
      <MedicalConditionsSummary
        taskId={findTask('Document Medical Conditions')}
      />
      <AllergiesSummary taskId={findTask('Enter Non-Drug Allergies')} />
      <VitalsSummary taskId={findTask('Document Patient Vitals')} />
      <PhysicalExamSummary taskId={findTask('Document Physical Exam')} />
      {orderedModulePOCTTasks.length > 0
        ? renderedOrderedModulePOCTTasks()
        : null}
      {approvedModulePOCTTasks.length > 0
        ? renderedApprovedModulePOCTTasks()
        : null}
      <MedicalDecisionMakingSummary
        taskId={findTask('Document MDM & Diagnoses')}
      />
      <DispositionSummary taskId={findTask('Record encounter disposition')} />
      <ClinicianBillingSummary taskId={findTask('Enter Billing Codes')} />
      {/* <ProgressNoteSummary taskId={findTask('Write Progress Note')} /> */}
      <VaccineSummary taskId={findTask('Administer flu vaccine')} />
      <VaccineSummary taskId={findTask('Administer COVID vaccine')} />
      <AttachmentsFormSummary
        taskId={findTask('Capture Transfer of Care Image')}
      />
      <AttachmentsFormSummary
        taskId={findTask('Capture POC Test Result Leave Behind Image')}
        header="POC Test Result Images"
      />
      <AddendumListSummary
        encounter={encounterData?.encounter as Encounter}
        encounterTasks={data?.encounterTasksList as EncounterTask[]}
        formstackFormTaskId={findTask('Sign Consent for Medical Services')}
        paymentMethodTaskId={findTask('Confirm Payment Method')}
        progressNoteTaskId={findTask('Write Progress Note')}
      />
    </StyledEncounterPrint>
  );
};

export default EncounterPrint;
