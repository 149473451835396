import { TelehealthMeetingAlternateConnectionMethodEnum as ConnectEnum } from 'generated/graphql';

const AlternateConnectionOptions = [
  {
    label: 'Google Meet',
    value: ConnectEnum.GOOGLE_MEET,
  },
  {
    label: 'Zoom (created outside of ReadyHealth)',
    value: ConnectEnum.NON_READYHEALTH_ZOOM,
  },
  {
    label: "Family member's teleconference",
    value: ConnectEnum.FAMILY_MEMBER_TELECONFERENCE,
  },
  {
    label: 'Other',
    value: ConnectEnum.OTHER,
  },
];

export default AlternateConnectionOptions;
