import { FormEvent } from 'react';

import {
  PatientPatchRecordInput,
  usePatientUpdatePatientMutation,
} from 'generated/graphql';

import UI from 'ui';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import { EditInfoSectionsProps } from '../../../types';
import { StyledEditSection } from '../StyledEditSection';

const EditAccounts = ({
  patient,
  setIsEditable,
}: EditInfoSectionsProps): JSX.Element => {
  const [updatePatientMutation] = usePatientUpdatePatientMutation();
  const { setToastMessage } = useToastMessage();

  const { formState, getData, setData, getErrors, setErrors } = useForm({
    teladocUser: {
      field: 'teladocUser',
      value: patient?.teladocUser ?? '',
    },
    teladocPass: {
      field: 'teladocPass',
      value: patient?.teladocPass ?? '',
    },
    globalHumanId: {
      field: 'globalHumanId',
      value: patient?.humanizedGlobalHumanId ?? '',
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = getErrors();

    const data = getData<PatientPatchRecordInput>();
    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
      return;
    }

    if (!patient?.patientId) return;

    const { patientId } = patient;
    const patch = {
      teladocUser: data?.teladocUser,
      teladocPass: data?.teladocPass,
    };

    try {
      const updatedPatient = await updatePatientMutation({
        variables: {
          input: {
            patientId,
            patch,
          },
        },
      });
      if (updatedPatient) {
        setToastMessage(
          `Successfully updated the patient's information`,
          UI.Toast.SeverityLevel.Success,
        );
      }
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update the patient's information`,
        UI.Toast.SeverityLevel.Error,
      );
    } finally {
      if (setIsEditable) setIsEditable(false);
    }
  };

  return (
    <StyledEditSection>
      <div className="heading">
        <p>Accounts</p>
      </div>

      <form className="body" onSubmit={handleSubmit} autoComplete="off">
        <p>All fields below, unless otherwise noted, are required.</p>

        <div className="fields">
          <div className="inputWrapper">
            <UI.Input
              id="teladocUser"
              name="teladocUser"
              label="Teladoc Login"
              fullWidth
              optional
              onChange={(event) => setData('teladocUser', event.target.value)}
              value={(formState.teladocUser.value as string) ?? ''}
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="teladocPass"
              name="teladocPass"
              label="Teladoc Password"
              fullWidth
              optional
              onChange={(event) => setData('teladocPass', event.target.value)}
              value={(formState.teladocPass.value as string) ?? ''}
            />
          </div>
          <UI.Input
            id="globalHumanId"
            name="globalHumanId"
            label="Patient ID"
            disabled={true}
            fullWidth
            // TODO: When the onChange implementation is made, ensure that the globalHumanId value is saved in lowercase to the db to match ReadyOps.
            // onChange={(event) => setData('globalHumanId', event.target.value)}
            value={(formState.globalHumanId.value as string) ?? ''}
          />
        </div>

        <div className="buttonContainer">
          <UI.Button
            variant="tertiary"
            className="closeButton"
            onClick={() => setIsEditable && setIsEditable(false)}
          >
            Close
          </UI.Button>
          <UI.Button variant="primary" className="saveButton" type="submit">
            Save
          </UI.Button>
        </div>
      </form>
    </StyledEditSection>
  );
};

export default EditAccounts;
