const Logo = (): JSX.Element => (
  <svg
    width="68"
    height="24"
    viewBox="0 0 68 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0312 15.8685C14.1599 15.8685 15.8914 14.133 15.8914 12.0002C15.8914 9.86697 14.1599 8.13153 12.0312 8.13153C9.90261 8.13153 8.17106 9.86697 8.17106 12.0002C8.17106 14.1334 9.90261 15.8685 12.0312 15.8685ZM9.22249 12.0002C9.22249 10.4483 10.4825 9.18578 12.0312 9.18578C13.58 9.18578 14.84 10.4483 14.84 12.0002C14.84 13.5521 13.58 14.8146 12.0312 14.8146C10.4825 14.8146 9.22249 13.5521 9.22249 12.0002Z"
      fill="#F70B0B"
    />
    <path
      d="M6.14232 12.0002C6.14232 15.2543 8.78413 17.9014 12.0312 17.9014C15.2783 17.9014 17.9201 15.254 17.9201 12.0002C17.9201 8.74601 15.2783 6.09856 12.0312 6.09856C8.78413 6.09856 6.14232 8.74601 6.14232 12.0002ZM7.19375 12.0002C7.19375 9.32731 9.36366 7.15281 12.0309 7.15281C14.6981 7.15281 16.868 9.32731 16.868 12.0002C16.868 14.673 14.6981 16.8475 12.0309 16.8475C9.36402 16.8475 7.19375 14.673 7.19375 12.0002Z"
      fill="#F70B0B"
    />
    <path
      d="M15.0016 2.49356C14.0444 2.19074 13.0378 2.03791 12.0312 2.03509C11.0246 2.03791 10.018 2.19074 9.06083 2.49356C8.78448 2.58109 8.63024 2.87757 8.71777 3.15463C8.7873 3.37487 8.98883 3.52311 9.21896 3.52311C9.2726 3.52311 9.3259 3.51499 9.37743 3.4984C10.233 3.22769 11.1319 3.0911 12.0312 3.08828C12.9305 3.0911 13.8298 3.22769 14.685 3.4984C14.7366 3.51464 14.7899 3.52311 14.8435 3.52311C15.074 3.52311 15.2755 3.37487 15.3447 3.15463C15.4319 2.87792 15.278 2.58109 15.0016 2.49356Z"
      fill="#F70B0B"
    />
    <path
      d="M14.9734 0.371652C14.0113 0.126002 13.0216 0.00211768 12.0312 0C11.0409 0.00211768 10.0508 0.126002 9.08907 0.371652C8.80813 0.443654 8.63801 0.730952 8.70965 1.0126C8.7693 1.24625 8.9786 1.40931 9.21896 1.40931C9.26272 1.40931 9.30649 1.40367 9.34919 1.39273C10.2259 1.16861 11.1284 1.05566 12.0316 1.05354C12.9348 1.05566 13.8373 1.16861 14.714 1.39273C14.7567 1.40367 14.8004 1.40931 14.8442 1.40931C15.0846 1.40931 15.2939 1.24625 15.3535 1.0126C15.4245 0.731305 15.2543 0.443654 14.9734 0.371652Z"
      fill="#F70B0B"
    />
    <path
      d="M15.0454 4.6723C14.0879 4.27312 13.0738 4.06947 12.0312 4.06594C10.9886 4.06947 9.97462 4.27312 9.01707 4.6723C8.74919 4.78419 8.62213 5.09301 8.73366 5.36125C8.81554 5.55855 9.00613 5.68596 9.21931 5.68596C9.28849 5.68596 9.35625 5.67255 9.42084 5.64573C10.2496 5.29984 11.1277 5.12337 12.0312 5.11984C12.9351 5.12337 13.8129 5.29984 14.6416 5.64573C14.7062 5.67255 14.774 5.68596 14.8432 5.68596C15.0563 5.68596 15.2469 5.55855 15.3288 5.36125C15.44 5.09337 15.3129 4.78419 15.0454 4.6723Z"
      fill="#F70B0B"
    />
    <path
      d="M9.06083 21.5064C10.018 21.8093 11.0246 21.9621 12.0312 21.9649C13.0378 21.9621 14.0444 21.8093 15.0016 21.5064C15.278 21.4189 15.4322 21.1224 15.3447 20.8454C15.2752 20.6251 15.0736 20.4769 14.8435 20.4769C14.7899 20.4769 14.7366 20.485 14.685 20.5016C13.8295 20.7723 12.9305 20.9089 12.0312 20.9117C11.1319 20.9089 10.2326 20.7723 9.37742 20.5016C9.32589 20.4854 9.2726 20.4769 9.21895 20.4769C8.98848 20.4769 8.78695 20.6251 8.71777 20.8454C8.63024 21.1224 8.78412 21.4189 9.06083 21.5064Z"
      fill="#F70B0B"
    />
    <path
      d="M9.08907 23.6283C10.0508 23.874 11.0409 23.9982 12.0312 24C13.0216 23.9979 14.0116 23.874 14.9734 23.6283C15.2543 23.5563 15.4245 23.269 15.3528 22.9874C15.2932 22.7537 15.0839 22.5907 14.8435 22.5907C14.7997 22.5907 14.756 22.5963 14.7133 22.6073C13.8365 22.8314 12.9341 22.9443 12.0309 22.9465C11.1277 22.9443 10.2252 22.8314 9.34848 22.6073C9.30578 22.5963 9.26201 22.5907 9.21825 22.5907C8.97789 22.5907 8.76859 22.7537 8.70895 22.9874C8.638 23.269 8.80812 23.5567 9.08907 23.6283Z"
      fill="#F70B0B"
    />
    <path
      d="M9.01707 19.328C9.97462 19.7272 10.9886 19.9309 12.0312 19.9344C13.0738 19.9309 14.0879 19.7272 15.0454 19.328C15.3133 19.2162 15.4403 18.9073 15.3288 18.6391C15.2469 18.4418 15.0563 18.3144 14.8432 18.3144C14.774 18.3144 14.7062 18.3278 14.6416 18.3546C13.8129 18.7005 12.9348 18.877 12.0312 18.8805C11.1273 18.877 10.2496 18.7005 9.42084 18.3546C9.35625 18.3278 9.28849 18.3144 9.21931 18.3144C9.00613 18.3144 8.81554 18.4418 8.73366 18.6391C8.62213 18.907 8.74919 19.2162 9.01707 19.328Z"
      fill="#F70B0B"
    />
    <path
      d="M5.2836 4.67442C4.54277 5.35208 3.90711 6.14727 3.40134 7.01763C2.90051 7.89082 2.52956 8.83884 2.31321 9.81932C2.25073 10.1024 2.43074 10.384 2.71415 10.4472C2.93969 10.4973 3.16875 10.3967 3.28381 10.1973C3.31063 10.1507 3.33004 10.1006 3.34169 10.048C3.53511 9.17202 3.86617 8.32495 4.31335 7.54458C4.76548 6.76704 5.33337 6.05691 5.99549 5.45126C6.03538 5.4149 6.06926 5.3729 6.09608 5.32631C6.21114 5.1269 6.18362 4.87843 6.02761 4.70795C5.83138 4.49371 5.49749 4.47889 5.2836 4.67442Z"
      fill="#F70B0B"
    />
    <path
      d="M3.43169 3.63817C2.73815 4.3483 2.13568 5.14349 1.63873 6.00009C1.14531 6.85881 0.757773 7.77823 0.489534 8.73401C0.41118 9.01319 0.5753 9.30437 0.854833 9.38308C1.08707 9.44837 1.33308 9.34849 1.45308 9.1406C1.47496 9.10284 1.4919 9.06225 1.5039 9.01954C1.74814 8.14812 2.10144 7.31022 2.55109 6.52704C3.00428 5.74597 3.55346 5.02101 4.18594 4.37371C4.21665 4.3423 4.24347 4.307 4.26535 4.26924C4.38536 4.061 4.349 3.79806 4.17641 3.6297C3.96853 3.42711 3.63464 3.43064 3.43169 3.63817Z"
      fill="#F70B0B"
    />
    <path
      d="M7.19199 5.72585C6.3675 6.35551 5.6842 7.13164 5.15972 8.03306C4.64124 8.93766 4.31089 9.9178 4.17782 10.9466C4.14077 11.2343 4.34477 11.499 4.63277 11.5368C4.84454 11.5643 5.04995 11.463 5.15654 11.2784C5.19113 11.2184 5.21337 11.1531 5.22255 11.0836C5.33761 10.1931 5.62349 9.34426 6.07244 8.56001C6.52739 7.77894 7.11928 7.10693 7.83294 6.56198C7.88835 6.51927 7.93388 6.46739 7.96847 6.40774C8.07506 6.22315 8.05988 5.99444 7.93035 5.82503C7.75388 5.59456 7.42281 5.55008 7.19199 5.72585Z"
      fill="#F70B0B"
    />
    <path
      d="M18.7789 4.67442C19.5197 5.35208 20.1553 6.14727 20.6611 7.01763C21.1619 7.89082 21.5329 8.83884 21.7493 9.81932C21.8117 10.1024 21.6317 10.384 21.3483 10.4472C21.1228 10.4973 20.8937 10.3967 20.7787 10.1973C20.7518 10.1507 20.7324 10.1006 20.7208 10.048C20.5274 9.17202 20.1963 8.32495 19.7491 7.54458C19.297 6.76704 18.7291 6.05691 18.067 5.45126C18.0271 5.4149 17.9932 5.3729 17.9664 5.32631C17.8513 5.1269 17.8788 4.87843 18.0348 4.70795C18.2311 4.49371 18.5646 4.47889 18.7789 4.67442Z"
      fill="#F70B0B"
    />
    <path
      d="M20.6304 3.63817C21.3243 4.3483 21.9264 5.14349 22.4234 6.00009C22.9168 6.85881 23.3043 7.77823 23.5726 8.73401C23.6509 9.01319 23.4868 9.30437 23.2073 9.38308C22.975 9.44837 22.729 9.34849 22.609 9.1406C22.5872 9.10284 22.5702 9.06225 22.5582 9.01954C22.314 8.14812 21.9607 7.31022 21.511 6.52704C21.0578 5.74597 20.5087 5.02101 19.8762 4.37371C19.8455 4.3423 19.8186 4.307 19.7968 4.26924C19.6768 4.061 19.7131 3.79806 19.8857 3.6297C20.0936 3.42711 20.4278 3.43064 20.6304 3.63817Z"
      fill="#F70B0B"
    />
    <path
      d="M16.8701 5.72585C17.6946 6.35551 18.3779 7.13164 18.9024 8.03306C19.4209 8.93766 19.7512 9.9178 19.8843 10.9466C19.9213 11.2343 19.7173 11.499 19.4293 11.5368C19.2176 11.5643 19.0122 11.463 18.9056 11.2784C18.871 11.2184 18.8487 11.1531 18.8396 11.0836C18.7245 10.1931 18.4386 9.34426 17.9897 8.56001C17.5347 7.77894 16.9428 7.10693 16.2292 6.56198C16.1738 6.51927 16.1282 6.46739 16.0936 6.40774C15.9871 6.22315 16.0022 5.99444 16.1318 5.82503C16.3082 5.59456 16.6397 5.55008 16.8701 5.72585Z"
      fill="#F70B0B"
    />
    <path
      d="M5.2836 19.3259C4.54277 18.6483 3.90711 17.8531 3.40134 16.9827C2.90051 16.1095 2.52956 15.1615 2.31321 14.181C2.25073 13.898 2.43074 13.6163 2.71415 13.5531C2.93969 13.503 3.16875 13.6036 3.28381 13.803C3.31063 13.8496 3.33004 13.8997 3.34169 13.9523C3.53511 14.8283 3.86617 15.6754 4.31335 16.4558C4.76548 17.2333 5.33337 17.9434 5.99549 18.5491C6.03538 18.5854 6.06926 18.6275 6.09608 18.674C6.21114 18.8735 6.18362 19.1219 6.02761 19.2924C5.83138 19.5066 5.49749 19.5215 5.2836 19.3259Z"
      fill="#F70B0B"
    />
    <path
      d="M3.43169 20.3622C2.73815 19.6521 2.13568 18.8569 1.63873 18.0003C1.14531 17.1415 0.757773 16.2221 0.489534 15.2663C0.41118 14.9872 0.5753 14.696 0.854833 14.6173C1.08707 14.552 1.33308 14.6519 1.45308 14.8597C1.47496 14.8975 1.4919 14.9381 1.5039 14.9808C1.74814 15.8522 2.10144 16.6901 2.55109 17.4733C3.00428 18.2544 3.55346 18.9793 4.18594 19.6266C4.21665 19.6581 4.24347 19.6933 4.26535 19.7311C4.38536 19.9394 4.349 20.2023 4.17641 20.3707C3.96853 20.5732 3.63464 20.5697 3.43169 20.3622Z"
      fill="#F70B0B"
    />
    <path
      d="M7.19199 18.2745C6.3675 17.6448 5.6842 16.8687 5.15972 15.9673C4.64124 15.0627 4.31089 14.0826 4.17782 13.0537C4.14077 12.7661 4.34477 12.5014 4.63277 12.4636C4.84454 12.4361 5.04995 12.5374 5.15654 12.722C5.19113 12.782 5.21337 12.8472 5.22255 12.9168C5.33761 13.8073 5.62349 14.6561 6.07244 15.4403C6.52739 16.2214 7.11928 16.8934 7.83294 17.4384C7.88835 17.4811 7.93388 17.533 7.96847 17.5926C8.07506 17.7772 8.05988 18.0059 7.93035 18.1753C7.75388 18.4058 7.42281 18.4503 7.19199 18.2745Z"
      fill="#F70B0B"
    />
    <path
      d="M18.7789 19.3259C19.5197 18.6483 20.1553 17.8531 20.6611 16.9827C21.1619 16.1095 21.5329 15.1615 21.7493 14.181C21.8117 13.898 21.6317 13.6163 21.3483 13.5531C21.1228 13.503 20.8937 13.6036 20.7787 13.803C20.7518 13.8496 20.7324 13.8997 20.7208 13.9523C20.5274 14.8283 20.1963 15.6754 19.7491 16.4558C19.297 17.2333 18.7291 17.9434 18.067 18.5491C18.0271 18.5854 17.9932 18.6275 17.9664 18.674C17.8513 18.8735 17.8788 19.1219 18.0348 19.2924C18.2311 19.5066 18.5646 19.5215 18.7789 19.3259Z"
      fill="#F70B0B"
    />
    <path
      d="M20.6304 20.3622C21.3243 19.6521 21.9264 18.8569 22.4234 18.0003C22.9168 17.1415 23.3043 16.2221 23.5726 15.2663C23.6509 14.9872 23.4868 14.696 23.2073 14.6173C22.975 14.552 22.729 14.6519 22.609 14.8597C22.5872 14.8975 22.5702 14.9381 22.5582 14.9808C22.314 15.8522 21.9607 16.6901 21.511 17.4733C21.0578 18.2544 20.5087 18.9793 19.8762 19.6266C19.8455 19.6581 19.8186 19.6933 19.7968 19.7311C19.6768 19.9394 19.7131 20.2023 19.8857 20.3707C20.0936 20.5732 20.4278 20.5697 20.6304 20.3622Z"
      fill="#F70B0B"
    />
    <path
      d="M16.8701 18.2745C17.6946 17.6448 18.3779 16.8687 18.9024 15.9673C19.4209 15.0627 19.7512 14.0826 19.8843 13.0537C19.9213 12.7661 19.7173 12.5014 19.4293 12.4636C19.2176 12.4361 19.0122 12.5374 18.9056 12.722C18.871 12.782 18.8487 12.8472 18.8396 12.9168C18.7245 13.8073 18.4386 14.6561 17.9897 15.4403C17.5347 16.2214 16.9428 16.8934 16.2292 17.4384C16.1738 17.4811 16.1282 17.533 16.0936 17.5926C15.9871 17.7772 16.0022 18.0059 16.1318 18.1753C16.3082 18.4058 16.6397 18.4503 16.8701 18.2745Z"
      fill="#F70B0B"
    />
    <path
      d="M28.0643 6.969C27.7053 6.969 27.4127 7.28383 27.4127 7.63855V16.4941C27.4127 16.8488 27.705 17.1375 28.0643 17.1375C28.4232 17.1375 28.7158 16.8488 28.7158 16.4941V12.8657H30.6549L33.0017 16.8195C33.1182 17.0157 33.3338 17.1375 33.5643 17.1375C33.6793 17.1375 33.793 17.1071 33.8929 17.0492C34.0436 16.9621 34.1509 16.8219 34.1943 16.6547C34.2377 16.4888 34.2134 16.3162 34.1255 16.1686L32.0942 12.7468C33.3659 12.3829 34.2511 11.2238 34.2511 9.91719C34.2511 8.29151 32.9113 6.96865 31.2641 6.96865H28.0643V6.969ZM32.9484 9.91719C32.9484 10.8335 32.1931 11.5789 31.2645 11.5789H28.7155V8.2788L31.2669 8.2555C32.1941 8.25692 32.9484 9.00235 32.9484 9.91719Z"
      fill="#FBFBFB"
    />
    <path
      d="M42.0898 12.6176C42.0824 12.5033 42.0594 12.3914 42.021 12.2848C41.596 10.6044 40.138 9.43083 38.4745 9.43083C36.4521 9.43083 34.8067 11.1497 34.8067 13.2628C34.8067 15.3756 36.4521 17.0944 38.4745 17.0944C39.4045 17.0944 40.4863 16.9557 41.4594 15.9272C41.5784 15.8016 41.6405 15.6382 41.6345 15.4666C41.6285 15.2947 41.5551 15.1352 41.4276 15.018C41.165 14.7759 40.7524 14.79 40.5071 15.0491C39.9093 15.6809 39.2859 15.8079 38.4742 15.8079C37.3952 15.8079 36.4581 15.0276 36.1863 13.9062H40.6829C40.6974 13.9066 40.7115 13.9066 40.7253 13.9066C41.2642 13.9066 41.6532 13.7658 41.8822 13.4876C42.19 13.1142 42.0937 12.6346 42.0898 12.6176ZM40.7623 12.6194H36.1867C36.4585 11.4981 37.3955 10.7173 38.4745 10.7173C39.5535 10.7177 40.4902 11.4981 40.7623 12.6194Z"
      fill="#FBFBFB"
    />
    <path
      d="M49.7495 9.60589C49.3902 9.60589 49.098 9.8946 49.098 10.2493V10.5564C48.409 9.8579 47.5051 9.47389 46.5458 9.47389C44.5234 9.47389 42.8779 11.1928 42.8779 13.3055C42.8779 15.4183 44.5234 17.1375 46.5458 17.1375C47.5051 17.1375 48.4093 16.7535 49.098 16.055V16.3621C49.098 16.7168 49.3902 17.0055 49.7495 17.0055C50.1084 17.0055 50.401 16.7168 50.401 16.3621V10.2493C50.4007 9.8946 50.1084 9.60589 49.7495 9.60589ZM48.9105 13.3055C48.9105 14.7088 47.8496 15.8506 46.5458 15.8506C45.242 15.8506 44.181 14.7088 44.181 13.3055C44.181 11.9022 45.242 10.7604 46.5458 10.7604C47.8496 10.7604 48.9105 11.9018 48.9105 13.3055Z"
      fill="#FBFBFB"
    />
    <path
      d="M58.2934 6.23981C57.9344 6.23981 57.6418 6.52852 57.6418 6.88324V10.5529C56.9532 9.85648 56.0507 9.47353 55.0932 9.47353C53.0708 9.47353 51.4253 11.1924 51.4253 13.3055C51.4253 15.4186 53.0708 17.1375 55.0932 17.1375C56.0507 17.1375 56.9536 16.7549 57.6418 16.0582V16.3617C57.6418 16.7164 57.9341 17.0051 58.2934 17.0051C58.6527 17.0051 58.9449 16.7164 58.9449 16.3617V6.88288C58.9449 6.52817 58.6527 6.23981 58.2934 6.23981ZM57.4583 13.3055C57.4583 14.7088 56.3973 15.8506 55.0935 15.8506C53.7897 15.8506 52.7287 14.7088 52.7287 13.3055C52.7287 11.9022 53.7897 10.7604 55.0935 10.7604C56.3973 10.7604 57.4583 11.9022 57.4583 13.3055Z"
      fill="#FBFBFB"
    />
    <path
      d="M67.4895 10.1865C67.4288 10.0245 67.3073 9.89566 67.1478 9.82437C66.8213 9.67789 66.4341 9.82119 66.2856 10.1441L63.7598 15.6311L61.2924 10.2317C61.1448 9.90837 60.7587 9.76436 60.4308 9.90978C60.2713 9.98072 60.1495 10.1092 60.0881 10.2712C60.0278 10.4311 60.0334 10.6051 60.1047 10.7607L63.0423 17.1894L62.6512 18.0389L62.6417 18.0573L62.6336 18.0802C62.6177 18.119 62.4673 18.466 62.126 18.7491C61.675 19.1235 61.0774 19.2199 60.3503 19.0367C60.0034 18.9488 59.6469 19.1571 59.558 19.5005C59.5153 19.6657 59.5396 19.8379 59.6272 19.9851C59.7157 20.1344 59.858 20.2403 60.0281 20.2833C60.3733 20.3705 60.7104 20.4146 61.0294 20.4146C61.9849 20.4146 62.6399 20.0183 63.0215 19.6854C63.5484 19.2259 63.7846 18.7042 63.8438 18.5563L67.4711 10.676C67.5435 10.5207 67.5495 10.3467 67.4895 10.1865Z"
      fill="#FBFBFB"
    />
  </svg>
);

export default Logo;
