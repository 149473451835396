import { Control, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form';

import { VaccineLocationEnum } from 'generated/graphql';

import { Tokens } from 'config';
import { getDrugIsExpired } from 'utils';
import { Controlled } from 'forms';
import UI from 'ui';
import { Option } from 'ui/Select/Select';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import StaticStandingOrders from '../StaticStandingOrders';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import {
  getDefaultValues,
  getSelectedDrugVaccineLookup,
  TInputs,
} from './utils';

const VaccineForm = ({
  control,
  errors,
  formState,
  inputsAreDisabled,
  setValue,
  taskHeaderProps,
  taskIsComplete,
  taskIsSkipped,
}: {
  control: Control<TInputs>;
  errors: DeepMap<TInputs, FieldError>;
  formState: TInputs;
  inputsAreDisabled: boolean;
  setValue: UseFormSetValue<TInputs>;
  taskHeaderProps: TaskHeaderProps;
  taskIsComplete: boolean;
  taskIsSkipped: boolean;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const defaultValues = getDefaultValues(task);

  const {
    contraindication,
    drugId,
    hadAdverseReaction,
    lotExpiration,
    lotNumber,
    lotNumberAttachment,
    standingOrderAddedAt,
    standingOrderId,
    visEdition,
  } = formState;

  const vaccineIsExpired = Boolean(
    lotExpiration && getDrugIsExpired(task, lotExpiration),
  );

  const sectionTwoIsDisabled =
    inputsAreDisabled ||
    typeof drugId !== 'number' ||
    typeof contraindication !== 'boolean';
  const sectionThreeIsDisabled =
    sectionTwoIsDisabled || !standingOrderId || Boolean(contraindication);
  const sectionFourIsDisabled = sectionThreeIsDisabled || !visEdition;
  const sectionFiveIsDisabled =
    sectionFourIsDisabled ||
    !lotNumber ||
    !lotNumberAttachment ||
    !lotExpiration ||
    vaccineIsExpired;

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
        style={{ paddingBottom: `calc(${Tokens.rhythm} * 6)` }}
      >
        <header>
          <h3>{task?.taskName}</h3>
          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
        </header>

        <p className="formInstructionsLabel">
          1. Confirm vaccine and contraindication screening status
        </p>

        <section>
          {task?.vaccineLookups && task?.vaccineLookups.length > 0 ? (
            <Controlled.Select
              name="drugId"
              control={control}
              label="Select the vaccine"
              options={task?.vaccineLookups.map(
                (lookup): Option => ({
                  label: lookup?.drug?.drugName ?? '',
                  value: lookup?.drug?.drugId ?? -1,
                }),
              )}
              disabled={inputsAreDisabled}
            />
          ) : null}

          <Controlled.RadioButtonGroup
            control={control}
            disabled={inputsAreDisabled}
            label="Does the patient have a contraindication?"
            name="contraindication"
            options={['Yes', 'No']}
          />

          {contraindication ? (
            <div style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}>
              <UI.AlertBox
                label="Skip administering vaccine to patient"
                message={
                  <>
                    <p>
                      Explain to the patient that based on their
                      contraindication, Ready will not administer their vaccine
                      in-home and that they should reach out to their PCP.
                      Please inform your supervisor then skip this task.
                    </p>

                    {!taskIsComplete && !taskIsSkipped ? (
                      <UI.Button
                        variant="primary"
                        onClick={taskHeaderProps.onTaskSkip}
                        disabled={taskHeaderProps.isSaving}
                      >
                        Skip Task
                      </UI.Button>
                    ) : null}
                  </>
                }
                severity={UI.AlertBox.SeverityLevel.Warning}
              />
            </div>
          ) : null}
        </section>

        <p className="formInstructionsLabel">
          2. Review/add the relevant Standing Order for the vaccine
        </p>

        <section>
          {defaultValues.drugId || drugId ? (
            <StaticStandingOrders
              disabled={sectionTwoIsDisabled}
              hasError={Boolean(errors.standingOrderId)}
              standingOrderId={standingOrderId}
              standingOrderOptions={
                getSelectedDrugVaccineLookup(formState, task)?.standingOrders
              }
              standingOrderAddedAt={standingOrderAddedAt}
              onAddStandingOrder={(order) => {
                setValue('standingOrderId', order.standingOrderId!, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                setValue('standingOrderAddedAt', new Date().toISOString(), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              onDeleteStandingOrder={() => {
                setValue('standingOrderId', undefined, { shouldDirty: true });
                setValue('standingOrderAddedAt', undefined, {
                  shouldDirty: true,
                });
              }}
            />
          ) : (
            <p>Select a vaccine to view standing orders</p>
          )}
          {errors.standingOrderId ? (
            <p style={{ color: Tokens.color.ui.error.base }}>
              {errors.standingOrderId.message}
            </p>
          ) : null}
        </section>

        <p className="formInstructionsLabel">
          3. Review the Vaccine Information Statement (VIS) with patient, record
          edition date, and give to patient
        </p>

        <section>
          <Controlled.Datepicker
            control={control}
            disabled={sectionThreeIsDisabled}
            fullWidth
            label="VIS edition date"
            name="visEdition"
            filterFutureDateAndTime
            format="MM/dd/yyyy"
            timeZone="UTC"
          />
        </section>

        <p className="formInstructionsLabel">4. Document vaccine details</p>

        <section>
          <Controlled.Input
            control={control}
            disabled={sectionFourIsDisabled}
            defaultValue=""
            fullWidth
            label="Vaccine Lot #"
            name="lotNumber"
          />

          <Controlled.ImageInput
            control={control}
            description="Vaccine Lot #"
            disabled={sectionFourIsDisabled}
            defaultValue=""
            folder={`${task?.encounter?.encounterId}`}
            fullWidth
            label="Photo Documentation"
            name="lotNumberAttachment"
          />

          <Controlled.Datepicker
            control={control}
            disabled={sectionFourIsDisabled}
            format="MM/dd/yyyy"
            fullWidth
            label="Lot Expiration Date"
            name="lotExpiration"
            timeZone="UTC"
          />

          {!taskIsComplete && vaccineIsExpired ? (
            <div style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}>
              <UI.AlertBox
                label="Discard expired vaccine"
                message={
                  <>
                    <p>
                      Because this vaccine has expired, you must discard the
                      expired vaccine.
                    </p>
                  </>
                }
                severity={UI.AlertBox.SeverityLevel.Warning}
              />
            </div>
          ) : null}
        </section>

        <p className="formInstructionsLabel">
          5. Administer the vaccine and record adverse reactions (if any)
        </p>

        <section>
          <Controlled.Select
            control={control}
            disabled={sectionFiveIsDisabled}
            label="Location"
            name="location"
            options={[
              {
                label: 'Left deltoid muscle',
                value: VaccineLocationEnum.LEFT_DELTOID_MUSCLE,
              },
              {
                label: 'Right deltoid muscle',
                value: VaccineLocationEnum.RIGHT_DELTOID_MUSCLE,
              },
              {
                label: 'Left anterolateral thigh muscle',
                value: VaccineLocationEnum.LEFT_ANTEROLATERAL_THIGH_MUSCLE,
              },
              {
                label: 'Right anterolateral thigh muscle',
                value: VaccineLocationEnum.RIGHT_ANTEROLATERAL_THIGH_MUSCLE,
              },
            ]}
            sortedOptions={false}
          />

          <div className="fieldGroup padTop">
            <div>
              <Controlled.Input
                control={control}
                defaultValue=""
                disabled={sectionFiveIsDisabled}
                fullWidth
                inputMode="decimal"
                label="Dose Number"
                name="doseNumber"
              />
            </div>

            <div>
              <Controlled.Input
                control={control}
                defaultValue=""
                disabled={sectionFiveIsDisabled}
                fullWidth
                inputMode="decimal"
                label="Total Doses"
                name="totalDoses"
              />
            </div>
          </div>

          <Controlled.RadioButtonGroup
            control={control}
            disabled={sectionFiveIsDisabled}
            label="Was there an adverse reaction?"
            name="hadAdverseReaction"
            options={['Yes', 'No']}
          />

          {hadAdverseReaction ? (
            <div style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}>
              <UI.AlertBox
                label="Adverse Reaction Escalation"
                message={
                  <>
                    <p>
                      If there is a potential anaphlyatic reaction, administer
                      an Epi-Pen then call 911. For minor or mild reaction,
                      administer Zyrtec (Cetirizine 10mg) then escalate to a
                      clinician.
                    </p>
                  </>
                }
                severity={UI.AlertBox.SeverityLevel.Warning}
              />
            </div>
          ) : null}
        </section>

        <p className="formInstructionsLabel">
          6. Prepare and document leave behind materials for patient
        </p>

        <section>
          <Controlled.ImageInput
            control={control}
            description="Vaccination Letter"
            disabled={sectionFiveIsDisabled}
            defaultValue=""
            folder={`${task?.encounter?.encounterId}`}
            fullWidth
            label="Photo Documentation"
            name="vaccinationLetterAttachment"
          />
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default VaccineForm;
