import { Control, DeepMap, FieldError } from 'react-hook-form';

import { Tokens } from 'config';
import UI from 'ui';
import { Controlled } from 'forms';
import { StyledTaskForm } from '../TaskForm';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { PatientIdInputs } from './utils';

const VerifyPatientDemographics = ({
  control,
  inputsAreDisabled,
  taskHeaderProps,
}: {
  control: Control<PatientIdInputs>;
  errors: DeepMap<PatientIdInputs, FieldError>;
  inputsAreDisabled: boolean;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const alertBoxLabel = `Review and make edits in the patient's profile`;
  const alertBoxMessage = `Please navigate to the patient's profile tab to review the patient's information. If any edits are necessary or info is missing, make the changes in their profile.`;

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
        style={{ paddingBottom: `calc(${Tokens.rhythm} * 6)` }}
      >
        <header>
          <h3>{task?.taskName}</h3>

          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
        </header>

        <p className="formInstructionsLabel">
          1. Confirm with the patient that their profile info is correct
        </p>
        <section>
          <UI.AlertBox
            label={alertBoxLabel}
            message={alertBoxMessage}
            severity={UI.AlertBox.SeverityLevel.Tip}
          ></UI.AlertBox>
        </section>

        <p className="formInstructionsLabel">2. Document the patient’s ID</p>
        <>
          <section>
            <Controlled.ImageInput
              className="patientIdFrontAttachment"
              control={control}
              description="Patient ID (Front)"
              disabled={inputsAreDisabled}
              folder={`${task?.encounter?.encounterId}`}
              fullWidth
              label="Photo Documentation"
              name="patientIdFrontAttachment"
            />

            <Controlled.ImageInput
              className="patientIdBackAttachment"
              control={control}
              description="Patient ID (Back)"
              disabled={inputsAreDisabled}
              folder={`${task?.encounter?.encounterId}`}
              fullWidth
              label=" "
              name="patientIdBackAttachment"
            />
          </section>
        </>

        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};
export default VerifyPatientDemographics;
