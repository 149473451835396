import { yup } from 'forms';

const errorMessage = 'Please provide a value';

const FormSchema = yup.object({
  isFirstSet: yup.boolean(), // helper field

  // Height Feet
  height: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Height Inches
  heightInches: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Height: Measured = true, Patient-Stated = false
  measuredHeight: yup.boolean().when('isFirstSet', {
    is: true,
    then: yup.boolean().required(errorMessage),
  }),

  // Weight
  weight: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Weight: Measured = true, Patient-Stated = false
  measuredWeight: yup.boolean().when('isFirstSet', {
    is: true,
    then: yup.boolean().required(errorMessage),
  }),

  // Measured At
  measuredAt: yup.string().required(errorMessage),

  // Body Position
  bodyPosition: yup.string().required(errorMessage),

  // Systolic Blood Pressure
  systolicBloodPressure: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Diastolic Blood Pressure
  diastolicBloodPressure: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Blood Pressure Location
  bloodPressureLocation: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Heart Rate
  heartRate: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Oxygen Saturation
  oxygenSaturation: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Body Temperature
  bodyTemperature: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Respiratory Rate
  respiratoryRate: yup.string().when('isFirstSet', {
    is: true,
    then: yup.string().required(errorMessage),
  }),

  // Note
  note: yup.string(),
});

export type TInputs = yup.InferType<typeof FormSchema>;

export default FormSchema;
