/**
 *
 * The Teledoc Task Form is deprecated, no need to further refactor it.
 * However, it needs to stay in the repo for past encounters that leverage
 * the 'teladoc' encounter task.
 *
 * */
import { useEffect, useState } from 'react';
import { Copy } from 'phosphor-react';

import {
  usePatientUpdatePatientMutation,
  useSearchCliniciansLazyQuery,
  useUpdateEncounterMutation,
  useUpdateEncounterTaskMutation,
  GetEncounterTaskByEncounterTaskIdQuery,
  User,
  Maybe,
} from 'generated/graphql';

import { useAutosave, useToastMessage, useForm, useTask } from 'hooks';
import UI from 'ui';
import { Logger } from 'utils';
import { Option } from 'ui/TypeAhead/TypeAhead';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader from '../TaskHeader';

const TeladocForm = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const { setToastMessage } = useToastMessage();

  const [updateEncounter] = useUpdateEncounterMutation();
  const [updateEncounterTask] = useUpdateEncounterTaskMutation();
  const [updatePatientMutation] = usePatientUpdatePatientMutation();

  const [clinicianOptions, setClinicianOptions] = useState<Option[]>([]);
  const [taskIsSaving, setTaskIsSaving] = useState(false);

  const {
    encounterIsComplete,
    inputsAreDisabled,
    taskIsComplete,
    taskIsSkipped,
  } = useTask(task);

  const formTitle = task?.taskName;
  const patient = task?.encounter?.patient;
  const clinician: Maybe<Partial<User>> = task?.encounter?.clinician ?? null;

  const [getClinicians, { loading: cliniciansLoading, data: cliniciansData }] =
    useSearchCliniciansLazyQuery({ fetchPolicy: 'network-only' });

  const { formState, setData, getErrors, setErrors, isDirty, cleanForm } =
    useForm({
      teladocUser: {
        errorMessage: 'Please enter a valid Teladoc login',
        field: 'teladocUser',
        required: true,
        value: patient?.teladocUser ?? '',
      },
      teladocPass: {
        errorMessage: 'Please enter a valid Teladoc password',
        field: 'teladocPass',
        required: true,
        value: patient?.teladocPass ?? '',
      },
      clinicianId: {
        errorMessage: 'Please select a valid Clinician name',
        field: 'clinicianId',
        required: true,
        value: clinician?.userId,
      },
    });

  const copyText = (fieldName: string) => {
    const copiedText = document.getElementById(fieldName);
    (copiedText as HTMLInputElement).select();
    document.execCommand('copy');

    setToastMessage('Text copied to clipboard', UI.Toast.SeverityLevel.Success);
  };

  const handleInputChange = (query?: string) => {
    if (!query) return;
    setClinicianOptions([]);
    getClinicians({ variables: { query } });
  };

  const onClinicianClick = (value: Record<string, unknown> | null) => {
    setData('clinicianId', (value?.userId as number) ?? null);
  };

  const saveTeladocInfo = async (currentState = formState) => {
    const patch = {
      teladocUser: (currentState.teladocUser.value as string) ?? null,
      teladocPass: (currentState?.teladocPass.value as string) ?? null,
    };

    await updatePatientMutation({
      variables: {
        input: {
          patientId: patient?.patientId as number,
          patch,
        },
      },
    });
  };

  const saveClinicianId = async (currentState = formState) => {
    const patch = {
      clinicianId: (currentState.clinicianId.value as number) ?? null,
    };

    await updateEncounter({
      variables: {
        input: {
          encounterId: task?.encounter?.encounterId as number,
          patch,
        },
      },
    });
  };

  const updateTask = async (currentState = formState) => {
    await saveTeladocInfo(currentState);
    await saveClinicianId(currentState);
  };

  const saveTask = async (currentState = formState) => {
    setTaskIsSaving(true);
    await updateTask(currentState);

    await updateEncounterTask({
      variables: {
        input: {
          patch: {
            inProgressAt: task?.inProgressAt ?? new Date(),
            responderCompletedAt: null,
            skippedAt: null,
            skippedBy: null,
            skippedReason: null,
            updatedAt: new Date(),
          },
          encounterTaskId: task?.encounterTaskId as number,
        },
      },
    });
  };

  const completeTask = async () => {
    await saveTeladocInfo();
    await saveClinicianId();

    await updateEncounterTask({
      variables: {
        input: {
          patch: {
            responderCompletedAt: new Date(),
            updatedAt: new Date(),
          },
          encounterTaskId: task?.encounterTaskId as number,
        },
      },
    });
  };

  const handleSubmit = async (
    shouldValidate = false,
    currentState = formState,
  ) => {
    const errors = getErrors();
    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, false);
      });
    } // Reset all form errors

    if (shouldValidate) {
      if (errors.length > 0) {
        errors.forEach((errorField) => {
          setErrors(errorField.field, true);
        });
        return;
      }
    } // Only validate errors if completing task

    if (!task?.encounterTaskId) return;

    try {
      if (shouldValidate) {
        await completeTask();
        setToastMessage(
          `Successfully completed task '${task?.taskName}'`,
          UI.Toast.SeverityLevel.Success,
        );
      } else {
        await saveTask(currentState);

        setToastMessage(
          `Successfully updated task '${task?.taskName}'`,
          UI.Toast.SeverityLevel.Success,
        );
      }

      setTaskIsSaving(false);
      cleanForm();
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update task '${task?.taskName}'`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  useEffect(() => {
    if (cliniciansData) {
      setClinicianOptions(
        (cliniciansData.searchClinicians ?? []).map((_clinician) => ({
          label: _clinician?.fullName ?? '',
          value: _clinician,
        })),
      );
    }
  }, [cliniciansData]);

  useAutosave({
    formState,
    handleSubmit,
    skipped: !isDirty || encounterIsComplete || taskIsComplete || taskIsSkipped,
  });

  return (
    <>
      <TaskHeader
        task={task}
        isDirty={isDirty}
        isSaving={taskIsSaving}
        isValidating={false}
        onTaskComplete={() => handleSubmit(true)}
        onTaskSave={() => handleSubmit(false)}
        onTaskSkip={updateTask}
      />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>{formTitle}</h3>
          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
          <p className="formInstructionsLabel">
            1. Log in to Teladoc with the credentials below and join the
            clinician queue
          </p>
        </header>

        <section>
          <UI.Input
            id={formState.teladocUser.field}
            name={formState.teladocUser.field}
            label="Teladoc Login"
            errorText={
              formState.teladocUser.hasError
                ? formState.teladocUser.errorMessage
                : undefined
            }
            onChange={(event) => setData('teladocUser', event.target.value)}
            rightIcon={
              <Copy
                data-testid="teladoc-user"
                onClick={
                  taskIsComplete
                    ? undefined
                    : () => copyText(formState.teladocUser.field)
                }
                weight="fill"
                style={{ cursor: taskIsComplete ? 'not-allowed' : 'pointer' }}
              />
            }
            value={(formState.teladocUser.value as string) ?? ''}
            disabled={inputsAreDisabled}
            fullWidth
          />
          <UI.Input
            id={formState.teladocPass.field}
            name={formState.teladocPass.field}
            label="Teladoc Password"
            errorText={
              formState.teladocPass.hasError
                ? formState.teladocPass.errorMessage
                : undefined
            }
            onChange={(event) => setData('teladocPass', event.target.value)}
            rightIcon={
              <Copy
                data-testid="teladoc-pass"
                onClick={
                  taskIsComplete
                    ? undefined
                    : () => copyText(formState.teladocPass.field)
                }
                weight="fill"
                style={{ cursor: taskIsComplete ? 'not-allowed' : 'pointer' }}
              />
            }
            value={
              taskIsComplete
                ? (formState?.teladocPass.value as string).replace(/./g, '*')
                : (formState.teladocPass.value as string) ?? ''
            }
            disabled={inputsAreDisabled}
            fullWidth
          />
        </section>

        <p className="formInstructionsLabel">
          2. Document the clinician seen during the encounter
        </p>
        <section>
          <UI.TypeAhead
            id="clinicianName"
            className="clinicianName"
            disabled={inputsAreDisabled}
            errorText={
              formState.clinicianId.hasError
                ? formState.clinicianId.errorMessage
                : undefined
            }
            label="Clinician's Name"
            loading={cliniciansLoading}
            name="clinicianSearch"
            onInputChange={handleInputChange}
            onOptionClick={(value) => {
              onClinicianClick(value as Record<string, unknown>);
            }}
            onReset={() => onClinicianClick(null)}
            options={clinicianOptions}
            placeholder="Search by clinician's first or last name"
            testId="clinicianName"
            threshold={2}
            initialValue={
              clinician?.firstName
                ? `${clinician.firstName} ${clinician.lastName}`
                : ''
            }
          />
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default TeladocForm;
