import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import { ProfileProps } from '../../types';
import NameAndDemographics from './NameAndDemographics';
import Address from './Address';
import Communications from './Communications';
import Accounts from './Accounts';
import PatientNotes from './PatientNotes';

const StyledProfile = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 calc(${Tokens.rhythm} * 2);
  height: calc(
    100vh - 173px
  ); // Subtract height of nav header and profile header
  overflow: hidden;
`;

const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  border-right: ${Tokens.border.size} solid
    ${Tokens.color.neutral.black.transparent[10]};
  height: 100%;
  overflow: scroll;
  --webkit-overflow-scrolling: touch;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding-bottom: 35px;
  } // iPad
`;

const Profile = ({ patient }: ProfileProps): JSX.Element => (
  <UI.Container backgroundColor={Tokens.color.neutral.grey[250]} padding="0px">
    <StyledProfile>
      <StyledLeftColumn>
        <NameAndDemographics patient={patient} />
        <Address patient={patient} />
        <Communications patient={patient} />
        <Accounts patient={patient} />
      </StyledLeftColumn>

      <PatientNotes patient={patient} />
    </StyledProfile>
  </UI.Container>
);

export default Profile;
