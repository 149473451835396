import { useContext } from 'react';

import {
  UserContext,
  UserContextWithRefetch,
} from 'providers/UserContextProvider';

const useUserContext = (): Readonly<UserContextWithRefetch> => {
  const userContextState = useContext(UserContext);

  //   if (userContextState === null) {
  //     throw new Error('useUserContext must be a child of UserContextProvider');
  //   }

  return userContextState;
};

export default useUserContext;
