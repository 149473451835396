import { EncounterTask } from 'generated/graphql';
import shortid from 'shortid';

import { renderClinicianAttestation } from 'modules/Patient/utils';
import { Tokens } from 'config';

const AttestationsSummary = ({
  encounterTasks,
}: {
  encounterTasks?: EncounterTask[];
}): JSX.Element | null => {
  if (!encounterTasks) return null;
  if (encounterTasks?.length === 0) return null;

  //Clinical attestations are only rendered on dataTypes below. Included dataTypes are specific to components included in EncounterPrint
  const filteredTasks = encounterTasks.filter((task: EncounterTask) =>
    task?.dataType
      ? [
          'attachment',
          'patient_allergy',
          'patient_problem',
          'physical_exam',
          'point_of_care_test',
          'telehealth_meeting',
          'vaccine',
          'vital',
        ].includes(task.dataType)
      : [],
  );

  return (
    <>
      <h3>Attestations</h3>
      <div style={{ marginLeft: `calc(${Tokens.rhythm}*3)` }}>
        {filteredTasks?.map((task) => {
          return (
            <div style={{ marginBottom: '-16px' }} key={shortid.generate()}>
              {renderClinicianAttestation(task)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AttestationsSummary;
