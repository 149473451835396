import styled from 'styled-components';

import { Tokens } from 'config';

const StyledTableBody = styled.tbody<
  React.HTMLAttributes<HTMLTableSectionElement>
>`
  th {
    padding: 0 calc(${Tokens.rhythm} * 2);
    border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  }
`;

const TableBody = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>): JSX.Element => (
  <StyledTableBody {...props}>{children}</StyledTableBody>
);

export default TableBody;
