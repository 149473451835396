import { UserRoleEnum } from 'generated/graphql';
import React from 'react';
import styled from 'styled-components';
import { EncounterTaskAvatarProps } from './EncounterTaskAvatar';

export declare interface EncounterTaskAvatarGroupProps
  extends React.HTMLProps<HTMLDivElement> {
  /**
   * The encounter task avatars.
   */
  children:
    | React.ReactElement<EncounterTaskAvatarProps>
    | React.ReactElement<EncounterTaskAvatarProps>[];
  /**
   * The logged in user's userId.
   */
  loggedInUserId?: number;
  /**
   * The logged in user's role.
   */
  loggedInUserRole?: UserRoleEnum;
}

const StyledEncounterTaskAvatarGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
`;

const EncounterTaskAvatarGroup = ({
  children,
  loggedInUserId,
  loggedInUserRole,
}: EncounterTaskAvatarGroupProps): JSX.Element => {
  //Clone children
  const clonedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...child.props,
    }),
  );

  // Sort children so that the loggedIn user is displayed 1st (furthest to the right) and on top of the other avatars
  clonedChildren.sort((a, b) => {
    if (
      a.props?.role === loggedInUserRole &&
      a.props?.userId === loggedInUserId
    )
      return 1;
    if (
      b.props?.role === loggedInUserRole &&
      b.props?.userId === loggedInUserId
    )
      return -1;
    return 0;
  });

  return (
    <StyledEncounterTaskAvatarGroup>
      {clonedChildren.map((child) => (
        <div
          key={child.key}
          style={{
            marginLeft: clonedChildren.length > 1 ? '-12px' : '0',
          }}
        >
          {child}
        </div>
      ))}
    </StyledEncounterTaskAvatarGroup>
  );
};

export default EncounterTaskAvatarGroup;
