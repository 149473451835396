import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { TInputs } from './schema';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export const getDefaultValues = (task: TTask): TInputs => {
  return {
    allergies: (task?.patientAllergyList ?? []).map((patientAllergy) => ({
      patientAllergyId: patientAllergy?.patientAllergyId ?? 0,
      isDeleted: patientAllergy?.isDeleted ?? false,
      allergy: {
        allergyId: patientAllergy?.allergy?.allergyId ?? 0,
        name: patientAllergy?.allergy?.name ?? '',
      },
      allergicReaction: patientAllergy?.allergicReaction
        ? {
            allergicReactionId:
              patientAllergy?.allergicReaction?.allergicReactionId ?? 0,
            name: patientAllergy?.allergicReaction?.name ?? '',
          }
        : null,
    })),
    deletedAllergies: [],
  };
};
