import { Addendum, Encounter, EncounterTask } from 'generated/graphql';
import { format as formatDate } from 'date-fns';

import { StyledEncounterPrint } from '../StyledEncounterPrint';
import AttestationsSummary from './AttestationsSummary';
import FormstackFormResponseSummary from '../FormstackForm/FormstackResponseSummary';
import PaymentMethodFormSummary from '../PaymentMethodForm/PaymentMethodFormSummary';
import ProgressNoteSummary from '../ProgressNoteForm/ProgressNoteSummary';
import SignatureListSummary from './SignatureListSummary';

const AddendumListSummary = ({
  encounter,
  encounterTasks,
  formstackFormTaskId,
  paymentMethodTaskId,
  progressNoteTaskId,
}: {
  encounter?: Encounter;
  encounterTasks: EncounterTask[];
  formstackFormTaskId: number;
  paymentMethodTaskId: number;
  progressNoteTaskId: number;
}): JSX.Element | null => {
  if (!encounter) return null;
  if (
    (!encounter?.addendaList || encounter?.addendaList.length === 0) &&
    !paymentMethodTaskId &&
    (!encounterTasks || encounterTasks.length === 0)
  ) {
    return (
      <div>
        <h2>No Addendums</h2>
      </div>
    );
  }

  const getAddendumBody = (addendum: Addendum, idx: number) => {
    const prettyDate = formatDate(
      new Date(addendum.createdAt),
      'MMMM dd, yyyy @ h:mmaaa',
    );
    const byLine = `${addendum?.user?.fullName} added on ${prettyDate}`;

    return (
      <p key={addendum.addendumId} className="addendum">
        {idx + 1}: <span>{addendum.note}</span>
        <div className="signature">
          <span>{byLine}</span>
        </div>
      </p>
    );
  };

  const renderAddendums = (addendaList: Array<Addendum>) => {
    return (
      <div>
        {addendaList
          ? addendaList.map((signature, index) =>
              getAddendumBody(signature as Addendum, index),
            )
          : null}
      </div>
    );
  };

  return (
    <div style={{ overflow: 'visible' }}>
      <h2>Progress Notes and/or Addendum</h2>
      <StyledEncounterPrint>
        <ProgressNoteSummary taskId={progressNoteTaskId} />
        {(encounter &&
          encounter?.addendaList &&
          encounter?.addendaList?.length > 0 &&
          renderAddendums(encounter?.addendaList)) ??
          '-'}
        <AttestationsSummary encounterTasks={encounterTasks} />
        <SignatureListSummary encounter={encounter as Encounter} />
        <PaymentMethodFormSummary taskId={paymentMethodTaskId} />
        <FormstackFormResponseSummary taskId={formstackFormTaskId} />
      </StyledEncounterPrint>
    </div>
  );
};

export default AddendumListSummary;
