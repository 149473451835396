import { useEffect, useRef } from 'react';

/**
 * A hook that works just like `useEffect`, but only after
 * the component has mounted. In other words, it skips the
 * effect on first render.
 * @param effect The effect function
 * @param deps an Array of dependencies
 */
const useDidMountEffect = (effect: () => void, deps: unknown[]): void => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    if (isMountedRef.current) {
      effect();
      return;
    }
    isMountedRef.current = true;
  }, deps);
};

export default useDidMountEffect;
