import {
  useController,
  Control,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import { useDidMountEffect, useFileUpload } from 'hooks';
import ImageInput, { ImageInputProps } from 'ui/ImageInput/ImageInput';

/**
 * Controlled Image Input Props
 * @extends {UI.ImageInput}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<
  ImageInputProps,
  | 'name'
  | 'errorText'
  | 'id'
  | 'value'
  | 'downloadFailed'
  | 'imageFile'
  | 'isDownloading'
  | 'isUploading'
  | 'localUrl'
  | 'onInputChange'
  | 'onDelete'
  | 'onDownload'
  | 'onClearFailed'
  | 'onRetry'
  | 'uploadFailed'
> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;

  /**
   * A folder name for where you want to store the image in the S3 bucket.
   */
  folder: string;
};

const ControlledImageInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  folder,
  name,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => {
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({ name, control, defaultValue });

  const {
    clearFailedUpload,
    downloadFile,
    downloadFailed,
    uploadFailed,
    file,
    fileUrl,
    isDownloading,
    isUploading,
    localUrl,
    retryUpload,
    removeFile,
    uploadFile,
  } = useFileUpload({
    folder,
    fileUrl: value,
  });

  useDidMountEffect(() => {
    onChange(fileUrl);
  }, [fileUrl]);

  return (
    <ImageInput
      id={name}
      errorText={error?.message}
      downloadFailed={downloadFailed}
      imageFile={file}
      isDownloading={isDownloading}
      isUploading={isUploading}
      localUrl={localUrl}
      onInputChange={uploadFile}
      onDelete={removeFile}
      onDownload={downloadFile}
      uploadFailed={uploadFailed}
      onClearFailed={clearFailedUpload}
      onRetry={retryUpload}
      value={value}
      {...field}
      {...props}
    />
  );
};

export default ControlledImageInput;
