import styled from 'styled-components';
import { X } from 'phosphor-react';

import { Tokens } from 'config';

export type Props = {
  /**
   * Background color of the tag
   */
  backgroundColor?: string;
  /**
   * Determines if the tag has an 'X' with a click event
   */
  cancellable?: boolean;
  /**
   * The className for overriding styles.
   */
  className?: string;
  /**
   * Determines if the tag is disabled
   */
  disabled?: boolean;
  /**
   * Label text displayed for the tag
   */
  label?: string;
  /**
   * Click event callback on 'X'
   */
  onCancel?: () => void | undefined;
};

const StyledTag = styled.div<Pick<Props, 'backgroundColor' | 'disabled'>>`
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  background-color: ${(props) => props.backgroundColor};
  color: ${Tokens.color.ui.charcoal.base};
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: ${Tokens.icon.size.small};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: calc(${Tokens.rhythm} / 2) 0;
  user-select: none;

  & + & {
    margin-left: calc(${Tokens.rhythm} / 2);
  }

  span {
    align-items: center;
    display: inline-flex;
    padding: 0 ${Tokens.rhythm};
    white-space: nowrap;
  }

  span + span {
    border-left: 1px solid ${Tokens.color.neutral.black.transparent[4]};
    padding: 0 calc(${Tokens.rhythm} / 2);
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-size: ${Tokens.font.size.paragraph.large};

    line {
      stroke: ${Tokens.color.ui.charcoal.base};
    }

    &:hover {
      line {
        stroke: ${(props) =>
          props.disabled
            ? Tokens.color.ui.charcoal.base
            : Tokens.color.neutral.grey[94]};
      }
    }

    &:active {
      line {
        stroke: ${(props) =>
          props.disabled
            ? Tokens.color.ui.charcoal.base
            : Tokens.color.neutral.grey[191]};
      }
    }
  }
`;

const Tag = (props: Readonly<Props>): JSX.Element => {
  const {
    backgroundColor = Tokens.color.ui.steel.base,
    cancellable = false,
    disabled = false,
    label = 'Tag',
    onCancel = undefined,
    className = undefined,
  } = props;

  return (
    <StyledTag
      className={className}
      backgroundColor={backgroundColor}
      disabled={disabled}
    >
      <span>{label}</span>
      {cancellable ? (
        <span onClick={!disabled ? onCancel : undefined}>
          <X size={Tokens.icon.size.small} />
        </span>
      ) : null}
    </StyledTag>
  );
};

export default Tag;
