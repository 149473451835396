import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  SystemOfOriginEnum,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';

import {
  usePersistTask,
  useDidMountEffect,
  useTask,
  useToastMessage,
} from 'hooks';
import { useForm } from 'forms';
import UI from 'ui';
import { Logger } from 'utils';
import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import VerifyPatientDemographics from './VerfiyPatientDemographics';
import { PatientIdInputs, PatientIdSchema, getDefaultValues } from './utils';

const VerifyPatientDemographicsContainer = ({
  task,
}: {
  task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>;
}): JSX.Element => {
  const defaultValues = getDefaultValues(task);
  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<PatientIdInputs>(PatientIdSchema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  const patientIdFrontAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.PATIENT_ID_FRONT,
  ).attachmentId;
  const patientIdBackAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.PATIENT_ID_BACK,
  ).attachmentId;

  const saveAttachment = async ({
    description,
    type,
    url,
  }: {
    description: string;
    type: EncounterTaskAttachmentTypeEnum;
    url: string;
  }) => {
    if (!type || !url) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              { description, type, images: [{ url }], isRequired: true },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);

      switch (type) {
        case EncounterTaskAttachmentTypeEnum.PATIENT_ID_FRONT:
          setValue('patientIdFrontAttachment', null);
          break;
        case EncounterTaskAttachmentTypeEnum.PATIENT_ID_BACK:
          setValue('patientIdBackAttachment', null);
          break;
        default:
          Logger.warn(`Attachment type ${type} not handled`);
      }

      setToastMessage(
        `An error occurred while uploading the attachment. Please try again.`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  const deleteAttachment = async ({ id }: { id?: number }) => {
    if (!id) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              {
                images: [
                  {
                    encounterTaskAttachmentId: id,
                    isDeleted: true,
                  },
                ],
              },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  useDidMountEffect(() => {
    if (formState.patientIdFrontAttachment) {
      saveAttachment({
        description: 'Patient ID (Front)',
        type: EncounterTaskAttachmentTypeEnum.PATIENT_ID_FRONT,
        url: formState.patientIdFrontAttachment,
      });
    } else {
      deleteAttachment({
        id: patientIdFrontAttachmentId,
      });
    }
  }, [formState.patientIdFrontAttachment]);

  useDidMountEffect(() => {
    if (formState.patientIdBackAttachment) {
      saveAttachment({
        description: 'Patient ID (Back)',
        type: EncounterTaskAttachmentTypeEnum.PATIENT_ID_BACK,
        url: formState.patientIdBackAttachment,
      });
    } else {
      deleteAttachment({
        id: patientIdBackAttachmentId,
      });
    }
  }, [formState.patientIdBackAttachment]);

  return (
    <VerifyPatientDemographics
      control={control}
      errors={errors}
      inputsAreDisabled={inputsAreDisabled}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default VerifyPatientDemographicsContainer;
