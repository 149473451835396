import styled from 'styled-components';

import { Tokens } from 'config';
import { InputProps } from './types';

const StyledInput = styled.input<InputProps>(
  ({ errorText, leftIcon, rightIcon, rightLabel, fullWidth, multiline }) => {
    const borderOverrideVars = errorText
      ? `--border-color-override: ${Tokens.color.ui.error.base};`
      : '';

    const paddingRight = (() => {
      if (rightIcon) {
        return `padding-right: calc(${Tokens.rhythm} * 2 + ${Tokens.font.size.paragraph.large});`;
      }
      if (rightLabel) {
        return `padding-right: calc(${Tokens.rhythm} * 2 + ${Tokens.font.size.paragraph.small} * ${rightLabel.length})`;
      }
      return '';
    })();

    const paddingLeft = leftIcon
      ? `padding-left: calc(${Tokens.rhythm} * 2 + ${Tokens.font.size.paragraph.large});`
      : '';

    return `
    ${borderOverrideVars}

    background-color: ${Tokens.color.neutral.white.base};
    border-color: var(--border-color-override, ${
      Tokens.color.neutral.black.transparent[25]
    });
    border-radius: 2px;
    border-style: solid;
    border-width: ${Tokens.border.size};
    color: ${Tokens.color.ui.charcoal.base};
    font-family: ${Tokens.font.family};
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: ${Tokens.lineHeight.paragraph.small};
    padding: 11px ${Tokens.rhythm};
    resize: ${multiline ? 'vertical' : 'none'};
    width: ${fullWidth ? '100%' : 'auto'};
    -webkit-appearance: none;

    ${paddingRight};
    ${paddingLeft};

    &:disabled {
      background-color: ${Tokens.color.neutral.grey[240]};
      cursor: not-allowed;
      opacity: 1;
      -webkit-text-fill-color: ${Tokens.color.ui.charcoal.transparent[50]};
    }

    &:focus,
    &.focus {
      outline: ${Tokens.outline};
      border-color: var(--border-color-override, ${
        Tokens.color.ui.charcoal.base
      });
    }

    &:hover,
    &.hover {
      border-color: var(--border-color-override, ${
        Tokens.color.neutral.black.transparent[50]
      });
    }
  `;
  },
);

export default StyledInput;
