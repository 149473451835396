import styled from 'styled-components';
import { CaretRight } from 'phosphor-react';
import { RefObject, useRef } from 'react';
import shortid from 'shortid';

import UI from 'ui';
import { Tokens } from 'config';

export type Props = {
  /**
   * The text color
   */
  color?: string;
  /**
   * Hint text displayed for the item
   */
  hint?: string;
  /**
   * Label text displayed for the item
   */
  label?: string;
  /**
   * onClick callback
   */
  onClick?: () => void;
  /**
   * A WAI-ARIA role
   */
  role?: string;
  /**
   * Controls whether the checkbox is in a checked state
   */
  selected?: boolean;
  /**
   * Controls whether a checkbox is shown with the item
   */
  showCheckbox?: boolean;
  /**
   * If `true`, display a caret to the right
   */
  rightCaret?: boolean;
  /**
   * A ref for the div element
   */
  anchorRef?: RefObject<HTMLDivElement>;
};

const StyledListItem = styled.div<{ color?: string; showCheckbox?: boolean }>`
  align-items: center;
  display: flex;
  color: ${({ color }) => color ?? Tokens.color.ui.charcoal.base};
  cursor: pointer;
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: 1;
  padding: ${({ showCheckbox }) =>
    showCheckbox ? 0 : `15px calc(${Tokens.rhythm} * 2)`};
  width: 100%;

  .checkbox {
    margin-right: calc(${Tokens.rhythm} - 2px);
  }

  label {
    padding: ${Tokens.rhythm} calc(${Tokens.rhythm} * 2);
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  .label {
    display: flex;
    flex: 1;
    justify-content: space-between;

    &.withHint {
      span {
        width: 50%;
      }
    }
  }

  .rightCaret {
    position: relative;
    top: 2px;
    margin-left: calc(${Tokens.rhythm} * 9);
  }

  &:hover {
    background-color: ${Tokens.color.neutral.grey[250]};
  }

  &:active {
    outline: auto 4px rgba(31, 117, 203, 0.25);
    outline: auto 4px rgba(31, 117, 203, 0.25);
  }
`;

const ListItem = (props: Readonly<Props>): JSX.Element => {
  const {
    color = undefined,
    hint = undefined,
    label = 'Tag',
    onClick = () => {},
    role,
    selected = false,
    showCheckbox = false,
    rightCaret = false,
    anchorRef,
  } = props;

  const checkboxNameRef = useRef(showCheckbox ? shortid.generate() : undefined);
  const checkboxName = checkboxNameRef.current;

  const labelClasses = ['label'];
  if (hint) labelClasses.push('withHint');

  const renderDetails = () => (
    <>
      <span className={labelClasses.join(' ')}>
        {label ? <span>{label}</span> : null}
        {hint ? <span>{hint}</span> : null}
      </span>

      {rightCaret ? (
        <div className="rightCaret">
          <CaretRight weight="bold" />
        </div>
      ) : null}
    </>
  );

  return showCheckbox ? (
    <StyledListItem
      showCheckbox={showCheckbox}
      color={color}
      ref={anchorRef}
      role={role}
    >
      <label htmlFor={checkboxName}>
        <UI.Checkbox
          id={checkboxName}
          name={checkboxName}
          className="checkbox"
          onChange={onClick}
          checked={selected}
        />
        {renderDetails()}
      </label>
    </StyledListItem>
  ) : (
    <StyledListItem
      color={color}
      ref={anchorRef}
      role={role}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {renderDetails()}
    </StyledListItem>
  );
};

export default ListItem;
