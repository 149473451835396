import { Route, Switch } from 'react-router-dom';

import { RoutePaths } from 'config';
import { Patient } from 'generated/graphql';
import Profile from './components/Profile';
import Encounters from './components/Encounters';

type Props = {
  patient: Partial<Patient>;
};

export const PatientRoutePaths = {
  profile: `${RoutePaths.patients}`,
  encounters: `${RoutePaths.patients}/encounters/:encounterId?`,
};

const PatientRoutes = ({ patient }: Props): JSX.Element => {
  return (
    <Switch>
      <Route exact path={PatientRoutePaths.profile}>
        <Profile patient={patient} />
      </Route>
      <Route exact path={PatientRoutePaths.encounters}>
        <Encounters patient={patient} />
      </Route>
    </Switch>
  );
};

export default PatientRoutes;
