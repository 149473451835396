import { Fragment, useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Enums } from 'utils';
import ImageView from 'ui/ImageView';

const PhysicalExamSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const physicalExamNote = task?.physicalExam?.note;
  const attachments =
    task?.encounterTaskAttachmentsList &&
    task?.encounterTaskAttachmentsList.length > 0 &&
    task?.encounterTaskAttachmentsList.filter(
      (attachment) => attachment.encounterTaskId === taskId,
    );

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isPhysicalExamTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Physical exam note may not exist on this encounter task (defensive)
  if (
    physicalExamNote === null ||
    physicalExamNote === undefined ||
    physicalExamNote.length === 0
  )
    return null;

  return (
    <div>
      <h2>Physical Exam</h2>
      <Grid>
        <div>
          <strong>Physical Exam Note:</strong>
        </div>
        <div>{physicalExamNote}</div>
        {attachments &&
          attachments.map((attachment) => {
            return (
              <Fragment key={attachment.encounterTaskAttachmentId}>
                <div>
                  <strong>{Enums.humanize(attachment.type)} Image:</strong>
                </div>
                <ImageView url={attachment.url as string} />
              </Fragment>
            );
          })}
      </Grid>
    </div>
  );
};

export default PhysicalExamSummary;
