import { useState } from 'react';
import styled from 'styled-components';
import {
  CaretDown,
  CaretUp,
  Clock,
  House,
  PhoneOutgoing,
} from 'phosphor-react';

import { Tokens } from 'config';
import UI from 'ui';
import PhoneUtils from 'utils/phone';
import EncounterCard from './EncounterCard';
import type { ListItemProps } from '../types';

const StyledListItem = styled.div<{ expanded: boolean }>`
  background-color: ${Tokens.color.ui.steel.base};
  border-radius: 2px;
  cursor: pointer;
  font-family: ${Tokens.font.family};
  margin-bottom: calc(${Tokens.rhythm} * 2);
  padding: calc(${Tokens.rhythm} * 2);
  position: relative;

  .head {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: ${(props) =>
      props.expanded ? `calc(${Tokens.rhythm} * 2)` : '0'};
    color: ${Tokens.color.ui.slate.base};

    .actionsButton {
      margin-right: calc(${Tokens.rhythm} * 4);
    }

    .headLabel {
      display: flex;
      flex-direction: row;
      font-weight: ${Tokens.font.weight.semiBold};
      font-size: ${Tokens.font.size.paragraph.base};
      line-height: ${Tokens.lineHeight.paragraph.small};
      margin-right: calc(${Tokens.rhythm} * 4);

      .iconWrapper {
        margin-right: ${Tokens.rhythm};
      }
    }

    .grow {
      flex-grow: 1;
    }
  }
`;

const ListItem = ({
  appointment,
  expanded: initialExpanded = false,
  onToggleExpanded,
  testId,
}: ListItemProps): JSX.Element => {
  const [expanded, setExpanded] = useState(initialExpanded);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    onToggleExpanded(!expanded);
  };

  const addressLine = [
    appointment?.address1,
    ...(appointment?.address2 ? [appointment?.address2] : []),
    ...(appointment?.unit ? [appointment?.unit] : []),
  ].join(', ');

  const getContantName = () => {
    if (appointment?.contactName && appointment?.contactName.length > 22) {
      return `${appointment?.contactName.substring(0, 22)}...`;
    }
    return appointment?.contactName;
  };

  return (
    <StyledListItem
      expanded={expanded}
      onClick={toggleExpanded}
      data-testid={testId}
    >
      <div className="head">
        <div className="headLabel">
          <div className="iconWrapper">
            <Clock weight="fill" />
          </div>
          <span>
            {appointment.scheduledForDate}
            <br />
            {appointment.scheduledForTime}
          </span>
        </div>

        <div className="headLabel">
          <div className="iconWrapper">
            <House weight="fill" />
          </div>
          <span>
            {addressLine}
            <br />
            {appointment?.city}, {appointment?.state} {appointment?.zip}
          </span>
        </div>

        <div className="headLabel">
          <div className="iconWrapper">
            <PhoneOutgoing weight="fill" />
          </div>
          <span>
            {getContantName()}
            <br />
            {appointment?.primaryPhone &&
              PhoneUtils.formatE164ToUSPhoneNumber(appointment?.primaryPhone)}
          </span>
        </div>

        <div className="grow" />

        <UI.Button
          onClick={toggleExpanded}
          variant="secondary"
          size="smallIcon"
        >
          {expanded ? <CaretUp weight="fill" /> : <CaretDown weight="fill" />}
        </UI.Button>
      </div>

      {expanded &&
        appointment?.encountersList &&
        appointment?.encountersList.map((encounter) => {
          const encounterCardTestId = `encountercard__encounter__${encounter?.encounterId}`;

          return (
            <EncounterCard
              key={encounterCardTestId}
              testId={encounterCardTestId}
              encounter={encounter!}
            />
          );
        })}
    </StyledListItem>
  );
};

export default ListItem;
