import React, { Fragment, useEffect } from 'react';
import { format as formatDate } from 'date-fns';

import {
  EncounterTaskAttachmentTypeEnum,
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Enums } from 'utils';
import ImageView from 'ui/ImageView';
import { Tokens } from 'config';

const PointOfCareTestSummary = ({
  taskId,
  header,
}: {
  taskId?: number;
  header?: string;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const pointOfCareTest = task?.pointOfCareTest;
  const criticalValueConfirmations =
    task?.pointOfCareTest?.criticalValueConfirmations;
  const taskCorrections = task?.corrections;
  const attachments =
    task?.encounterTaskAttachmentsList &&
    task?.encounterTaskAttachmentsList.length > 0 &&
    task?.encounterTaskAttachmentsList.filter(
      (attachment) =>
        attachment.encounterTaskId === taskId &&
        (attachment.type === EncounterTaskAttachmentTypeEnum.TEST_RESULT ||
          attachment.type === EncounterTaskAttachmentTypeEnum.LEAVE_BEHIND ||
          attachment.type === EncounterTaskAttachmentTypeEnum.WORK_SCHOOL_NOTE),
    );
  const standingOrders = task?.pointOfCareTestLookup?.standingOrders;

  const getAttestedAt = (attestedAt: string) =>
    attestedAt
      ? `${formatDate(new Date(attestedAt), 'M/dd/yyyy @ h:mmaaa')}`
      : '-';

  const getClinicianName = () => {
    if (task?.pointOfCareTest?.clinicianFirstName) {
      return (
        <>
          <div>
            <strong>Clinician name:</strong>
          </div>
          <div>
            {`${task?.pointOfCareTest?.clinicianFirstName} ${task?.pointOfCareTest?.clinicianLastName}`}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isPointOfCareTestTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // POCT may not exist on this encounter task (defensive)
  if (pointOfCareTest === null || pointOfCareTest === undefined) return null;

  return (
    <div style={{ marginLeft: `calc(${Tokens.rhythm}*3)` }}>
      {task?.group === 'Ordered Modules' ? (
        <h3>{header ? header : task?.taskName}</h3>
      ) : (
        <h2>{header ? header : task?.taskName}</h2>
      )}

      {pointOfCareTest && (
        <Grid>
          <div>
            <strong>Lot #:</strong>
          </div>
          <div>{pointOfCareTest.lotNumber ?? '-'}</div>
          <div>
            <strong>Result:</strong>
          </div>
          <div>{pointOfCareTest.result ?? '-'}</div>
          {criticalValueConfirmations &&
            criticalValueConfirmations.length > 0 &&
            criticalValueConfirmations.map((confirmation) => {
              return (
                <Fragment key={confirmation?.attestedAt}>
                  <div>
                    <strong>Attested By:</strong>
                  </div>
                  <div>
                    {confirmation?.attestedBy
                      ? `${confirmation.user?.fullName} ${confirmation.user?.credentials}`
                      : '-'}
                  </div>
                  <div>
                    <strong>Attested At:</strong>
                  </div>
                  <div>{getAttestedAt(confirmation?.attestedAt)}</div>
                </Fragment>
              );
            })}
          {taskCorrections &&
            taskCorrections.length > 0 &&
            taskCorrections.map((correction) => {
              return (
                <div key={correction?.createdAt}>
                  <div>
                    <strong>Correction Reason:</strong>
                  </div>
                  <div>{correction?.correctionReason ?? '-'}</div>
                  <div>
                    <strong>Created By:</strong>
                  </div>
                  <div>{correction?.createdBy ?? '-'}</div>
                  <div>
                    <strong>Created At:</strong>
                  </div>
                  <div>{correction?.createdAt ?? '-'}</div>
                </div>
              );
            })}
          {attachments &&
            attachments.map((attachment) => {
              return (
                <Fragment key={attachment.encounterTaskAttachmentId}>
                  <div>
                    <strong>{Enums.humanize(attachment.type)} Image:</strong>
                  </div>
                  <ImageView url={attachment.url as string} />
                </Fragment>
              );
            })}
          {standingOrders && standingOrders.length > 0 ? (
            <>
              <div>
                <strong>Standing orders:</strong>
              </div>
              <div>
                {standingOrders.length > 1
                  ? standingOrders.map((order) => (
                      <div key={order?.standingOrderId}>- {order?.name}</div>
                    ))
                  : standingOrders.map((order) => (
                      <div key={order?.lookupId}>{order?.name}</div>
                    ))}
              </div>
            </>
          ) : (
            getClinicianName()
          )}
        </Grid>
      )}
    </div>
  );
};

export default PointOfCareTestSummary;
