import { useEffect, useState, MutableRefObject } from 'react';
import { formatDateString } from 'utils';

import {
  Patient,
  SearchPatientsQuery,
  useSearchPatientsLazyQuery,
} from 'generated/graphql';
import UI from 'ui';
import { Option } from 'ui/TypeAhead/TypeAhead';

type Props = {
  initialValue: string | undefined;
  onOptionClick: (patient: Partial<Patient> | null) => void;
  onReset: () => void;
  patientFilterRef: MutableRefObject<{ reset: () => void }>;
};

const PatientSearchBox = ({
  initialValue,
  onOptionClick,
  onReset,
  patientFilterRef,
}: Props): JSX.Element => {
  const [options, setOptions] = useState<Option[]>();
  const [queryResults, setQueryResults] = useState<SearchPatientsQuery | null>(
    null,
  );

  // Query patients from filter menu type ahead search
  const [getPatients, { loading, data }] = useSearchPatientsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setQueryResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (queryResults) {
      setOptions(
        (queryResults.searchPatients ?? []).map((_patient) => {
          const { dob } = _patient;
          const formattedDob = dob ? `(${formatDateString(dob)})` : '';
          return {
            key: `${_patient.patientId}`,
            label: _patient.globalHumanId ?? '',
            value: _patient,
            hint: `${
              _patient?.preferredName
                ? `${_patient?.firstName} "${_patient?.preferredName}" ${_patient?.lastName}`
                : `${_patient?.firstName} ${_patient?.lastName}`
            } ${formattedDob}`,
          };
        }),
      );
      return;
    }
    setOptions([]);
  }, [queryResults]);

  const handleInputChange = (query?: string) => {
    setQueryResults(null);
    if (!query) return;
    getPatients({ variables: { query } });
  };

  return (
    <UI.TypeAhead
      initialValue={initialValue}
      loading={loading}
      name="patientSearch"
      options={options}
      onInputChange={handleInputChange}
      onOptionClick={(arg) => onOptionClick(arg as Record<string, unknown>)}
      onReset={onReset}
      placeholder="Search by Patient ID or name"
      threshold={2}
      ref={patientFilterRef}
    />
  );
};

export default PatientSearchBox;
