import { Dispatch, SetStateAction } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { TInputs, TSystem } from './utils';
import ReviewOfSystems from './ReviewOfSystems';

const ChiefComplaintAndHpiForm = ({
  control,
  formState,
  inputsAreDisabled,
  setValue,
  systems,
  setSystems,
  taskHeaderProps,
}: {
  control: Control<TInputs>;
  formState: TInputs;
  inputsAreDisabled: boolean;
  setValue: UseFormSetValue<TInputs>;
  systems: TSystem[];
  setSystems: Dispatch<SetStateAction<TSystem[]>>;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
        style={{ paddingBottom: `calc(${Tokens.rhythm} * 6)` }}
      >
        <header>
          <h3>{task?.taskName}</h3>
          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
        </header>

        <section>
          <Controlled.Input
            control={control}
            disabled={inputsAreDisabled}
            defaultValue=""
            fullWidth
            label="Chief Complaint"
            multiline
            name="chiefComplaint"
          />

          <Controlled.Input
            control={control}
            disabled={inputsAreDisabled}
            defaultValue=""
            fullWidth
            label="History of Present Illness (HPI)"
            multiline
            name="historyOfPresentIllness"
            style={{ height: `calc(${Tokens.rhythm} * 30)` }}
          />
        </section>

        <section>
          <ReviewOfSystems
            disabled={inputsAreDisabled}
            formState={formState}
            setValue={setValue}
            systems={systems}
            setSystems={setSystems}
          />
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default ChiefComplaintAndHpiForm;
