import { EncounterTaskAttachmentTypeEnum } from 'generated/graphql';
import { Enums } from 'utils';

export type TypeOption = {
  label: string;
  value: EncounterTaskAttachmentTypeEnum;
};

const typeOptions = Object.values(EncounterTaskAttachmentTypeEnum).map(
  (value) => ({ label: Enums.humanize(value), value }),
);

export default typeOptions;
