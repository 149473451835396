import { useEffect, useState } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

import { Tokens } from 'config';
import { Logger } from 'utils';
import { parseResponseData, humanizeKey } from './utils';
import { ResponseData } from './types';

const StyledPatientFormResponse = styled.div`
  a {
    color: ${Tokens.color.ui.link.base};
  }
  color: ${Tokens.color.ui.charcoal.base};
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: ${Tokens.lineHeight.paragraph.small};

  tr {
    td {
      vertical-align: top;
      padding-bottom: calc(${Tokens.rhythm} * 2);
      width: 50%;
    }

    th {
      color: ${Tokens.color.neutral.grey[94]};
      font-weight: ${Tokens.font.weight.normal};
      padding-bottom: calc(${Tokens.rhythm} * 2);
      padding-right: calc(${Tokens.rhythm} * 5);
      text-align: left;
      width: 50%;
    }
  }
`;

type Props = { responseData: ResponseData };

const PatientFormResponse = ({ responseData }: Props): JSX.Element => {
  const [hasParsingError, setHasParsingError] = useState(false);
  const [responseRows, setResponseRows] = useState<string[][] | null>(null);

  useEffect(() => {
    try {
      setResponseRows(parseResponseData(responseData));
    } catch (err) {
      setHasParsingError(true);
      Logger.error(err);
    }
  }, [setResponseRows, setHasParsingError]);

  if (hasParsingError) {
    return <p>Cannot read FormStack data.</p>;
  }

  return (
    <StyledPatientFormResponse>
      <table>
        <tbody>
          {responseRows?.map((row) => (
            <tr key={shortid.generate()}>
              <th>{humanizeKey(row[0])}:</th>
              <td>{row[1]?.startsWith('http') ? 'Signed' : row[1] || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledPatientFormResponse>
  );
};

export default PatientFormResponse;
