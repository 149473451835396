import React, { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Tokens } from 'config';

const MedicalDecisionMakingSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const diagnosisCodeList =
    task?.diagnosisCodeReferencesByPatientDiagnosisEncounterTaskIdAndDiagnosisCodeReferenceIdList;
  const medicalDecisionMakingNote = task?.medicalDecisionMaking?.note;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isMedicalDecisionMakingTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // MedicalDecisionMaking may not exist on this encounter task (defensive)
  if (
    medicalDecisionMakingNote === null ||
    medicalDecisionMakingNote === undefined ||
    medicalDecisionMakingNote.length === 0
  )
    return null;

  return (
    <>
      <h2>Assessment & Plan</h2>
      <p style={{ marginLeft: `calc(${Tokens.rhythm} * 3)` }}>
        {medicalDecisionMakingNote}
      </p>

      <Grid style={{ marginTop: `calc(${Tokens.rhythm} * -2)` }}>
        <h3>
          <strong>Diagnosis Code</strong>
        </h3>
        <h3>
          <strong>Description</strong>
        </h3>
      </Grid>
      <div>
        {diagnosisCodeList &&
          diagnosisCodeList.length > 0 &&
          diagnosisCodeList.map((code, index) => {
            if (index === 0) {
              return (
                <Grid key={code.code}>
                  <div>{`${code.code}*` ?? '-'}</div>
                  <div>{code.name ?? '-'}</div>
                </Grid>
              );
            }
            return (
              <Grid key={code.code}>
                <div>{code.code ?? '-'}</div>
                <div>{code.name ?? '-'}</div>
              </Grid>
            );
          })}
      </div>
    </>
  );
};

export default MedicalDecisionMakingSummary;
