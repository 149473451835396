import { Control, UseFormSetValue } from 'react-hook-form';
import { Plus } from 'phosphor-react';
import styled from 'styled-components';

import {
  EncounterPaymentModeEnum,
  EncounterTaskLookupFieldsFragment,
} from 'generated/graphql';

import { Tokens } from 'config';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import InsuranceSection from './InsuranceSection';
import PaymentModeSection from './PaymentModeSection';
import { FormInputs, InsuranceInputs } from './schema';

const StyledPaymentMethodForm = styled(StyledTaskForm)`
  min-height: 500px;
  color: ${Tokens.color.ui.charcoal.base};

  .field {
    margin-bottom: calc(${Tokens.rhythm} * 2);
  }

  .paymentMethodSteps {
    margin-bottom: calc(${Tokens.rhythm} * 4);
  }

  .primaryInsurance h2 {
    margin-top: calc(${Tokens.rhythm} * 4);
  }

  .addSecondaryButton {
    margin-top: calc(${Tokens.rhythm} * 4);
  }
`;

type Encounter = EncounterTaskLookupFieldsFragment['encounter'];

type TProps = {
  control: Control<FormInputs>;
  encounter: Encounter;
  paymentMode: EncounterPaymentModeEnum;
  isPrimaryValid: boolean;
  inputsAreDisabled: boolean;
  taskHeaderProps: TaskHeaderProps;
  secondaryInsurance: InsuranceInputs;
  setValue: UseFormSetValue<FormInputs>;
  handleAddSecondary: () => void;
  handleDeleteSecondary: () => void;
  onSaveImageAttachment: (
    insuranceRecordId: number | undefined,
    source: 'primary' | 'secondary',
    imageUrl: string | null,
    side: 'front' | 'back',
  ) => Promise<number | undefined>;
};

const PaymentMethodForm = ({
  control,
  encounter,
  paymentMode,
  isPrimaryValid,
  inputsAreDisabled,
  taskHeaderProps,
  secondaryInsurance,
  setValue,
  handleAddSecondary,
  handleDeleteSecondary,
  onSaveImageAttachment,
}: TProps): JSX.Element => {
  const { task } = taskHeaderProps;
  const renderAlertMessage = () => {
    if (paymentMode === EncounterPaymentModeEnum.INSURANCE) {
      return null;
    }

    const messageMap = {
      [`${EncounterPaymentModeEnum.PREPAID}`]: {
        label: 'Patient pre-paid for this encounter',
        message: encounter?.stripeId
          ? 'The patient has already provided a payment via the web portal. If the patient paid a copayment, the visit costs will be determined by the services provided and their insurance benefits. If the visit costs are less than the copayment, we’ll refund the difference. If the visits costs are more than the copayment, we’ll send the patient a bill. If the patient did not use insurance or their insurance is out of network, then the patient has paid in full for the services received today and will not receive a bill.'
          : 'By selecting this option, you are confirming that the patient has already provided their payment via the web portal for this encounter.',
      },
      [`${EncounterPaymentModeEnum.INVOICE}`]: {
        label: "Patient's payment responsibility",
        message:
          'The patient will be invoiced directly for this encounter and will be responsible for an out-of-pocket payment. Even if they have insurance, their insurance will not be billed for this encounter.',
      },
      [`${EncounterPaymentModeEnum.PARTNERSHIP}`]: {
        label: 'Partner payment for this encounter',
        message:
          'By selecting this option, you are confirming that a Ready contracted partner has agreed to pay for this encounter. Only use this option when instructed by a supervisor.',
      },
      [`${EncounterPaymentModeEnum.UNINSURED}`]: {
        label: 'Uninsured payment for this encounter',
        message:
          'The patient has confirmed via the web portal that they are uninsured. Under current federal guidance, the patient’s COVID-19 rapid antigen test is fully covered so free to the patient.',
      },
      [`${EncounterPaymentModeEnum.NO_INSURANCE}`]: {
        label: 'No insurance',
        message:
          'During the encounter, the Responder indicated that the patient will not use insurance.',
      },
    };

    const alert = messageMap[paymentMode] ?? {};

    return alert ? (
      <UI.AlertBox
        label={alert.label}
        message={alert.message}
        severity={UI.AlertBox.SeverityLevel.Information}
      />
    ) : null;
  };

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledPaymentMethodForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h1>Confirm payment method</h1>
          <p>All fields below, unless otherwise noted, are required.</p>
        </header>

        <section>
          <div className="paymentMethodSteps">
            <p className="formStep">
              1. Select/confirm patient’s payment method
            </p>

            <PaymentModeSection
              control={control}
              disabled={inputsAreDisabled || !!encounter?.stripeId}
              setValue={setValue}
            />

            {paymentMode === EncounterPaymentModeEnum.INSURANCE && (
              <>
                <InsuranceSection
                  key="primaryInsurance"
                  control={control}
                  setValue={setValue}
                  index={0}
                  uploadFolder={`${encounter?.encounterId}`}
                  onSaveImageAttachment={onSaveImageAttachment}
                  disabled={inputsAreDisabled}
                />
                {secondaryInsurance ? (
                  <InsuranceSection
                    key="secondaryInsurance"
                    control={control}
                    setValue={setValue}
                    index={1}
                    uploadFolder={`${encounter?.encounterId}`}
                    onSaveImageAttachment={onSaveImageAttachment}
                    onDelete={handleDeleteSecondary}
                    disabled={inputsAreDisabled}
                  />
                ) : (
                  <UI.Button
                    leftIcon={<Plus />}
                    className="addSecondaryButton"
                    variant="secondary"
                    onClick={handleAddSecondary}
                    disabled={inputsAreDisabled || !isPrimaryValid}
                  >
                    Add Secondary Insurance
                  </UI.Button>
                )}
              </>
            )}
          </div>

          {renderAlertMessage()}
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledPaymentMethodForm>
    </>
  );
};

export default PaymentMethodForm;
