import {
  EncounterTaskAttachment,
  EncounterTaskAttachmentTypeEnum,
  useGetEncounterTaskAttachmentsByEncounterTaskIdLazyQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { TInputs } from '../utils';

type Props = {
  encounterTaskId: number;
};

type ReturnValues = {
  attachmentsLoading: boolean;
  attachmentsSaving: boolean;
  fetchAttachments: () => void;
  setAttachmentsSaving: (isSaving: boolean) => void;
  defaultFormValues: TInputs;
};

const useAdditionalPhotoDocSection = ({
  encounterTaskId,
}: Props): ReturnValues => {
  const [attachmentsSaving, setAttachmentsSaving] = useState(false);
  const [
    fetchAttachments,
    { data: attachmentsData, loading: attachmentsLoading },
  ] = useGetEncounterTaskAttachmentsByEncounterTaskIdLazyQuery({
    fetchPolicy: 'network-only',
    variables: { id: encounterTaskId },
  });

  useEffect(() => {
    fetchAttachments();
  }, []);

  const getFormValues = (): TInputs => {
    const grouped = (
      attachmentsData?.encounterTaskAttachmentsList ?? []
    ).reduce((prev, attachment) => {
      if (attachment.isRequired) return prev; // filter out required

      if (attachment.type in prev) {
        return {
          ...prev,
          [attachment.type]: [...prev[attachment.type], attachment],
        };
      }

      return { ...prev, [attachment.type]: [attachment] };
    }, {} as Record<EncounterTaskAttachmentTypeEnum, Partial<EncounterTaskAttachment>[]>);

    const attachmentsValues = Object.entries(grouped).map(([type, value]) => ({
      description: value.length > 0 ? value[0].description ?? '' : '',
      type,
      images: value.map(
        ({ encounterTaskAttachmentId: id, url, isDeleted }) => ({
          id: id ?? null,
          url: url ?? '',
          isDeleted: !!isDeleted,
        }),
      ),
    }));

    return { attachments: attachmentsValues };
  };

  return {
    attachmentsLoading,
    attachmentsSaving,
    fetchAttachments,
    setAttachmentsSaving,
    defaultFormValues: getFormValues(),
  };
};

export default useAdditionalPhotoDocSection;
