import { Control, UseFormGetValues } from 'react-hook-form';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { useForm } from 'forms';
import { usePersistTask, useTask } from 'hooks';
import { TaskHeaderProps } from '../../TaskHeader';
import {
  FormSchema,
  TInputs,
  getPatchValues,
  getDefaultValues,
} from '../utils';

export type UseVitalsFormReturnValues = {
  control: Control<TInputs>;
  getValues: UseFormGetValues<TInputs>;
  inputsAreDisabled: boolean;
  isFirstSet: boolean;
  taskHeaderProps: TaskHeaderProps;
};

const useVitalsForm = (
  task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>,
): UseVitalsFormReturnValues => {
  const isFirstSet = task?.dataCategory === 'first_set';
  const defaultValues = getDefaultValues(task?.vitalsList ?? [], isFirstSet);

  const {
    control,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
    getValues,
    handleSave,
    handleSubmit,
    reset,
    watch,
  } = useForm<TInputs>(FormSchema, { defaultValues });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatchValues(data),
      onPersistTask: (updatedTask) =>
        reset(getDefaultValues(updatedTask?.vitalsList ?? [], isFirstSet)),
    },
  );

  return {
    control,
    inputsAreDisabled,
    isFirstSet,
    getValues,
    taskHeaderProps: {
      task,
      isDirty,
      isSaving,
      isValidating,
      onTaskComplete: handleCompleteTask,
      onTaskSkip: handleSkipTask,
      onTaskSave: handleSaveTask,
    },
  };
};

export default useVitalsForm;
