import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import UI from 'ui';
import { Analytics } from 'utils';
import {
  ApolloProvider,
  AuthProvider,
  IconProvider,
  SidebarContextProvider,
  UserContextProvider,
  ToastContextProvider,
} from 'providers';
import AppRoutes from './AppRoutes';

const App = (): JSX.Element => {
  // Send internet disconnect event to Mixpanel
  useEffect(() => {
    const onOffline = () => {
      Analytics.timeEvent('Internet Disconnected');
    };
    const onOnline = () => {
      Analytics.track('Internet Disconnected');
    };
    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  });

  return (
    <ErrorBoundary FallbackComponent={UI.ErrorFallback}>
      <AuthProvider>
        <ApolloProvider>
          <UserContextProvider>
            <SidebarContextProvider>
              <ToastContextProvider>
                <IconProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </IconProvider>
              </ToastContextProvider>
            </SidebarContextProvider>
          </UserContextProvider>
        </ApolloProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
