import {
  UpsertEncounterTaskInput,
  TelehealthMeetingAlternateConnectionMethodEnum,
} from 'generated/graphql';

import { TInputs } from './schema';

const getPatchValues = (
  formData: TInputs,
): Partial<UpsertEncounterTaskInput> => {
  const { alternateConnectionMethod, alternateConnectionReason } = formData;

  const patch = {
    telehealthMeeting: {
      alternateConnectionMethod:
        alternateConnectionMethod as TelehealthMeetingAlternateConnectionMethodEnum,
      alternateConnectionReason,
    },
  };

  return patch;
};

export default getPatchValues;
