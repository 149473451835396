import UI from 'ui';
import { useState } from 'react';
import PhoneUtils from 'utils/phone';
import { InfoSectionsProps } from '../../types';
import { StyledSection } from './StyledSection';
import EditCommunications from './EditCommunications';

const Communications = ({ patient }: InfoSectionsProps): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false);

  return !isEditable ? (
    <StyledSection>
      <div className="heading">
        <p>Communications</p>

        <UI.Button
          variant="link"
          as="button"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </UI.Button>
      </div>

      <div className="body">
        <p>
          <span className="fieldName">Preferred Phone Number:</span>
          <span className="fieldValue">
            {patient?.preferredPhoneNumber
              ? PhoneUtils.formatE164ToUSPhoneNumber(
                  patient?.preferredPhoneNumber,
                )
              : '-'}
          </span>
        </p>
        <p>
          <span className="fieldName">Email Address:</span>
          <span className="fieldValue">{patient?.email ?? '-'}</span>
        </p>

        {patient?.humanizedLanguages && (
          <p>
            <span className="fieldName">Preferred Language:</span>
            <span className="fieldValue">
              {patient?.humanizedLanguages.join(', ')}
            </span>
          </p>
        )}

        {patient?.communicationNeed && (
          <p>
            <span className="fieldName">Special Communication Needs:</span>
            <span className="fieldValue">
              {patient?.humanizedCommunicationNeed?.join(', ')}
            </span>
          </p>
        )}
      </div>
    </StyledSection>
  ) : (
    <EditCommunications patient={patient} setIsEditable={setIsEditable} />
  );
};
export default Communications;
