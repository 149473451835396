import { useEffect } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';

import { Controlled } from 'forms';
import { EncounterPaymentModeEnum } from 'generated/graphql';
import { NoInsuranceReasonOptions, PaymentOptions } from './options';
import { FormInputs } from './schema';

type Props = {
  control: Control<FormInputs>;
  disabled: boolean;
  setValue: UseFormSetValue<FormInputs>;
};

const PaymentModeSection = ({
  control,
  disabled,
  setValue,
}: Props): JSX.Element => {
  const paymentMode = useWatch<FormInputs>({ name: 'paymentMode', control });
  const isUninsured = paymentMode === EncounterPaymentModeEnum.UNINSURED;
  const isNoInsurance = paymentMode === EncounterPaymentModeEnum.NO_INSURANCE;
  const isPrepaid = paymentMode === EncounterPaymentModeEnum.PREPAID;

  useEffect(() => {
    if (paymentMode !== EncounterPaymentModeEnum.INVOICE) {
      setValue('noInsuranceReason', undefined);
    }
  }, [paymentMode]);

  const getPaymentOptions = () => {
    if (isUninsured) {
      return [
        ...PaymentOptions,
        {
          label: 'Uninsured',
          value: EncounterPaymentModeEnum.UNINSURED,
        },
      ];
    }

    if (isNoInsurance) {
      return [
        ...PaymentOptions,
        {
          label: 'No Insurance',
          value: EncounterPaymentModeEnum.NO_INSURANCE,
        },
      ];
    }

    if (isPrepaid) {
      return [
        ...PaymentOptions,
        {
          label: 'Pre-paid (out of pocket)',
          value: EncounterPaymentModeEnum.PREPAID,
        },
      ];
    }

    return PaymentOptions;
  };

  return (
    <>
      <div className="field">
        <Controlled.Select
          name="paymentMode"
          control={control}
          label="Payment Method"
          options={getPaymentOptions()}
          disabled={disabled || isUninsured || isNoInsurance || isPrepaid}
        />
      </div>
      {paymentMode === EncounterPaymentModeEnum.INVOICE && (
        <div className="field">
          <Controlled.Select
            name="noInsuranceReason"
            control={control}
            label="Reason for invoice payment"
            options={NoInsuranceReasonOptions}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default PaymentModeSection;
