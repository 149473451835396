import { Activity } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens } from 'config';

const Animation = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: leftToRight 1500ms linear infinite;
  }

  @keyframes leftToRight {
    from {
      stroke-dashoffset: 500;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const Loading = (): JSX.Element => (
  <Animation>
    <Activity color={Tokens.color.ui.error.base} size={64}></Activity>
  </Animation>
);

export default Loading;
