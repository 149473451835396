import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm as useHookForm,
  UseFormProps,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

import { useDidMountEffect } from 'hooks';
import type { UseFormHandleSubmit, UseFormReturn } from './types';

/**
 * Wrapper around React Hook Form `useForm` hook. This hook accepts
 * a yup schema, injects a resolver into `useForm` and adds an
 * `overrideRequired` boolean to the schema's context object
 * during validation.
 *
 * In addition to `handleSubmit`, this hook provides a `handleSave`
 * function which when called sets `overrideRequired` to `true`,
 * and allows required yup schemas to pass validation if they
 * have empty values.
 *
 * @param {AnyObjectSchema} schema - yup validation schema
 * @param {UseFormProps} useFormProps - React Hook Form options object
 * @returns {UseFormReturn}
 */
const useForm = <TFieldValues extends FieldValues>(
  schema: AnyObjectSchema,
  options: Omit<UseFormProps<TFieldValues>, 'resolver'> = {},
): UseFormReturn<TFieldValues> => {
  const { context, ...props } = options;

  const [submitOptions, setSubmitOptions] = useState<{
    count: number;
    overrideRequired: boolean;
    onValid: SubmitHandler<TFieldValues>;
    onError?: SubmitErrorHandler<TFieldValues>;
  }>({
    count: 0,
    overrideRequired: false,
    onValid: () => {},
  });

  const { handleSubmit: handleHookFormSubmit, ...rest } =
    useHookForm<TFieldValues>({
      resolver: yupResolver(schema),
      context: Object.assign(context ?? {}, {
        overrideRequired: submitOptions.overrideRequired,
      }),
      ...props,
    });

  useDidMountEffect(() => {
    const { onValid, onError } = submitOptions;
    handleHookFormSubmit(onValid, onError)();
  }, [submitOptions]);

  const handleSubmit: UseFormHandleSubmit<TFieldValues> =
    (onValid, onError) => async () => {
      setSubmitOptions((prev) => ({
        count: prev.count + 1,
        overrideRequired: false,
        onValid,
        onError,
      }));
    };

  const handleSave: UseFormHandleSubmit<TFieldValues> =
    (onValid, onError) => async () => {
      setSubmitOptions((prev) => ({
        count: prev.count + 1,
        overrideRequired: true,
        onValid,
        onError,
      }));
    };

  return {
    handleSubmit,
    handleSave,
    ...rest,
  };
};

export default useForm;
