import { Encounter, EncounterSignature, UserRoleEnum } from 'generated/graphql';
import { Enums } from 'utils';
import { format as formatDate } from 'date-fns';
import { Tokens } from 'config';

const SignatureListSummary = ({
  encounter,
}: {
  encounter: Partial<Encounter>;
}): JSX.Element | null => {
  if (
    !encounter?.encounterSignaturesList ||
    encounter?.encounterSignaturesList.length === 0
  ) {
    return null;
  }
  const getSignatureBody = (signature: EncounterSignature) => {
    const action =
      signature.operatingAsRole === UserRoleEnum.RESPONDER
        ? 'completed'
        : 'signed';

    const prettyDate = formatDate(
      new Date(signature.createdAt),
      'MMMM dd, yyyy @ h:mmaaa',
    );

    const key = `${signature.operatingAsRole}${signature.user?.userId}`;
    const signatureText = `${Enums.humanize(signature.operatingAsRole || '')} ${
      signature.user?.fullName
    } ${action} on ${prettyDate}`;

    return <div key={key}>{signatureText}</div>;
  };

  const renderSignatures = (encounterSignatures: Array<EncounterSignature>) => {
    // resilience for testing. Generated mock creates encounterSignatures as [{}] which breaks code
    if (
      encounterSignatures.length === 1 &&
      encounterSignatures[0].operatingAsRole === undefined
    ) {
      return null;
    }

    return (
      <div style={{ marginLeft: `calc(${Tokens.rhythm}*3)` }}>
        {encounterSignatures
          ? encounterSignatures.map((signature) =>
              getSignatureBody(signature as EncounterSignature),
            )
          : null}
      </div>
    );
  };
  return (
    <div>
      <h3>Signatures</h3>
      {renderSignatures(encounter?.encounterSignaturesList)}
    </div>
  );
};

export default SignatureListSummary;
