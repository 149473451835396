import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';
import { TInputs } from './schema';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

const getDefaultValues = ({ task }: { task: TTask }): TInputs => {
  const meeting = task?.telehealthMeeting;

  const alternateConnectionMethod = meeting?.alternateConnectionMethod ?? null;

  const alternateConnectionReason = meeting?.alternateConnectionReason ?? '';

  const isAlternateConnectMethod =
    !!alternateConnectionMethod || !!alternateConnectionReason;

  return {
    isMeetingComplete: undefined,
    isAlternateConnectMethod,
    alternateConnectionMethod,
    alternateConnectionReason,
  };
};

export default getDefaultValues;
