import _ from 'lodash';
import {
  Controller,
  Control,
  FieldErrors,
  FieldValue,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import TypeAhead, { TypeAheadProps } from 'ui/TypeAhead/TypeAhead';

/**
 * Controlled Image Input Props
 * @extends {UI.TypeAhead}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<
  TypeAheadProps,
  'name' | 'errorText' | 'id' | 'initialValue' | 'onOptionClick' | 'onReset'
> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;

  /**
   * Transforms the value for the initial display input.
   * Useful for when the value is an object, and you need to specify
   * the display value.
   */
  transformInput?: (value: FieldValue<FieldValues>) => string;

  /**
   * Transforms the selected option value for output.
   * Useful for when the selected option's value needs
   * to be modified to conform to your field's schema.
   */
  transformOutput?: (
    value: string | number | Record<string, unknown>,
  ) => FieldValue<FieldValues>;
};

const ControlledTypeAhead = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  name,
  transformInput,
  transformOutput,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({
      field: { onChange, ref, value, ...field },
      fieldState: { error },
    }) => {
      const handleReset = () => {
        if (_.isObject(value)) {
          onChange(_.mapValues(value, () => undefined));
          return;
        }
        onChange(undefined);
      };

      const handleOptionClick = (
        option?: string | number | Record<string, unknown>,
      ) => {
        if (option && transformOutput) {
          return onChange(transformOutput(option));
        }
        return onChange(option);
      };

      const getInitialValue = () => {
        if (!value) {
          return '';
        }
        return transformInput ? transformInput(value) : value;
      };

      const renderError = (): string | undefined => {
        if (!error) return undefined;
        if (error.message) return error.message;

        return Object.values(error as FieldErrors)
          .map((err) => err?.message)
          .join(' ');
      };

      return (
        <TypeAhead
          id={name}
          initialValue={getInitialValue()}
          errorText={renderError()}
          onOptionClick={handleOptionClick}
          onReset={handleReset}
          {...field}
          {...props}
        />
      );
    }}
  />
);

export default ControlledTypeAhead;
