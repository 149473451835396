import styled from 'styled-components';
import { Tokens } from 'config';

export const StyledTaskDrawer = styled.aside<{ expanded?: boolean | null }>`
  max-width: 660px;
  width: ${({ expanded }) => (expanded ? '100%' : '0')};

  background-color: ${Tokens.color.neutral.white.base};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition-property: width;
  transition-duration: ${Tokens.transition.duration[3]};
  transition-timing-function: ${Tokens.transition.timing.easeIn};

  & > * {
    width: 100%;
    flex-grow: 1;
  }

  .content {
    border-left: 1px solid ${Tokens.color.neutral.black.transparent[10]};
    visibility: hidden;
  }

  .content.visible {
    visibility: visible;
  }

  .children {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;
