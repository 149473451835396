import styled from 'styled-components';

import { Tokens } from 'config';
import { TabProps } from './types';

const StyledTab = styled.li<React.HTMLAttributes<HTMLLIElement>>`
  color: ${Tokens.color.ui.slate.base};
  cursor: pointer;
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  margin-right: calc(${Tokens.rhythm} * 4);
  padding: calc(${Tokens.rhythm} - 2px) 0 ${Tokens.rhythm} 0;

  .badgeCount {
    background-color: ${Tokens.color.neutral.black.transparent[4]};
    border-radius: 40px;
    line-height: ${Tokens.font.size.paragraph.large};
    padding: 0 ${Tokens.rhythm};
    margin-left: 4px;
  }

  &:hover {
    color: ${Tokens.color.ui.charcoal.base};
    .badgeCount {
      background-color: ${Tokens.color.neutral.black.transparent[10]};
    }
  }

  &.selected {
    border-bottom: ${Tokens.color.ui.charcoal.base} 2px solid;
    color: ${Tokens.color.ui.charcoal.base};
    font-weight: ${Tokens.font.weight.semiBold};

    .badgeCount {
      background-color: ${Tokens.color.ui.charcoal.base};
      color: ${Tokens.color.neutral.white.base};
    }
  }
`;

const Tab = ({
  children,
  count,
  selected = false,
  onClick,
}: TabProps): JSX.Element => (
  <StyledTab className={selected ? 'selected' : ''} onClick={onClick}>
    {children}
    {(count || count === 0) && <span className="badgeCount">{count}</span>}
  </StyledTab>
);

export default Tab;
