import {
  GenderIdentityEnum,
  GenderPronounEnum,
  NoSsnReasonEnum,
  PatientSuffixEnum,
  RaceEnum,
  SexEnum,
} from 'generated/graphql';
import { Enums } from 'utils';
import { Option } from 'ui/Select/Select';

export const GenderIdentityOptions: Option[] = Object.values(
  GenderIdentityEnum,
).map((option) => {
  if (
    option === GenderIdentityEnum.NON_BINARY ||
    option === GenderIdentityEnum.NON_DISCLOSE
  ) {
    return {
      label: Enums.addDash(option),
      value: option,
    };
  }
  return {
    label: Enums.humanize(option),
    value: option,
  };
});

export const GenderPronounOptions: Option[] = Object.values(
  GenderPronounEnum,
).map((option) => ({
  label: Enums.addForwardSlash(option),
  value: option,
}));

export const noReasonSSNOptions: Option[] = Object.values(NoSsnReasonEnum).map(
  (option) => ({
    label: Enums.humanize(option),
    value: option,
  }),
);

export const RaceOptions: Option[] = Object.values(RaceEnum).map((option) => ({
  label: Enums.humanize(option),
  value: option,
}));

export const SexOptions: Option[] = Object.values(SexEnum)
  .filter((option) => option !== 'OTHER')
  .map((filteredOption) => ({
    label: Enums.humanize(filteredOption),
    value: filteredOption,
  }));

export const SuffixOptions: Option[] = Object.values(PatientSuffixEnum).map(
  (option) => {
    return {
      label: [PatientSuffixEnum.JR, PatientSuffixEnum.SR].includes(option)
        ? Enums.titleCase(option)
        : option,
      value: option,
    };
  },
);
