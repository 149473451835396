import { DoseSpotModal } from 'modules';
import UI from 'ui';
import { RxIcon } from './components';
import { useDoseSpotButton } from './hooks';

type TProps = {
  patientId: number | undefined;
};

const DoseSpotButton = ({ patientId }: TProps): JSX.Element | null => {
  const {
    handleClick,
    isDisabled,
    isVisible,
    initializing,
    setModalOpen,
    modalOpen,
    urlQueryArgs,
  } = useDoseSpotButton({ patientId });

  if (isVisible) {
    return (
      <>
        <UI.Button
          data-testid="doseSpotButton"
          isLoading={initializing}
          disabled={isDisabled}
          variant="secondary"
          size="icon"
          onClick={handleClick}
        >
          <RxIcon />
        </UI.Button>

        <DoseSpotModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          urlQueryArgs={urlQueryArgs}
        />
      </>
    );
  }

  return null;
};

export default DoseSpotButton;
