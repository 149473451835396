import styled from 'styled-components';
import { ReactNode } from 'react';

import { Tokens } from 'config';

export const StyledSelect = styled.div<{
  errorText?: string | ReactNode;
}>`
  .errorTextWrapper {
    margin-top: ${Tokens.rhythm};
    span {
      color: ${Tokens.color.ui.error.base};
      font-family: ${Tokens.font.family};
      font-size: ${Tokens.font.size.paragraph.base};
      line-height: ${Tokens.lineHeight.paragraph.small};
    }
  }

  .react-dropdown-select {
    background-color: ${Tokens.color.neutral.white.base};
    border-color: ${({ errorText }) =>
      errorText
        ? Tokens.color.ui.error.base
        : Tokens.color.neutral.black.transparent[25]};
    border-radius: 2px;
    border-style: solid;
    border-width: ${Tokens.border.size};
    color: ${Tokens.color.ui.charcoal.base};
    font-family: ${Tokens.font.family};
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: 1;
    min-height: 40px;
    padding: 10px calc(${Tokens.rhythm});

    .react-dropdown-select-input {
      margin: 0;

      ::placeholder {
        font-size: ${Tokens.font.size.paragraph.base};
        padding: 0;
      }
    }

    .react-dropdown-select-dropdown-handle {
      margin: 0;
    }

    &:focus {
      outline: ${Tokens.outline};
      border-color: ${Tokens.color.ui.charcoal.base};
    }

    &:hover {
      border-color: ${Tokens.color.neutral.black.transparent[50]};
    }

    &.disabled {
      background-color: ${Tokens.color.neutral.grey[240]};
      cursor: not-allowed;
      opacity: 1;
      pointer-events: auto;
      -webkit-text-fill-color: ${Tokens.color.ui.charcoal.transparent[50]};

      & * {
        pointer-events: none;
      }

      &:focus {
        outline: 0;
        border-color: ${Tokens.color.ui.charcoal.base};
      }

      .react-dropdown-select-dropdown-handle {
        display: none;
      }
    }

    &.multi {
      padding-bottom: 4px;
      padding-top: 4px;
    }

    .react-dropdown-select-type-multi > * {
      margin: calc(${Tokens.rhythm} / 4);
    }

    .react-dropdown-select-type-multi input {
      margin: 0;
    }

    .react-dropdown-select-dropdown {
      padding: ${Tokens.rhythm} 0;
      box-shadow: ${Tokens.boxShadow.dark};
      min-width: 100%;
    }
  }
`;

export default StyledSelect;
