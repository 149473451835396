import styled from 'styled-components';

import { Tokens } from 'config';
import { InstructionTextProps } from './types';

const StyledInstructionText = styled.div`
  color: ${Tokens.color.ui.charcoal.base};
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.small};
  line-height: ${Tokens.lineHeight.paragraph.small};
  margin-bottom: ${Tokens.rhythm};
`;

const InstructionText = ({ children }: InstructionTextProps): JSX.Element => (
  <StyledInstructionText>{children}</StyledInstructionText>
);

export default InstructionText;
