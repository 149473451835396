import styled from 'styled-components';

import { Tokens } from 'config';
import { AvatarProps } from './types';

const StyledAvatar = styled.div<AvatarProps>(
  ({ backgroundColor, children, size }) => {
    const sizeVars = (() => {
      switch (size) {
        case 'xs':
          return `
          font-size: 4px;
          height: ${Tokens.rhythm};
          width: ${Tokens.rhythm};`;
        case 'sm':
          return `
          font-size: 9px;
          height: calc(${Tokens.rhythm} * 2);
          width: calc(${Tokens.rhythm} * 2);`;
        case 'md':
          return `
          font-size: ${Tokens.font.size.paragraph.small};
          height: calc(${Tokens.rhythm} * 3);
          width: calc(${Tokens.rhythm} * 3);`;
        case 'lg':
          return `
          font-size: ${Tokens.font.size.paragraph.large};
          height: calc(${Tokens.rhythm} * 4);
          width: calc(${Tokens.rhythm} * 4);`;
        default:
          return `
          font-size: ${Tokens.font.size.paragraph.small};
          height: calc(${Tokens.rhythm} * 2);
          width: calc(${Tokens.rhythm} * 2);`;
      }
    })();
    const backgroundColorVars = (() => {
      switch (children) {
        case 'C':
          return `
          background-color: ${
            backgroundColor ? backgroundColor : Tokens.color.ui.roles.clinician
          };
          outline: 1px solid ${Tokens.color.neutral.white.base};`;
        case 'R':
          return `
          background-color: ${
            backgroundColor ? backgroundColor : Tokens.color.ui.roles.responder
          };
          outline: 1px solid ${Tokens.color.neutral.white.base};`;
        default:
          return `
          background-color: ${backgroundColor};
          outline: none;`;
      }
    })();

    return `
    ${sizeVars}
    ${backgroundColorVars}
    align-items: center;
    border-radius: 50%;
    color: ${Tokens.color.neutral.grey[102]};
    display: flex;
    font-weight: ${Tokens.font.weight.semiBold};
    justify-content: center;

    img {
      ${sizeVars}
      border-radius: 50%;
    }
  `;
  },
);

/**
 * The base avatar component.
 * @param props
 */
const Avatar = ({
  backgroundColor,
  children = <img src="https://via.placeholder.com/150/303C46" />,
  size,
}: Readonly<AvatarProps>): JSX.Element => (
  <StyledAvatar
    backgroundColor={backgroundColor}
    children={children}
    size={size}
  />
);

export default Avatar;
