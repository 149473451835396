import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Check, Minus, X } from 'phosphor-react';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { TaskDrawer } from 'cache/reactiveVars';
import { Tokens } from 'config';
import { useTask } from 'hooks';
import UI from 'ui';
import { StyledTaskHeader } from './StyledTaskHeader';
import TaskHeaderActions from './TaskHeaderActions';
import SkipTaskModal from '../SkipTaskModal';
import TaskCorrectionsModal from '../TaskCorrectionsModal';

const TaskHeaderButton = styled(UI.Button)`
  display: inline-flex;

  & + & {
    margin-left: ${Tokens.rhythm};
  }
`;

const TaskCompleteButton = styled(TaskHeaderButton)`
  &.completed {
    --background-color-override: ${Tokens.color.ui.success.base};
    --border-color-override: ${Tokens.color.ui.success.base};
    --border-color: ${Tokens.color.ui.success.base};
    --color: ${Tokens.color.neutral.white.base};
  }

  &.skipped {
    --background-color-override: ${Tokens.color.ui.warning.base};
    --border-color-override: ${Tokens.color.ui.warning.base};
    --border-color: ${Tokens.color.ui.warning.base};
    --color: ${Tokens.color.neutral.white.base};
  }
`;

export type THeaderAction = 'skip' | 'save' | 'complete' | 'correct' | 'close';

export type TProps = {
  correctionsModalOpen?: boolean;
  correctionsWorkflow?: boolean;
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
  isDirty: boolean;
  isSaving: boolean;
  isValidating: boolean;
  handleCorrectionsModal?: () => void;
  onTaskComplete: () => void;
  onTaskCorrect?: (correctionsReason: string) => void;
  onTaskSave: () => void;
  onTaskSkip?: () => void;
  setCorrectionsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setCorrectionsWorkflow?: Dispatch<SetStateAction<boolean>>;
};

const TaskHeaderComponent = ({
  correctionsModalOpen,
  correctionsWorkflow,
  handleCorrectionsModal,
  isDirty,
  isSaving,
  isValidating,
  onTaskComplete,
  onTaskCorrect,
  onTaskSave,
  onTaskSkip,
  setCorrectionsModalOpen,
  setCorrectionsWorkflow,
  task,
}: Readonly<TProps>): JSX.Element => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);

  const {
    actionsAreDisabled,
    clinicianReviewedStatus,
    encounterIsComplete,
    inputsAreDisabled,
    taskIsComplete,
    taskIsSkipped,
  } = useTask(task);

  const savingProgressIsDisabled =
    !isDirty || isValidating || inputsAreDisabled;

  const buttonClasses = [];
  if (taskIsSkipped) buttonClasses.push('skipped');
  if (taskIsComplete) buttonClasses.push('completed');

  const getCompleteButtonText = () => {
    if (taskIsSkipped) return 'Skipped';
    if (taskIsComplete) return 'Completed';
    return 'Mark Complete';
  };

  const getIconColor = (): string =>
    taskIsComplete || taskIsSkipped
      ? Tokens.color.neutral.white.base
      : Tokens.color.ui.charcoal.base;

  const handleTaskDrawerClose = () => {
    TaskDrawer.state(TaskDrawer.defaultState); // Reset task drawer on close
  };

  const handleAction = (action: THeaderAction) => {
    switch (action) {
      case 'skip':
        if (setSkipTaskModalOpen) setSkipTaskModalOpen(true);
        break;
      case 'save':
        onTaskSave();
        break;
      case 'complete':
        onTaskComplete();
        break;
      case 'correct':
        if (setCorrectionsWorkflow) setCorrectionsWorkflow(true);
        break;
      case 'close':
        handleTaskDrawerClose();
        break;
    }

    setActionsOpen(false);
  };

  return correctionsWorkflow ? (
    <StyledTaskHeader>
      <p className="correctionsHeaderTitle">Make a Correction</p>
      <div>
        <UI.Button
          className="correctionsCancel"
          variant="tertiary"
          size="small"
          style={{ marginRight: `calc(${Tokens.rhythm} * 2)` }}
          onClick={() => {
            if (setCorrectionsWorkflow) setCorrectionsWorkflow(false);
          }}
        >
          Cancel
        </UI.Button>
        <UI.Button
          className="correctionsSubmit"
          variant="primary"
          size="small"
          onClick={() => {
            if (handleCorrectionsModal) handleCorrectionsModal();
          }}
          disabled={!isDirty}
        >
          Submit
        </UI.Button>
      </div>

      {setCorrectionsModalOpen && onTaskCorrect && setCorrectionsWorkflow ? (
        <TaskCorrectionsModal
          modalOpen={correctionsModalOpen ?? false}
          onTaskCorrect={onTaskCorrect}
          setModalOpen={setCorrectionsModalOpen}
        />
      ) : null}
    </StyledTaskHeader>
  ) : (
    <StyledTaskHeader>
      <div>
        <TaskCompleteButton
          variant="basic"
          data-testid="taskCompleteButton"
          className={buttonClasses.join(' ')}
          size="small"
          leftIcon={
            taskIsSkipped ? (
              <Minus color={getIconColor()} />
            ) : (
              <Check color={getIconColor()} />
            )
          }
          onClick={() =>
            taskIsComplete || taskIsSkipped ? onTaskSave() : onTaskComplete()
          }
          disabled={encounterIsComplete || actionsAreDisabled}
        >
          {getCompleteButtonText()}
        </TaskCompleteButton>

        {!taskIsComplete && !taskIsSkipped ? (
          <TaskHeaderButton
            variant="basic"
            size="small"
            isLoading={isSaving}
            disabled={savingProgressIsDisabled}
            onClick={() => onTaskSave()}
          >
            {isSaving ? 'Saving...' : 'Save Progress'}
          </TaskHeaderButton>
        ) : null}
      </div>

      <div>
        <span className="notice">{clinicianReviewedStatus}</span>

        <TaskHeaderActions
          actionsOpen={actionsOpen}
          handleAction={handleAction}
          setActionsOpen={setActionsOpen}
          task={task}
        />

        <X className="close" onClick={handleTaskDrawerClose} />
      </div>

      <SkipTaskModal
        encounterTaskId={task?.encounterTaskId}
        inProgressAt={task?.inProgressAt}
        isDirty={isDirty}
        modalOpen={skipTaskModalOpen}
        onTaskSkip={onTaskSkip}
        setModalOpen={setSkipTaskModalOpen}
      />
    </StyledTaskHeader>
  );
};

export default TaskHeaderComponent;
