import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import mime from 'mime-types';

import { Config } from 'config';
import { Logger } from 'utils';
import { TFile } from './types';
import { getMD5Hash, createLocalUrl } from './utils';

type Props = {
  fileUrls: string[];
  folder: string;
};

type ReturnValues = {
  anyDownloadFailed: boolean;
  anyIsUploading: boolean;
  anyUploadFailed: boolean;
  append: () => void;
  clearAllUploadFailed: () => void;
  files: TFile[];
};

type FileState = {
  downloadFailed: boolean;
  file: Blob | null;
  fileUrl: string | null;
  isDownloading: boolean;
  isUploading: boolean;
  localUrl: string | null;
  uploadFailed: boolean;
};

const defaultState: FileState = {
  downloadFailed: false,
  file: null,
  fileUrl: null,
  isDownloading: false,
  isUploading: false,
  localUrl: null,
  uploadFailed: false,
};

const createStateMap = (fileUrls: string[]): Record<number, FileState> =>
  fileUrls.reduce(
    (prev, fileUrl, index) =>
      Object.assign(prev, { [index]: { ...defaultState, fileUrl } }),
    {},
  );

const useMultiFileUpload = ({
  fileUrls: initialFileUrls = [],
  folder,
}: Props): ReturnValues => {
  const [stateMap, setStateMap] = useState(createStateMap(initialFileUrls));

  const { getAccessTokenSilently } = useAuth0();

  // Internal state
  const [failedFiles, setFailedFiles] = useState<Record<number, File>>({});

  const setIsDownloading = (index: number) => (value: boolean) => {
    setStateMap((state) => {
      const updated = { ...state[index], isDownloading: value };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const setDownloadFailed = (index: number) => (value: boolean) => {
    setStateMap((state) => {
      const updated = { ...state[index], downloadFailed: value };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const setFile = (index: number) => (value: Blob | null) => {
    const url = value ? createLocalUrl(value) : null;
    setStateMap((state) => {
      const updated = { ...state[index], file: value, localUrl: url };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const setFileUrl = (index: number) => (value: string | null) => {
    setStateMap((state) => {
      const updated = { ...state[index], fileUrl: value };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const setIsUploading = (index: number) => (value: boolean) => {
    setStateMap((state) => {
      const updated = { ...state[index], isUploading: value };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const setUploadFailed = (index: number) => (value: boolean) => {
    setStateMap((state) => {
      const updated = { ...state[index], uploadFailed: value };
      return {
        ...state,
        [index]: updated,
      };
    });
  };

  const downloadFile = (index: number) => async () => {
    const { fileUrl } = stateMap[index];

    if (!fileUrl) {
      throw new Error('Cannot download a file without setting the `fileUrl`');
    }

    setIsDownloading(index)(true);
    setDownloadFailed(index)(false);

    try {
      const accessToken = await getAccessTokenSilently({ ignoreCache: true });
      const resp = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (resp.ok) {
        const data = await resp.blob();
        setFile(index)(data);
        return;
      }

      setDownloadFailed(index)(true);

      // On fetch failure
      Logger.error(
        `An error occurred while retrieving a file. Response Status: ${
          resp.status
        } ${resp.statusText}. Response Body: ${resp.body?.toString()}`,
      );
    } catch (err) {
      setDownloadFailed(index)(true);
      Logger.error(err);
    } finally {
      setIsDownloading(index)(false);
    }
  };

  const uploadFile =
    (index: number) =>
    async (originalFile: File): Promise<{ success: boolean; url?: string }> => {
      setUploadFailed(index)(false);
      setIsUploading(index)(true);

      const getFileUrl = async (): Promise<string> => {
        const baseUrl = Config.fileUploadUrl;
        const bucket = Config.fileUploadBucket;
        const filename = uuid();
        const extension = mime.extension(originalFile.type);
        const url = `${baseUrl}/${bucket}/${folder}/${filename}.${extension}`;

        if (Config.fileUploadRequireMd5) {
          const hash = await getMD5Hash(originalFile);
          return `${url}?md5=${encodeURIComponent(hash)}`;
        }

        return url;
      };

      try {
        const accessToken = await getAccessTokenSilently({ ignoreCache: true });
        const url = await getFileUrl();

        const resp = await fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': originalFile.type,
            'Content-Length': originalFile.size.toString(),
          },
          body: originalFile,
        });

        if (resp.ok) {
          // The media upload service currently returns a 200 response
          // even if the upload fails on S3. If the upload is successful,
          // an empty response body is returned. Otherwise, an XML error
          // message will be returned which we'll throw and log to Sentry.
          const contentLength = resp.headers.get('Content-Length');
          if (contentLength && `${contentLength}` !== '0') {
            const body = await resp.text();
            throw new Error(body);
          }

          setFileUrl(index)(url);
          setFile(index)(originalFile);
          return {
            success: true,
            url,
          };
        }

        // On upload failure
        setUploadFailed(index)(true);
        setFailedFiles((state) => ({
          ...state,
          [index]: originalFile,
        }));

        Logger.error(
          `An error occurred while uploading a file. Response Status: ${
            resp.status
          } ${
            resp.statusText
          }. Response Body: ${resp.body?.toString()}. File Size: ${
            originalFile.size
          } bytes.`,
        );
      } catch (err) {
        Logger.error(err);
        setUploadFailed(index)(true);
        setFailedFiles((state) => ({
          ...state,
          [index]: originalFile,
        }));
      } finally {
        setIsUploading(index)(false);
      }

      return {
        success: false,
      };
    };

  const removeFile = (index: number) => (softDelete: boolean) => {
    setStateMap((state) => {
      const { [index]: removed, ...rest } = state;
      return {
        ...rest,
        ...(softDelete && {
          [index]: {
            ...removed,
            isDeleted: true,
          },
        }),
      };
    });
  };

  const retryUpload = (index: number) => async () => {
    const { uploadFailed } = stateMap[index];

    if (!uploadFailed) {
      throw new Error('Cannot retry before a failed call to `uploadFile`');
    }

    setUploadFailed(index)(false);
    await uploadFile(index)(failedFiles[index]);
  };

  const append = () => {
    setStateMap((state) => ({
      ...state,
      [Object.keys(state).length]: { ...defaultState },
    }));
  };

  const clearAllUploadFailed = () => {
    setStateMap((state) => {
      return Object.entries(state).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: {
            ...value,
            uploadFailed: false,
            file: null,
          },
        }),
        {},
      );
    });
  };

  const stateMapValues = Object.values(stateMap);

  const anyDownloadFailed = stateMapValues.reduce(
    (prev, current) => current.downloadFailed || prev,
    false,
  );

  const anyUploadFailed = stateMapValues.reduce(
    (prev, current) => current.uploadFailed || prev,
    false,
  );

  const anyIsUploading = stateMapValues.reduce(
    (prev, current) => current.isDownloading || prev,
    false,
  );

  return {
    anyDownloadFailed,
    anyIsUploading,
    anyUploadFailed,
    append,
    clearAllUploadFailed,
    files: stateMapValues.map((_, index) => ({
      ...stateMap[index],
      downloadFile: downloadFile(index),
      removeFile: removeFile(index),
      retryUpload: retryUpload(index),
      uploadFile: uploadFile(index),
    })),
  };
};

export default useMultiFileUpload;
