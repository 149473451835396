import { useEffect, useState } from 'react';

import { Config } from 'config';
import {
  EncounterClinicianStatusEnum,
  useGetEncountersByPatientIdQuery,
} from 'generated/graphql';
import { useUserContext } from 'hooks';

type TProps = {
  patientId: number | undefined;
};

type TQueryArgs = {
  encounterId?: number;
  marketId: number;
  patientId: number;
};

type TReturnValues = {
  handleClick: () => void;
  initializing: boolean;
  isDisabled: boolean;
  isVisible: boolean;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  urlQueryArgs?: TQueryArgs;
};

const useDoseSpotButton = ({ patientId }: TProps): TReturnValues => {
  const userContext = useUserContext();

  const [doseSpotModalState, setDoseSpotModalState] = useState<
    Pick<
      TReturnValues,
      'initializing' | 'isDisabled' | 'modalOpen' | 'urlQueryArgs'
    >
  >({
    initializing: true,
    isDisabled: false,
    modalOpen: false,
    urlQueryArgs: undefined,
  });

  const { data } = useGetEncountersByPatientIdQuery({
    variables: {
      id: patientId ?? 0,
    },
    skip: !userContext?.operatingAsClinician,
  });

  const encounters = data?.encountersList ?? [];
  const clinicianInProgress = encounters.filter(
    (encounter) =>
      encounter?.clinicianStatus === EncounterClinicianStatusEnum.IN_PROGRESS,
  );

  let isDisabled = false; // enable by default
  let marketId = 0;

  if (clinicianInProgress.length > 0) {
    //  Don't allow DoseSpot if clinician in progress encounters exist - the clinician should use DS through the encounter
    isDisabled = true;
  } else {
    const closedEncounters = encounters
      .filter(
        (encounter) =>
          encounter?.claimedAt &&
          encounter?.clinicianStatus === EncounterClinicianStatusEnum.COMPLETED,
      )
      .sort(
        (encounterA, encounterB) =>
          new Date(encounterB?.claimedAt).getTime() -
          new Date(encounterA?.claimedAt).getTime(),
      );

    if (closedEncounters.length === 0) {
      //  Don't allow DoseSpot if there are no clinician completed encounters
      isDisabled = true;
    } else {
      marketId = closedEncounters[0].appointment?.marketId || 0;
    }
  }

  useEffect(() => {
    if (data) {
      const urlQueryArgs = isDisabled
        ? undefined
        : {
            patientId: patientId ?? 0,
            marketId,
          };

      setDoseSpotModalState((prev) => ({
        ...prev,
        initializing: false,
        isDisabled,
        urlQueryArgs,
      }));
    }
  }, [data, setDoseSpotModalState]);

  const setModalOpen = (open: boolean) => {
    setDoseSpotModalState((prev) => ({
      ...prev,
      modalOpen: open,
    }));
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  return {
    ...doseSpotModalState,
    handleClick,
    isVisible: Config.isDoseSpotEnabled && !!userContext?.operatingAsClinician,
    setModalOpen,
  };
};

export default useDoseSpotButton;
