import { CheckSquare } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens, RoutePaths } from 'config';
import { Layout } from 'modules';
import UI from 'ui';
import { EncounterTableRow } from './components';
import { usePatientQueue } from './hooks';

const StyledPatientQueue = styled.div`
  background-color: ${Tokens.color.neutral.white.base};

  .header {
    background-color: ${Tokens.color.ui.steel.base};
    position: sticky;
    top: 0;
    z-index: ${Tokens.zIndex.header};

    h1 {
      border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.heading[1]};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin: 0;
      margin-bottom: ${Tokens.rhythm};
      padding: calc(${Tokens.rhythm} * 2);
    }

    .tabs {
      padding: 0 calc(${Tokens.rhythm} * 2);
    }
  }

  .tableContainer {
    overflow-x: scroll;
    margin: 0 calc(${Tokens.rhythm} * 2);
  }

  .footerPadding {
    height: 80px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: 115px; /* 80 + 35px */
    } /* iPad */
  }
`;

const PatientQueue = (): JSX.Element => {
  const {
    encounters,
    handleEncounterClick,
    handleClaimEncounter,
    loading,
    totalResults,
  } = usePatientQueue();

  return (
    <Layout
      title="Patient Queue"
      breadcrumbsProps={[
        {
          title: 'Patient Queue',
          href: RoutePaths.queue,
        },
      ]}
    >
      <StyledPatientQueue>
        <div className="header">
          <h1>Patient Queue</h1>
          <div className="tabs">
            <UI.Tabs>
              <UI.Tab onClick={() => {}}>Active</UI.Tab>
            </UI.Tabs>
          </div>
        </div>
        <div className="tableContainer">
          {loading ? (
            <UI.Spinner testId="spinner" centered />
          ) : (
            <>
              {encounters && (
                <>
                  {totalResults === 0 ? (
                    <UI.EmptyState
                      icon={CheckSquare}
                      iconWeight="regular"
                      title="No tasks found."
                    />
                  ) : (
                    <UI.Table
                      fullWidth
                      style={{ marginBottom: `calc(${Tokens.rhythm} * 10)` }}
                    >
                      <UI.TableHead>
                        <UI.TableRow>
                          <UI.TableCell>Time in queue</UI.TableCell>
                          <UI.TableCell>Market</UI.TableCell>
                          <UI.TableCell>Patient</UI.TableCell>
                          <UI.TableCell>Age</UI.TableCell>
                          <UI.TableCell>Partnership</UI.TableCell>
                          <UI.TableCell>Insurance Company</UI.TableCell>
                          <UI.TableCell>Service Line</UI.TableCell>
                          <UI.TableCell>Appt Status</UI.TableCell>
                          <UI.TableCell>Responder(s)</UI.TableCell>
                          <UI.TableCell>Clinician Status</UI.TableCell>
                          <UI.TableCell>Clinician</UI.TableCell>
                        </UI.TableRow>
                      </UI.TableHead>
                      <UI.TableBody>
                        {encounters.map((encounter) => (
                          <EncounterTableRow
                            key={`encounterrow__${encounter.encounterId}`}
                            encounter={encounter}
                            handleEncounterClick={handleEncounterClick}
                            handleClaimEncounter={handleClaimEncounter}
                          />
                        ))}
                      </UI.TableBody>
                    </UI.Table>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </StyledPatientQueue>
    </Layout>
  );
};

export default PatientQueue;
