import { Control, DeepMap, FieldError } from 'react-hook-form';

import { Tokens } from 'config';
import styled from 'styled-components';
import { Controlled } from 'forms';
import { StyledTaskForm } from '../TaskForm';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { TransferOfCareInputs, LeaveBehindInputs } from './utils';
import { Enums } from 'utils';

const StyledErrorMessage = styled.p`
  color: ${Tokens.color.ui.error.base};
  margin-top: 0;
  line-height: ${Tokens.lineHeight.paragraph.small};
`;

const AttachmentsForm = ({
  control,
  errors,
  inputsAreDisabled,
  taskHeaderProps,
}: {
  control: Control<TransferOfCareInputs & LeaveBehindInputs>;
  errors: DeepMap<TransferOfCareInputs, FieldError>;
  inputsAreDisabled: boolean;
  taskHeaderProps: TaskHeaderProps;
  onCompleteTask: () => void;
  onSaveTask: () => void;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const getHumanizedDataCategory = () =>
    (task?.dataCategory && Enums.humanize(task?.dataCategory)) ?? '';

  const AttachmentOtherErrorMessage = (
    <StyledErrorMessage>
      Please upload a valid {getHumanizedDataCategory()} image
    </StyledErrorMessage>
  );

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
        style={{ paddingBottom: `calc(${Tokens.rhythm} * 6)` }}
      >
        <header>
          <h3>{task?.taskName}</h3>

          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
        </header>
        <p className="formInstructionsLabel">1. {task?.taskName}</p>

        {task?.dataCategory === 'leave_behind' ? (
          <>
            <section>
              <Controlled.ImageInput
                className="leaveBehindAttachment"
                control={control}
                description="Leave Behind"
                disabled={inputsAreDisabled}
                folder={`${task?.encounter?.encounterId}`}
                fullWidth
                label="Photo Documentation"
                name="leaveBehindAttachment"
              />

              <Controlled.ImageInput
                className="workOrSchoolNoteAttachment"
                control={control}
                description="Work School Note"
                disabled={inputsAreDisabled}
                folder={`${task?.encounter?.encounterId}`}
                fullWidth
                label=" "
                name="workOrSchoolNoteAttachment"
                optional
              />
            </section>
          </>
        ) : (
          <section>
            <Controlled.ImageInput
              className="transferOfCareAttachment"
              control={control}
              description={getHumanizedDataCategory()}
              disabled={inputsAreDisabled}
              folder={`${task?.encounter?.encounterId}`}
              fullWidth
              label="Photo Documentation"
              name="transferOfCareAttachment"
            />
            {errors.transferOfCareAttachment?.message === ' '
              ? AttachmentOtherErrorMessage
              : null}
          </section>
        )}
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};
export default AttachmentsForm;
