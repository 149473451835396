import { useReactiveVar } from '@apollo/client';
import { CalendarX } from 'phosphor-react';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Patient,
  useGetEncountersByPatientIdQuery,
  EncountersOrderBy,
  EncounterResponderStatusEnum,
} from 'generated/graphql';

import { Tokens } from 'config';
import { TaskDrawer } from 'cache/reactiveVars';
import { useDidMountEffect } from 'hooks';
import UI from 'ui';
import { useEffect } from 'react';
import Encounter from './Encounter';
import { TaskForm } from '../TaskForm';

const StyledEncounters = styled.div<{ taskDrawerIsExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${Tokens.color.neutral.grey[250]};
  height: calc(
    100vh - 173px
  ); // Subtract height of nav header and profile header
  overflow: hidden;

  .encounters {
    width: ${({ taskDrawerIsExpanded }) =>
      taskDrawerIsExpanded ? '100%' : '100%'};

    transition-property: width;
    transition-duration: ${Tokens.transition.duration[3]};
    transition-timing-function: ${Tokens.transition.timing.easeIn};
    height: 100%;
    overflow-y: scroll;
    --webkit-overflow-scrolling: touch;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      padding-bottom: 35px;
    } // iPad
  }
`;

const Encounters = ({
  patient,
}: Readonly<{
  patient: Partial<Patient>;
}>): JSX.Element => {
  const { encounterId: selectedEncounterId } =
    useParams<{ encounterId?: string }>();
  const selectedEncounterRef = useRef<HTMLDivElement>(null);

  const { data, loading, error } = useGetEncountersByPatientIdQuery({
    variables: {
      id: patient?.patientId as number,
      orderBy: EncountersOrderBy.SCHEDULED_FOR_DESC,
    },
  });

  const taskDrawerState = useReactiveVar(TaskDrawer.state);
  const taskDrawerIsExpanded = Boolean(taskDrawerState?.expanded);
  const encounterList = data?.encountersList;

  useEffect(
    () => () => {
      TaskDrawer.state(TaskDrawer.defaultState);
    }, // Reset TaskDrawer when exiting encounters
    [],
  );

  useDidMountEffect(() => {
    if (!loading && selectedEncounterId && selectedEncounterRef.current) {
      selectedEncounterRef.current.scrollIntoView();
    }
  }, [loading]);

  if (error) return <UI.ErrorFallback error={error} />;
  if (loading) return <UI.Spinner centered data-testid="spinner" />;

  return (
    <StyledEncounters taskDrawerIsExpanded={taskDrawerIsExpanded}>
      <UI.Container
        backgroundColor={Tokens.color.neutral.white.base}
        className="encounters"
        padding={`calc(${Tokens.rhythm} * 2)`}
      >
        {encounterList && encounterList.length > 0 ? (
          encounterList.map((encounter) => {
            const selected =
              `${encounter?.encounterId}` === selectedEncounterId;
            return (
              <React.Fragment key={`${encounter.encounterId}`}>
                {selected && <div ref={selectedEncounterRef} />}
                <Encounter
                  encounter={encounter}
                  isExpanded={
                    selected &&
                    !!encounter?.responderStatus &&
                    ![
                      EncounterResponderStatusEnum.NOT_STARTED,
                      EncounterResponderStatusEnum.TERMINATED,
                    ].includes(encounter.responderStatus)
                  }
                />
              </React.Fragment>
            );
          })
        ) : (
          <UI.EmptyState
            icon={CalendarX}
            title="No encounters scheduled yet!"
          />
        )}
      </UI.Container>

      {taskDrawerState ? <TaskForm taskDrawerState={taskDrawerState} /> : null}
    </StyledEncounters>
  );
};

export default Encounters;
