import { useAuth0 } from '@auth0/auth0-react';
import { createLocalUrl } from 'hooks/useFileUpload/utils';
import { useEffect, useState } from 'react';
import { Logger } from 'utils';

const ImageView = ({ url }: { url: string }): JSX.Element | null => {
  const { getAccessTokenSilently } = useAuth0();
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [localUrl, setObjectUrl] = useState<string | null>(null);

  const downloadFile = async () => {
    if (!url) {
      throw new Error('Cannot download a file without setting the `fileUrl`');
    }

    setIsDownloading(true);
    setDownloadFailed(false);

    try {
      const accessToken = await getAccessTokenSilently({ ignoreCache: true });
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (resp.ok) {
        const data = await resp.blob();
        const lurl = createLocalUrl(data);
        setObjectUrl(lurl);
        return;
      }

      setDownloadFailed(true);

      // On fetch failure
      Logger.error(
        `An error occurred while retrieving a file. Response Status: ${
          resp.status
        } ${resp.statusText}. Response Body: ${resp.body?.toString()}`,
      );
    } catch (err) {
      setDownloadFailed(true);
      Logger.error(err);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    downloadFile();
  }, []);

  // We shouldn't see these various states but output something for information in the event of an issue
  if (!localUrl) return <p>...</p>;
  if (isDownloading) return <p>Loading</p>;
  if (downloadFailed) return <p>Image Download failed.</p>;

  return (
    <img
      id="view-image"
      src={localUrl ?? undefined}
      style={{ maxWidth: '100%', maxHeight: '100%' }}
    />
  );
};

export default ImageView;
