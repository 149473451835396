import { startOfToday, endOfToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import {
  Encounter,
  EncounterClinicianStatusConditionEnum,
  EncounterClinicianStatusEnum,
  useClaimEncounterMutation,
  useGetEncountersByUserIdQuery,
} from 'generated/graphql';
import { useUserContext, useToastMessage } from 'hooks';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import UI from 'ui';

type ReturnValue = {
  encounters: Encounter[] | undefined;
  handleClaimEncounter: (encounter: Encounter) => void;
  handleEncounterClick: (encounter: Encounter) => void;
  loading: boolean;
  totalResults: number;
};

type PatientQueueState = {
  encounters: Encounter[] | undefined;
  loading: boolean;
  totalResults: number;
};

const usePatientQueue = (): ReturnValue => {
  const userContext = useUserContext();
  const history = useHistory();
  const location = useLocation();
  const { setToastMessage } = useToastMessage();

  const [queueState, setQueueState] = useState<PatientQueueState>({
    encounters: undefined,
    loading: true,
    totalResults: 0,
  });

  const [claimEncounter, { error: claimError }] = useClaimEncounterMutation();

  const { encounters, loading, totalResults } = queueState;

  /**
   * Perform Encounter Search
   */
  const { data: encounterData, loading: encountersLoading } =
    useGetEncountersByUserIdQuery({
      variables: {
        clinicianStatus: [
          EncounterClinicianStatusConditionEnum.IN_PROGRESS,
          EncounterClinicianStatusConditionEnum.IN_QUEUE,
        ],
        marketId: (userContext?.user?.userMarketsList ?? []).map(
          (market) => market?.marketId ?? 0,
        ),
        scheduledForBefore: endOfToday().toISOString(),
        scheduledForAfter: startOfToday().toISOString(),
        offset: 0,
        limit: undefined,
        includeTaskStatuses: true,
        includeResponderNames: true,
      },
      fetchPolicy: 'network-only',
      pollInterval: 3000,
    });

  useEffect(() => {
    const items = [
      ...((encounterData?.encounters?.nodes ?? []) as Encounter[]),
    ];

    const { inProgress, inQueue } = items.reduce(
      (prev, encounter) => {
        if (
          encounter?.clinicianStatus === EncounterClinicianStatusEnum.IN_QUEUE
        ) {
          return {
            inProgress: [...prev.inProgress],
            inQueue: [...prev.inQueue, encounter],
          };
        }

        return {
          inProgress: [...prev.inProgress, encounter],
          inQueue: [...prev.inQueue],
        };
      },
      {
        inProgress: [],
        inQueue: [],
      } as { inProgress: Encounter[]; inQueue: Encounter[] },
    );

    const sorted: Encounter[] = [
      ...inQueue.sort((inQueueA, inQueueB) => {
        if (inQueueA.inQueueAt && inQueueB.inQueueAt) {
          return (
            new Date(inQueueA.inQueueAt).getTime() -
            new Date(inQueueB.inQueueAt).getTime()
          );
        }
        return 1;
      }),
      ...inProgress.sort((inProgressA, inProgressB) => {
        if (inProgressA.claimedAt && inProgressB.claimedAt) {
          return (
            new Date(inProgressA.claimedAt).getTime() -
            new Date(inProgressB.claimedAt).getTime()
          );
        }
        return 1;
      }),
    ];

    setQueueState((state) => ({
      ...state,
      encounters: sorted,
      totalResults: encounterData?.encountersCount?.totalCount ?? 0,
      loading: encountersLoading,
    }));
  }, [encounterData, setQueueState, encountersLoading]);

  const handleEncounterClick = (encounter: Encounter) => {
    const url = generatePath(PatientRoutePaths.encounters, {
      id: `${encounter.patient?.globalPatientId}`,
      encounterId: encounter.encounterId,
    });
    history.push(url, {
      referrer: {
        pageTitle: 'Patient Queue',
        pathname: location.pathname,
      },
    });
  };

  useEffect(() => {
    if (claimError) {
      setToastMessage(
        claimError?.message ??
          'An error occurred while claiming the encounter.',
        UI.Toast.SeverityLevel.Error,
      );
    }
  }, [claimError]);

  const handleClaimEncounter = async (encounter: Encounter) => {
    try {
      await claimEncounter({ variables: { id: encounter.encounterId } });
      handleEncounterClick(encounter);
    } catch {
      return;
    }
  };

  return {
    encounters,
    handleClaimEncounter,
    handleEncounterClick,
    loading,
    totalResults,
  };
};

export default usePatientQueue;
