import styled from 'styled-components';
import { CheckCircle, MinusCircle, Triangle } from 'phosphor-react';
import { useReactiveVar } from '@apollo/client';

import { EncounterTask, Maybe, UserRoleEnum } from 'generated/graphql';

import { TaskDrawer } from 'cache/reactiveVars';
import { useTask, useUserContext } from 'hooks';
import { Tokens } from 'config';
import UI from 'ui';
import { getStateFromTask } from 'cache/reactiveVars/TaskDrawer';
import EncounterTaskAvatarGroup from 'ui/EncounterTaskAvatar/EncounterTaskAvatarGroup';
import EncounterTaskAvatar from 'ui/EncounterTaskAvatar';

const StyledEncounterTask = styled.div<{
  activeTaskId: number | undefined | null;
  encounterTaskId: number;
}>(
  ({ activeTaskId, encounterTaskId }) => `
  .card {
    display: flex;
    align-items: center;

    .icon {
      width: 16px;
      height: 16px;
    }

    .taskName {
      margin: 0;
      overflow: hidden;
      padding: 4px ${Tokens.rhythm} 4px calc(${Tokens.rhythm} * 2);
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    border: ${
      activeTaskId === encounterTaskId
        ? `${Tokens.border.size} solid ${Tokens.border.color.charcoal}}`
        : 'none'
    }
  }

  & + & {
    margin-top: ${Tokens.rhythm};
  }
`,
);

const StyledReviewAndAvatars = styled.div<{
  taskRoles: Maybe<UserRoleEnum>[];
}>(
  ({ taskRoles }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: calc(${Tokens.rhythm}*2);

  .avatarGroupWrapper {
    margin-left: ${taskRoles.length > 1 ? '12px' : '0px'};
    margin-bottom: calc(${Tokens.rhythm}*3);
  }

  .correctionsCountWrapper {
    margin-right: ${Tokens.rhythm};
    display: flex;
    align-items: center;

    .correctionsCount {
      color: ${Tokens.color.ui.charcoal.base};
      padding-left: calc(${Tokens.rhythm}/5);
    }
  }

  .notice {
    color: ${Tokens.color.neutral.grey.base};
    font-style: italic;
    white-space: nowrap;
    margin-right: ${Tokens.rhythm};

    @media (max-width: ${Tokens.dimensions.narrowTaskList}) {
      display: none;
    }
  }
`,
);

const EncounterTaskComponent = ({
  encounterTask,
}: Readonly<{
  encounterTask: EncounterTask;
}>): JSX.Element => {
  const { taskIsComplete, taskIsSkipped, clinicianReviewedStatus } =
    useTask(encounterTask);
  const { user, operatingAs } = useUserContext() ?? {};
  const { taskId } = useReactiveVar(TaskDrawer.state);
  const { encounterTaskId, taskRoles } = encounterTask;

  const getIcon = () => {
    switch (true) {
      case taskIsComplete:
        return (
          <CheckCircle fill={Tokens.color.ui.success.base} weight="fill" />
        );
      case taskIsSkipped:
        return (
          <MinusCircle fill={Tokens.color.ui.warning.base} weight="fill" />
        );
      default:
        return (
          <CheckCircle fill={Tokens.color.neutral.grey[219]} weight="fill" />
        );
    }
  };

  const Avatars =
    taskRoles && taskRoles.length > 0 ? (
      <div
        className="avatarGroupWrapper"
        data-testid="EncounterTaskAvatarGroup"
      >
        <EncounterTaskAvatarGroup
          loggedInUserRole={operatingAs}
          loggedInUserId={user?.userId}
        >
          {taskRoles.map((taskRole) => (
            <EncounterTaskAvatar
              key={taskRole}
              role={taskRole}
              size="md"
              userId={user?.userId}
            />
          ))}
        </EncounterTaskAvatarGroup>
      </div>
    ) : null;

  const handleClick = () => TaskDrawer.state(getStateFromTask(encounterTask));

  return (
    <StyledEncounterTask
      activeTaskId={taskId}
      encounterTaskId={encounterTaskId}
    >
      <UI.Card
        className="card"
        onClick={handleClick}
        style={{ justifyContent: 'space-between' }}
      >
        <div className="icon">{getIcon()}</div>

        <p className="taskName">{encounterTask.taskName}</p>

        <StyledReviewAndAvatars taskRoles={taskRoles}>
          <span className="notice">{clinicianReviewedStatus}</span>

          {encounterTask?.corrections &&
          encounterTask?.corrections?.length > 0 ? (
            <div className="correctionsCountWrapper">
              <Triangle />
              <span className="correctionsCount">
                {encounterTask?.corrections?.length}
              </span>
            </div>
          ) : null}
          {Avatars}
        </StyledReviewAndAvatars>
      </UI.Card>
    </StyledEncounterTask>
  );
};

export default EncounterTaskComponent;
