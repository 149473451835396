import { IconProps } from 'phosphor-react';
import styled from 'styled-components';
import { ForwardRefExoticComponent, RefAttributes } from 'react';

import { Tokens } from 'config';
import Button from 'ui/Button/Button';

export type EmptyStateProps = {
  /**
   * The action button text.
   */
  actionLabel?: string;
  /**
   * The empty state title.
   */
  title: string;
  /**
   * The icon above the title.
   */
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
  /**
   * The icon weight.
   */
  iconWeight?: 'fill' | 'regular' | 'duotone' | undefined;
  /**
   * The action made when the button is clicked.
   */
  onActionClick?: () => void;
  /**
   * The empty state sub-title.
   */
  subtitle?: string;
  /**
   * The `data-testid` attribute.
   */
  testId?: string;
  /**
   * The `max-width` of the empty state content as a CSS value.
   */
  width?: string;
};

const StyledEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  .action {
    margin-top: calc(${Tokens.rhythm} * 2);
    align-self: center;
    color: ${Tokens.color.ui.charcoal.base};
  }

  .inner {
    color: ${Tokens.color.ui.charcoal.base};
    display: inline-block;
    font-family: ${Tokens.font.family};
    text-align: center;

    .icon {
      margin-bottom: 18px;
    }

    .title {
      font-size: ${Tokens.font.size.paragraph.large};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.paragraph.small};
    }

    .subtitle {
      font-size: ${Tokens.font.size.paragraph.base};
      font-weight: ${Tokens.font.weight.normal};
      line-height: ${Tokens.lineHeight.paragraph.base};
      padding-top: ${Tokens.rhythm};
    }
  }
`;

const EmptyState = ({
  actionLabel,
  icon: IconComponent,
  iconWeight,
  onActionClick,
  title,
  subtitle,
  testId,
  width = '100%',
}: EmptyStateProps): JSX.Element => (
  <StyledEmptyState data-testid={testId}>
    <div className="inner" style={{ maxWidth: width, alignContent: 'center' }}>
      <IconComponent className="icon" size={24} weight={iconWeight ?? 'fill'} />
      <div className="title">{title}</div>
      {subtitle ? <div className="subtitle">{subtitle}</div> : null}
    </div>
    {actionLabel && onActionClick && (
      <Button
        as="button"
        variant="secondary"
        onClick={onActionClick}
        className="action"
      >
        {actionLabel}
      </Button>
    )}
  </StyledEmptyState>
);

export default EmptyState;
