import { Bell } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import { useDoseSpotNotifications } from './hooks';

const Container = styled.div<{ hasErrors: boolean }>`
  position: relative;

  button {
    cursor: ${(props) => (props.hasErrors ? 'pointer' : 'not-allowed')};
  }

  .badge {
    background-color: ${Tokens.color.ui.error.base};
    border-radius: ${Tokens.border.radius};
    color: ${Tokens.color.neutral.white.base};
    font-size: ${Tokens.font.size.paragraph.small};
    padding: 2px 4px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
  }
`;

const DoseSpotNotifications = (): JSX.Element | null => {
  const { showNotifications, errorCount, handleButtonClick } =
    useDoseSpotNotifications();

  return showNotifications ? (
    <Container hasErrors={errorCount > 0}>
      <UI.Button size="icon" variant="tertiary" onClick={handleButtonClick}>
        <Bell size="24" />
      </UI.Button>
      {errorCount > 0 && (
        <span className="badge">{errorCount < 10 ? errorCount : '9+'}</span>
      )}
    </Container>
  ) : null;
};

export default DoseSpotNotifications;
