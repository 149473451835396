import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { DotsThree, Flag } from 'phosphor-react';

import { Tokens } from 'config';
import UI from 'ui';
import type { EncounterCardProps } from '../types';
import AppointmentsContext from '../AppointmentsContext';

const StyledEncounterCard = styled(UI.Card)`
  color: ${Tokens.color.ui.charcoal.base};
  display: flex;
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  flex-direction: row;
  line-height: ${Tokens.lineHeight.paragraph.base};
  margin-bottom: ${Tokens.rhythm};

  &:last-child {
    margin-bottom: 0;
  }

  .patientCol {
    margin-right: calc(${Tokens.rhythm} * 2);
    min-width: 160px;
    max-width: 160px;

    .name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .details {
      color: ${Tokens.color.ui.slate.base};
      display: block;
    }
  }

  .serviceCol {
    display: -webkit-box;
    flex-grow: 1;
    margin-right: calc(${Tokens.rhythm} * 2);
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .serviceLine {
      font-weight: ${Tokens.font.weight.bold};
    }

    .reason::before {
      content: ' - ';
    }
  }

  .flagsCol {
    align-items: center;
    display: flex;
    min-width: calc(${Tokens.rhythm} * 6);
    margin-right: calc(${Tokens.rhythm} * 2);
  }

  .actionsCol {
    align-items: center;
    display: flex;
    white-space: nowrap;

    .statusPill {
      margin-right: calc(${Tokens.rhythm} * 2);
    }
  }
`;

const EncounterCard = ({
  encounter,
  testId,
}: EncounterCardProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownAnchorRef = useRef(null);
  const { onEncounterClick } = useContext(AppointmentsContext);

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onEncounterClick) {
      onEncounterClick(encounter?.patient?.globalPatientId ?? '');
    }
  };

  return (
    <StyledEncounterCard onClick={handleCardClick} data-testid={testId}>
      <div className="patientCol">
        <span className="name">{encounter?.patient?.fullName}</span>
        <span className="details">{`${encounter?.patient?.age}, ${encounter?.patient?.humanizedSex}`}</span>
      </div>

      <div className="serviceCol">
        <span className="serviceLine">
          {encounter?.serviceLine?.name ?? ''}
          {encounter?.serviceLine?.subType &&
            ` - ${encounter?.serviceLine.subType}`}
        </span>
        {encounter?.reasonForEncounter && (
          <span className="reason">{encounter?.reasonForEncounter}</span>
        )}
      </div>

      <div className="flagsCol">
        {/* TODO: Add Flags */}
        {false && (
          <UI.Button
            className="flagsButton"
            variant="danger"
            leftIcon={<Flag weight="fill" />}
            size="small"
          >
            0
          </UI.Button>
        )}
      </div>

      <div className="actionsCol">
        <UI.EncounterStatusPill
          className="statusPill"
          status={encounter?.humanizedResponderStatus ?? ''}
        />

        <div ref={dropdownAnchorRef}>
          <UI.Button
            className="actionsButton"
            variant="tertiary"
            size="icon"
            onClick={() => setDropdownOpen((state) => !state)}
          >
            <DotsThree size={34} weight="fill" />
          </UI.Button>

          <UI.Dropdown
            anchorRef={dropdownAnchorRef}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            position="bottomLeft"
          >
            <UI.ListItem
              label="View Patient's Chart"
              onClick={
                onEncounterClick
                  ? () =>
                      onEncounterClick(
                        encounter?.patient?.globalPatientId ?? '',
                      )
                  : () => {}
              }
            />
          </UI.Dropdown>
        </div>
      </div>
    </StyledEncounterCard>
  );
};

export default EncounterCard;
