import styled from 'styled-components';

import { Tokens } from 'config';
import { LabelProps } from './types';

const StyledLabel = styled.label<LabelProps>`
  color: ${Tokens.color.neutral.grey[94]};
  display: flex;
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  font-weight: ${Tokens.font.weight.normal};
  line-height: ${Tokens.lineHeight.paragraph.small};

  i {
    font-weight: ${Tokens.font.weight.normal};
  }
`;

const Label = ({
  htmlFor,
  children,
  required = false,
  optional,
  ...props
}: LabelProps): JSX.Element => (
  <StyledLabel htmlFor={htmlFor} required={required} {...props}>
    {children}
    {required ? ' *' : ''}
  </StyledLabel>
);

export default Label;
