import { useEffect, RefObject } from 'react';

const useOutsideClickHandler = (
  ref: RefObject<HTMLDivElement>,
  onClick: () => void,
): void => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Element)) {
        onClick();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);
};

export default useOutsideClickHandler;
