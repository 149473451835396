import { makeVar, FieldPolicy } from '@apollo/client';

import { EncounterTask } from 'generated/graphql';

export type TTasks = {
  isAllergiesTask?: boolean | null;
  isAttachmentTask?: boolean | null;
  isChiefComplaintAndHpiTask?: boolean | null;
  isTeladocTask?: boolean | null;
  isClinicianBillingTask?: boolean | null;
  isDispositionTask?: boolean | null;
  isDoseSpotTask?: boolean | null;
  isMedicalConditionTask?: boolean | null;
  isMedicalDecisionMakingTask?: boolean | null;
  isPatientDemographicsTask?: boolean | null;
  isPatientFormTask?: boolean | null;
  isPaymentMethodTask?: boolean | null;
  isPhysicalExamTask?: boolean | null;
  isPointOfCareTestTask?: boolean | null;
  isProgressNoteTask?: boolean | null;
  isTelehealthTask?: boolean | null;
  isVaccineTask?: boolean | null;
  isVitalTask?: boolean | null;
};

export type TTaskDrawerState = TTasks & {
  expanded: boolean;
  taskId?: number | null;
};

export const defaultState = {
  expanded: false,
  taskId: null,
  isAllergiesTask: false,
  isAttachmentTask: false,
  isChiefComplaintAndHpiTask: false,
  isTeladocTask: false,
  isClinicianBillingTask: false,
  isDispositionTask: false,
  isDoseSpotTask: false,
  isMedicalConditionTask: false,
  isMedicalDecisionMakingTask: false,
  isPatientDemographicsTask: false,
  isPatientFormTask: false,
  isPaymentMethodTask: false,
  isPhysicalExamTask: false,
  isPointOfCareTestTask: false,
  isProgressNoteTask: false,
  isTelehealthTask: false,
  isVaccineTask: false,
  isVitalTask: false,
};

export const getStateFromTask = (task: EncounterTask): TTaskDrawerState => ({
  expanded: true,
  taskId: task.encounterTaskId,
  isAllergiesTask: task.dataType === 'patient_allergy',
  isAttachmentTask: task.dataType === 'attachment',
  isChiefComplaintAndHpiTask: task.dataType === 'chief_complaint_and_hpi',
  isTeladocTask: task.dataType === 'teladoc',
  isClinicianBillingTask: task.dataType === 'billing_diagnostics',
  isDispositionTask: task.dataType === 'disposition',
  isDoseSpotTask: task.dataType === 'patient_reported_medications',
  isMedicalConditionTask: task.dataType === 'patient_problem',
  isMedicalDecisionMakingTask: task.dataType === 'medical_decision_making',
  isPatientDemographicsTask: task.dataType === 'patient',
  isPatientFormTask: task.dataType === 'patient_form',
  isPaymentMethodTask: task.dataType === 'encounter',
  isPhysicalExamTask: task.dataType === 'physical_exam',
  isPointOfCareTestTask: task.dataType === 'point_of_care_test',
  isProgressNoteTask: task.dataType === 'progress_note',
  isTelehealthTask: task.dataType === 'telehealth_meeting',
  isVaccineTask: task.dataType === 'vaccine',
  isVitalTask: task.dataType === 'vital',
});

export const state = makeVar<TTaskDrawerState>(defaultState);

export const policy = {
  taskDrawerState: {
    read() {
      return { ...state() };
    },
  } as FieldPolicy,
};
