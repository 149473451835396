import { FormEvent, useState, useRef, useEffect } from 'react';

import {
  PatientPatchRecordInput,
  usePatientUpdatePatientMutation,
} from 'generated/graphql';

import UI from 'ui';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import { Config } from 'config';
import { Loader } from '@googlemaps/js-api-loader';
import { Option } from 'ui/TypeAhead/TypeAhead';
import { EditInfoSectionsProps } from '../../../types';
import { StyledEditSection } from '../StyledEditSection';
import normalizePlaceDetails from '../../../utils';

const loader = new Loader({
  apiKey: Config.googleMapsApiKey,
  version: 'weekly',
  libraries: ['places'],
});

const EditAddress = ({
  patient,
  setIsEditable,
}: EditInfoSectionsProps): JSX.Element => {
  const [updatePatientMutation] = usePatientUpdatePatientMutation();
  const { setToastMessage } = useToastMessage();
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [existingValue, setExistingValue] = useState<string | undefined>(
    patient?.address1 && patient?.addressLine2
      ? `${patient?.address1 as string} ${patient?.addressLine2 as string}`
      : undefined,
  );

  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const placesService = useRef<google.maps.places.PlacesService>();

  useEffect(() => {
    loader.load().then(() => {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new google.maps.places.AutocompleteService();
      }
    });
  }, [autocompleteService]);

  useEffect(() => {
    loader.load().then(() => {
      if (!placesService.current) {
        placesService.current = new google.maps.places.PlacesService(
          document.createElement('div'),
        );
      }
    });
  }, [placesService]);

  const { formState, getData, setData, getErrors, setErrors } = useForm({
    address1: {
      errorMessage: `Please provide a valid home address`,
      field: 'address1',
      hasError: false,
      required: true,
      value: patient?.address1 ?? '',
    },
    address2: {
      field: 'address2',
      value: patient?.address2 ?? '',
    },
    unit: {
      field: 'unit',
      value: patient?.unit ?? '',
    },
    city: {
      field: 'city',
      value: patient?.city ?? '',
    },
    state: {
      field: 'state',
      value: patient?.state ?? '',
    },
    zip: {
      field: 'zip',
      value: patient?.zip ?? '',
    },
    specialInstructions: {
      field: 'specialInstructions',
      value: patient?.specialInstructions ?? '',
    },
  });

  const handleInputChange = async (query?: string) => {
    if (!query) return;

    setLoading(true);
    setOptions([]);
    setExistingValue(undefined);

    if (autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        {
          input: query,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        },
        (results, status) => {
          if (
            results &&
            results.length > 0 &&
            status === google.maps.places.PlacesServiceStatus.OK
          ) {
            setOptions(
              results.length > 0
                ? results.map((item) => ({
                    label: item.description ?? '',
                    value: {
                      placeId: item.place_id ?? '',
                      description: item.description ?? '',
                    },
                  }))
                : [],
            );
          }
        },
      );
    }

    setLoading(false);
  };

  const handleClick = (value?: Record<string, unknown>) => {
    if (placesService.current) {
      placesService.current.getDetails(
        {
          placeId: value?.placeId as string,
          fields: ['address_components', 'geometry'],
        },
        (results, status) => {
          if (results && status === google.maps.places.PlacesServiceStatus.OK) {
            const address = normalizePlaceDetails(
              results,
              value?.description as string,
            );
            Object.entries(address).forEach(([fieldName, fieldValue]) => {
              setData(fieldName, fieldValue);
            });
          }
        },
      );
    }
  };

  const handleReset = () => {
    setData('address1', '');
    setData('address2', '');
    setData('city', '');
    setData('state', '');
    setData('zip', '');
    setExistingValue(undefined);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = getErrors();
    const formData = getData<PatientPatchRecordInput>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
      return;
    }
    if (!patient?.patientId) return;
    const { patientId } = patient;
    const patch = {
      address1: formData?.address1,
      address2: formData?.address2,
      unit: formData?.unit,
      city: formData?.city,
      state: formData?.state,
      zip: formData?.zip,
      specialInstructions: formData?.specialInstructions,
    };
    try {
      const updatedPatient = await updatePatientMutation({
        variables: {
          input: {
            patientId,
            patch,
          },
        },
      });
      if (updatedPatient) {
        setToastMessage(
          `Successfully updated the patient's information`,
          UI.Toast.SeverityLevel.Success,
        );
      }
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update the patient's information`,
        UI.Toast.SeverityLevel.Error,
      );
    } finally {
      if (setIsEditable) setIsEditable(false);
    }
  };

  return (
    <StyledEditSection>
      <div className="heading">
        <p>Address</p>
      </div>

      <form className="body" onSubmit={handleSubmit}>
        <p>All fields below, unless otherwise noted, are required.</p>

        <div className="fields">
          <div className="inputWrapper">
            <UI.TypeAhead
              testId="Patient__Profile__EditAddress"
              onReset={handleReset}
              initialValue={existingValue}
              loading={loading}
              label="Home Address"
              name="homeAddress"
              options={options}
              onInputChange={handleInputChange}
              onOptionClick={(value) =>
                handleClick(value as Record<string, unknown>)
              }
              placeholder="Enter street address, city, zipcode"
              threshold={2}
              errorText={
                formState.address1.hasError
                  ? formState.address1.errorMessage
                  : undefined
              }
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="unit"
              name="unit"
              label="Apt, Suite, Unit, Bldg #"
              fullWidth
              optional
              onChange={(event) => setData('unit', event.target.value)}
              value={(formState.unit.value as string) ?? ''}
            />
          </div>
          <UI.Input
            id="specialInstructions"
            name="specialInstructions"
            label="Special Instructions"
            fullWidth
            multiline
            optional
            onChange={(event) =>
              setData('specialInstructions', event.target.value)
            }
            value={(formState.specialInstructions.value as string) ?? ''}
          />
        </div>

        <div className="buttonContainer">
          <UI.Button
            variant="tertiary"
            className="closeButton"
            onClick={() => setIsEditable && setIsEditable(false)}
          >
            Close
          </UI.Button>
          <UI.Button variant="primary" className="saveButton" type="submit">
            Save
          </UI.Button>
        </div>
      </form>
    </StyledEditSection>
  );
};

export default EditAddress;
