export const move = <T>(
  rows: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = [...rows];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const remove = <T>(rows: T[], index: number): T[] =>
  rows.filter((row, idx) => idx !== index);
