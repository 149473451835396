import * as yup from 'yup';
import shortid from 'shortid';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  UpsertEncounterTaskInput,
} from 'generated/graphql';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type PatientProblemListType = {
  isDeleted?: boolean;
  key?: string;
  patientProblemId?: number;
  problemText?: string | null;
  startedAt?: Date | null;
};

export type TInputs = {
  patientProblemList?: PatientProblemListType[] | null;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  patientProblemList: yup.array().of(
    yup.object({
      isDeleted: yup.boolean(),
      key: yup.string(),
      patientProblemId: yup.number(),
      problemText: yup.string(),
      startedAt: yup.date(),
    }),
  ),
});

export const getDefaultValues = (task: TTask): TInputs => ({
  patientProblemList:
    (task?.patientProblemList ?? []).map((item) => {
      return {
        isDeleted: item?.isDeleted,
        key: shortid.generate(),
        patientProblemId: item?.patientProblemId,
        problemText: item?.problemText,
        startedAt: item?.startedAt ? new Date(item?.startedAt) : undefined,
      };
    }) ?? [],
});

export const getPatch = (
  formData: TInputs,
): Partial<UpsertEncounterTaskInput> => {
  const { patientProblemList } = formData;

  const patch: Partial<UpsertEncounterTaskInput> = {};

  patch.patientProblem = [];

  patientProblemList?.forEach((item) => {
    // Don't include if it's a new item and deleted
    if (!item.patientProblemId && item.isDeleted) return;
    // Don't include if it's an existing item and not deleted
    if (item.patientProblemId && !item.isDeleted) return;

    // Delete existing item
    const problem: PatientProblemListType = {};

    // Add new item
    if (item.patientProblemId) {
      problem.patientProblemId = item.patientProblemId;
      problem.isDeleted = true;
    } else {
      problem.problemText = item.problemText;
      problem.startedAt = item.startedAt;
    }

    patch?.patientProblem?.push(problem);
  });

  return patch;
};
