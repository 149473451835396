import { CheckSquare } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens, RoutePaths } from 'config';
import { Layout } from 'modules';
import UI from 'ui';
import EncounterTableRow from './components/EncounterTableRow';
import useMyTasks from './hooks/useMyTasks';

const StyledMyTasks = styled.div`
  background-color: ${Tokens.color.neutral.white.base};

  .header {
    background-color: ${Tokens.color.ui.steel.base};
    position: sticky;
    top: 0;
    z-index: ${Tokens.zIndex.header};

    h1 {
      border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.heading[1]};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin: 0;
      padding: calc(${Tokens.rhythm} * 2);
    }
  }

  .tableContainer {
    overflow-x: scroll;
    margin: 0 calc(${Tokens.rhythm} * 2);
  }

  .footerPadding {
    height: 80px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: 115px; /* 80 + 35px */
    } /* iPad */
  }
`;

const MyTasks = (): JSX.Element => {
  const {
    currentPage,
    encounters,
    handleEncounterClick,
    handleNextPage,
    handlePrevPage,
    handlePageChange,
    hasNextPage,
    hasPrevPage,
    loading,
    operatingAs,
    pageCount,
    pageSize,
    totalResults,
  } = useMyTasks();

  return (
    <Layout
      title="My Tasks"
      breadcrumbsProps={[
        {
          title: 'My Tasks',
          href: RoutePaths.tasks,
        },
      ]}
    >
      <StyledMyTasks>
        <div className="header">
          <h1>My Tasks</h1>
        </div>
        <div className="tableContainer">
          {loading ? (
            <UI.Spinner testId="spinner" centered />
          ) : (
            <>
              {encounters && (
                <>
                  {totalResults === 0 ? (
                    <UI.EmptyState
                      icon={CheckSquare}
                      iconWeight="regular"
                      title="No tasks found."
                    />
                  ) : (
                    <UI.Table fullWidth>
                      <UI.TableHead>
                        <UI.TableRow>
                          <UI.TableCell>Scheduled Date/Time</UI.TableCell>
                          <UI.TableCell>Service Line/Sub-type</UI.TableCell>
                          <UI.TableCell>Patient</UI.TableCell>
                          <UI.TableCell>Appointment Status</UI.TableCell>
                          <UI.TableCell>Chart Status</UI.TableCell>
                          <UI.TableCell>Tasks Remaining</UI.TableCell>
                        </UI.TableRow>
                      </UI.TableHead>
                      <UI.TableBody>
                        {encounters.map((encounter) => (
                          <EncounterTableRow
                            key={`encounterrow__${encounter.encounterId}`}
                            encounter={encounter}
                            onClick={() => handleEncounterClick(encounter)}
                            operatingAs={operatingAs}
                          />
                        ))}
                      </UI.TableBody>
                    </UI.Table>
                  )}
                </>
              )}
            </>
          )}
          {encounters && currentPage <= pageCount && (
            <>
              <div className="footerPadding" />
              <UI.TableFooter
                currentPage={currentPage}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                itemLabel="Encounters"
                itemCount={totalResults}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
                onPageChange={handlePageChange}
                pageCount={pageCount}
                pageSize={pageSize}
              />
            </>
          )}
        </div>
      </StyledMyTasks>
    </Layout>
  );
};

export default MyTasks;
