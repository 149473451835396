import { useEffect, useState, useRef } from 'react';
import { generatePath, Link, useParams, useHistory } from 'react-router-dom';
import { DotsThree, CaretDown, CaretUp, Handshake } from 'phosphor-react';

import { PatientRoutePaths } from '../../PatientRoutes';

import {
  ChannelAttributionInstitutionTypeEnum,
  EncounterResponderStatusEnum,
  ModuleNameEnum,
  GetEncountersByPatientIdQuery,
  useStartEncounterLazyQuery,
  EncounterClinicianStatusEnum,
  useClaimEncounterMutation,
  Addendum,
} from 'generated/graphql';

import { Logger } from 'utils';
import { RoutePaths, Tokens } from 'config';
import { useEncounter, useUserContext, useToastMessage } from 'hooks';
import UI from 'ui';
import EncounterTasks from '../EncounterTasks';
import { ToggleButton, StyledEncounter } from './styles';
import AddFormModal from './AddFormModal';
import ClinicianOrderModal from './ClinicianOrderModal';
import VitalsModal from './VitalsModal';
import EscalateEncounterModal from '../EscalateEncounterModal';
import { TModalState } from './types';
import AddAddendumModal from './AddAddendumModal';
import AddendumList from './AddendumList';
import UnclaimEncounterModal from 'modules/UnclaimEncounter';

type Props = {
  encounter: NonNullable<
    GetEncountersByPatientIdQuery['encountersList']
  >[number];
  isExpanded?: boolean;
};

// Per compliance - only allow the encounter to be printed once the Clinician has signed off on the encounter.
// If the encounter is "responder" only, the responder must have "completed" the encounter before printing.
export const canPrintEncounter = (encounter: Props['encounter']): boolean => {
  const responderIsCompleted =
    encounter.responderStatus === EncounterResponderStatusEnum.COMPLETED;
  const clinicianIsCompleted =
    encounter.clinicianStatus === EncounterClinicianStatusEnum.COMPLETED;

  if (
    encounter.clinicianIncluded ||
    encounter.modules?.includes(ModuleNameEnum.CLINICIAN) ||
    encounter.modules?.includes(ModuleNameEnum.CLINICIAN_ACTIVATION)
  ) {
    return responderIsCompleted && clinicianIsCompleted;
  }

  return responderIsCompleted;
};

const EncounterComponent = ({
  encounter,
  isExpanded = false,
}: Readonly<Props>): JSX.Element => {
  const actionsAnchorRef = useRef(null);
  const addendaList = encounter?.addendaList || [];

  const history = useHistory();
  const { id: globalPatientId } = useParams<{ id: string }>();

  const { setToastMessage } = useToastMessage();
  const [expanded, setExpanded] = useState(isExpanded);
  const [loading, setLoading] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [modalState, setModalState] = useState<TModalState>({
    selectedType: null,
    isOpen: false,
  });

  const [claimEncounter, { loading: claimLoading, error: claimError }] =
    useClaimEncounterMutation();
  const [startEncounter, { data }] = useStartEncounterLazyQuery({
    variables: { id: encounter.encounterId },
  });

  const {
    operatingAsClinician,
    operatingAsResponder,
    operatingAsSupervisor,
    user,
  } = useUserContext() ?? {};

  const {
    encounterIsInProgress,
    encounterCanBeClaimed,
    encounterCanBeUnclaimed,
    encounterCanBeStarted,
  } = useEncounter(encounter);

  const operatingAsHasClaimedEncounter =
    operatingAsClinician && user?.userId === encounter?.clinician?.userId;

  const encounterIsStarted =
    encounter?.responderStatus !== EncounterResponderStatusEnum.NOT_STARTED;

  const renderStatus = (orientation: 'portrait' | 'landscape') => {
    if (operatingAsResponder && encounter.responderStatus) {
      return (
        <div className={`status ${orientation}`}>
          <UI.EncounterStatusPill
            status={encounter?.humanizedResponderStatus ?? ''}
          />
        </div>
      );
    }

    if (operatingAsClinician && encounter.clinicianStatus) {
      return (
        <div className={`status ${orientation}`}>
          <UI.EncounterStatusPill
            status={encounter?.humanizedClinicianStatus ?? ''}
          />
        </div>
      );
    }

    return (
      <div className={`status ${orientation}`}>
        <UI.EncounterStatusPill
          status={`R: ${encounter?.humanizedResponderStatus ?? ''}`}
        />
        <UI.EncounterStatusPill
          status={`C: ${encounter?.humanizedClinicianStatus ?? ''}`}
        />
      </div>
    );
  };

  let tripleDotMenuOptions = [
    {
      type: 'clinicianOrder',
      title: 'Add clinician order',
    },
    {
      type: 'escalateEncounter',
      title: 'Escalate encounter',
    },
    {
      type: 'addForm',
      title: 'Add form',
    },
  ];

  if (!encounter.modules?.includes(ModuleNameEnum.VITALS_ADDITIONAL)) {
    tripleDotMenuOptions.splice(1, 0, {
      type: 'vitals',
      title: 'Add vitals set',
    }); // Hide this option if there is already an additional vitals set
  }

  if (encounterCanBeUnclaimed && operatingAsHasClaimedEncounter) {
    tripleDotMenuOptions.splice(1, 0, {
      type: 'unclaimEncounter',
      title: 'Unclaim encounter',
    });
  }

  // Add Addendum option
  let addendumsAvailable = false;
  if (
    ((operatingAsResponder || operatingAsSupervisor) &&
      encounter.responderStatus === EncounterResponderStatusEnum.COMPLETED) ||
    (operatingAsClinician &&
      encounter.clinicianStatus === EncounterClinicianStatusEnum.COMPLETED)
  ) {
    tripleDotMenuOptions = [
      {
        type: 'addAddendum',
        title: 'Add an addendum',
      },
    ];
    addendumsAvailable = true;
  }

  if (canPrintEncounter(encounter)) {
    if (addendumsAvailable) {
      tripleDotMenuOptions.splice(1, 0, {
        type: 'printEncounter',
        title: 'Print encounter',
      });
    } else {
      tripleDotMenuOptions = [
        {
          type: 'printEncounter',
          title: 'Print encounter',
        },
      ];
    }
  }

  const handleStartClick = () => {
    setLoading(true);
    startEncounter({ variables: { id: encounter.encounterId } });
  };

  const handleClaimClick = async () => {
    try {
      await claimEncounter({ variables: { id: encounter.encounterId } });
    } catch {
      return;
    }
  };

  const renderModal = (selectedType: string) => {
    switch (selectedType) {
      case 'addForm':
        return (
          <AddFormModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      case 'clinicianOrder':
        return (
          <ClinicianOrderModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      case 'unclaimEncounter':
        return (
          <UnclaimEncounterModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      case 'vitals':
        return (
          <VitalsModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      case 'escalateEncounter':
        return (
          <EscalateEncounterModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      case 'addAddendum':
        return (
          <AddAddendumModal
            encounterId={encounter.encounterId}
            modalState={modalState}
            setModalState={setModalState}
          />
        );
      default:
        return Logger.error('Oops, an error occurred in rendering a modal!');
    }
  };

  useEffect(() => {
    if (claimError) {
      setToastMessage(
        claimError?.message ??
          'An error occurred while claiming the encounter.',
        UI.Toast.SeverityLevel.Error,
      );
    }
  }, [claimError]);

  useEffect(() => {
    if (data) {
      setExpanded(true);
    }
  }, [data]);

  const handleExpandButtonClick = () => {
    const url = generatePath(PatientRoutePaths.encounters, {
      id: globalPatientId,
      encounterId: !expanded ? encounter?.encounterId : undefined,
    });

    history.replace(url);
    setExpanded((state) => !state);
  };

  return (
    <StyledEncounter>
      <UI.Container padding={`calc(${Tokens.rhythm} * 2) ${Tokens.rhythm}`}>
        <header>
          <div>
            <p>
              <span className="date">
                {encounter.appointment?.scheduledForDate} -{' '}
              </span>
              <span className="time">
                {encounter.appointment?.scheduledForTime}
              </span>
            </p>

            <p className="serviceLine">
              {encounter.serviceLine?.name} - {encounter.serviceLine?.subType}
            </p>
          </div>

          <div className="actions">
            {renderStatus('landscape')}

            {encounterIsInProgress || addendumsAvailable ? (
              <div ref={actionsAnchorRef}>
                <UI.Button
                  className="actionsButton"
                  variant="tertiary"
                  size="icon"
                  onClick={() => setActionsOpen((state) => !state)}
                >
                  <DotsThree size={34} weight="fill" />
                </UI.Button>
                <UI.Dropdown
                  anchorRef={actionsAnchorRef}
                  position="bottomLeft"
                  open={actionsOpen}
                  setOpen={setActionsOpen}
                >
                  {tripleDotMenuOptions.map((action) => {
                    if (action.type === 'printEncounter') {
                      return (
                        <Link
                          key={action.title}
                          to={{
                            pathname: generatePath(
                              `${RoutePaths.encounterPrint}`,
                              {
                                id: globalPatientId,
                                encounterId: encounter?.encounterId,
                              },
                            ),
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          target="EncounterPrint"
                          style={{ textDecoration: 'none' }}
                        >
                          <UI.ListItem
                            label={action.title}
                            onClick={() => {
                              setActionsOpen(false);
                            }}
                          />
                        </Link>
                      );
                    } else {
                      return (
                        <UI.ListItem
                          key={action.title}
                          label={action.title}
                          onClick={() => {
                            setModalState({
                              selectedType: action.type,
                              isOpen: true,
                            });

                            setActionsOpen(false);
                          }}
                        />
                      );
                    }
                  })}
                </UI.Dropdown>
              </div>
            ) : null}

            <ToggleButton
              className="expandButton"
              disabled={!encounterIsStarted}
              onClick={handleExpandButtonClick}
              size="smallIcon"
              variant="secondary"
            >
              {expanded ? (
                <CaretUp weight="fill" />
              ) : (
                <CaretDown weight="fill" />
              )}
            </ToggleButton>

            {modalState.selectedType && renderModal(modalState.selectedType)}
          </div>
        </header>

        {renderStatus('portrait')}

        <p className="reason">{encounter.reasonForEncounter}</p>
        {encounter.channelAttribution &&
        ![
          ChannelAttributionInstitutionTypeEnum.MARKETING,
          ChannelAttributionInstitutionTypeEnum.TRAINING,
          ChannelAttributionInstitutionTypeEnum.UNKNOWN,
        ].includes(encounter.channelAttribution?.institutionType) ? (
          <p className="channelAttribution">
            <Handshake weight="fill" id="handshakeIcon" />
            <span id="channelName">
              Partnership: {encounter.channelAttribution?.channel}
            </span>
          </p>
        ) : null}

        <div className="modules">
          {encounter.humanizedModuleEncounterTags
            ? encounter.humanizedModuleEncounterTags?.map(
                (module) =>
                  module && <UI.Tag key={module} label={module ?? undefined} />,
              )
            : null}
        </div>

        {!loading && encounterCanBeStarted ? (
          <UI.Button className="startButton" onClick={handleStartClick}>
            Start Encounter
          </UI.Button>
        ) : null}

        {!loading && encounterCanBeClaimed ? (
          <UI.Button
            className="claimButton"
            onClick={handleClaimClick}
            isLoading={claimLoading}
          >
            Claim Encounter
          </UI.Button>
        ) : null}

        {!encounterIsStarted && loading ? (
          <UI.Spinner testId="spinner" />
        ) : null}

        <AddendumList
          isVisible={expanded}
          addenda={addendaList as Array<Addendum>}
        />

        {encounterIsStarted && expanded ? (
          <EncounterTasks
            key={encounter.encounterId}
            disposition={encounter.disposition}
            encounter={encounter}
            moduleLength={encounter.modules?.length}
            serviceLine={encounter.serviceLine?.name}
            subType={encounter.serviceLine?.subType}
          />
        ) : null}
      </UI.Container>
    </StyledEncounter>
  );
};

export default EncounterComponent;
