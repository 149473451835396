import {
  Check,
  UploadSimple,
  DotsThree,
  Pencil,
  ArrowClockwise,
  Trash,
} from 'phosphor-react';
import { useRef, useState, FormEvent, ForwardedRef } from 'react';

import { Tokens } from 'config';
import { TFile } from 'hooks/useFileUpload/types';
import Button from 'ui/Button/Button';
import Dropdown from 'ui/Dropdown/Dropdown';
import HelpText from 'ui/HelpText/HelpText';
import Label from 'ui/Label/Label';
import ListItem from 'ui/ListItem/ListItem';
import ImageModal from './ImageModal';
import Tag from 'ui/Tag/Tag';
import StyledImageInput from './StyledImageInput';
import { TMultiInputValue } from '../types';

export const defaultCompressOptions = {
  maxSizeMB: 0.99,
  maxWidthOrHeight: 1100,
  useWebWorker: true,
  fileType: 'image/jpeg',
};

export type BaseInputProps = {
  className?: string;
  description: string;
  disabled?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  id: string;
  images: TFile[];
  imageCount?: number | undefined;
  imageType?: string;
  inputRef: ForwardedRef<HTMLInputElement>;
  isUploading?: boolean;
  label?: string;
  onClearFailed?: () => void;
  onDelete: () => void;
  onMultiUploadButtonClick?: () => void;
  onRetry?: () => void;
  onSingleInputChange?: (event: FormEvent<HTMLInputElement>) => Promise<void>;
  optional?: boolean;
  testId?: string;
  type: 'single' | 'multi';
  uploadFailed?: boolean;
  value: string | TMultiInputValue | null;
};

const BaseImageInput = ({
  className = undefined,
  description,
  disabled = false,
  errorText,
  fullWidth = false,
  id,
  images,
  imageCount,
  imageType,
  inputRef,
  isUploading = false,
  label,
  onClearFailed,
  onDelete,
  onMultiUploadButtonClick,
  onRetry,
  onSingleInputChange,
  optional = false,
  testId,
  type,
  uploadFailed = false,
  value,
}: BaseInputProps): JSX.Element => {
  const menuContainerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const getHasValue = () => {
    if (type === 'single') {
      return !!value;
    }
    const allDeleted = ((value as TMultiInputValue)?.images ?? []).reduce(
      (prev, image) => image.isDeleted && prev,
      true,
    );
    return !allDeleted;
  };

  const hasValue = getHasValue();

  const getCheckIconColor = (): string => {
    if (hasValue) {
      return Tokens.color.ui.success.base;
    }
    if (disabled) {
      return Tokens.color.ui.charcoal.transparent[50];
    }
    return Tokens.color.ui.charcoal.transparent[25];
  };

  const handleViewClick = () => {
    setModalOpen(true);
    setMenuOpen(false);
  };

  const handleDeleteClick = () => {
    onDelete();
    setMenuOpen(false);
  };

  const handleModalCloseClick = () => {
    setModalOpen(false);
  };

  return (
    <div className={className} data-testid={testId}>
      {label && (
        <Label htmlFor={id} style={{ marginBottom: Tokens.rhythm }}>
          {label}
        </Label>
      )}
      <StyledImageInput
        className="imageInput"
        hasValue={hasValue}
        fullWidth={fullWidth}
        hasError={!!errorText}
        disabled={disabled}
      >
        <Check size="16" className="checkIcon" color={getCheckIconColor()} />
        {hasValue ? (
          <>
            {type === 'single' ? (
              <Button
                variant="link"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleViewClick();
                }}
              >
                {description}
              </Button>
            ) : (
              <div
                className="container"
                style={{
                  display: 'flex',
                  flex: '1',
                  alignItems: 'center',
                  minWidth: 0,
                }}
              >
                <Button
                  variant="link"
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    handleViewClick();
                  }}
                  fullWidth={false}
                  style={{
                    alignSelf: 'center',
                    display: 'block',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    lineHeight: '32px',
                  }}
                >
                  {description}
                </Button>
                {imageCount && (
                  <span
                    style={{
                      fontSize: Tokens.font.size.paragraph.base,
                      marginRight: Tokens.rhythm,
                    }}
                  >
                    ({imageCount})
                  </span>
                )}
                <span className="spacer" />
                {imageType && (
                  <div style={{ marginRight: Tokens.rhythm }}>
                    <Tag label={imageType} />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <span className="description">
            {description} {optional && <i>(optional)</i>}
          </span>
        )}
        {(type === 'single' || (type === 'multi' && !hasValue)) && (
          <span className="spacer" />
        )}
        {hasValue && !uploadFailed ? (
          <>
            <div ref={menuContainerRef}>
              <Button
                variant="tertiary"
                size="icon"
                className="menuButton"
                onClick={() => setMenuOpen((state) => !state)}
              >
                <DotsThree />
              </Button>
              <Dropdown
                role="menu"
                open={menuOpen}
                setOpen={setMenuOpen}
                anchorRef={menuContainerRef}
                position="bottomLeft"
              >
                <ListItem
                  label="View"
                  role="menuitem"
                  onClick={handleViewClick}
                />
                {type === 'multi' && !disabled && (
                  <ListItem
                    label="Edit"
                    role="menuitem"
                    onClick={onMultiUploadButtonClick}
                  />
                )}
                {!disabled && (
                  <ListItem
                    color={Tokens.color.ui.error.base}
                    label="Delete"
                    role="menuitem"
                    onClick={handleDeleteClick}
                  />
                )}
              </Dropdown>
            </div>
            <ImageModal
              images={images}
              onClose={handleModalCloseClick}
              open={modalOpen}
              testId={`${testId}__imageModal`}
              title={description}
              type={type}
            />
          </>
        ) : (
          <>
            {type === 'single' && (
              <input
                id={id}
                name={id}
                type="file"
                accept="image/*"
                style={{
                  display: 'none',
                }}
                onChange={onSingleInputChange}
                disabled={disabled || isUploading}
                ref={inputRef}
              />
            )}
            {uploadFailed ? (
              <div className="failed">
                <span>Upload Failed</span>
                {type === 'single' ? (
                  <Button
                    variant="tertiary"
                    size="smallIcon"
                    className="retryButton"
                    onClick={onRetry}
                  >
                    <ArrowClockwise />
                  </Button>
                ) : (
                  <Button
                    variant="tertiary"
                    size="smallIcon"
                    className="retryButton"
                    onClick={onMultiUploadButtonClick}
                  >
                    <Pencil />
                  </Button>
                )}
                <Button
                  variant="tertiary"
                  size="smallIcon"
                  className="clearFailedButton"
                  onClick={onClearFailed}
                >
                  <Trash color={Tokens.color.ui.error.base} />
                </Button>
              </div>
            ) : (
              <Button
                as={type === 'single' ? 'label' : 'button'}
                htmlFor={type === 'single' ? id : undefined}
                variant="tertiary"
                size="smallIcon"
                className="uploadButton"
                isLoading={isUploading}
                disabled={disabled}
                onClick={
                  type === 'multi' ? onMultiUploadButtonClick : undefined
                }
              >
                <UploadSimple />
              </Button>
            )}
          </>
        )}
      </StyledImageInput>
      {errorText && <HelpText error>{errorText}</HelpText>}
    </div>
  );
};

export default BaseImageInput;
