import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
  EncounterPaymentModeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  InsuranceRecordFieldsFragment,
  useCreateInsuranceRecordMutation,
  useEncounterUpdatePaymentMethodMutation,
  useUpdateInsuranceRecordMutation,
} from 'generated/graphql';

import { useAutosave, usePersistTask, useTask, useToastMessage } from 'hooks';
import { useForm } from 'forms';
import { Logger } from 'utils';
import UI from 'ui';
import PaymentMethodForm from './PaymentMethodForm';
import FormSchema, {
  InsuranceSchema,
  FormInputs,
  InsuranceInputs,
} from './schema';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type Props = {
  task: TTask;
};

const getInsuranceValues = (
  record: InsuranceRecordFieldsFragment | undefined | null,
): InsuranceInputs => {
  const companyNotFound = !!record?.company && !record?.packageId;

  return {
    insuranceRecordId: record?.insuranceRecordId,
    planType: record?.planType ?? '',
    memberId: record?.memberId ?? '',
    groupNumber: record?.groupNumber ?? '',
    noInsuranceCards: record?.noInsuranceCards ?? false,
    attachmentFrontUrl: record?.attachmentFrontUrl ?? null,
    attachmentBackUrl: record?.attachmentBackUrl ?? null,
    company: {
      name: companyNotFound ? '' : record?.company ?? '',
      packageId: companyNotFound ? null : record?.packageId,
    },
    companyNotFound,
    unlistedCompany: companyNotFound ? record?.company ?? '' : undefined,
    relationshipToPolicyHolder: record?.relationshipToPolicyHolder || undefined,
    policyHolderFirstName: record?.policyHolderFirstName || undefined,
    policyHolderLastName: record?.policyHolderLastName || undefined,
    policyHolderGender: record?.policyHolderGender || undefined,
    policyHolderDob: record?.policyHolderDob || undefined,
  };
};

const getDefaultValues = (task: TTask) => {
  const {
    paymentMode,
    noInsuranceReason,
    primaryInsuranceRecord: primaryIns,
    secondaryInsuranceRecord: secondaryIns,
  } = task?.encounter ?? {};

  return {
    paymentMode: paymentMode || EncounterPaymentModeEnum.INSURANCE,
    noInsuranceReason: noInsuranceReason || undefined,
    insurances: [
      ...[getInsuranceValues(primaryIns)],
      ...(secondaryIns ? [getInsuranceValues(secondaryIns)] : []),
    ],
  };
};

const PaymentMethodFormContainer = ({ task }: Props): JSX.Element => {
  const [insuranceRequired, setInsuranceRequired] = useState(
    task?.encounter?.paymentMode === EncounterPaymentModeEnum.INSURANCE,
  );
  const [isPrimaryValid, setIsPrimaryValid] = useState(false);
  const { setToastMessage } = useToastMessage();
  const [updatePaymentMethod] = useEncounterUpdatePaymentMethodMutation();
  const [createInsuranceRecord] = useCreateInsuranceRecordMutation();
  const [updateInsuranceRecord] = useUpdateInsuranceRecordMutation();

  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<FormInputs>(FormSchema, {
    defaultValues: getDefaultValues(task),
    context: { insuranceRequired },
    reValidateMode: 'onChange',
  });

  const { remove: removeInsurances } = useFieldArray<FormInputs>({
    control,
    name: 'insurances',
  });

  const [paymentMode, primaryInsurance, secondaryInsurance] = watch([
    'paymentMode',
    'insurances.0',
    'insurances.1',
  ]);

  const {
    encounterIsComplete,
    taskIsComplete,
    taskIsSkipped,
    inputsAreDisabled,
  } = useTask(task);

  const deleteSecondaryInsurance = async (insuranceRecordId: number) => {
    if (!task?.encounter?.encounterId) {
      Logger.warn(
        `No encounter id exists for task id ${task?.encounterTaskId}`,
      );
      return;
    }

    await Promise.all([
      updateInsuranceRecord({
        variables: {
          insuranceRecordId,
          isDeleted: true,
        },
      }),
      updatePaymentMethod({
        variables: {
          encounterId: task?.encounter?.encounterId,
          secondaryInsuranceId: null,
        },
      }),
    ]);
  };

  const saveInsuranceAttachment = async (
    insuranceRecordId: number | undefined,
    source: 'primary' | 'secondary',
    imageUrl: string | null,
    side: 'front' | 'back',
  ): Promise<number | undefined> => {
    if (!task?.patientId) {
      Logger.warn(`No patient id exists for task id ${task?.encounterTaskId}`);
      return undefined;
    }

    if (!task?.encounter?.encounterId) {
      Logger.warn(
        `No encounter id exists for task id ${task?.encounterTaskId}`,
      );
      return undefined;
    }

    const saveRecord = async (): Promise<number | undefined> => {
      const input = {
        ...(side === 'front' && { attachmentFrontUrl: imageUrl }),
        ...(side === 'back' && { attachmentBackUrl: imageUrl }),
      };

      if (!insuranceRecordId) {
        const { data } = await createInsuranceRecord({
          variables: {
            patientId: task?.patientId,
            ...input,
          },
        });
        return data?.createInsuranceRecord?.insuranceRecord?.insuranceRecordId;
      }

      const { data } = await updateInsuranceRecord({
        variables: {
          insuranceRecordId,
          ...input,
        },
      });
      return data?.updateInsuranceRecord?.insuranceRecord?.insuranceRecordId;
    };

    const recordId = await saveRecord();

    await updatePaymentMethod({
      variables: {
        encounterId: task?.encounter?.encounterId,
        ...(source === 'primary' && { primaryInsuranceId: recordId }),
        ...(source === 'secondary' && {
          secondaryInsuranceId: recordId,
        }),
      },
    });

    return recordId;
  };

  const saveInsuranceRecord = async (
    formData: InsuranceInputs,
  ): Promise<number | undefined> => {
    if (!task?.patientId) {
      Logger.warn(`No patient id exists for task id ${task?.encounterTaskId}`);
      return undefined;
    }

    const {
      company: companyObj,
      companyNotFound,
      unlistedCompany,
      insuranceRecordId,
      ...rest
    } = formData;
    const { name: company, packageId } = companyObj || {};

    const input = {
      company: companyNotFound ? unlistedCompany : company ?? null,
      packageId: packageId ? `${packageId}` : null,
      ...rest,
    };

    if (!insuranceRecordId) {
      const { data } = await createInsuranceRecord({
        variables: {
          patientId: task?.patientId,
          ...input,
        },
      });
      return data?.createInsuranceRecord?.insuranceRecord?.insuranceRecordId;
    }

    const { data } = await updateInsuranceRecord({
      variables: {
        insuranceRecordId,
        ...input,
      },
    });
    return data?.updateInsuranceRecord?.insuranceRecord?.insuranceRecordId;
  };

  const saveEncounter = async (formData: FormInputs) => {
    if (!task?.encounter?.encounterId) {
      Logger.warn(
        `No encounter id exists for task id ${task?.encounterTaskId}`,
      );
      return;
    }

    // Save insurance records in parallel
    const saveInsurance = () => {
      const { insurances } = formData;

      return Promise.all([
        ...(insurances && insurances.length > 0
          ? [saveInsuranceRecord(insurances[0])]
          : []),
        ...(insurances && insurances.length > 1
          ? [saveInsuranceRecord(insurances[1])]
          : []),
      ]);
    };

    const [primaryInsuranceId, secondaryInsuranceId] = await saveInsurance();

    await updatePaymentMethod({
      variables: {
        encounterId: task?.encounter?.encounterId,
        paymentMode: formData.paymentMode,
        noInsuranceReason: formData.noInsuranceReason ?? null,
        primaryInsuranceId,
        secondaryInsuranceId: secondaryInsuranceId ?? null,
      },
    });
  };

  const { persistTask } = usePersistTask({
    cancelAutosave: true,
    isDirty,
    task,
    handleSave,
    handleSubmit,
  });

  const saveTask = async (formData: FormInputs): Promise<TTask> => {
    if (!task?.encounterTaskId) {
      Logger.warn(`No task id exists`);
      return undefined;
    }

    try {
      await saveEncounter(formData);
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(error);
        setToastMessage(
          `An error occurred while saving task '${task?.taskName}'`,
          UI.Toast.SeverityLevel.Error,
        );
      }
    }

    await persistTask({ data: {}, workflow: 'save' });
  };

  const skipTask = async (formData: FormInputs): Promise<TTask> => {
    if (!task?.encounterTaskId) {
      Logger.warn(`No task id exists`);
      return undefined;
    }

    try {
      await saveEncounter(formData);
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(error);
        setToastMessage(
          `An error occurred while saving task '${task?.taskName}'`,
          UI.Toast.SeverityLevel.Error,
        );
      }
    }

    await persistTask({ data: {}, workflow: 'skip' });
  };

  const completeTask = async (formData: FormInputs): Promise<TTask> => {
    if (!task?.encounterTaskId) {
      Logger.warn(`No task id exists`);
      return undefined;
    }

    try {
      await saveEncounter(formData);
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(error);
        setToastMessage(
          `An error occurred while saving task '${task?.taskName}'`,
          UI.Toast.SeverityLevel.Error,
        );
      }
    }

    await persistTask({ data: {}, workflow: 'complete' });
  };

  const updateInsuranceRecordIds = (savedTask: TTask) => {
    if (savedTask?.encounter) {
      const { primaryInsuranceRecord, secondaryInsuranceRecord } =
        savedTask?.encounter ?? {
          primaryInsuranceRecord: null,
          secondaryInsuranceRecord: null,
        };

      // Update Primary Insurance ID
      setValue(
        'insurances.0.insuranceRecordId',
        primaryInsuranceRecord?.insuranceRecordId,
      );

      // Update Secondary Insurance ID
      if (secondaryInsurance) {
        setValue(
          'insurances.1.insuranceRecordId',
          secondaryInsuranceRecord?.insuranceRecordId,
        );
      }
    }
  };

  const handleSaveTask = handleSave(async (data: FormInputs) => {
    const savedTask = await saveTask(data);
    updateInsuranceRecordIds(savedTask);
    reset(undefined, { keepValues: true });
  });

  const handleSkipTask = handleSave(async (data: FormInputs) => {
    const savedTask = await skipTask(data);
    updateInsuranceRecordIds(savedTask);
    reset(undefined, { keepValues: true });
  });

  const handleCompleteTask = handleSubmit(async (data: FormInputs) => {
    const savedTask = await completeTask(data);
    updateInsuranceRecordIds(savedTask);
    reset(undefined, { keepValues: true });
  });

  const handleDeleteSecondary = () => {
    const { insuranceRecordId } = secondaryInsurance;
    removeInsurances(1);
    if (insuranceRecordId) {
      deleteSecondaryInsurance(insuranceRecordId);
    }
  };

  const handleAddSecondary = () => {
    setValue('insurances.1', getInsuranceValues(undefined));
  };

  useEffect(() => {
    setInsuranceRequired(paymentMode === EncounterPaymentModeEnum.INSURANCE);
    if (
      !secondaryInsurance &&
      paymentMode === EncounterPaymentModeEnum.INSURANCE
    ) {
      setIsPrimaryValid(
        InsuranceSchema.isValidSync(primaryInsurance, {
          context: { insuranceRequired: true },
        }),
      );
    }
  }, [
    paymentMode,
    primaryInsurance,
    secondaryInsurance,
    taskIsComplete,
    taskIsSkipped,
  ]);

  /*
   * TODO: Use usePersistTask autosave once this is all consolidated
   * under the upsertEncounterTask mutation
   */
  const skipped =
    !isDirty || encounterIsComplete || taskIsComplete || taskIsSkipped;
  useAutosave({
    formState: watch(),
    handleHookFormSubmit: saveTask,
    skipped,
  });

  return (
    <PaymentMethodForm
      control={control}
      encounter={task?.encounter}
      paymentMode={paymentMode}
      isPrimaryValid={isPrimaryValid}
      inputsAreDisabled={inputsAreDisabled}
      secondaryInsurance={secondaryInsurance}
      setValue={setValue}
      handleAddSecondary={handleAddSecondary}
      handleDeleteSecondary={handleDeleteSecondary}
      onSaveImageAttachment={saveInsuranceAttachment}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default PaymentMethodFormContainer;
