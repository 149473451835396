import CryptoJS from 'crypto-js';

export const createLocalUrl = (file: File | Blob): string => {
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator?.createObjectURL ? urlCreator.createObjectURL(file) : '';
};

export const getMD5Hash = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result as string;
      const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(result));
      resolve(hash.toString(CryptoJS.enc.Base64));
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsBinaryString(file);
  });
