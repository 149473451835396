import { Encounter } from 'generated/graphql';

import { Grid } from '../StyledEncounterPrint';

const EncounterSummary = ({
  encounter,
}: {
  encounter: Partial<Encounter>;
}): JSX.Element | null => {
  if (!encounter || !encounter.appointment) return null;

  return (
    <>
      <Grid>
        <div>
          <strong>Reason for Encounter:</strong>
        </div>
        <div>{encounter.reasonForEncounter ?? '-'}</div>
      </Grid>
    </>
  );
};

export default EncounterSummary;
