import isToday from 'date-fns/isToday';

import {
  EncounterTask,
  EncounterTaskStatusEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  UserRoleEnum,
} from 'generated/graphql';

import useUserContext from './useUserContext';
import { Permissions } from 'utils';
import type { TStatusesAndPermissionsOptions } from 'utils/permissions';

export type TUseEncounter = {
  encounterCanBeClaimed?: boolean;
  encounterCanBeUnclaimed?: boolean;
  encounterCanBeStarted?: boolean;
  encounterIsComplete?: boolean;
  encounterIsInProgress?: boolean;
};

export type TEncounter = NonNullable<
  GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
>['encounter'] & { encounterTasksList?: Partial<EncounterTask>[] };

const useEncounter = (encounter?: TEncounter | null): TUseEncounter => {
  const { operatingAs } = useUserContext() ?? {};

  const baseOptions: Omit<TStatusesAndPermissionsOptions, 'action'> = {
    userRole: operatingAs ?? UserRoleEnum.RESPONDER,
    taskRoles: [],
    taskStatus: null,
    encounterClinicianStatus: encounter?.clinicianStatus,
    encounterResponderStatus: encounter?.responderStatus,
  };

  const clinicianReviewedTaskList =
    encounter?.encounterTasksList?.filter(
      (task) => task.status === EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
    ) ?? [];

  const isScheduledForToday = isToday(
    new Date(encounter?.appointment?.scheduledFor),
  );

  const encounterCanBeClaimed = Permissions.hasStatusOrPermission({
    ...baseOptions,
    action: Permissions.ActionAndStatusEnum.CLAIM_ENCOUNTER,
  });

  const encounterCanBeUnclaimed =
    clinicianReviewedTaskList.length === 0 &&
    Permissions.hasStatusOrPermission({
      ...baseOptions,
      action: Permissions.ActionAndStatusEnum.UNCLAIM_ENCOUNTER,
    });

  const encounterCanBeStarted =
    isScheduledForToday &&
    Permissions.hasStatusOrPermission({
      ...baseOptions,
      action: Permissions.ActionAndStatusEnum.START_ENCOUNTER,
    });

  const encounterIsComplete = Permissions.hasStatusOrPermission({
    ...baseOptions,
    action: Permissions.ActionAndStatusEnum.ENCOUNTER_COMPLETE,
  });

  const encounterIsInProgress = Permissions.hasStatusOrPermission({
    ...baseOptions,
    action: Permissions.ActionAndStatusEnum.ENCOUNTER_IN_PROGRESS,
  });

  return {
    encounterCanBeClaimed,
    encounterCanBeUnclaimed,
    encounterCanBeStarted,
    encounterIsComplete,
    encounterIsInProgress,
  };
};

export default useEncounter;
