import styled from 'styled-components';
import { Tokens } from 'config';

export const StyledTaskHeader = styled.header`
  align-items: center;
  background-color: ${Tokens.color.neutral.white.base};
  border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  display: flex;
  justify-content: space-between;
  padding: ${Tokens.rhythm} calc(${Tokens.rhythm} * 2);

  div {
    display: flex;
    align-items: center;
  }

  .notice {
    color: ${Tokens.color.neutral.grey.base};
    font-style: italic;
    white-space: nowrap;
    margin-right: ${Tokens.rhythm};

    @media (max-width: ${Tokens.dimensions.narrowTaskList}) {
      display: none;
    }
  }

  .close {
    cursor: pointer;
  }

  .correctionsHeaderTitle {
    font-size: ${Tokens.font.size.paragraph.small};
    font-weight: ${Tokens.font.weight.semiBold};
    line-height: ${Tokens.lineHeight.paragraph.small};
    margin-bottom: ${Tokens.rhythm};
  }
`;
