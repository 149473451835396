import {
  GetEncounterTaskByEncounterTaskIdQuery,
  ReviewOfSystemEvaluationPayload,
  ReviewOfSystemSystemPayload,
} from 'generated/graphql';

import { Grid } from '../StyledEncounterPrint';
import { humanize } from 'utils/enums/enums';

const ROSSummary = ({
  task,
}: {
  task?: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element | null => {
  const reviewOfSystems = task?.reviewOfSystems || [];
  const systems = reviewOfSystems[0] ? reviewOfSystems[0].systems : [];
  let hasEvaluations = true;

  // Don't print if no evaluations have been made or bad data
  if (!systems || systems?.length === 0) return null;
  systems.map((system) => {
    if (system && system.evaluations.length === 0) {
      hasEvaluations = false;
    }
  });
  if (!hasEvaluations) return null;

  const renderEvaluation = (evaluation: ReviewOfSystemEvaluationPayload) => {
    if (evaluation === null || evaluation.value === undefined) return null;
    return (
      <div key={evaluation.key}>
        {evaluation.key}: {evaluation.value ? 'Yes' : 'No'}
      </div>
    );
  };

  const renderSystem = (
    system: ReviewOfSystemSystemPayload,
  ): JSX.Element | null => {
    const emptyEvaluations: Array<ReviewOfSystemEvaluationPayload> = [];
    const evaluations = system.evaluations || emptyEvaluations;

    return (
      <span key={system.system}>
        <div>
          <strong>{humanize(system.system) ?? '-'}</strong>
        </div>
        <div>
          {evaluations
            ? evaluations.map((evaluation) =>
                renderEvaluation(evaluation as ReviewOfSystemEvaluationPayload),
              )
            : null}
        </div>
      </span>
    );
  };

  return (
    <>
      <h2>ROS</h2>
      <Grid>
        {systems &&
          systems.map((system, index) => (
            <div key={index}>
              {renderSystem(system as ReviewOfSystemSystemPayload)}
            </div>
          ))}
      </Grid>
    </>
  );
};

export default ROSSummary;
