import { Tokens } from 'config';
import { UserRoleEnum } from 'generated/graphql';
import Avatar from 'ui/Avatar';

export type EncounterTaskAvatarProps = {
  /**
   * The profile picture url of the user assigned to the task.
   */
  profilePictureUrl?: string;
  /**
   * The user role assigned to the encounter task
   */
  role?: UserRoleEnum | null;
  /**
   * The size of the avatar
   */
  size?: 'lg' | 'md' | 'sm' | 'xs';
  /**
   * The userId of the user assigned to the encounter task.
   */
  userId?: number | null;
};

const EncounterTaskAvatar = ({
  profilePictureUrl,
  role,
  size,
}: EncounterTaskAvatarProps): JSX.Element => {
  let backgroundColor: string;
  let content: string;

  switch (role) {
    case UserRoleEnum.CLINICIAN:
      backgroundColor = profilePictureUrl
        ? 'none'
        : Tokens.color.ui.roles.clinician;
      content = profilePictureUrl ? profilePictureUrl : 'C';
      break;
    case UserRoleEnum.RESPONDER:
      backgroundColor = profilePictureUrl
        ? 'none'
        : Tokens.color.ui.roles.responder;
      content = profilePictureUrl ? profilePictureUrl : 'R';
      break;
    default:
      backgroundColor = 'none';
      content = '';
  }

  return (
    <Avatar size={size} backgroundColor={backgroundColor}>
      {profilePictureUrl ? <img src={content} /> : content}
    </Avatar>
  );
};

export default EncounterTaskAvatar;
