import { useEffect, useState, ReactNode } from 'react';

import { StyledTaskDrawer } from './StyledTaskDrawer';

const TaskDrawerComponent = ({
  children,
  expanded = false,
}: Readonly<{
  children: ReactNode;
  expanded?: boolean | null;
}>): JSX.Element => {
  const [contentIsVisible, setContentIsVisible] = useState(false);

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setContentIsVisible(true);
      }, 300);
    } else {
      setContentIsVisible(false);
    }
  }, [expanded]);

  return (
    <StyledTaskDrawer expanded={expanded}>
      <div className={`content ${contentIsVisible ? `visible` : null}`}>
        <div className="children">{expanded ? children : null}</div>
      </div>
    </StyledTaskDrawer>
  );
};

export default TaskDrawerComponent;
