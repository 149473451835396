import { Flag, DotsThree } from 'phosphor-react';
import UI from 'ui';
import React, { useState, useRef } from 'react';
import { usePatientUpdatePatientNoteMutation } from 'generated/graphql';
import { useToastMessage } from 'hooks';
import { Logger } from 'utils';
import { PatientNoteProps } from '../../types';
import { StyledNotes } from './StyledPatientNotes';

const PatientNote = ({ patientNote }: PatientNoteProps): JSX.Element => {
  const actionsAnchorRef = useRef(null);
  const [updatePatientNoteMutation] = usePatientUpdatePatientNoteMutation();
  const { setToastMessage } = useToastMessage();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const renderNote = (note: string, patientNoteId: number) => {
    const notesWithLinebreaks = note.split('\n');

    return notesWithLinebreaks?.map((line: string, index) => (
      <React.Fragment key={`patientnote_${patientNoteId}_${index}`}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handleDelete = async () => {
    if (!patientNote) return;

    try {
      await updatePatientNoteMutation({
        variables: {
          input: {
            patch: {
              isDeleted: true,
            },
            patientNoteId: patientNote.patientNoteId,
          },
        },
      });

      setToastMessage(
        `Successfully deleted the patient's note`,
        UI.Toast.SeverityLevel.Success,
      );
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to delete the patient's note`,
        UI.Toast.SeverityLevel.Error,
      );
    }

    setModalOpen(false);
  };

  return (
    <>
      <StyledNotes
        isHighPriority={patientNote.isHighPriority}
        key={patientNote.patientNoteId}
      >
        <div className="subHeadingWrapper">
          <div>
            <p className="subHeadingBold">
              {patientNote.migrationSource
                ? 'Note transferred from RH 1.0'
                : `${patientNote.userByCreatedBy?.firstName} ${patientNote.userByCreatedBy?.lastName}`}
            </p>
            <p className="subHeadingDate">
              {patientNote.formattedCreatedAt ?? '-'}
            </p>
          </div>
          <div className="tripleDotMenu" ref={actionsAnchorRef}>
            <UI.Button
              data-testid="actionsButton"
              className="actionsButton"
              variant="tertiary"
              size="icon"
              onClick={() => setMenuOpen((state) => !state)}
            >
              <DotsThree size={34} weight="fill" />
            </UI.Button>
            <UI.Dropdown
              position="bottomLeft"
              anchorRef={actionsAnchorRef}
              open={menuOpen}
              setOpen={setMenuOpen}
            >
              <UI.ListItem
                label="Delete"
                onClick={() => {
                  setModalOpen(true);
                  setMenuOpen((state) => !state);
                }}
              />
            </UI.Dropdown>
          </div>
        </div>

        <div className="noteBody">
          <p>
            {patientNote.note.length > 0
              ? renderNote(patientNote.note, patientNote.patientNoteId)
              : '-'}
          </p>
        </div>

        {patientNote.isHighPriority && (
          <footer className="highPriority">
            <Flag color="red" weight="fill" data-testid="flagIcon" />{' '}
            <span>High Priority</span>
          </footer>
        )}
      </StyledNotes>

      <UI.Modal
        body="Deleting a sticky note from the patient’s profile is permanent. You will not be able to recover this note upon deletion."
        closeButtonLabel="Cancel"
        title="Delete this sticky note?"
        footerButtons={[
          {
            className: 'deleteButton',
            children: 'Delete',
            variant: 'primary',
            size: 'small',
            onClick: handleDelete,
          },
        ]}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        size="small"
      />
    </>
  );
};

export default PatientNote;
