import { useEffect } from 'react';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  SystemOfOriginEnum,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';

import {
  usePersistTask,
  useDidMountEffect,
  useTask,
  useToastMessage,
} from 'hooks';
import UI from 'ui';
import { useForm } from 'forms';
import { Logger } from 'utils';
import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import VaccineForm from './VaccineForm';
import {
  TInputs,
  Schema,
  getExistingDrugId,
  getDefaultValues,
  getPatchValues,
} from './utils';

const VaccineFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();

  const defaultValues = getDefaultValues(task);
  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    context: { task },
    defaultValues,
  });

  const formState = watch();
  const { inputsAreDisabled, taskIsComplete, taskIsSkipped } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatchValues(data, task),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  const vaccinationLetterAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.VACCINATION_LETTER,
  )?.attachmentId;
  const lotNumberAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.LOT_NUMBER,
  )?.attachmentId;

  const saveAttachment = async ({
    description,
    type,
    url,
  }: {
    description: string;
    type: EncounterTaskAttachmentTypeEnum;
    url: string;
  }) => {
    if (!type || !url) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              { images: [{ url }], description, type, isRequired: true },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
      setValue('lotNumberAttachment', null);
      setToastMessage(
        `An error occurred while uploading the attachment. Please try again.`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  const deleteAttachment = async ({
    id,
    name,
  }: {
    id?: number;
    name?: keyof TInputs;
  }) => {
    if (!id || !name) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              {
                images: [{ isDeleted: true, encounterTaskAttachmentId: id }],
                isRequired: true,
              },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  useEffect(() => {
    register('standingOrderAddedAt');
    register('standingOrderId');
  }, []);

  useEffect(() => {
    if (formState.drugId !== getExistingDrugId(task)) {
      setValue('standingOrderId', undefined, { shouldDirty: true });
      setValue('standingOrderAddedAt', undefined, { shouldDirty: true });
    }
  }, [formState.drugId]); // Don't dirty formState

  useDidMountEffect(() => {
    if (formState.lotNumberAttachment) {
      saveAttachment({
        description: 'Lot Number',
        type: EncounterTaskAttachmentTypeEnum.LOT_NUMBER,
        url: formState.lotNumberAttachment,
      });
    } else {
      deleteAttachment({
        id: lotNumberAttachmentId,
        name: 'lotNumberAttachment',
      });
    }
  }, [formState.lotNumberAttachment]);

  useDidMountEffect(() => {
    if (formState.vaccinationLetterAttachment) {
      saveAttachment({
        description: 'Vaccination Letter',
        type: EncounterTaskAttachmentTypeEnum.VACCINATION_LETTER,
        url: formState.vaccinationLetterAttachment,
      });
    } else {
      deleteAttachment({
        id: vaccinationLetterAttachmentId,
        name: 'vaccinationLetterAttachment',
      });
    }
  }, [formState.vaccinationLetterAttachment]);

  return (
    <VaccineForm
      control={control}
      errors={errors}
      formState={formState}
      inputsAreDisabled={inputsAreDisabled}
      setValue={setValue}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
      taskIsComplete={taskIsComplete}
      taskIsSkipped={taskIsSkipped}
    />
  );
};

export default VaccineFormContainer;
