import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useWatch, Control } from 'react-hook-form';

import { TInputs } from '../utils';

type Props = {
  control: Control<TInputs>;
  defaultAttachmentsValue: TInputs['attachments'];
  onSubmit: (attachments: TInputs['attachments']) => Promise<void>;
};

const useWatchForChanges = ({
  control,
  defaultAttachmentsValue,
  onSubmit,
}: Props): void => {
  const prevValuesRef = useRef<TInputs['attachments']>(defaultAttachmentsValue);

  const attachments = useWatch({
    control,
    name: 'attachments',
    defaultValue: defaultAttachmentsValue,
  });

  useEffect(() => {
    if (attachments) {
      const cleanedValues = attachments.filter((value) => !!value);

      if (!_.isEqual(cleanedValues, prevValuesRef.current)) {
        (async () => {
          await onSubmit(cleanedValues);
          prevValuesRef.current = cleanedValues;
        })();
      }
    }
  }, [attachments]);
};

export default useWatchForChanges;
