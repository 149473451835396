import { FormEvent, useEffect, useState } from 'react';
import { format as formatDate } from 'date-fns';

import {
  PatientPatchRecordInput,
  usePatientUpdatePatientMutation,
} from 'generated/graphql';

import { Tokens } from 'config';
import UI from 'ui';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import { EditInfoSectionsProps, PatientType } from '../../../types';
import * as Options from './enumsToOptions';
import { StyledEditSection } from '../StyledEditSection';

const EditNameAndDemographics = ({
  patient,
  setIsEditable,
}: EditInfoSectionsProps): JSX.Element => {
  const [updatePatientMutation] = usePatientUpdatePatientMutation();
  const { setToastMessage } = useToastMessage();
  const [invalidSsn, setInvalidSsn] = useState(false);

  // Return null or false if field is not true
  const fieldIsNotTrue = (fieldName: keyof PatientType) => {
    if (patient[fieldName] === null) {
      return null;
    }
    return false;
  };

  const { formState, getData, setData, setRequired, getErrors, setErrors } =
    useForm({
      prefix: {
        field: 'prefix',
        value: patient?.prefix ?? undefined,
      },
      preferredName: {
        field: 'preferredName',
        value: patient?.preferredName ?? '',
      },
      hasPreferredName: {
        field: 'hasPreferredName',
        value:
          patient?.preferredName || patient?.hasPreferredName
            ? true
            : fieldIsNotTrue('hasPreferredName'),
      },
      firstName: {
        errorMessage: `Please provide a valid legal first name`,
        field: 'firstName',
        hasError: false,
        required: true,
        value: patient?.firstName ?? '',
      },
      middleName: {
        field: 'middleName',
        value: patient?.middleName ?? undefined,
      },
      lastName: {
        errorMessage: `Please provide a valid legal last name`,
        field: 'lastName',
        hasError: false,
        required: true,
        value: patient?.lastName ?? '',
      },
      suffix: {
        field: 'suffix',
        value: patient?.suffix ?? undefined,
      },
      dob: {
        errorMessage: `Please select patient's date of birth`,
        field: 'dob',
        hasError: false,
        required: true,
        value: patient?.dob ? patient.dob : undefined,
      },
      sex: {
        errorMessage: `Please select patient's sex at birth`,
        field: 'sex',
        hasError: false,
        required: true,
        value:
          patient?.sex && patient?.sex !== 'OTHER' ? patient?.sex : undefined,
      },
      genderIdentity: {
        field: 'genderIdentity',
        value: patient?.genderIdentity ?? undefined,
      },
      hasDifferentGenderIdentity: {
        field: 'hasDifferentGenderIdentity',
        value: patient?.hasDifferentGenderIdentity
          ? patient?.hasDifferentGenderIdentity
          : fieldIsNotTrue('hasDifferentGenderIdentity'),
      },
      genderPronoun: {
        field: 'genderPronoun',
        value: patient?.genderPronoun ?? undefined,
      },
      races: {
        field: 'races',
        value: (patient?.races as string[]) ?? undefined,
      },
      ssn: {
        field: 'ssn',
        value: undefined,
      },
      cannotProvideSsn: {
        field: 'cannotProvideSsn',
        value: patient?.cannotProvideSsn
          ? patient?.cannotProvideSsn
          : fieldIsNotTrue('cannotProvideSsn'),
      },
      noSsnReason: {
        errorMessage: `Please select reason for not providing SSN`,
        field: 'noSsnReason',
        hasError: false,
        required: true,
        value: (patient?.noSsnReason as string[]) ?? undefined,
      },
    });

  // Convert false or null, selectedValue on RadioButtonGroup component to id string on RadioButton component
  const convertSelectedValue = (fieldName: keyof PatientType) => {
    if (formState[fieldName].value === null) {
      return undefined;
    }
    return `${fieldName}No`;
  };

  // Convert RadioButton component id string values to true or false for formData boolean fields
  const convertValueToBoolean = (
    fieldName: keyof PatientPatchRecordInput,
    value: string,
  ) => {
    if (value === `${[fieldName]}Yes`) return true;
    if (value === `${[fieldName]}No`) return false;
    return null;
  };

  // If cannotProvideSsn checkbox is checked, then set noSsnReason as required
  useEffect(() => {
    setRequired('noSsnReason', formState.cannotProvideSsn.value as boolean);
  }, [formState.cannotProvideSsn.value]);

  useEffect(() => {
    setInvalidSsn(false);
  }, [formState.ssn.value]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = getErrors();
    const formData = getData<PatientPatchRecordInput>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
      return;
    }

    if (
      formState.ssn.value &&
      formState.ssn.value !== patient?.ssnSerial &&
      (formState.ssn.value as string).length !== 9
    ) {
      setInvalidSsn(true);
      return;
    } // Check ssn length if it has been updated

    if (!patient?.patientId) return;

    const { patientId } = patient;
    const patch: PatientPatchRecordInput = {
      prefix: formData?.prefix,
      preferredName: formData?.hasPreferredName
        ? formData?.preferredName
        : null,
      hasPreferredName: formData?.hasPreferredName,
      firstName: formData?.firstName,
      middleName: formData?.middleName,
      lastName: formData?.lastName,
      suffix: formData?.suffix ? formData?.suffix : null,
      dob: formData?.dob
        ? formatDate(new Date(formData.dob), 'yyyy-MM-dd')
        : null,
      sex: formData?.sex,
      genderIdentity: formData?.hasDifferentGenderIdentity
        ? formData?.genderIdentity
        : null,
      hasDifferentGenderIdentity: formData?.hasDifferentGenderIdentity,
      genderPronoun: formData?.hasDifferentGenderIdentity
        ? formData?.genderPronoun
        : null,
      races: formData?.races,
      noSsnReason: formData?.cannotProvideSsn ? formData?.noSsnReason : null,
      cannotProvideSsn: formData?.cannotProvideSsn,
    };

    if (formData?.ssn !== patient?.ssnSerial) {
      patch.ssn = formData?.ssn;
    }

    try {
      const updatedPatient = await updatePatientMutation({
        variables: {
          input: {
            patientId,
            patch,
          },
        },
      });
      if (updatedPatient) {
        setToastMessage(
          `Successfully updated the patient's information`,
          UI.Toast.SeverityLevel.Success,
        );
      }
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update the patient's information`,
        UI.Toast.SeverityLevel.Error,
      );
    } finally {
      if (setIsEditable) setIsEditable(false);
    }
  };

  return (
    <StyledEditSection>
      <div className="heading">
        <p>Name &amp; Demographics</p>
      </div>

      <form
        className="body"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <p>All fields below, unless otherwise noted, are required.</p>

        <div className="fields">
          <div className="inputWrapper">
            <UI.Input
              id="prefix"
              name="prefix"
              label="Prefix"
              fullWidth
              onChange={(event) => setData('prefix', event.target.value)}
              optional
              value={(formState.prefix.value as string) ?? undefined}
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="firstName"
              name="firstName"
              label="Legal First Name"
              fullWidth
              errorText={
                formState.firstName.hasError
                  ? formState.firstName.errorMessage
                  : undefined
              }
              onChange={(event) => setData('firstName', event.target.value)}
              value={(formState.firstName.value as string) ?? undefined}
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="middleName"
              name="middleName"
              label="Legal Middle Name"
              fullWidth
              onChange={(event) => setData('middleName', event.target.value)}
              optional
              value={(formState.middleName.value as string) ?? undefined}
            />
          </div>
          <div className="inputWrapper">
            <UI.Input
              id="lastName"
              name="lastName"
              label="Legal Last Name"
              fullWidth
              errorText={
                formState.lastName.hasError
                  ? formState.lastName.errorMessage
                  : undefined
              }
              onChange={(event) => setData('lastName', event.target.value)}
              value={(formState.lastName.value as string) ?? undefined}
            />
          </div>
          <div className="inputWrapper">
            <UI.Select
              id="suffix"
              label="Suffix"
              name="suffix"
              onChange={(values) =>
                setData('suffix', values?.map((value) => value.value)[0])
              }
              optional
              options={Options.SuffixOptions}
              values={Options.SuffixOptions.filter(
                (option) => option.value === formState.suffix.value,
              )}
            />
          </div>
          <div className="inputWrapper">
            <UI.RadioButtonGroup
              id="has-preferred-name"
              label="Does the patient have a preferred first name different from their legal first name?"
              name="radio-button-group"
              onClickRadioButton={(value) => {
                if (value) {
                  setData(
                    'hasPreferredName',
                    convertValueToBoolean('hasPreferredName', value),
                  );
                }
              }}
              optional
              selectedValue={
                formState.hasPreferredName.value
                  ? 'hasPreferredNameYes'
                  : convertSelectedValue('hasPreferredName')
              }
              fullWidth
            >
              <UI.RadioButton
                checked={formState.hasPreferredName.value as boolean}
                id="hasPreferredNameYes"
                labelText="Yes"
                name="hasPreferredName"
              />
              <UI.RadioButton
                checked={formState.hasPreferredName.value === false}
                id="hasPreferredNameNo"
                labelText="No"
                name="hasPreferredName"
              />
            </UI.RadioButtonGroup>
          </div>

          {((patient?.preferredName &&
            formState.hasPreferredName.value !== false) ||
            formState.hasPreferredName.value) && (
            <div className="inputWrapper">
              <UI.Input
                id="preferredName"
                name="preferredName"
                label="Preferred First Name"
                fullWidth
                optional
                onChange={(event) =>
                  setData('preferredName', event.target.value)
                }
                value={(formState.preferredName.value as string) ?? undefined}
              />
            </div>
          )}
          <div className="inputWrapper">
            <UI.Datepicker
              id="dob"
              label="Date of Birth"
              name="dob"
              errorText={
                formState.dob.hasError ? formState.dob.errorMessage : undefined
              }
              filterFutureDateAndTime
              fullWidth
              format="MM/dd/yyyy"
              onChange={(dateString) => setData('dob', dateString)}
              placeholder="mm/dd/yyyy"
              value={
                formState.dob.value
                  ? new Date(formState.dob.value as string)
                  : undefined
              }
            />
          </div>
          <div className="inputWrapper">
            <UI.Select
              id="sex"
              label="Sex at Birth"
              name="sex"
              errorText={
                formState.sex.hasError ? formState.sex.errorMessage : undefined
              }
              onChange={(values) =>
                setData('sex', values?.map((value) => value.value)[0] as string)
              }
              options={Options.SexOptions}
              values={Options.SexOptions.filter(
                (option) => option.value === formState.sex.value,
              )}
            />
          </div>
          <div className="inputWrapper">
            <UI.RadioButtonGroup
              id="has-different-gender-identity"
              label="Does the patient’s gender identity differ from their sex at birth?"
              name="radio-button-group"
              onClickRadioButton={(value) => {
                if (value) {
                  setData(
                    'hasDifferentGenderIdentity',
                    convertValueToBoolean('hasDifferentGenderIdentity', value),
                  );
                }
              }}
              optional
              selectedValue={
                formState.hasDifferentGenderIdentity.value
                  ? 'hasDifferentGenderIdentityYes'
                  : convertSelectedValue('hasDifferentGenderIdentity')
              }
              fullWidth
            >
              <UI.RadioButton
                checked={formState.hasDifferentGenderIdentity.value as boolean}
                id="hasDifferentGenderIdentityYes"
                labelText="Yes"
                name="hasDifferentGenderIdentity"
              />
              <UI.RadioButton
                checked={formState.hasDifferentGenderIdentity.value === false}
                id="hasDifferentGenderIdentityNo"
                labelText="No"
                name="hasDifferentGenderIdentity"
              />
            </UI.RadioButtonGroup>
          </div>
          {formState.hasDifferentGenderIdentity.value && (
            <>
              <div className="inputWrapper">
                <UI.Select
                  id="genderIdentity"
                  label="Preferred Gender Identity"
                  name="genderIdentity"
                  optional
                  options={Options.GenderIdentityOptions}
                  onChange={(values) =>
                    setData(
                      'genderIdentity',
                      values?.map((value) => value.value)[0] as string,
                    )
                  }
                  values={Options.GenderIdentityOptions.filter(
                    (option) => option.value === formState.genderIdentity.value,
                  )}
                />
              </div>
              <div className="inputWrapper">
                <UI.Select
                  id="genderPronoun"
                  label="Preferred Gender Pronoun"
                  name="genderPronoun"
                  optional
                  options={Options.GenderPronounOptions}
                  onChange={(values) =>
                    setData(
                      'genderPronoun',
                      values?.map((value) => value.value)[0] as string,
                    )
                  }
                  values={Options.GenderPronounOptions.filter(
                    (option) => option.value === formState.genderPronoun.value,
                  )}
                />
              </div>
            </>
          )}
          <div className="inputWrapper">
            <UI.Select
              id="races"
              label="Race &amp; Ethnicity"
              name="races"
              optional
              options={Options.RaceOptions}
              multi
              onChange={(values) =>
                setData(
                  'races',
                  values?.map((value) => value.value) as string[],
                )
              }
              values={Options.RaceOptions.filter((option) =>
                (formState.races.value as string)?.includes(
                  option.value as string,
                ),
              )}
            />
          </div>
          <div className="inputWrapper">
            <UI.SSN
              id="ssn"
              name="ssn"
              label="SSN"
              fullWidth
              optional
              disabled={formState.cannotProvideSsn.value as boolean}
              errorText={invalidSsn ? 'Please provide a valid SSN' : undefined}
              onUpdate={(value) => setData('ssn', value)}
              ssnSerial={patient?.ssnSerial?.toString()}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: `calc(${Tokens.rhythm} * 2)`,
            }}
          >
            <UI.Checkbox
              checked={formState.cannotProvideSsn.value as boolean}
              id="ssnCheckbox"
              onChange={(value) => {
                setData('cannotProvideSsn', value);
              }}
            />
            <span style={{ marginLeft: Tokens.rhythm }}>
              Patient cannot provide SSN
            </span>
          </div>
          {formState.cannotProvideSsn.value && (
            <UI.Select
              id="noSsnReason"
              label="Reason for not providing SSN"
              name="noSsnReason"
              options={Options.noReasonSSNOptions}
              errorText={
                formState.noSsnReason.hasError
                  ? formState.noSsnReason.errorMessage
                  : undefined
              }
              onChange={(values) => {
                setData(
                  'noSsnReason',
                  values?.map((value) => value.value) as string[],
                );
              }}
              values={Options.noReasonSSNOptions.filter((option) =>
                (formState.noSsnReason.value as string)?.includes(
                  option.value as string,
                ),
              )}
            />
          )}
        </div>

        <div className="buttonContainer">
          <UI.Button
            variant="tertiary"
            className="closeButton"
            onClick={() => setIsEditable && setIsEditable(false)}
          >
            Close
          </UI.Button>
          <UI.Button variant="primary" className="saveButton" type="submit">
            Save
          </UI.Button>
        </div>
      </form>
    </StyledEditSection>
  );
};

export default EditNameAndDemographics;
