import { Addendum } from 'generated/graphql';
import { format as formatDate } from 'date-fns';

import styled from 'styled-components';
import { Tokens } from 'config';
import { ToggleButton } from './styles';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useState } from 'react';
import { AddendumListProps } from './types';

const StyledAddendumList = styled.div`
  background-color: ${Tokens.color.neutral.white};
  border: 1px solid;
  border-color: rgba(48, 53, 70, 0.25);
  border-radius: ${Tokens.border.radius};
  margin-bottom: calc(${Tokens.rhythm} * 2);
  .header {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: calc(${Tokens.rhythm} * 2);
    padding-top: calc(${Tokens.rhythm} * 1);
    padding-bottom: calc(${Tokens.rhythm} * 1);
  }
  .expandButton {
    margin-left: auto;
  }
  .addendum {
    border-top: 1px solid;
    border-color: rgba(48, 53, 70, 0.25);
    padding: calc(${Tokens.rhythm} * 2);
    color: ${Tokens.color.neutral.grey[64]};
  }
  .signature {
    padding-top: calc(${Tokens.rhythm} * 2);
    font-style: italic;
    color: ${Tokens.color.ui.slate.base};
  }
`;

const AddendumBlock = ({
  addendum,
}: {
  addendum: Addendum;
}): JSX.Element | null => {
  const prettyDate = formatDate(
    new Date(addendum.createdAt),
    'MMMM dd, yyyy @ h:mmaaa',
  );
  const byLine = `${addendum?.user?.fullName} added on ${prettyDate}`;

  return (
    <div key={addendum.addendumId} className="addendum">
      <span>{addendum.note}</span>
      <div className="signature">
        <span>{byLine}</span>
      </div>
    </div>
  );
};

const AddendumList = ({
  isVisible,
  addenda,
}: AddendumListProps): JSX.Element | null => {
  if (!isVisible || addenda.length === 0) {
    return null;
  }
  if (addenda.length === 0) {
    return null;
  }
  // resilience to test suites dummy objects
  if (addenda.length === 1 && addenda[0].addendumId === undefined) {
    return null;
  }

  const [expanded, setExpanded] = useState(false);
  const handleExpandButtonClick = () => {
    setExpanded((state) => !state);
  };

  return (
    <StyledAddendumList>
      <div className="header">
        <span className="heading">Addendums ({addenda.length})</span>
        <ToggleButton
          className="expandButton"
          disabled={false}
          onClick={handleExpandButtonClick}
          size="smallIcon"
          variant="secondary"
        >
          {expanded ? <CaretUp /> : <CaretDown />}
        </ToggleButton>
      </div>
      {expanded
        ? addenda.map((addendum: Addendum) => (
            <AddendumBlock addendum={addendum} />
          ))
        : null}
    </StyledAddendumList>
  );
};

export default AddendumList;
