import { memo, useEffect, useState } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import styled from 'styled-components';

import {
  useGetUsersQuery,
  useGetMarketsQuery,
  UsersOrderBy,
  GetUsersQuery,
  GetMarketsQuery,
} from 'generated/graphql';

import { Layout } from 'modules';
import UI from 'ui';
import { RoutePaths, Tokens } from 'config';
import RowContainer from './components';

const StyledTable = styled.table`
  color: ${Tokens.color.ui.charcoal.base};
  margin: calc(${Tokens.rhythm} * 5) auto;
  min-width: 100%;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;

  th,
  td {
    padding: ${Tokens.rhythm};
    text-align: left;

    &.centered {
      text-align: center;
    }
  }
`;

const StyledSearch = styled.div`
  display: flex;
  justify-content: flex-end;

  & input {
    width: calc(${Tokens.rhythm} * 40);
  }
`;

const Users = (): JSX.Element => {
  const [users, setUsers] = useState<GetUsersQuery['usersList']>([]);
  const [markets, setMarkets] = useState<GetMarketsQuery['marketsList']>([]);
  const [filteredUsers, setFilteredUsers] = useState<
    GetUsersQuery['usersList']
  >([]);
  const [searchText, setSearchText] = useState<string>('');

  const { data, error, loading } = useGetUsersQuery({
    variables: { orderBy: UsersOrderBy.EMAIL_ASC },
  });
  const { data: marketsData, error: marketsError } = useGetMarketsQuery();

  useEffect(() => {
    if (data?.usersList) {
      setUsers(data.usersList);
    }
  }, [data]);

  useEffect(() => {
    if (marketsData?.marketsList) {
      setMarkets(marketsData.marketsList);
    }
  }, [marketsData]);

  useEffect(() => {
    if (searchText === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users?.filter((user) =>
          searchText
            .trim()
            .toLowerCase()
            .split('')
            .every((letter) =>
              user.email!.split('@')[0].toLowerCase().includes(letter),
            ),
        ),
      );
    }
  }, [searchText, users]);

  if (error || marketsError) return <UI.ErrorFallback />;

  return (
    <Layout
      title="Users &amp; Roles"
      breadcrumbsProps={[{ title: 'Users & Roles', href: RoutePaths.users }]}
    >
      <UI.Container>
        <h2>Users</h2>
        <StyledSearch>
          <UI.Input
            disabled={loading}
            id="SearchUsers"
            rightIcon={<MagnifyingGlass />}
            onChange={(event) => setSearchText(event?.target.value)}
            placeholder="Email..."
            value={searchText}
          />
        </StyledSearch>

        {loading ? (
          <UI.Spinner testId="usersSpinner" centered />
        ) : (
          <StyledTable>
            <thead>
              <tr>
                <th></th>
                <th>Email</th>
                <th>Name</th>
                <th className="centered">Responder</th>
                <th className="centered">Clinician</th>
                <th className="centered">Supervisor</th>
              </tr>
            </thead>

            <tbody>
              {filteredUsers && filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <RowContainer
                    key={user.userId}
                    user={user}
                    markets={markets}
                  />
                ))
              ) : (
                <tr>
                  <td>No users found.</td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        )}
      </UI.Container>
    </Layout>
  );
};

Users.Row = RowContainer;

export default memo(Users);
