import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import ProgressNoteForm from './ProgressNoteForm';
import { TInputs, Schema, getDefaultValues, getPatch } from './utils';

const ProgressNoteFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const defaultValues = getDefaultValues(task);
  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatch(data, task),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  return (
    <ProgressNoteForm
      control={control}
      errors={errors}
      inputsAreDisabled={inputsAreDisabled}
      setValue={setValue}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default ProgressNoteFormContainer;
