import styled from 'styled-components';

import { Tokens } from 'config';

export const StyledEncounterPrint = styled.div`
  @media screen {
    height: 100vh;
    margin: calc(${Tokens.rhythm}*3);
  }
`;

export const Grid = styled.div`
  display: grid;
  column-gap: ${Tokens.rhythm};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: calc(${Tokens.rhythm}*3);
`;
