import styled from 'styled-components';

import { Tokens } from 'config';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
  children?: React.ReactNode;
  padding?: string;
}

const StyledContainer = styled.div<Props>`
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
`;

const Container = (props: Readonly<Props>): JSX.Element => {
  const {
    backgroundColor = Tokens.color.neutral.white.base,
    children,
    padding = `calc(${Tokens.rhythm} * 2)`,
    ...rest
  } = props;

  return (
    <StyledContainer
      backgroundColor={backgroundColor}
      padding={padding}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
