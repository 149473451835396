import React from 'react';
import styled from 'styled-components';
import { CalendarX } from 'phosphor-react';

import { ResponderAppointmentFilter } from 'generated/graphql';
import { Tokens } from 'config';
import UI from 'ui';
import ListItem from './ListItem';
import type { AppointmentsViewProps } from '../types';

const StyledAppointments = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    background-color: ${Tokens.color.ui.steel.base};
    max-width: 100vw;
    padding: 0 calc(${Tokens.rhythm} * 2);
    padding-top: calc(${Tokens.rhythm} * 2);
    z-index: ${Tokens.zIndex.header};

    h1 {
      color: ${Tokens.color.ui.charcoal.base};
      font-family: ${Tokens.font.family};
      font-size: ${Tokens.font.size.heading[1]};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin: 0;
      margin-bottom: calc(${Tokens.rhythm} * 2);
    }
  }

  .appointments {
    flex-grow: 1;
    max-width: 100vw;
    overflow-x: hidden;
    padding: calc(${Tokens.rhythm} * 2) calc(${Tokens.rhythm} * 2)
      calc(${Tokens.rhythm} * 16);
    --webkit-overflow-scrolling: touch;
  }
`;

const AppointmentsView = ({
  appointments,
  expandedItems,
  filter,
  emptyMessage,
  onTabClick,
  updateExpandedItems,
  testId,
}: AppointmentsViewProps): JSX.Element => {
  const selectedIndex = (() => {
    switch (filter) {
      case 'CANCELLED':
        return 3;
      case 'FUTURE':
        return 2;
      case 'PAST':
        return 1;
      case 'TODAY':
      default:
        return 0;
    }
  })();

  return (
    <StyledAppointments data-testid={testId}>
      <div className="header">
        <h1>My Appointments</h1>

        <UI.Tabs selectedIndex={selectedIndex}>
          <UI.Tab onClick={() => onTabClick(ResponderAppointmentFilter.TODAY)}>
            Today
          </UI.Tab>
          <UI.Tab onClick={() => onTabClick(ResponderAppointmentFilter.PAST)}>
            Past
          </UI.Tab>
          <UI.Tab onClick={() => onTabClick(ResponderAppointmentFilter.FUTURE)}>
            Future
          </UI.Tab>
          <UI.Tab
            onClick={() => onTabClick(ResponderAppointmentFilter.CANCELLED)}
          >
            Cancelled
          </UI.Tab>
        </UI.Tabs>
      </div>

      <div className="appointments">
        {appointments && appointments.length > 0 ? (
          appointments.map((appointment) => {
            const listItemTestId = `listitem__appointment__${appointment?.appointmentId}`;
            return (
              <ListItem
                key={listItemTestId}
                testId={listItemTestId}
                appointment={appointment}
                expanded={expandedItems[appointment?.appointmentId] ?? false}
                onToggleExpanded={(expanded) =>
                  updateExpandedItems(appointment?.appointmentId, expanded)
                }
              />
            );
          })
        ) : (
          <UI.EmptyState icon={CalendarX} title={emptyMessage} />
        )}
      </div>
    </StyledAppointments>
  );
};

export default AppointmentsView;
