import { useRef, useState, ReactNode, useEffect } from 'react';

import Dropdown from '../Dropdown/Dropdown';
import ListItem from '../ListItem/ListItem';

export declare interface SubMenuProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The sub menu items
   */
  children?: ReactNode;

  /**
   * The sub menu label
   */
  label: string;

  /**
   * If `true`, the parent is open
   */
  parentOpen: boolean;

  /**
   * Styles to apply to the sub menu
   */
  submenuStyle?: Record<string, string | number>;
}

const SubMenu = ({
  children,
  label,
  parentOpen,
  submenuStyle,
  ...props
}: SubMenuProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!parentOpen) {
      setOpen(false);
    }
  }, [parentOpen]);

  return (
    <div role="menuitem" ref={ref} {...props}>
      <ListItem
        label={label}
        onClick={() => {
          setOpen((state) => !state);
        }}
        rightCaret
      />
      <Dropdown
        anchorRef={ref}
        position="right"
        open={open}
        setOpen={setOpen}
        style={submenuStyle}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default SubMenu;
