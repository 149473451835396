import type { BaseSchema } from 'yup';
import isAbsent from 'yup/lib/util/isAbsent';
import * as yup from 'yup';

/**
 * Mark the schema as not required if `overrideRequired: true`
 * is included in the context object. Otherwise, mark the schema
 * as required.
 * @param this the schema chain
 * @param errorMessage an optional validation error message
 * @returns boolean
 */
function overrideRequired(this: BaseSchema, errorMessage?: string) {
  return this.test({
    name: 'test-override-required',
    // eslint-disable-next-line no-template-curly-in-string
    message: errorMessage ?? '${path} is a required field',
    test: (value, testContext) => {
      const {
        options: { context },
      } = testContext;
      return (
        Boolean(context?.overrideRequired) ||
        typeof value === 'boolean' ||
        Boolean(value)
      );
    },
  });
}

/**
 * Mark the schema as not requiring a min if `overrideRequired: true`
 * is included in the context object. Otherwise, mark the schema
 * as requiring a min value.
 * @param this the schema chain
 * @param errorMessage an optional validation error message
 * @returns boolean
 */
function overrideMin(this: BaseSchema, min: number, errorMessage?: string) {
  return this.test({
    name: 'test-override-min',
    // eslint-disable-next-line no-template-curly-in-string
    message: errorMessage ?? '${path} field must have at least ${min} items',
    params: { min },
    test: (value, testContext) => {
      const {
        options: { context },
      } = testContext;
      return (
        Boolean(context?.overrideRequired) ||
        isAbsent(value) ||
        value.length >= min
      );
    },
  });
}

yup.addMethod<BaseSchema>(yup.mixed, 'required', overrideRequired);
yup.addMethod<BaseSchema>(yup.array, 'min', overrideMin);

export default yup;
