import { Tokens } from 'config';
import { Controlled } from 'forms';
import { StyledTaskForm } from '../TaskForm';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader from '../TaskHeader';
import { usePhysicalExamForm } from './hooks';
import { TTask } from './utils';
import styled from 'styled-components';

type Props = {
  task: TTask;
};

const StyledPhysicalExamForm = styled.div`
  header {
    padding-bottom: 0;
  }

  section {
    border-top: none;

    textarea {
      height: calc(${Tokens.rhythm} * 30);
    }
  }
`;

const PhysicalExamForm = ({ task }: Props): JSX.Element => {
  const { control, inputsAreDisabled, taskHeaderProps } = usePhysicalExamForm({
    task,
  });

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <StyledPhysicalExamForm>
          <header>
            <h3>{task?.taskName}</h3>
            <p>All fields below, unless otherwise noted, are required.</p>
          </header>

          <section>
            <Controlled.Input
              control={control}
              disabled={inputsAreDisabled}
              defaultValue=""
              fullWidth
              label="Physical Exam"
              multiline
              name="note"
              optional
            />
          </section>
          {task?.encounterTaskId && (
            <AdditionalPhotoDocSection
              encounterTaskId={task?.encounterTaskId}
              disabled={inputsAreDisabled}
            />
          )}
        </StyledPhysicalExamForm>
      </StyledTaskForm>
    </>
  );
};

export default PhysicalExamForm;
