import { DotsThree } from 'phosphor-react';
import { useRef, useState } from 'react';
import { format as formatDate } from 'date-fns';
import { PatientProblem } from 'generated/graphql';
import UI from 'ui';
import styled from 'styled-components';

type RowProps = {
  deleteMedicalCondition: (generatedKey?: string) => void;
  generatedKey?: string;
  inputsAreDisabled: boolean;
  item: Partial<PatientProblem>;
};

const StyledTripleDotMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = ({
  deleteMedicalCondition,
  generatedKey,
  inputsAreDisabled,
  item,
}: RowProps): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  return (
    <>
      <tr>
        <td>{item.problemText}</td>
        <td>
          {item.startedAt ? formatDate(item.startedAt, 'M/dd/yyyy') : '-'}
        </td>
        <td>
          <StyledTripleDotMenu ref={menuAnchorRef}>
            <UI.Button
              disabled={inputsAreDisabled}
              variant="tertiary"
              size="icon"
              className="dotsThree"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <DotsThree size={34} weight="fill" />
            </UI.Button>
            <UI.Dropdown
              position="bottomLeft"
              anchorRef={menuAnchorRef}
              open={menuOpen}
              setOpen={setMenuOpen}
            >
              <UI.ListItem
                label="Delete"
                onClick={() => deleteMedicalCondition(generatedKey)}
              />
            </UI.Dropdown>
          </StyledTripleDotMenu>
        </td>
      </tr>
    </>
  );
};

export default Row;
