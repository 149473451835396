import { Tokens } from 'config';
import UI from 'ui';

/**
 * EncounterStatusPill component props
 */
export type EncounterStatusPillProps = {
  /**
   * The style class name.
   */
  className?: string;

  /**
   * The status label.
   */
  status: string;
};

const EncounterStatusPill = ({
  className,
  status,
}: EncounterStatusPillProps): JSX.Element => {
  const props = (() => {
    switch (true) {
      case status.includes('Cancelled'):
      case status.includes('Terminated'):
        return {
          backgroundColor: Tokens.color.ui.error.transparent[10],
          borderColor: Tokens.color.ui.error.base,
          textColor: Tokens.color.ui.error.base,
        };
      case status.includes('Completed'):
        return {
          backgroundColor: Tokens.color.ui.success.transparent[10],
          borderColor: Tokens.color.ui.success.base,
          textColor: Tokens.color.ui.success.base,
        };
      case status.includes('In Progress'):
        return {
          backgroundColor: Tokens.color.ui.warning.transparent[10],
          borderColor: Tokens.color.ui.warning.base,
          textColor: Tokens.color.ui.warning.base,
        };
      case status.includes('Rescheduled'):
        return {
          backgroundColor: Tokens.color.ui.charcoal.transparent[10],
          borderColor: Tokens.color.neutral.grey[102],
          textColor: Tokens.color.ui.charcoal.base,
        };
      case status.includes('Not Started'):
      default:
        return {
          backgroundColor: Tokens.color.neutral.grey[240],
          borderColor: Tokens.color.neutral.grey[219],
          textColor: Tokens.color.neutral.grey[102],
        };
    }
  })();

  return (
    <UI.Pill className={className} size="small" {...props}>
      {status}
    </UI.Pill>
  );
};

export default EncounterStatusPill;
