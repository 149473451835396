import { TypePolicy, FieldPolicy } from '@apollo/client';
import { format as formatDate } from 'date-fns';

import { Appointment } from 'generated/graphql';

import { Enums } from 'utils';

const AppointmentTypePolicy: TypePolicy = {
  keyFields: ['appointmentId'],
  fields: {
    humanizedStatus: {
      read(value, { readField }) {
        const status = readField<Appointment['status']>('status');
        if (status) {
          return Enums.humanize(status);
        }
        return null;
      },
    } as FieldPolicy,
    scheduledForDate: {
      read(value, { readField }) {
        const scheduledFor =
          readField<Appointment['scheduledFor']>('scheduledFor');

        return scheduledFor
          ? formatDate(new Date(scheduledFor), 'M/dd/yyyy')
          : null;
      },
    } as FieldPolicy,
    scheduledForTime: {
      read(value, { readField }) {
        const scheduledFor =
          readField<Appointment['scheduledFor']>('scheduledFor');

        return scheduledFor
          ? formatDate(new Date(scheduledFor), 'h:mmaaa')
          : null;
      },
    } as FieldPolicy,
  },
};

export default AppointmentTypePolicy;
