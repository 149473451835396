import * as yup from 'yup';

import { Controlled, useForm, MultiPhotoSchema } from 'forms';

const FormSchema = yup.object({
  multi: MultiPhotoSchema,
});

type TInputs = yup.InferType<typeof FormSchema>;

const UploadImageTest = (): JSX.Element => {
  const { control, watch } = useForm<TInputs>(FormSchema, {
    defaultValues: {
      multi: null,
    },
  });

  const data = watch('multi');

  return (
    <div style={{ margin: '50px', height: '100vh', width: '600px' }}>
      <h2>Multi Upload</h2>
      <Controlled.MultiImageInput
        name="multi"
        control={control}
        folder="multi-test"
        defaultDescription="Additional Attachment"
        typeOptions={[
          {
            label: 'Test 1',
            value: 'test1',
          },
          {
            label: 'Test 2',
            value: 'test2',
          },
          {
            label: 'Test 3',
            value: 'test3',
          },
        ]}
        fullWidth
      />
      <p>
        <b>Data: </b>
        {`${JSON.stringify(data)}`}
      </p>
    </div>
  );
};

export default UploadImageTest;
