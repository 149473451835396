import { DotsThree } from 'phosphor-react';
import { useRef, useState } from 'react';
import { Control, useWatch, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import { useDidMountEffect } from 'hooks';
import UI from 'ui';
import InsuranceTypeAhead from './InsuranceTypeAhead';
import {
  InsurancePolicyHolderGenderOptions,
  InsurancePlanTypeOptions,
  InsuranceRelationshipTypeOptions,
} from './options';
import { FormInputs } from './schema';

type Props = {
  control: Control<FormInputs>;
  disabled?: boolean;
  index: number;
  onDelete?: () => void;
  setValue: UseFormSetValue<FormInputs>;
  uploadFolder: string;
  onSaveImageAttachment: (
    insuranceRecordId: number | undefined,
    source: 'primary' | 'secondary',
    imageUrl: string | null,
    side: 'front' | 'back',
  ) => Promise<number | undefined>;
};

const StyledInsuranceSection = styled.div`
  border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};

  h2 {
    margin-bottom: calc(${Tokens.rhythm} * 2);
    font-size: ${Tokens.font.size.heading[2]};
    line-height: ${Tokens.lineHeight.heading[2]};
    font-weight: ${Tokens.font.weight.bold};
  }

  .attachmentFrontField {
    margin-bottom: ${Tokens.rhythm};
  }

  h2.primary {
    margin-top: calc(${Tokens.rhythm} * 4);
  }

  .secondary {
    align-items: center;
    margin-top: calc(${Tokens.rhythm} * 4);
    display: flex;
    justify-content: space-between;
  }
`;

const InsuranceSection = ({
  control,
  disabled,
  index,
  onDelete,
  setValue,
  uploadFolder,
  onSaveImageAttachment,
}: Props): JSX.Element => {
  const [
    insuranceRecordId,
    relationship,
    attachmentFrontUrl,
    attachmentBackUrl,
    noInsuranceCards,
  ] = useWatch({
    name: [
      `insurances.${index}.insuranceRecordId`,
      `insurances.${index}.relationshipToPolicyHolder`,
      `insurances.${index}.attachmentFrontUrl`,
      `insurances.${index}.attachmentBackUrl`,
      `insurances.${index}.noInsuranceCards`,
    ],
    control,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  useDidMountEffect(() => {
    const save = async () => {
      const recordId = await onSaveImageAttachment(
        insuranceRecordId,
        index === 0 ? 'primary' : 'secondary',
        attachmentFrontUrl ?? null,
        'front',
      );
      setValue(`insurances.${index}.insuranceRecordId`, recordId);
    };
    save();
  }, [attachmentFrontUrl]);

  useDidMountEffect(() => {
    const save = async () => {
      const recordId = await onSaveImageAttachment(
        insuranceRecordId,
        index === 0 ? 'primary' : 'secondary',
        attachmentBackUrl ?? null,
        'back',
      );
      setValue(`insurances.${index}.insuranceRecordId`, recordId);
    };
    save();
  }, [attachmentBackUrl]);

  return (
    <StyledInsuranceSection>
      {index === 0 ? (
        <h2>Primary Insurance</h2>
      ) : (
        <div className="secondary">
          <h2>Secondary Insurance</h2>
          <div ref={menuAnchorRef}>
            <UI.Button variant="tertiary" size="icon">
              <DotsThree size={24} onClick={() => setMenuOpen(!menuOpen)} />
            </UI.Button>
            <UI.Dropdown
              position="bottomLeft"
              anchorRef={menuAnchorRef}
              open={menuOpen}
              setOpen={setMenuOpen}
            >
              <UI.ListItem
                label="Delete"
                onClick={onDelete}
                color={Tokens.color.ui.error.base}
              />
            </UI.Dropdown>
          </div>
        </div>
      )}
      <div className="field">
        <Controlled.ImageInput
          name={`insurances.${index}.attachmentFrontUrl`}
          control={control}
          label="Photo Documentation"
          folder={uploadFolder}
          className="attachmentFrontField"
          description="Insurance Card (Front)"
          disabled={disabled || noInsuranceCards}
          fullWidth
        />
        <Controlled.ImageInput
          name={`insurances.${index}.attachmentBackUrl`}
          control={control}
          folder={uploadFolder}
          description="Insurance Card (Back)"
          disabled={disabled || noInsuranceCards}
          fullWidth
        />
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          marginTop: Tokens.rhythm,
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
        }}
      >
        <Controlled.Checkbox
          name={`insurances.${index}.noInsuranceCards`}
          control={control}
          defaultValue={false}
          label="Cannot find insurance cards"
          disabled={disabled || (!!attachmentFrontUrl && !!attachmentBackUrl)}
        />
      </div>
      <div className="field">
        <InsuranceTypeAhead
          control={control}
          disabled={disabled}
          setValue={setValue}
          index={index}
        />
      </div>
      <div className="field">
        <Controlled.Select
          name={`insurances.${index}.planType`}
          control={control}
          label="Plan Type"
          options={InsurancePlanTypeOptions}
          disabled={disabled}
        />
      </div>
      <div className="field">
        <Controlled.Input
          name={`insurances.${index}.memberId`}
          control={control}
          defaultValue=""
          label="Member ID"
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className="field">
        <Controlled.Input
          name={`insurances.${index}.groupNumber`}
          control={control}
          defaultValue=""
          label="Group Number"
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className="field">
        <Controlled.Select
          name={`insurances.${index}.relationshipToPolicyHolder`}
          control={control}
          label="Relationship to Policy Holder"
          options={InsuranceRelationshipTypeOptions}
          disabled={disabled}
        />
      </div>
      {relationship && relationship !== 'self' && (
        <>
          <div className="field">
            <Controlled.Input
              name={`insurances.${index}.policyHolderFirstName`}
              control={control}
              defaultValue=""
              label="Policy Holder's Legal First Name"
              fullWidth
              disabled={disabled}
            />
          </div>
          <div className="field">
            <Controlled.Input
              name={`insurances.${index}.policyHolderLastName`}
              control={control}
              defaultValue=""
              label="Policy Holder's Legal Last Name"
              fullWidth
              disabled={disabled}
            />
          </div>
          <div className="field">
            <Controlled.Select
              name={`insurances.${index}.policyHolderGender`}
              control={control}
              label="Policy Holder's Sex at Birth"
              options={InsurancePolicyHolderGenderOptions}
              disabled={disabled}
            />
          </div>
          <div className="field">
            <Controlled.Datepicker
              name={`insurances.${index}.policyHolderDob`}
              control={control}
              label="Policy Holder's Date of Birth"
              filterFutureDateAndTime
              format="MM/dd/yyyy"
              fullWidth
              timeZone="UTC"
              disabled={disabled}
            />
          </div>
        </>
      )}
    </StyledInsuranceSection>
  );
};

export default InsuranceSection;
