import styled from 'styled-components';
import { XCircle } from 'phosphor-react';

import { Tokens } from 'config';
import { PillProps } from './types';

const StyledPill = styled.span<PillProps>(
  ({ backgroundColor, borderColor, fixedWidth, size, textColor }) => {
    const sizeVars = (() => {
      switch (size) {
        case 'extraSmall':
          return `
            font-size: ${Tokens.font.size.paragraph.small};
            height: 16px;`;
        case 'small':
          return `
            font-size: ${Tokens.font.size.paragraph.small};
            height: 24px;`;
        case 'medium':
          return `
            font-size: ${Tokens.font.size.paragraph.base};
            height: 32px;`;
        default:
          return `${size} = medium`;
      }
    })();

    return `
      ${sizeVars};
      ${
        size !== 'extraSmall'
          ? `padding: 0 calc(${Tokens.rhythm} * 2);`
          : `padding: 0 ${Tokens.rhythm};`
      };
      ${fixedWidth ? `width: calc(${Tokens.rhythm} * 24);` : 'width: auto;'};

      align-items: center;
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};
      border-radius: 50px;
      color: ${textColor};
      display: inline-flex;
      font-family: ${Tokens.font.family};
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;

      .closeIcon {
        margin-left: calc(${Tokens.rhythm} / 2);
        cursor: pointer;

        :hover {
          opacity: 75%;
        }
      }
      `;
  },
);

/**
 * The base pill component.
 * @param props
 */
const Pill = ({
  backgroundColor = Tokens.pill.color.default,
  borderColor = Tokens.color.ui.charcoal.base,
  className,
  closeable = false,
  fixedWidth = false,
  onClose = undefined,
  size = 'medium',
  textColor = Tokens.color.ui.charcoal.base,
  children,
  ...rest
}: Readonly<PillProps>): JSX.Element => (
  <StyledPill
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    className={className}
    fixedWidth={fixedWidth}
    size={size}
    textColor={textColor}
    {...rest}
  >
    {children}
    {closeable && (
      <XCircle
        className="closeIcon"
        weight="fill"
        size={16}
        onClick={onClose}
      />
    )}
  </StyledPill>
);

export default Pill;
