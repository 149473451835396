import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  EncounterTaskStatusEnum,
  UserRoleEnum,
  EncounterClinicianStatusEnum,
} from 'generated/graphql';

import useUserContext from './useUserContext';
import useEncounter from './useEncounter';

export type TUseTask = {
  actionsAreDisabled: boolean;
  clinicianReviewedStatus?: string;
  encounterIsComplete: boolean;
  inputsAreDisabled: boolean;
  taskCanBeCorrected: boolean;
  taskIsComplete: boolean;
  taskIsSkipped: boolean;
  taskRequiresClinician: boolean;
  taskRequiresResponder: boolean;
};

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

const useTask = (task: TTask): TUseTask => {
  const {
    operatingAs,
    operatingAsClinician,
    operatingAsResponder,
    operatingAsSupervisor,
    user,
  } = useUserContext() ?? {};

  const { encounterIsComplete } = useEncounter(task?.encounter);

  const taskRequiresClinician = Boolean(
    task?.taskRoles.includes(UserRoleEnum.CLINICIAN),
  );
  const taskRequiresResponder = Boolean(
    task?.taskRoles.includes(UserRoleEnum.RESPONDER),
  );

  let taskIsComplete = Boolean(
    task?.status &&
      [
        EncounterTaskStatusEnum.RESPONDER_COMPLETED,
        EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
      ].includes(task.status),
  ); // Handle RESPONDER case

  if (taskRequiresClinician) {
    taskIsComplete = Boolean(
      operatingAsClinician
        ? task?.status &&
            [EncounterTaskStatusEnum.CLINICIAN_REVIEWED].includes(task.status)
        : task?.status &&
            [
              EncounterTaskStatusEnum.RESPONDER_COMPLETED,
              EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
            ].includes(task.status),
    ); // Handle CLINICIAN case
  }

  const taskIsSkipped = task?.status === EncounterTaskStatusEnum.SKIPPED;

  const isDeprecatedRH = isBefore(
    parseISO(task?.encounter?.appointment?.scheduledFor),
    parseISO('2021-12-17'), // RH 3.0 launch date
  );

  let actionsAreDisabled = false;

  if (isDeprecatedRH) {
    // Legacy Logic ASIS from RH V2
    actionsAreDisabled = Boolean(
      (operatingAsSupervisor &&
        !task?.taskRoles.includes(UserRoleEnum.RESPONDER)) ||
        (!operatingAsSupervisor && !task?.taskRoles.includes(operatingAs!)) ||
        (operatingAsClinician &&
          task?.encounter?.clinicianStatus ===
            EncounterClinicianStatusEnum.NO_CLINICIAN) ||
        (!operatingAsClinician &&
          task?.status === EncounterTaskStatusEnum.CLINICIAN_REVIEWED),
    );
  } else {
    // NEW Logic ASIS from RH V3
    actionsAreDisabled = Boolean(
      (operatingAsSupervisor &&
        !task?.taskRoles.includes(UserRoleEnum.RESPONDER)) ||
        (!operatingAsSupervisor && !task?.taskRoles.includes(operatingAs!)) ||
        (operatingAsClinician &&
          (!user?.userId ||
            user?.userId !== task?.encounter?.clinician?.userId)) ||
        (operatingAsClinician &&
          task?.encounter?.clinicianStatus &&
          ![
            EncounterClinicianStatusEnum.IN_PROGRESS,
            EncounterClinicianStatusEnum.COMPLETED,
          ].includes(task?.encounter?.clinicianStatus)) ||
        (!operatingAsClinician &&
          task?.status === EncounterTaskStatusEnum.CLINICIAN_REVIEWED),
    );
  }

  const inputsAreDisabled =
    taskIsComplete || taskIsSkipped || actionsAreDisabled;

  const clinicianReviewedStatus =
    taskRequiresResponder && taskRequiresClinician
      ? task?.status === EncounterTaskStatusEnum.CLINICIAN_REVIEWED
        ? 'Reviewed'
        : 'Needs Review'
      : undefined;

  const isPointOfCareTest = task?.dataType === 'point_of_care_test';

  const userCanCorrectTask =
    (!taskRequiresClinician &&
      (operatingAsResponder || operatingAsSupervisor)) ||
    (taskRequiresClinician && operatingAsClinician);

  const taskCanBeCorrected = Boolean(
    encounterIsComplete &&
      isPointOfCareTest &&
      !taskIsSkipped &&
      userCanCorrectTask,
  );

  return {
    actionsAreDisabled,
    clinicianReviewedStatus,
    encounterIsComplete: Boolean(encounterIsComplete),
    inputsAreDisabled,
    taskCanBeCorrected,
    taskIsComplete,
    taskIsSkipped,
    taskRequiresClinician,
    taskRequiresResponder,
  };
};

export default useTask;
