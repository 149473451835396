import { FieldPolicy } from '@apollo/client';
import {
  Appointment,
  AppointmentFieldsFragmentDoc,
  AppointmentDispatchStatusEnum,
  AppointmentStatusEnum,
  QueryreadResponderAppointmentsArgs,
} from 'generated/graphql';

import { differenceInCalendarDays, isToday } from 'date-fns';

type AppointmentDispatchRef = {
  appointment: { __ref: string };
  status: AppointmentDispatchStatusEnum;
};

const makeAppointmentRef = (appointment: Appointment | null) => ({
  __ref: `Appointment:{"appointmentId":${appointment?.appointmentId}}`,
});

const ResponderAppointments = {
  readResponderAppointments: {
    read(value, { readField, args, cache }) {
      const { responderId, filter } =
        args as QueryreadResponderAppointmentsArgs;

      const appointmentDispatchesList:
        | readonly AppointmentDispatchRef[]
        | undefined = readField({
        fieldName: 'appointmentDispatchesList',
        args: {
          condition: {
            responderId,
            statusIsNot: AppointmentDispatchStatusEnum.REMOVED,
          },
        },
      });

      const appointments = (appointmentDispatchesList ?? [])
        .filter(
          (appointmentDispatch) =>
            appointmentDispatch.status !==
            AppointmentDispatchStatusEnum.PENDING_DISPATCH,
        )
        .map((appointmentDispatch) => {
          const appointment = cache.readFragment<Appointment>({
            id: appointmentDispatch.appointment.__ref,
            fragment: AppointmentFieldsFragmentDoc,
          });

          return appointment;
        })
        .filter((appointment) => {
          const daysDelta = differenceInCalendarDays(
            new Date(),
            new Date(appointment?.scheduledFor),
          );

          return daysDelta <= 7;
        })
        .sort(
          (appointmentA, appointmentB) =>
            new Date(appointmentA?.scheduledFor).getTime() -
            new Date(appointmentB?.scheduledFor).getTime(),
        );

      if (filter) {
        if (filter === 'CANCELLED') {
          return appointments
            .filter(
              (appointment) =>
                appointment?.status === AppointmentStatusEnum.RESCHEDULED ||
                appointment?.status === AppointmentStatusEnum.CANCELLED,
            )
            .reverse() // sort in reverse chronological order:
            .map(makeAppointmentRef);
        }

        const notCancelled = appointments.filter(
          (appointment) =>
            appointment?.status !== AppointmentStatusEnum.RESCHEDULED &&
            appointment?.status !== AppointmentStatusEnum.CANCELLED,
        );

        if (filter === 'TODAY') {
          return notCancelled
            .filter((appointment) =>
              isToday(new Date(appointment?.scheduledFor)),
            )
            .map(makeAppointmentRef);
        }

        if (filter === 'FUTURE') {
          return notCancelled
            .filter(
              (appointment) =>
                differenceInCalendarDays(
                  new Date(appointment?.scheduledFor),
                  new Date(),
                ) > 0,
            )
            .map(makeAppointmentRef);
        }

        if (filter === 'PAST') {
          return notCancelled
            .filter(
              (appointment) =>
                differenceInCalendarDays(
                  new Date(appointment?.scheduledFor),
                  new Date(),
                ) < 0,
            )
            .reverse() // sort in reverse chronological order
            .map(makeAppointmentRef);
        }
      }

      return appointments;
    },
  } as FieldPolicy,
};

export default ResponderAppointments;
