import * as yup from 'yup';

const DiagnosisCodeReferenceSchema = yup.object({
  code: yup.string().required(),
  diagnosisCodeReferenceId: yup.number().required(),
  name: yup.string().required(),
});

const FormSchema = yup.object({
  note: yup.string().required('Please enter a valid MDM'),
  diagnosisCodeReferences: yup
    .array()
    .of(DiagnosisCodeReferenceSchema.required())
    .min(1, 'Please enter at least one diagnosis code')
    .required(),
});

export type DiagnosisCodeReferenceInput = yup.InferType<
  typeof DiagnosisCodeReferenceSchema
>;
export type FormInputs = yup.InferType<typeof FormSchema>;

export default FormSchema;
