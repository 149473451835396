import { InMemoryCache } from '@apollo/client';

import * as Policies from './typePolicies';

const cache = new InMemoryCache({
  typePolicies: {
    Appointment: { ...Policies.appointmentPolicies },
    Encounter: { ...Policies.encounterPolicies },
    EncounterTask: { ...Policies.encounterTaskPolicies },
    EncounterTaskAttachment: { ...Policies.encounterTaskAttachmentPolicies },
    Patient: { ...Policies.patientPolicies },
    PatientNote: { ...Policies.patientNotePolicies },
    PatientProblem: { ...Policies.patientProblemPolicies },
    StandingOrder: { ...Policies.standingOrderPolicies },
    Query: { ...Policies.queryPolicies },
    TelehealthMeeting: { ...Policies.telehealthMeetingPolicies },
    User: { ...Policies.userPolicies },
    Vital: { ...Policies.vitalPolicies },
  },
});

export default cache;
