import { generatePath, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { format as formatDate } from 'date-fns';

import { Encounter, UserFieldsFragment } from 'generated/graphql';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import { Tokens } from 'config';
import UI from 'ui';

export type EncounterTableRowProps = {
  /**
   * The Encounter.
   */
  encounter: Partial<Encounter>;

  /**
   * The Responder user.
   */
  responder: UserFieldsFragment | null;

  /**
   * The `data-testid`
   */
  testId?: string;

  /**
   * handles the onClick event
   */
  onClick: () => void;
};

const StyledEncounterTableRow = styled(UI.TableRow)`
  .dateTimeCell {
    .date {
      display: block;
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.paragraph.large};
    }

    .time {
      display: block;
      color: ${Tokens.color.neutral.grey[94]};
    }
  }

  .serviceLineCell {
    color: ${Tokens.color.neutral.grey[94]};
  }

  .respondersCell .moreTag {
    margin-left: ${Tokens.rhythm};
  }
`;

const EncounterTableRow = ({
  encounter,
  responder,
  testId = undefined,
  onClick,
}: EncounterTableRowProps): JSX.Element => {
  const location = useLocation();

  const date = encounter.appointment
    ? formatDate(new Date(encounter.appointment?.scheduledFor), 'M/dd/yyyy')
    : null;

  const time = encounter.appointment
    ? formatDate(new Date(encounter.appointment?.scheduledFor), 'h:mmaaa')
    : '';

  const moreRespondersCount = encounter.appointment
    ? encounter.appointment?.appointmentDispatchesList?.length - 1
    : 0;

  const getResponderName = (): string => {
    if (responder) {
      return `${responder.firstName} ${responder.lastName}`;
    }

    if (encounter.appointment?.appointmentDispatchesList?.length) {
      const user =
        encounter.appointment?.appointmentDispatchesList![0].responder?.user;
      return `${user?.firstName} ${user?.lastName}`;
    }

    return '';
  };

  const getClinicianName = (): string =>
    `${encounter?.clinician?.fullName ?? ''}`;

  return (
    <StyledEncounterTableRow data-testid={testId} onClick={onClick}>
      <UI.TableCell className="dateTimeCell">
        <span className="date">{date}</span>
        <span className="time">{time}</span>
      </UI.TableCell>
      <UI.TableCell className="serviceLineCell">
        {encounter.serviceLine?.name} - {encounter.serviceLine?.subType}
      </UI.TableCell>
      <UI.TableCell>
        <Link
          to={{
            pathname: generatePath(PatientRoutePaths.profile, {
              id: `${encounter.patient?.globalPatientId}`,
            }),
            state: {
              referrer: {
                pageTitle: 'Encounters',
                pathname: location.pathname,
                search: location.search,
              },
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {encounter.patient?.preferredName
            ? `${encounter.patient?.firstName} "${encounter.patient?.preferredName}" ${encounter.patient?.lastName}`
            : encounter.patient?.fullName}
        </Link>
      </UI.TableCell>
      <UI.TableCell className="respondersCell">
        {getResponderName()}
        {moreRespondersCount > 0 && (
          <UI.Tag className="moreTag" label={`+${moreRespondersCount}`} />
        )}
      </UI.TableCell>
      <UI.TableCell className="cliniciansCell">
        {getClinicianName()}
      </UI.TableCell>
      <UI.TableCell>{encounter.appointment?.market?.name}</UI.TableCell>
      <UI.TableCell>{encounter.appointment?.humanizedStatus}</UI.TableCell>
      <UI.TableCell>
        <UI.EncounterStatusPill
          status={encounter.humanizedResponderStatus ?? 'N/A'}
        />
      </UI.TableCell>
      <UI.TableCell>
        <UI.EncounterStatusPill
          status={encounter.humanizedClinicianStatus ?? 'N/A'}
        />
      </UI.TableCell>
    </StyledEncounterTableRow>
  );
};

export default EncounterTableRow;
