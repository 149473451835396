import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';

import { Tokens } from 'config';
import { useOutsideClickHandler } from 'hooks';

export type DropdownItem = {
  label: string;
  onClick: () => void;
  ref?: RefObject<HTMLDivElement>;
};

export declare interface DropdownProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * If `true`, the menu is open
   */
  open?: boolean;

  /**
   * A `useState` function for setting `true` prop
   */
  setOpen: (open: boolean) => void;

  /**
   * If `true`, indicates the `onClick` function opens
   * a new menu.
   */
  hasSecondaryAction?: boolean;

  /**
   * An element ref to serve as the anchor position.
   */
  anchorRef?: RefObject<HTMLDivElement>;

  /**
   * Renders the dropdown to either the left, right or bottom of the anchor element.
   */
  position?: 'bottomRight' | 'bottomLeft' | 'left' | 'right';
}

export const StyledDropdown = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  border-radius: 2px;
  box-shadow: ${Tokens.boxShadow.dark};
  min-width: 150px;
  padding: ${Tokens.rhythm} 0;
  width: fit-content;
  z-index: 1;
`;

const Dropdown = ({
  open = false,
  setOpen,
  anchorRef,
  position = 'left',
  children,
  style = {},
  ...props
}: DropdownProps): JSX.Element | null => {
  if (anchorRef) {
    useOutsideClickHandler(anchorRef, () => {
      setOpen(false);
    });
  }

  const getAnchorPositionStyles = (): CSSProperties | undefined => {
    if (anchorRef) {
      const anchorHeight = anchorRef.current?.offsetHeight;
      const anchorWidth = anchorRef.current?.offsetWidth;

      if (anchorRef.current) {
        anchorRef.current.style.position = 'relative'; // eslint-disable-line no-param-reassign
      }

      switch (position) {
        case 'bottomRight':
          return {
            position: 'absolute',
            top: `${anchorHeight}px`,
            left: `0px`,
          };
        case 'bottomLeft':
          return {
            position: 'absolute',
            top: `${anchorHeight}px`,
            // 30px accounts for the width of the tripleDot icon
            right: `calc(${anchorWidth}px - 30px)`,
          };
        case 'left':
          return {
            position: 'absolute',
            top: `0px`,
            right: `${anchorWidth}px`,
          };
        case 'right':
          return {
            position: 'absolute',
            top: `0px`,
            left: `${anchorWidth}px`,
          };
        default:
          return undefined;
      }
    }
    return undefined;
  };

  return open ? (
    <StyledDropdown
      role="menu"
      {...props}
      style={{ ...style, ...getAnchorPositionStyles() }}
    >
      {children}
    </StyledDropdown>
  ) : null;
};

export default Dropdown;
