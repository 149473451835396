import { useEffect, useState } from 'react';

import {
  ModuleNameEnum,
  useCreateAddOnTaskLazyQuery,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';

import { Enums, Logger } from 'utils';
import { Tokens } from 'config';
import { useToastMessage } from 'hooks';
import UI from 'ui';
import * as Options from '../Encounters/enumsToOptions';
import { TModalState, TSetModalState } from '../Encounters/types';

const EncounterModal = ({
  encounter,
  setModalState,
  modalState,
}: {
  encounter?: NonNullable<
    GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
  >['encounter'];
  setModalState: TSetModalState;
  modalState: TModalState;
}): JSX.Element => {
  const [createAddOnTask, { data, error }] = useCreateAddOnTaskLazyQuery();
  const { setToastMessage } = useToastMessage();

  const [moduleName, setModuleName] = useState(ModuleNameEnum.DEFAULT);

  const escalateEncounterModalContent = (
    <>
      <p
        style={{
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
          marginTop: '0px',
        }}
      >
        The{' '}
        <strong>
          {encounter?.serviceLine?.name} - {encounter?.serviceLine?.subType}
        </strong>{' '}
        encounter will be escalated.
      </p>
      <UI.Select
        id="module"
        label={'Select escalation type'}
        name="module"
        options={Options.EscalationModuleOptions}
        onChange={(selectedOption) => {
          setModuleName(
            (selectedOption && selectedOption[0].value) as ModuleNameEnum,
          );
        }}
      />
    </>
  );

  useEffect(() => {
    if (data) {
      if (moduleName === ModuleNameEnum.CLINICIAN_ACTIVATION) {
        setToastMessage(
          `Successfully escalated encounter: ${Enums.humanize(moduleName)}`,
          UI.Toast.SeverityLevel.Success,
        );
      } else if (moduleName === ModuleNameEnum.EMS_ACTIVATION) {
        setToastMessage(
          `Successfully escalated encounter: EMS Activation`,
          UI.Toast.SeverityLevel.Success,
        );
      }
      setModalState({
        selectedType: null,
        isOpen: false,
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Logger.error(error);
      setToastMessage(`Failed to add task`, UI.Toast.SeverityLevel.Error);
    }
  }, [error]);

  return (
    <UI.Modal
      body={escalateEncounterModalContent}
      closeButtonLabel="Cancel"
      onClose={() =>
        setModalState({
          selectedType: null,
          isOpen: false,
        })
      }
      title={'Escalate encounter'}
      size="small"
      open={modalState.isOpen}
      footerButtons={[
        {
          name: 'addEscalateEncounterButton',
          children: 'Add Module',
          variant: 'primary',
          size: 'small',
          onClick: () => {
            if (moduleName !== ModuleNameEnum.DEFAULT) {
              createAddOnTask({
                variables: {
                  id: encounter?.encounterId as number,
                  moduleName,
                },
              });
            }
          },
        },
      ]}
    />
  );
};

export default EncounterModal;
