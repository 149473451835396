import { SmileyNervous } from 'phosphor-react';

import { Layout } from 'modules';
import UI from 'ui';

const NoRole = (): JSX.Element => (
  <Layout title="Request an Account Role">
    <div style={{ minHeight: 'calc(100vh - 64px)' }}>
      <UI.EmptyState
        icon={SmileyNervous}
        title="Request an Account Role"
        subtitle="Your user account does not have any roles configured. Please email support@getready.com to request a role."
        width="318px"
      />
    </div>
  </Layout>
);

export default NoRole;
