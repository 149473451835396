import { useRef, useState, Ref, useEffect } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { useForm } from 'forms';
import {
  useSearchAllergyCodesLazyQuery,
  useSearchReactionCodesLazyQuery,
  SearchAllergyCodesTypeGroup,
} from 'generated/graphql';
import { useTask, usePersistTask } from 'hooks';
import { Option } from 'ui/TypeAhead/TypeAhead';
import {
  FormSchema,
  getDefaultValues,
  getPatchValues,
  TInputs,
  TTask,
  AllergyInputs,
} from '../utils';
import { TaskHeaderProps } from '../../TaskHeader';

type Props = {
  task: TTask;
};

type ReturnValues = {
  addButtonDisabled: boolean;
  allergyOptions: Option[];
  allergyOptionsLoading: boolean;
  allergyTypeAheadRef: Ref<{ reset: () => void }>;
  allergiesFields: TInputs['allergies'];
  clearAllergyInput: () => void;
  clearReactionInput: () => void;
  control: Control<TInputs>;
  handleAddClick: () => void;
  handleAllergyInputChange: (searchTerm: string | undefined) => void;
  handleDeleteAllergy: (index: number) => void;
  handleReactionInputChange: (searchTerm: string | undefined) => void;
  inputsAreDisabled: boolean;
  reactionOptions: Option[];
  reactionOptionsLoading: boolean;
  reactionTypeAheadRef: Ref<{ reset: () => void }>;
  setAllergyInput: (input: AllergyInputs['allergy']) => void;
  setReactionInput: (input: AllergyInputs['allergicReaction']) => void;
  taskHeaderProps: TaskHeaderProps;
};

const useAllergiesForm = ({ task }: Props): ReturnValues => {
  const defaultValues = getDefaultValues(task);

  const [formValues, setFormValues] = useState<AllergyInputs>({
    allergy: null,
    allergicReaction: null,
  });

  const allergyTypeAheadRef = useRef<{ reset: () => void }>(null);
  const reactionTypeAheadRef = useRef<{ reset: () => void }>(null);

  const [allergyOptions, setAllergyOptions] = useState<Option[]>([]);
  const [reactionOptions, setReactionOptions] = useState<Option[]>([]);

  const [
    searchAllergyCodes,
    { data: allergyOptionsData, loading: allergyOptionsLoading },
  ] = useSearchAllergyCodesLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    searchReactionCodes,
    { data: reactionOptionsData, loading: reactionOptionsLoading },
  ] = useSearchReactionCodesLazyQuery({ fetchPolicy: 'no-cache' });

  const {
    control,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
    getValues,
    setValue,
    handleSave,
    handleSubmit,
    reset,
    watch,
  } = useForm<TInputs>(FormSchema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { inputsAreDisabled } = useTask(task);
  const formState = watch();

  const { append: appendAllergy, remove: removeAllergy } = useFieldArray({
    control,
    name: 'allergies',
    keyName: 'patientAllergyId',
  });

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => {
        const deletedAllergies = getValues('deletedAllergies');
        return getPatchValues(data, deletedAllergies);
      },
      onPersistTask: (updatedTask) => {
        setValue('deletedAllergies', []);
        reset(getDefaultValues(updatedTask));
      },
    },
  );

  const handleAllergyInputChange = (searchTerm: string | undefined) => {
    if (!searchTerm) return;
    setAllergyOptions([]);
    searchAllergyCodes({
      variables: {
        searchTerm,
        allergyTypeGroup: SearchAllergyCodesTypeGroup.NON_DRUG_ALLERGY,
      },
    });
  };

  const handleReactionInputChange = (searchTerm: string | undefined) => {
    if (!searchTerm) return;
    setReactionOptions([]);
    searchReactionCodes({
      variables: {
        searchTerm,
      },
    });
  };

  const handleDeleteAllergy = (index: number) => {
    const allergy = getValues(`allergies.${index}`);
    removeAllergy(index);
    if (allergy.patientAllergyId) {
      const deletedAllergies = getValues('deletedAllergies');
      setValue('deletedAllergies', [...deletedAllergies, allergy]);
    }
  };

  useEffect(() => {
    if (allergyOptionsData) {
      const allergies = getValues('allergies');
      const allergyIds = allergies.reduce((prev, currentAllergy) => {
        if (!currentAllergy.isDeleted) {
          return [...prev, currentAllergy.allergy.allergyId];
        }
        return prev;
      }, [] as number[]);

      const filteredOptions = (
        allergyOptionsData.searchAllergyCodes ?? []
      ).filter((allergy) => !allergyIds.includes(allergy.allergyId));

      setAllergyOptions(
        filteredOptions.map((allergy) => ({
          label: allergy.name,
          hint: allergy.code,
          value: allergy,
        })),
      );
    }
  }, [allergyOptionsData, setAllergyOptions]);

  useEffect(() => {
    if (reactionOptionsData) {
      setReactionOptions(
        (reactionOptionsData.searchReactionCodes ?? []).map((reaction) => ({
          label: reaction.name,
          hint: reaction.code,
          value: reaction,
        })),
      );
    }
  }, [reactionOptionsData, setReactionOptions]);

  const setAllergyInput = (input: AllergyInputs['allergy']) => {
    setFormValues((prev) => ({
      ...prev,
      allergy: input,
    }));
  };

  const clearAllergyInput = () => {
    setFormValues((prev) => ({
      ...prev,
      allergy: null,
    }));
  };

  const setReactionInput = (input: AllergyInputs['allergicReaction']) => {
    setFormValues((prev) => ({
      ...prev,
      allergicReaction: input,
    }));
  };

  const clearReactionInput = () => {
    setFormValues((prev) => ({
      ...prev,
      allergicReaction: null,
    }));
  };

  const handleAddClick = () => {
    const { allergy, allergicReaction } = formValues;

    if (allergy) {
      appendAllergy({
        patientAllergyId: undefined,
        allergy,
        allergicReaction: allergicReaction || null,
        isDeleted: false,
      });

      setFormValues({
        allergy: null,
        allergicReaction: null,
      });

      setTimeout(() => {
        allergyTypeAheadRef.current?.reset();
        reactionTypeAheadRef.current?.reset();
      });
    }
  };

  const addButtonDisabled = !formValues.allergy;

  return {
    addButtonDisabled,
    allergiesFields: formState.allergies,
    allergyOptions,
    allergyOptionsLoading,
    allergyTypeAheadRef,
    clearAllergyInput,
    clearReactionInput,
    control,
    handleAddClick,
    handleAllergyInputChange,
    handleDeleteAllergy,
    handleReactionInputChange,
    inputsAreDisabled,
    reactionOptions,
    reactionOptionsLoading,
    reactionTypeAheadRef,
    setAllergyInput,
    setReactionInput,
    taskHeaderProps: {
      task,
      isDirty,
      isSaving,
      isValidating,
      onTaskSave: handleSaveTask,
      onTaskSkip: handleSkipTask,
      onTaskComplete: handleCompleteTask,
    },
  };
};

export default useAllergiesForm;
