import {
  Maybe,
  UserRoleEnum,
  EncounterClinicianStatusEnum,
  EncounterResponderStatusEnum,
  EncounterTaskStatusEnum,
} from 'generated/graphql';
import { actionData } from './actionData';
import { statusData } from './statusData';

export type TStatusesAndPermissionsOptions = {
  /* The desired task action to complete */
  action: string;
  /* The current user's operatingAs */
  userRole: string;
  /* Task roles array on the encounter task */
  taskRoles: Maybe<UserRoleEnum>[];
  /* Current status of the task */
  taskStatus?: Maybe<EncounterTaskStatusEnum>;
  /* Clinician status on the encounter */
  encounterClinicianStatus?: Maybe<EncounterClinicianStatusEnum>;
  /* Responder status on the encounter */
  encounterResponderStatus?: Maybe<EncounterResponderStatusEnum>;
};

type TUsersEncounterStatusEnum =
  | EncounterResponderStatusEnum
  | EncounterClinicianStatusEnum;

const actionAndStatusData = [...actionData, ...statusData];

export const hasStatusOrPermission = ({
  action,
  userRole,
  taskRoles,
  taskStatus,
  encounterClinicianStatus,
  encounterResponderStatus,
}: TStatusesAndPermissionsOptions): boolean => {
  const hasStatusOrPermissionValue = true;

  const rules = actionAndStatusData.filter(
    (rule) => rule.action === action && rule.role === userRole,
  );

  // No rules means no permissions
  if (rules.length === 0) {
    return false;
  }

  const encounterClinicianStatusStr: EncounterClinicianStatusEnum =
    encounterClinicianStatus ?? EncounterClinicianStatusEnum.NOT_STARTED;
  const encounterResponderStatusStr: EncounterResponderStatusEnum =
    encounterResponderStatus ?? EncounterResponderStatusEnum.NOT_STARTED;
  const usersEncounterStatus: TUsersEncounterStatusEnum =
    userRole === UserRoleEnum.CLINICIAN
      ? encounterClinicianStatusStr
      : encounterResponderStatusStr;
  const taskStatusStr: EncounterTaskStatusEnum =
    taskStatus ?? EncounterTaskStatusEnum.NOT_STARTED;

  // For now only 1 rule can be parsed. TBD whether we need to get more complicated
  const rule = rules[0];

  const encounterStatusArray: Array<TUsersEncounterStatusEnum> =
    rule.usersEncounterStatus;
  if (
    encounterStatusArray.length > 0 &&
    !encounterStatusArray.includes(usersEncounterStatus)
  ) {
    return false;
  }

  // Task status must be in the inclusion list (if provided)
  const taskStatusArray: Array<EncounterTaskStatusEnum> = rule.taskStatus ?? [];
  if (
    taskStatusArray &&
    taskStatusArray.length > 0 &&
    !taskStatusArray.includes(taskStatusStr)
  ) {
    return false;
  }

  // User role specified in the rule must be in the included task role list (if provided)
  // This mechanism allows data driven substitution of Responder for Supervisor
  if (rule.includeTaskRole && !taskRoles.includes(rule.includeTaskRole)) {
    return false;
  }

  // User role specified in the rule must not be in the excluded task role list (if provided)
  // This mechanism allows shared tasks to prevent actions by Responders/Supervisors if Clinicians are linked to tasks
  if (rule.excludeTaskRole && taskRoles.includes(rule.excludeTaskRole)) {
    return false;
  }

  if (rule.shared) {
    if (
      !(
        taskRoles.includes(UserRoleEnum.CLINICIAN) &&
        taskRoles.includes(UserRoleEnum.RESPONDER)
      )
    ) {
      return false;
    }
  }

  return hasStatusOrPermissionValue;
};
