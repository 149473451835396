// Docs located at https://reactdatepicker.com/
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Calendar } from 'phosphor-react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { Tokens } from 'config';
import Input from '../Input/Input';

const StyledDatepicker = styled.div<{ fullWidth?: boolean }>`
  .react-datepicker-wrapper {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  }

  .calendarInput {
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
  }

  .calendarWrapper {
    width: auto;
    z-index: ${Tokens.zIndex.dropdown};
  }

  .calendarPopper {
    display: flex;

    .react-datepicker__triangle {
      border-bottom-color: ${Tokens.color.ui.steel.base};
      transform: translate3d(120px, 0px, 0px) !important;
    }

    .react-datepicker__header {
      background-color: ${Tokens.color.ui.steel.base};
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      background-color: ${Tokens.color.ui.charcoal.base};
    }

    .react-datepicker__navigation-icon--previous,
    .react-datepicker__navigation-icon--next {
      width: 100%;
    }

    .react-datepicker__navigation-icon--next::before {
      left: 0;
    }

    .react-datepicker__navigation-icon--previous::before {
      right: 0;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export type DatepickerProps = {
  /**
   * If `true`, the input will be disabled.
   */
  disabled?: boolean;
  /**
   * Error displayed below the input.
   */
  errorText?: string;
  /**
   * If `true`, all past dates and times will be disabled.
   */
  filterPastDateAndTime?: boolean;
  /**
   * date-fns format string
   */
  filterFutureDateAndTime?: boolean;
  /**
   * date-fns format string
   */
  format?: string;
  /**
   * If `true`, the input with fill the width of it's container.
   */
  fullWidth?: boolean;
  /**
   * The `id` attribute of the input.
   */
  id?: string;
  /**
   * Instruction text displayed above the input.
   */
  instructionText?: string;
  /**
   * Renders a label with the provided text.
   */
  label?: string;
  /**
   * The `name` attribute of the input.
   */
  name?: string;
  /**
   * If `true`, the label will have italicized `optional` text.
   */
  optional?: boolean;
  /**
   * The `placeholder` attribute of the input.
   */
  placeholder?: string;
  /**
   * If `true`, the input will be required.
   */
  required?: boolean;
  /**
   * If `true`, allows adjusting the calendar month. Defaults to true.
   */
  showMonthDropdown?: boolean;
  /**
   * If `true`, the time selector is visible.
   */
  showTime?: boolean;
  /**
   * If `true`, allows adjusting the calendar year. Defaults to true.
   */
  showYearDropdown?: boolean;
  /**
   * A `Time Zone` string. If present, will adjust the datepicker for time zone.
   */
  timeZone?: string;
  /**
   * The selected date.
   */
  value?: Date;
  /**
   * The `onChange` event handler.
   */
  onChange?: (date: string) => void;
};

const Datepicker = forwardRef<HTMLInputElement, Readonly<DatepickerProps>>(
  (
    {
      disabled = false,
      errorText,
      filterPastDateAndTime = false,
      filterFutureDateAndTime = false,
      format = 'MMMM d, yyyy h:mm aa',
      fullWidth = false,
      id = 'Datepicker',
      instructionText,
      label,
      optional = false,
      name = 'Datepicker',
      placeholder,
      required = false,
      showMonthDropdown = true,
      showTime = false,
      showYearDropdown = true,
      timeZone = undefined,
      value = undefined,
      onChange = () => {},
    },
    inputRef,
  ): JSX.Element => {
    const getSelectedTime = (): Date | undefined => {
      if (value) {
        return timeZone
          ? value && utcToZonedTime(value, timeZone)
          : new Date(value);
      }
      return undefined;
    };

    const getMinDate = (): Date | undefined => {
      if (filterPastDateAndTime) {
        return timeZone ? utcToZonedTime(new Date(), timeZone) : new Date();
      }
      return undefined;
    };

    const getMaxDateToday = (): Date | undefined => {
      if (filterFutureDateAndTime) {
        return timeZone ? utcToZonedTime(new Date(), timeZone) : new Date();
      }
      return undefined;
    };

    const filterTime = (time: Date): boolean => {
      if (filterPastDateAndTime) {
        const currentDate = new Date();
        const timeSlot = new Date(time);

        return timeZone
          ? utcToZonedTime(currentDate, timeZone).getTime() < timeSlot.getTime()
          : currentDate.getTime() <= timeSlot.getTime();
      }
      return true;
    };

    const handleChange = (date: Date): void =>
      timeZone
        ? onChange(date && zonedTimeToUtc(date && date, timeZone).toISOString())
        : onChange(date && date.toISOString());

    return (
      <StyledDatepicker fullWidth={fullWidth}>
        <ReactDatepicker
          className="calendarInput"
          calendarClassName="calendarWrapper"
          customInput={
            <Input
              label={label}
              errorText={errorText}
              instructionText={instructionText}
              id={id}
              optional={optional}
              rightIcon={<Calendar />}
              required={required}
              fullWidth={fullWidth}
              inputMode="none"
              ref={inputRef}
            />
          }
          dateFormat={format}
          disabled={disabled}
          filterTime={filterTime}
          name={name}
          minDate={getMinDate()}
          maxDate={getMaxDateToday()}
          onChange={handleChange}
          popperClassName="calendarPopper"
          placeholderText={placeholder}
          selected={getSelectedTime()}
          showMonthDropdown={showMonthDropdown}
          showTimeSelect={showTime}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          strictParsing={true}
          timeCaption="Time"
          timeFormat="h:mm aa"
          timeIntervals={15}
        />
      </StyledDatepicker>
    );
  },
);

export default Datepicker;
