import styled from 'styled-components';

import { BreadcrumbsProps } from './types';

const StyledBreadcrumbs = styled.div`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Breadcrumbs = ({ children, ...props }: BreadcrumbsProps): JSX.Element => (
  <StyledBreadcrumbs {...props}>{children}</StyledBreadcrumbs>
);

export default Breadcrumbs;
