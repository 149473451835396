import imageCompression from 'browser-image-compression';
import { Camera } from 'phosphor-react';
import { Fragment, useEffect } from 'react';
import { useFieldArray, FieldArrayWithId } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { Tokens } from 'config';
import { useForm, Controlled } from 'forms';
import { TFile } from 'hooks/useFileUpload/types';
import Button from 'ui/Button/Button';
import Modal from 'ui/Modal/Modal';
import Thumbnail from './Thumbnail';
import { defaultCompressOptions } from './BaseImageInput';
import { TMultiInputValue, CompressionOptions } from '../types';
import { Analytics } from 'utils';

type TInputs = Omit<TMultiInputValue, 'images'> & {
  hiRes: boolean;
  images: Array<{
    fileList?: FileList;
    id?: number | null;
    isDeleted: boolean;
    url: string;
  }>;
};

const EditSchema = yup.object({
  description: yup.string().required('Please enter a valid title'),
  hiRes: yup.boolean(),
  images: yup
    .array()
    .of(
      yup.object({
        id: yup.number().nullable(true),
        url: yup.string().nullable(true),
        isDeleted: yup.boolean(),
      }),
    )
    .test('has-image', 'Please upload a valid image', (value) => {
      const allDeleted = (value ?? []).reduce(
        (prev, image) => prev && !!image?.isDeleted,
        true,
      );
      return !allDeleted;
    }),
  type: yup.string().required('Please select a valid type'),
});

type MultiEditModalProps = {
  anyUploadFailed: boolean;
  anyIsUploading: boolean;
  appendFile: () => void;
  clearAllUploadFailed: () => void;
  compressOptions?: CompressionOptions;
  defaultValues: Omit<TInputs, 'hiRes'>;
  files: TFile[];
  open: boolean;
  onClose: () => void;
  onSave: (value: TMultiInputValue) => void;
  testId?: string;
  typeOptions: Array<{ label: string; value: string }>;
};

const Container = styled.div`
  .field {
    margin-bottom: calc(${Tokens.rhythm} * 2);
  }

  .images {
    display: flex;

    .button-col {
      flex-direction: column;
      margin-right: calc(${Tokens.rhythm} * 2);
    }

    .thumbnail-col {
      flex-direction: column;
      flex-grow: 1;

      .thumbnails {
        flex-wrap: wrap;
        flex-direction: row;

        .thumbnail {
          display: inline-block;
          height: 80px;
          background-color: ${Tokens.color.neutral.white.base};
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 1px solid ${Tokens.color.neutral.grey[219]};
          width: 80px;
          margin-right: calc(${Tokens.rhythm} * 2);
          margin-bottom: calc(${Tokens.rhythm});
        }

        .thumbnail-overlay {
          top: 0;
          height: 80px;
          left: 0;
          position: absolute;
          background-color: ${Tokens.color.neutral.white.transparent[50]};
          width: 80px;

          .spinner > div {
            left: calc(50% - 12px);
            top: calc(40px - 12px);
          }

          &.error {
            border-color: ${Tokens.color.ui.error.base};
            color: ${Tokens.color.ui.error.base};
          }
        }
      }
    }
  }
`;

const MultiEditModal = ({
  anyUploadFailed,
  anyIsUploading,
  appendFile,
  clearAllUploadFailed,
  compressOptions = {},
  defaultValues,
  files,
  open,
  onClose,
  onSave,
  testId,
  typeOptions,
}: MultiEditModalProps): JSX.Element => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<TInputs>(EditSchema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const {
    fields: imageFields,
    append: appendImage,
    update: updateImage,
  } = useFieldArray({
    control,
    name: 'images',
    keyName: 'key',
  });

  useEffect(() => {
    files.forEach(({ file, downloadFile }) => {
      if (!file) downloadFile();
    });
  }, []);

  const onSubmit = async (data: TInputs) => {
    const compress = !data.hiRes;

    const awaitingUpload = data.images
      .map((image, index) => ({
        image,
        index,
      }))
      .filter((item) => !item.image.url);

    const results: Array<{ success: boolean; url?: string }> =
      await Promise.all(
        awaitingUpload.map((item) => {
          if (item.image.fileList) {
            const file = item.image.fileList[0];
            const promise = async () => {
              const preparedFile = compress
                ? await imageCompression(file, {
                    ...defaultCompressOptions,
                    ...compressOptions,
                  })
                : file;

              Analytics.timeEvent('Upload Image');
              const success = await files[item.index].uploadFile(preparedFile);
              Analytics.track('Upload Image', {
                'File Compressed': compress,
                'Original File Size': file.size,
                'Upload File Size': preparedFile.size,
                'Upload Success': success.success,
              });

              return success;
            };

            return promise();
          }
          return Promise.resolve({ success: false });
        }),
      );

    awaitingUpload.forEach((item, index) => {
      const result = results[index];
      if (result.success) {
        updateImage(item.index, { ...item.image, url: result.url });
      }
    });

    const success = results.reduce(
      (prev, result) => prev && result.success,
      true,
    );

    if (success) {
      const images = getValues('images').map((image) => {
        const { fileList, ...rest } = image; // remove fileList
        return rest;
      });

      const { hiRes, ...rest } = data;

      onSave({
        ...rest,
        images: [...images],
      });
    }
  };

  const handleDeleteImage = (
    image: FieldArrayWithId<TInputs, 'images', 'key'>,
    index: number,
  ) => {
    const { key, ...rest } = image;
    updateImage(index, { ...rest, isDeleted: true });
  };

  const handleClose = () => {
    if (!isSubmitting) {
      if (anyUploadFailed) {
        imageFields.forEach((image, index) => {
          if (files[index].uploadFailed) {
            handleDeleteImage(image, index);
          }
        });
        clearAllUploadFailed();
      } else {
        reset({ ...defaultValues });
      }
      onClose();
    }
  };

  const handleFileInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      appendFile();
      appendImage({
        id: null,
        url: '',
        fileList: event.currentTarget.files,
        isDeleted: false,
      });
    }
  };

  return (
    <Modal
      body={
        <Container>
          <form>
            <div className="field">
              <Controlled.Input
                control={control}
                defaultValue=""
                label="Title"
                name="description"
                fullWidth
              />
            </div>
            <div className="field">
              <Controlled.Select
                control={control}
                defaultValue=""
                label="Type"
                name="type"
                options={typeOptions}
              />
            </div>
            <div className="field">
              <Controlled.Checkbox
                control={control}
                defaultValue={false}
                label="Upload with high resolution"
                name="hiRes"
              />
            </div>
            <div className="images">
              <div className="button-col">
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="image/*"
                  style={{
                    display: 'none',
                  }}
                  onChange={handleFileInputChange}
                />
                <Button
                  size="icon"
                  variant="secondary"
                  as="label"
                  htmlFor="file"
                >
                  <Camera weight="fill" />
                </Button>
              </div>
              <div className="thumbnail-col">
                <div className="thumbnails">
                  {imageFields.map((image, index) => {
                    const file = files[index];
                    return (
                      <Fragment key={image.key}>
                        {!image.isDeleted && (
                          <Thumbnail
                            downloadFailed={file?.downloadFailed}
                            file={image.fileList ? image.fileList[0] : null}
                            localUrl={file?.localUrl}
                            isLoading={file?.isDownloading || file?.isUploading}
                            onDelete={() => {
                              handleDeleteImage(image, index);
                            }}
                            onRetryDownload={file?.downloadFile}
                            uploadFailed={file?.uploadFailed}
                            uploadComplete={!!file?.fileUrl && isSubmitting}
                          />
                        )}
                        {image?.fileList && (
                          <input
                            id={`images.${index}`}
                            type="file"
                            accept="image/*"
                            capture
                            style={{
                              display: 'none',
                            }}
                            {...register(`images.${index}.fileList`)}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            {errors.images &&
            (imageFields ?? []).reduce(
              (prev, image) => prev && !!image?.isDeleted,
              true,
            ) ? (
              <p style={{ color: Tokens.color.ui.error.base }}>
                Please upload a valid image
              </p>
            ) : null}
          </form>
        </Container>
      }
      footerButtons={[
        {
          children: anyUploadFailed ? 'Retry Upload' : 'Upload',
          onClick: handleSubmit(onSubmit),
          disabled: isSubmitting || !isDirty,
          isLoading: anyIsUploading || isSubmitting,
          variant: 'primary',
          size: 'small',
        },
      ]}
      open={open}
      onClose={handleClose}
      closeButtonLabel="Cancel"
      size="small"
      testId={testId}
      title="Edit"
    />
  );
};

export default MultiEditModal;
