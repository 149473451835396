import UI from 'ui';
import { Tokens } from 'config';

type FilterType =
  | 'appointmentStatus'
  | 'chartStatus'
  | 'market'
  | 'patient'
  | 'paymentMode'
  | 'serviceLine';

export type FilterPillProps = {
  /**
   * The filter pill's class name
   */
  className?: string;

  /**
   * The filter's value
   */
  value: number | string;

  /**
   * The filter's label
   */
  label: string;

  /**
   * Handles the close icon click.
   */
  onClose: (value: number | string, type: FilterType) => void;

  /**
   * The filter type.
   */
  type: FilterType;
};

const humanizedTypes = {
  appointmentStatus: 'Appointment Status',
  chartStatus: 'Chart Status',
  market: 'Market',
  patient: 'Patient',
  paymentMode: 'Payment Mode',
  serviceLine: 'Service Line',
};

const FilterPill = ({
  className = undefined,
  value,
  label,
  onClose,
  type,
}: FilterPillProps): JSX.Element => (
  <UI.Pill
    backgroundColor={Tokens.color.neutral.white.base}
    borderColor={Tokens.color.ui.charcoal.base}
    className={className}
    closeable
    onClose={() => onClose(value, type)}
    size="small"
    textColor={Tokens.color.ui.charcoal.base}
  >
    <>
      <span>{humanizedTypes[type] ?? type}:</span>
      <span style={{ marginLeft: 2, fontWeight: 600 }}>{label}</span>
    </>
  </UI.Pill>
);

export default FilterPill;
