import { Dispatch, SetStateAction, useRef } from 'react';
import { DotsThree } from 'phosphor-react';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { Tokens } from 'config';
import { useTask, useUserContext } from 'hooks';
import UI from 'ui';
import type { THeaderAction } from './TaskHeader';

type TProps = {
  actionsOpen: boolean;
  handleAction: (action: THeaderAction) => void;
  setActionsOpen: Dispatch<SetStateAction<boolean>>;
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
};

const TaskHeaderActions = ({
  actionsOpen,
  handleAction,
  setActionsOpen,
  task,
}: Readonly<TProps>): JSX.Element => {
  const { operatingAsClinician, operatingAsResponder } = useUserContext() ?? {};
  const {
    actionsAreDisabled,
    clinicianReviewedStatus,
    encounterIsComplete,
    inputsAreDisabled,
    taskCanBeCorrected,
  } = useTask(task);

  const actionsAnchorRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      {!actionsAreDisabled ? (
        <div ref={actionsAnchorRef} style={{ marginRight: Tokens.rhythm }}>
          <UI.Button
            className="actionsButton"
            variant="tertiary"
            size="small"
            onClick={() => setActionsOpen((state) => !state)}
            disabled={
              (encounterIsComplete && !taskCanBeCorrected) ||
              (!encounterIsComplete &&
                !operatingAsClinician &&
                clinicianReviewedStatus === 'Reviewed')
            }
          >
            <DotsThree size={34} weight="fill" />
          </UI.Button>

          <UI.Dropdown
            anchorRef={actionsAnchorRef}
            position="bottomLeft"
            open={actionsOpen}
            setOpen={setActionsOpen}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {inputsAreDisabled
              ? !encounterIsComplete && (
                  <UI.ListItem
                    label="Mark Incomplete"
                    onClick={() => handleAction('save')}
                  />
                )
              : !encounterIsComplete && (
                  <UI.ListItem
                    label="Mark Complete"
                    onClick={() => handleAction('complete')}
                  />
                )}

            {!inputsAreDisabled && !operatingAsResponder ? (
              <UI.ListItem
                label="Mark Skipped"
                onClick={() => handleAction('skip')}
              />
            ) : null}

            {taskCanBeCorrected ? (
              <UI.ListItem
                label="Make a Correction"
                onClick={() => handleAction('correct')}
              />
            ) : null}
          </UI.Dropdown>
        </div>
      ) : null}
    </div>
  );
};

export default TaskHeaderActions;
