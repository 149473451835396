import { DotsThree, MonitorPlay, WarningCircle } from 'phosphor-react';
import { millisecondsToMinutes } from 'date-fns';
import { useRef, useState } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { EncounterClinicianStatusEnum, Encounter } from 'generated/graphql';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import { useEncounter, useUserContext } from 'hooks';
import { Tokens } from 'config';
import UI from 'ui';
import UnclaimEncounterModal from 'modules/UnclaimEncounter';

export type EncounterTableRowProps = {
  /**
   * The Encounter.
   */
  encounter: Encounter;

  /**
   * Handle encounter click
   */
  handleEncounterClick: (encounter: Encounter) => void;

  /**
   * Handle claim encounter click
   */
  handleClaimEncounter: (encounter: Encounter) => void;

  /**
   * The `data-testid`
   */
  testId?: string;
};

type TModalState = {
  selectedType: string | null;
  isOpen: boolean;
};

const StyledEncounterTableRow = styled(UI.TableRow)`
  .dateTimeCell {
    .date {
      display: block;
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.paragraph.large};
    }

    .time {
      display: block;
      color: ${Tokens.color.neutral.grey[94]};
    }
  }

  .serviceLineCell {
    color: ${Tokens.color.neutral.grey[94]};
  }

  .clinicianStatusCell {
    svg {
      position: relative;
      top: 3px;
      margin-right: ${Tokens.rhythm};
    }
  }
`;

const EncounterTableRow = ({
  encounter,
  handleClaimEncounter,
  handleEncounterClick,
  testId = undefined,
}: EncounterTableRowProps): JSX.Element => {
  const location = useLocation();
  const menuAnchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalState, setModalState] = useState<TModalState>({
    selectedType: null,
    isOpen: false,
  });
  const [claimingEncounter, setClaimingEncounter] = useState(false);

  const { operatingAsClinician, user } = useUserContext() ?? {};

  const { encounterCanBeUnclaimed } = useEncounter(encounter);

  const operatingAsHasClaimedEncounter =
    operatingAsClinician && user?.userId === encounter?.clinician?.userId;

  const onClaimEncounter = async () => {
    setMenuOpen(false);
    setClaimingEncounter(true);
    await handleClaimEncounter(encounter);
    setClaimingEncounter(false);
  };

  const currentTime = new Date();

  const getRowClassName = (): 'inQueuePriority' | 'inQueue' | 'inProgress' => {
    if (
      encounter?.clinicianStatus === EncounterClinicianStatusEnum.IN_PROGRESS
    ) {
      return 'inProgress';
    }

    const ms = currentTime.getTime() - new Date(encounter.inQueueAt).getTime();
    return ms > 900000 ? 'inQueuePriority' : 'inQueue'; //  15m
  };

  const rowClassName = getRowClassName();

  const getTimeInQueue = () => {
    if (
      encounter.clinicianStatus === EncounterClinicianStatusEnum.IN_PROGRESS
    ) {
      if (!encounter.claimedAt) {
        return 'N/A';
      }

      const ms =
        currentTime.getTime() - new Date(encounter.claimedAt).getTime();

      return `T + ${millisecondsToMinutes(ms).toLocaleString()}m`;
    }

    if (!encounter.inQueueAt) {
      return 'N/A';
    }

    const ms = currentTime.getTime() - new Date(encounter.inQueueAt).getTime();
    return `${millisecondsToMinutes(ms).toLocaleString()}m`;
  };

  const moreRespondersCount = encounter.appointment
    ? encounter.appointment?.appointmentDispatchesList?.length - 1
    : 0;

  const getResponderName = (): string => {
    if (encounter.appointment?.appointmentDispatchesList?.length) {
      return (
        encounter.appointment?.appointmentDispatchesList![0].responder?.user
          ?.fullName ?? ''
      );
    }

    return '-';
  };

  const getRowBackgroundColor = () => {
    switch (rowClassName) {
      case 'inProgress':
        return Tokens.color.ui.success.transparent[10];
      case 'inQueuePriority':
        return Tokens.color.ui.error.transparent[10];
      case 'inQueue':
        return Tokens.color.ui.warning.transparent[10];
    }
  };

  const getClinicianStatus = (): JSX.Element => {
    switch (rowClassName) {
      case 'inProgress':
        return (
          <>
            <MonitorPlay color={Tokens.color.ui.success.base} weight="fill" />
            <span style={{ color: Tokens.color.ui.success.base }}>
              In Progress
            </span>
          </>
        );
      case 'inQueuePriority':
        return (
          <>
            <WarningCircle color={Tokens.color.ui.error.base} weight="fill" />
            <span style={{ color: Tokens.color.ui.error.base }}>In Queue</span>
          </>
        );
      case 'inQueue':
        return (
          <>
            <WarningCircle color={Tokens.color.ui.warning.base} weight="fill" />
            <span style={{ color: Tokens.color.ui.warning.base }}>
              In Queue
            </span>
          </>
        );
    }
  };

  return (
    <StyledEncounterTableRow
      data-testid={testId}
      style={{ backgroundColor: getRowBackgroundColor() }}
    >
      <UI.TableCell>{getTimeInQueue()}</UI.TableCell>
      <UI.TableCell>{encounter?.appointment?.market?.name}</UI.TableCell>
      <UI.TableCell>
        <Link
          to={{
            pathname: generatePath(PatientRoutePaths.profile, {
              id: `${encounter.patient?.globalPatientId}`,
            }),
            state: {
              referrer: {
                pageTitle: 'Patient Queue',
                pathname: location.pathname,
              },
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {encounter.patient?.preferredName
            ? `${encounter.patient?.firstName} "${encounter.patient?.preferredName}" ${encounter.patient?.lastName}`
            : encounter.patient?.fullName}
        </Link>
      </UI.TableCell>
      <UI.TableCell>{encounter.patient?.age}</UI.TableCell>
      <UI.TableCell>{encounter.channelAttribution?.channel}</UI.TableCell>
      <UI.TableCell>
        {encounter?.primaryInsuranceRecord?.company ?? '-'}
      </UI.TableCell>
      <UI.TableCell>
        {encounter.serviceLine?.name} - {encounter.serviceLine?.subType}
      </UI.TableCell>
      <UI.TableCell>{encounter.appointment?.humanizedStatus}</UI.TableCell>
      <UI.TableCell className="respondersCell">
        {getResponderName()}
        {moreRespondersCount > 0 && (
          <UI.Tag className="moreTag" label={`+${moreRespondersCount}`} />
        )}
      </UI.TableCell>
      <UI.TableCell className="clinicianStatusCell">
        {getClinicianStatus()}
      </UI.TableCell>
      <UI.TableCell>
        {encounter.clinician
          ? `${encounter.clinician?.firstName} ${encounter.clinician?.lastName}`
          : '-'}
      </UI.TableCell>
      <UI.TableCell>
        {claimingEncounter ? (
          <UI.Spinner />
        ) : (
          <div ref={menuAnchorRef}>
            <UI.Button
              onClick={() => {
                setMenuOpen(true);
              }}
              variant="tertiary"
              size="icon"
            >
              <DotsThree size={24} />
            </UI.Button>
            <UI.Dropdown
              anchorRef={menuAnchorRef}
              position="bottomLeft"
              open={menuOpen}
              setOpen={setMenuOpen}
            >
              <UI.ListItem
                label="View Chart"
                onClick={() => {
                  handleEncounterClick(encounter);
                }}
              />
              {encounter?.clinicianStatus ===
                EncounterClinicianStatusEnum.IN_QUEUE && (
                <UI.ListItem
                  label="Claim Encounter"
                  onClick={onClaimEncounter}
                />
              )}
              {encounterCanBeUnclaimed && operatingAsHasClaimedEncounter && (
                <UI.ListItem
                  label="Unclaim Encounter"
                  onClick={() => {
                    setModalState({
                      selectedType: null,
                      isOpen: true,
                    });
                    setMenuOpen(false);
                  }}
                />
              )}
            </UI.Dropdown>
          </div>
        )}
      </UI.TableCell>

      <UnclaimEncounterModal
        encounter={encounter}
        modalState={modalState}
        setModalState={setModalState}
      />
    </StyledEncounterTableRow>
  );
};

export default EncounterTableRow;
