import { UpsertEncounterTaskInput } from 'generated/graphql';
import { TInputs } from './schema';
import { FieldValues } from 'react-hook-form';

const formatAllergies = (
  allergies: TInputs['allergies'],
  isDeleted: boolean,
) => {
  return allergies.map(({ patientAllergyId, allergy, allergicReaction }) => ({
    patientAllergyId,
    isDeleted,
    allergyId: allergy.allergyId,
    allergicReactionId: allergicReaction
      ? allergicReaction.allergicReactionId
      : null,
    startedAt: new Date(),
  }));
};

export const getPatchValues = (
  formData: FieldValues,
  deletedAllergies: TInputs['allergies'],
): Partial<UpsertEncounterTaskInput> => {
  return {
    patientAllergy: [
      ...formatAllergies(formData.allergies, false),
      ...formatAllergies(deletedAllergies, true),
    ],
  };
};
