import { Dispatch, SetStateAction } from 'react';
import { Control, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form';
import {
  DispositionEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Tokens } from 'config';
import styled from 'styled-components';
import { StyledTaskForm } from '../TaskForm';
import { modalBody } from './modalBody';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import EscalateEncounterModal from '../EscalateEncounterModal';
import { TModalState } from '../Encounters/types';
import { TInputs, selectOptions, activationSelectOptions } from './utils';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { Controlled } from 'forms';

const StyledLink = styled.a<{
  linkTextSmall?: boolean;
}>(
  ({ linkTextSmall }) => `
  color: ${Tokens.color.ui.link.base};
  cursor: pointer;
  font-family: ${Tokens.font.family};
  text-decoration: none;

  ${
    linkTextSmall &&
    `
    font-size: ${Tokens.font.size.paragraph.small};
    line-height: ${Tokens.lineHeight.paragraph.small};`
  }
`,
);

const DispositionForm = ({
  control,
  errors,
  encounter,
  formState,
  formTitle,
  isClinicianModuleType,
  inputsAreDisabled,
  modalState,
  operatingAsClinician,
  patientInstructionsDisabled,
  setModalState,
  taskHeaderProps,
}: {
  control: Control<TInputs>;
  encounter?: NonNullable<
    GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
  >['encounter'];
  errors: DeepMap<TInputs, FieldError>;
  formState: TInputs;
  formTitle?: string;
  isClinicianModuleType?: boolean;
  inputsAreDisabled: boolean;
  modalState: TModalState;
  operatingAsClinician: boolean;
  patientInstructionsDisabled: boolean;
  setModalState: Dispatch<SetStateAction<TModalState>>;
  setValue: UseFormSetValue<TInputs>;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const { clinicianIncluded, disposition } = formState;
  const claimedEncounter = Boolean(task?.encounter?.clinician);

  const handleLinkOnClick = (modalType: string) => {
    setModalState({
      selectedType: modalType,
      isOpen: true,
    });
  };

  const renderAlertBoxMessage = (type: string) => {
    const skippedTasks = 'Because the patient declined services,';
    const autoMarkedSkippedTasks = 'Because there was an activation,';

    return (
      <>
        <p>
          {type === 'skippedTasks' ? skippedTasks : autoMarkedSkippedTasks} not
          all tasks are expected to be marked complete. Document all possible
          information within tasks before finishing this encounter.
        </p>

        <p>
          Any incomplete tasks will be marked as ‘Skipped’ upon finishing the
          encounter.
        </p>
      </>
    );
  };

  const activationNoEMSModuleErrorMessage = (
    <p
      style={{
        color: Tokens.color.ui.error.base,
        marginTop: Tokens.rhythm,
        lineHeight: Tokens.lineHeight.paragraph.small,
      }}
    >
      There are no associated activation tasks on this encounter.{' '}
      <StyledLink
        className="clickHereButton"
        href="#"
        onClick={(event) => {
          event.preventDefault();
          handleLinkOnClick('escalateEncounter');
        }}
      >
        Click here
      </StyledLink>{' '}
      to escalate the encounter with the appropriate activation.
    </p>
  );

  const instructionText = (
    <p>
      Select the correct disposition for what has happened throughout the
      encounter.{' '}
      <StyledLink
        href="#"
        linkTextSmall
        onClick={(event) => {
          event.preventDefault();
          handleLinkOnClick('dispositionDefinitions');
        }}
      >
        View disposition definitions
      </StyledLink>
    </p>
  );

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>{formTitle}</h3>
          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
          <p className="formInstructionsLabel">
            1. Confirm that a clinician encounter occurred
          </p>
        </header>

        <section>
          <Controlled.RadioButtonGroup
            control={control}
            disabled={
              operatingAsClinician ||
              isClinicianModuleType === false ||
              claimedEncounter ||
              inputsAreDisabled
            }
            fullWidth
            label="Did the patient connect with a clinician?"
            name="clinicianIncluded"
            options={['Yes', 'No']}
          />
        </section>

        <p className="formInstructionsLabel">
          2. Select the disposition for this encounter
        </p>

        <section>
          <Controlled.Select
            control={control}
            disabled={inputsAreDisabled || clinicianIncluded === null}
            instructionText={instructionText}
            label="Disposition"
            name="disposition"
            options={selectOptions(isClinicianModuleType, formState)}
            sortedOptions={false}
          />

          {[
            DispositionEnum.DISCHARGED_WITH_ACTIVATION,
            DispositionEnum.RESPONDER_INITIATED_ACTIVATION,
          ].includes(disposition as DispositionEnum) ? (
            <>
              <Controlled.Select
                control={control}
                disabled={
                  inputsAreDisabled ||
                  clinicianIncluded === null ||
                  !disposition
                }
                label="What type of activation was this?"
                name="activation"
                options={activationSelectOptions}
                sortedOptions={false}
              />

              {errors.activation?.message === ' '
                ? activationNoEMSModuleErrorMessage
                : null}

              <div style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}>
                <UI.AlertBox
                  label="Incomplete tasks auto-marked as 'Skipped'"
                  message={renderAlertBoxMessage('autoMarkedSkippedTasks')}
                  severity={UI.AlertBox.SeverityLevel.Warning}
                />
              </div>
            </>
          ) : null}

          {[
            DispositionEnum.DISCHARGED_WITH_SERVICE_REFUSAL,
            DispositionEnum.PATIENT_DECLINED_SERVICES,
          ].includes(disposition as DispositionEnum) ? (
            <div style={{ marginTop: `calc(${Tokens.rhythm} * 2)` }}>
              <UI.AlertBox
                label="Incomplete tasks will be 'Skipped'"
                message={renderAlertBoxMessage('skippedTasks')}
                severity={UI.AlertBox.SeverityLevel.Warning}
              />
            </div>
          ) : null}
        </section>

        <UI.Modal
          body={modalBody}
          closeButtonLabel="Cancel"
          onClose={() =>
            setModalState({
              selectedType: null,
              isOpen: false,
            })
          }
          title={'Disposition definitions'}
          size="small"
          open={modalState.selectedType === 'dispositionDefinitions'}
          footerButtons={[
            {
              children: 'Okay',
              variant: 'primary',
              size: 'small',
              onClick: () =>
                setModalState({
                  selectedType: null,
                  isOpen: false,
                }),
            },
          ]}
        />

        <p className="formInstructionsLabel">
          3. Document patient instructions
        </p>

        <section>
          <Controlled.Input
            control={control}
            disabled={inputsAreDisabled || patientInstructionsDisabled}
            defaultValue=""
            fullWidth
            label="Care Instructions"
            multiline
            rows={8}
            name="careInstructions"
            optional
          />
        </section>

        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}

        {modalState.selectedType === 'escalateEncounter' && (
          <EscalateEncounterModal
            encounter={encounter}
            modalState={modalState}
            setModalState={setModalState}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default DispositionForm;
