import { useEffect } from 'react';

import {
  ModuleNameEnum,
  useCreateAddOnTaskLazyQuery,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';

import { Logger } from 'utils';
import { Tokens } from 'config';
import { useToastMessage } from 'hooks';
import UI from 'ui';
import { TModalState, TSetModalState } from './types';

const VitalsModal = ({
  encounter,
  setModalState,
  modalState,
}: {
  encounter?: NonNullable<
    GetEncounterTaskByEncounterTaskIdQuery['encounterTask']
  >['encounter'];
  setModalState: TSetModalState;
  modalState: TModalState;
}): JSX.Element => {
  const [createAddOnTask, { data, error }] = useCreateAddOnTaskLazyQuery();
  const { setToastMessage } = useToastMessage();

  const vitalsSetModalContent = (
    <>
      <p
        style={{
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
          marginTop: '0px',
        }}
      >
        A vitals set module will be added to the{' '}
        <strong>
          {encounter?.serviceLine?.name} - {encounter?.serviceLine?.subType}
        </strong>{' '}
        encounter.
      </p>
    </>
  );

  useEffect(() => {
    if (data) {
      setToastMessage(
        `Successfully added 1 module`,
        UI.Toast.SeverityLevel.Success,
      );
      setModalState({
        selectedType: null,
        isOpen: false,
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Logger.error(error);
      setToastMessage(`Failed to add task`, UI.Toast.SeverityLevel.Error);
    }
  }, [error]);

  return (
    <UI.Modal
      body={vitalsSetModalContent}
      closeButtonLabel="Cancel"
      onClose={() =>
        setModalState({
          selectedType: null,
          isOpen: false,
        })
      }
      title={'Add vitals set'}
      size="small"
      open={modalState.isOpen}
      footerButtons={[
        {
          name: 'addVitalsButton',
          children: 'Add Module',
          variant: 'primary',
          size: 'small',
          onClick: () => {
            createAddOnTask({
              variables: {
                id: encounter?.encounterId as number,
                moduleName: ModuleNameEnum.VITALS_ADDITIONAL,
              },
            });
          },
        },
      ]}
    />
  );
};

export default VitalsModal;
