import {
  UserRoleEnum,
  EncounterClinicianStatusEnum,
  EncounterResponderStatusEnum,
  EncounterTaskStatusEnum,
} from 'generated/graphql';

import { ActionAndStatusEnum } from './utils';
import type { TActionOrStatus } from './utils';

export const actionData: TActionOrStatus[] = [
  // ACTION: MARK_COMPLETE
  {
    action: ActionAndStatusEnum.MARK_COMPLETE,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },
  {
    action: ActionAndStatusEnum.MARK_COMPLETE,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },
  {
    action: ActionAndStatusEnum.MARK_COMPLETE,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
    ],
  },

  // ACTION: SKIP_TASK
  {
    action: ActionAndStatusEnum.SKIP_TASK,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },
  {
    action: ActionAndStatusEnum.SKIP_TASK,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },

  // ACTION: UNSKIP_TASK
  {
    action: ActionAndStatusEnum.UNSKIP_TASK,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.SKIPPED],
  },
  {
    action: ActionAndStatusEnum.UNSKIP_TASK,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.SKIPPED],
  },
  {
    action: ActionAndStatusEnum.UNSKIP_TASK,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.SKIPPED],
  },

  // ACTION: MARK_INCOMPLETE
  {
    action: ActionAndStatusEnum.MARK_INCOMPLETE,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
      EncounterTaskStatusEnum.SKIPPED,
    ],
  },
  {
    action: ActionAndStatusEnum.MARK_INCOMPLETE,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
      EncounterTaskStatusEnum.SKIPPED,
    ],
  },
  {
    action: ActionAndStatusEnum.MARK_INCOMPLETE,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
      EncounterTaskStatusEnum.SKIPPED,
    ],
  },

  // ACTION: MAKE_CORRECTION
  {
    action: ActionAndStatusEnum.MAKE_CORRECTION,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: UserRoleEnum.CLINICIAN,
    usersEncounterStatus: [EncounterResponderStatusEnum.COMPLETED],
    taskStatus: [EncounterTaskStatusEnum.RESPONDER_COMPLETED],
  },
  {
    action: ActionAndStatusEnum.MAKE_CORRECTION,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: UserRoleEnum.CLINICIAN,
    usersEncounterStatus: [EncounterResponderStatusEnum.COMPLETED],
    taskStatus: [EncounterTaskStatusEnum.RESPONDER_COMPLETED],
  },
  {
    action: ActionAndStatusEnum.MAKE_CORRECTION,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.COMPLETED],
    taskStatus: [EncounterTaskStatusEnum.CLINICIAN_REVIEWED],
  },

  // ACTION: START_ENCOUNTER
  {
    action: ActionAndStatusEnum.START_ENCOUNTER,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.NOT_STARTED],
    taskStatus: null,
  },
  {
    action: ActionAndStatusEnum.START_ENCOUNTER,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.NOT_STARTED],
    taskStatus: null,
  },

  // ACTION: CLAIM_ENCOUNTER
  {
    action: ActionAndStatusEnum.CLAIM_ENCOUNTER,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_QUEUE],
    taskStatus: null,
  },

  // ACTION: UNCLAIM_ENCOUNTER
  {
    action: ActionAndStatusEnum.UNCLAIM_ENCOUNTER,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.IN_PROGRESS,
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
      EncounterTaskStatusEnum.SKIPPED,
    ],
  },
];
