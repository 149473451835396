import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { Config } from 'config';
import Cache from 'cache';

type Props = {
  children: React.ReactNode;
};

const ApolloProviderComponent = ({
  children,
}: Readonly<Props>): JSX.Element | null => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: Config.graphqlUrl,
  });

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenSilently();

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: Cache,
  });

  return isAuthenticated ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : null;
};

export default withAuthenticationRequired(ApolloProviderComponent);
