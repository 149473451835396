import { memo } from 'react';
import { List } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';

type Props = {
  setSidebarIsOpen?: (arg: boolean) => void;
};

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  padding: calc(${Tokens.rhythm} * 2);

  & > * + * {
    margin-left: calc(${Tokens.rhythm} * 2);
  }
`;

const Header = ({
  setSidebarIsOpen = () => {},
}: Readonly<Props>): JSX.Element => (
  <StyledHeader>
    <UI.Button
      onClick={() => setSidebarIsOpen(false)}
      size="icon"
      style={{
        backgroundColor: Tokens.color.neutral.white.transparent[10],
      }}
    >
      <List size={22} color={Tokens.color.neutral.white.base} />
    </UI.Button>

    <UI.Logo />
  </StyledHeader>
);

export default memo(Header);
