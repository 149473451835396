import { DotsThree } from 'phosphor-react';
import { useRef, useState } from 'react';

import UI from 'ui';

type Props = {
  allergyName: string;
  inputsAreDisabled: boolean;
  reactionName: string;
  onDelete: () => void;
};

const AllergyTableRow = ({
  allergyName,
  inputsAreDisabled,
  onDelete,
  reactionName,
}: Props): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);

  const handleDelete = () => {
    setMenuOpen(false);
    onDelete();
  };

  return (
    <UI.TableRow>
      <UI.TableCell>{allergyName}</UI.TableCell>
      <UI.TableCell>{reactionName}</UI.TableCell>
      <UI.TableCell>
        <div ref={menuAnchorRef}>
          <UI.Button
            onClick={() => {
              setMenuOpen(true);
            }}
            disabled={inputsAreDisabled}
            variant="tertiary"
            size="icon"
          >
            <DotsThree size={24} />
          </UI.Button>
          <UI.Dropdown
            anchorRef={menuAnchorRef}
            position="bottomLeft"
            open={menuOpen}
            setOpen={setMenuOpen}
          >
            <UI.ListItem label="Delete" onClick={handleDelete} />
          </UI.Dropdown>
        </div>
      </UI.TableCell>
    </UI.TableRow>
  );
};

export default AllergyTableRow;
