import styled from 'styled-components';

import { Tokens } from 'config';
import { HelpTextProps } from './types';

const StyledHelpText = styled.span<HelpTextProps>(({ error, success }) => {
  const fontStyles = (() => {
    if (error)
      return `
        color: ${Tokens.color.ui.error.base};
        font-size: ${Tokens.font.size.paragraph.base};
      `;
    if (success)
      return `
        color: ${Tokens.color.ui.success.base};
        font-size: ${Tokens.font.size.paragraph.base};
      `;
    return `
      color: ${Tokens.color.neutral.grey[102]};
      font-size: ${Tokens.font.size.paragraph.small};
    `;
  })();
  return `
    display: flex;
    line-height: ${Tokens.lineHeight.paragraph.small};
    margin-top: ${Tokens.rhythm};
    font-family: ${Tokens.font.family};
    ${fontStyles}
  `;
});

export const HelpTextPropsError = Error;
const validateProps = (props: HelpTextProps): void => {
  if (props.success && props.error) {
    throw HelpTextPropsError(
      '"success" and "error" props cannot both be true.',
    );
  }
};

const HelpText = (props: HelpTextProps): JSX.Element => {
  validateProps(props);
  const { children, error, success, ...rest } = props;
  return (
    <StyledHelpText error={error} success={success} {...rest}>
      {children}
    </StyledHelpText>
  );
};

HelpText.defaultProps = {
  error: false,
  success: false,
};

export default HelpText;
