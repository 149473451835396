import styled from 'styled-components';
import { CaretRight } from 'phosphor-react';
import { Link } from 'react-router-dom';

import { Tokens } from 'config';
import { BreadcrumbProps } from './types';

const StyledBreadcrumb = styled.div<React.ButtonHTMLAttributes<HTMLDivElement>>`
  align-items: center;
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.small};
  height: 16px;
  line-height: ${Tokens.lineHeight.paragraph.small};

  a {
    color: ${Tokens.color.ui.charcoal.base};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: ${Tokens.outline};
    }
  }

  &.active {
    font-weight: ${Tokens.font.weight.semiBold};
  }
`;

const Caret = styled.div`
  margin: 0 ${Tokens.rhythm};
  height: 16px;
  vertical-align: middle;
`;

export const BreadcrumbPropsError = Error;

const Breadcrumb = ({
  children,
  href,
  to,
  active = false,
  ...props
}: BreadcrumbProps): JSX.Element => {
  if (!href && !to) {
    throw new BreadcrumbPropsError('Must have either `href` or `to` prop.');
  }
  if (href && to) {
    throw new BreadcrumbPropsError('Cannot have both `href` and `to` props.');
  }
  return (
    <>
      <StyledBreadcrumb
        className={`breadcrumb ${active ? 'active' : ''}`}
        {...props}
      >
        <Link to={href ?? to}>{children}</Link>
      </StyledBreadcrumb>
      {!active && (
        <Caret className="caret">
          <CaretRight size={16} />
        </Caret>
      )}
    </>
  );
};

export default Breadcrumb;
