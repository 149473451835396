import {
  Controller,
  Control,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import Datepicker, { DatepickerProps } from 'ui/Datepicker/Datepicker';

/**
 * Controlled Datepicker Props
 * @extends {UI.Datepicker}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<
  DatepickerProps,
  'name' | 'errorText' | 'id' | 'onChange' | 'value'
> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;
};

const ControlledDatepicker = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  name,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field, fieldState: { error } }) => (
      <Datepicker id={name} errorText={error?.message} {...field} {...props} />
    )}
  />
);

export default ControlledDatepicker;
