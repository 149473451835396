import { Control, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { TInputs } from './utils';
import styled from 'styled-components';

const StyledProgressNoteForm = styled.div`
  header {
    padding-bottom: 0;
  }

  section {
    border-top: none;

    textarea {
      height: calc(${Tokens.rhythm} * 30);
    }
  }
`;

const ProgressNoteForm = ({
  control,
  inputsAreDisabled,
  taskHeaderProps,
}: {
  control: Control<TInputs>;
  errors: DeepMap<TInputs, FieldError>;
  inputsAreDisabled: boolean;
  setValue: UseFormSetValue<TInputs>;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <StyledProgressNoteForm>
          <header>
            <h3>{task?.taskName}</h3>
            <p>All fields below, unless otherwise noted, are required.</p>
          </header>

          <section>
            <Controlled.Input
              control={control}
              disabled={inputsAreDisabled}
              defaultValue=""
              fullWidth
              label="Progress Note"
              multiline
              name="note"
              optional
            />
          </section>
          {task?.encounterTaskId && (
            <AdditionalPhotoDocSection
              encounterTaskId={task?.encounterTaskId}
              disabled={inputsAreDisabled}
            />
          )}
        </StyledProgressNoteForm>
      </StyledTaskForm>
    </>
  );
};

export default ProgressNoteForm;
