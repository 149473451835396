import { useContext } from 'react';
import {
  ToastContext,
  SetToastMessage,
  ToastContextType,
} from 'providers/ToastContextProvider';

type ReturnValue = {
  setToastMessage: SetToastMessage;
  clearToastMessage: () => void;
};

const useToastMessage = (): ReturnValue => {
  const { setToastMessage } = useContext<ToastContextType>(ToastContext);
  const clearToastMessage = () => {
    setToastMessage(undefined);
  };
  return { setToastMessage, clearToastMessage };
};

export default useToastMessage;
