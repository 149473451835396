export enum Environment {
  Local = 'local',
  Test = 'test',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
}

type Auth0Settings = {
  domain: string;
  clientId: string;
  audience: string;
};

type Config = {
  environment: Environment;
  graphqlUrl: string;
  version: string;
  sentryDsn?: string;
  googleMapsApiKey: string;
  logLevel: number;
  auth0: Auth0Settings;
  readyhealthV1Url: string;
  inactivityTimeout: number;
  fileUploadUrl: string;
  fileUploadBucket: string;
  fileUploadRequireMd5: boolean;
  mixpanelToken: string;
  enableImageUploadTest: boolean;
  isDoseSpotEnabled: boolean;
};

const baseConfig = {
  version: process.env.REACT_APP_VERSION || 'unknown',
  sentryDsn:
    'https://3c324e765f574de380fee497a2b0e816@o411821.ingest.sentry.io/5617428',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
  logLevel: LogLevel.debug,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  },
  inactivityTimeout: 1000 * 60 * 30, // 30 mins
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN!,
  fileUploadRequireMd5:
    process.env.REACT_APP_FILE_UPLOAD_REQUIRE_MD5 === 'true',
  enableImageUploadTest:
    process.env.REACT_APP_ENABLE_IMAGE_UPLOAD_TEST === 'true',
  isDoseSpotEnabled: process.env.REACT_APP_DOSE_SPOT_ENABLED === 'true',
};

const configs: Record<Environment, Config> = {
  [Environment.Local]: {
    ...baseConfig,
    environment: Environment.Local,
    version: 'local',
    graphqlUrl: 'https://dev.ready.dev/graphql',
    readyhealthV1Url: 'https://dev-health.getready.com',
    googleMapsApiKey: 'AIzaSyCoAKPgIZ96zCsn_9alQpplFFkO1pfOn2w',
    fileUploadUrl: 'https://media.dev.ready.dev',
    fileUploadBucket: 'rco-20-uploads-dev-dodo',
    mixpanelToken: 'local',
    fileUploadRequireMd5: true,
    isDoseSpotEnabled: true,
  },
  [Environment.Test]: {
    ...baseConfig,
    environment: Environment.Test,
    version: 'test',
    graphqlUrl: 'https://dev.ready.dev/graphql',
    readyhealthV1Url: 'https://dev-health.getready.com',
    googleMapsApiKey: 'AIzaSyCoAKPgIZ96zCsn_9alQpplFFkO1pfOn2w',
    fileUploadUrl: '',
    fileUploadBucket: 'test',
    isDoseSpotEnabled: true,
  },
  [Environment.Development]: {
    ...baseConfig,
    environment: Environment.Development,
    graphqlUrl: 'https://dev.ready.dev/graphql',
    readyhealthV1Url: 'https://dev-health.getready.com',
    googleMapsApiKey: 'AIzaSyCoAKPgIZ96zCsn_9alQpplFFkO1pfOn2w',
    fileUploadUrl: 'https://media.dev.ready.dev',
    fileUploadBucket: 'rco-20-uploads-dev-dodo',
    inactivityTimeout: 1000 * 60 * 5, // 5 mins
  },
  [Environment.Staging]: {
    ...baseConfig,
    environment: Environment.Staging,
    graphqlUrl: 'https://stag.ready.dev/graphql',
    readyhealthV1Url: 'https://staging-health.getready.com',
    fileUploadUrl: 'https://media.stag.ready.dev',
    fileUploadBucket: 'rco-20-uploads-stag-skink',
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN_DEPLOY_PREVIEW!,
    fileUploadRequireMd5:
      process.env.REACT_APP_FILE_UPLOAD_REQUIRE_MD5_DEPLOY_PREVIEW === 'true',
    isDoseSpotEnabled:
      process.env.REACT_APP_DOSE_SPOT_ENABLED_DEPLOY_PREVIEW === 'true',
  },
  [Environment.Production]: {
    ...baseConfig,
    environment: Environment.Production,
    graphqlUrl: 'https://core.getready.com/graphql',
    readyhealthV1Url: 'https://health.getready.com',
    logLevel: LogLevel.info,
    fileUploadUrl: 'https://media.core.getready.com',
    fileUploadBucket: 'rco-20-uploads-prod-bull',
  },
};

const getConfig = (): Config => {
  if (process.env.STORYBOOK) {
    return configs[Environment.Local];
  }
  const env: Environment = process.env.REACT_APP_ENVIRONMENT as Environment;
  if (!Object.values(Environment).includes(env)) {
    throw Error(`Cannot find app configuration for environment named "${env}"`);
  }
  return configs[env];
};

export default getConfig();
