import { useState } from 'react';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';
import { usePersistTask, useTask } from 'hooks';
import { TaskHeaderProps } from '../../TaskHeader';

type Props = {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
};

type ReturnValues = {
  closeModal: () => void;
  inputsAreDisabled: boolean;
  modalOpen: boolean;
  openModal: () => void;
  taskHeaderProps: TaskHeaderProps;
  urlQueryArgs:
    | { encounterId: number; marketId: number; patientId: number }
    | undefined;
};

const useDoseSpotForm = ({ task }: Props): ReturnValues => {
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { inputsAreDisabled } = useTask(task);
  const { persistTask } = usePersistTask({ task });

  const handlePersistTask = async (workflow: 'complete' | 'save' | 'skip') => {
    setIsSaving(true);
    await persistTask({ data: {}, workflow });
    setIsSaving(false);
  };

  const encounterId = task?.encounter?.encounterId;
  const patientId = task?.encounter?.patient?.patientId;
  const marketId = task?.encounter?.appointment?.market?.marketId;

  const urlQueryArgs =
    encounterId && patientId && marketId
      ? {
          encounterId,
          patientId,
          marketId,
        }
      : undefined;

  return {
    closeModal: () => {
      setModalOpen(false);
    },
    inputsAreDisabled,
    modalOpen,
    openModal: () => {
      setModalOpen(true);
    },
    taskHeaderProps: {
      task,
      isDirty: false,
      isSaving,
      isValidating: false,
      onTaskComplete: () => handlePersistTask('complete'),
      onTaskSkip: () => handlePersistTask('skip'),
      onTaskSave: () => handlePersistTask('save'),
    },
    urlQueryArgs,
  };
};

export default useDoseSpotForm;
