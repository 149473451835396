import { useAuth0 } from '@auth0/auth0-react';

import { Analytics } from 'utils';

type TUseLogout = () => { logout: (idle?: boolean) => void };

const useLogout: TUseLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = (idle = false) => {
    if (!Analytics.enabled) {
      auth0Logout({ returnTo: window.location.origin });
      return;
    }
    Analytics.track(idle ? 'Idle Sign Out' : 'Sign Out', undefined, () => {
      Analytics.reset();
      auth0Logout({ returnTo: window.location.origin });
    });
  };

  return { logout };
};

export default useLogout;
