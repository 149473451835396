import { memo, useState } from 'react';
import styled from 'styled-components';

import { Tokens } from 'config';
import { useLogout, useSidebarContext, useUserContext } from 'hooks';
import { Footer, Header, Navigation } from './components';

const StyledSidebar = styled.div`
  box-shadow: 0px 1px 3px ${Tokens.color.neutral.black.transparent[25]};
  color: ${Tokens.color.neutral.white.base};
  display: flex;
  flex-direction: column;
  font-size: ${Tokens.font.size.paragraph.base};
  height: 100vh;
  justify-content: space-between;
  max-height: 100%;
  position: relative;

  .overlay {
    background-color: ${Tokens.color.ui.charcoal.transparent[50]};
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > footer {
    box-shadow: 0px -1px 0px ${Tokens.color.neutral.black.transparent[25]};
    position: relative;

    &.active {
      background-color: ${Tokens.color.neutral.black.transparent[50]};
    }
  }
`;

const Sidebar = (): JSX.Element => {
  const userContext = useUserContext();
  const sidebarContext = useSidebarContext();
  const [overlayIsVisible, setOverlayIsVisible] = useState(false);
  const { logout } = useLogout();

  return (
    <StyledSidebar>
      <header>
        <Header setSidebarIsOpen={sidebarContext?.setSidebarIsOpen} />
        <Navigation operatingAs={userContext?.operatingAs} />
      </header>

      {overlayIsVisible ? (
        <div className="overlay" onClick={() => setOverlayIsVisible(false)} />
      ) : null}

      <footer className={overlayIsVisible ? 'active' : ''}>
        <Footer
          logout={logout}
          operatingAs={userContext?.operatingAs}
          overlayIsVisible={overlayIsVisible}
          setOperatingAs={userContext?.setOperatingAs}
          setOverlayIsVisible={setOverlayIsVisible}
          sidebarIsOpen={sidebarContext?.sidebarIsOpen}
          user={userContext?.user}
        />
      </footer>
    </StyledSidebar>
  );
};

// Export components as subcomponents of Sidebar
Sidebar.Footer = Footer;
Sidebar.Header = Header;
Sidebar.Navigation = Navigation;

export default memo(Sidebar);
