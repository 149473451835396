import {
  EncounterClinicianStatusEnum,
  EncounterResponderStatusEnum,
  EncounterTaskStatusEnum,
  UserRoleEnum,
} from 'generated/graphql';

export enum ActionEnum {
  MARK_COMPLETE = 'MARK COMPLETE',
  MARK_INCOMPLETE = 'MARK INCOMPLETE',
  SKIP_TASK = 'SKIP TASK',
  UNSKIP_TASK = 'UNSKIP TASK',
  MAKE_CORRECTION = 'MAKE CORRECTION',
  START_ENCOUNTER = 'START ENCOUNTER',
  CLAIM_ENCOUNTER = 'CLAIM ENCOUNTER',
  UNCLAIM_ENCOUNTER = 'UNCLAIM ENCOUNTER',
}

export enum StatusEnum {
  ENCOUNTER_CAN_BE_CLAIMED = 'ENCOUNTER CAN BE CLAIMED',
  ENCOUNTER_CAN_BE_UNCLAIMED = 'ENCOUNTER CAN BE UNCLAIMED',
  ENCOUNTER_COMPLETE = 'ENCOUNTER IS COMPLETE',
  ENCOUNTER_IN_PROGRESS = 'ENCOUNTER IS IN PROGRESS',
  TASK_VISIBLE = 'TASK IS VISIBLE',
  TASK_EDITABLE = 'TASK IS EDITABLE',
  TASK_NEEDS_REVIEW = 'TASK NEEDS REVIEW',
  TASK_REVIEWED = 'TASK IS REVIEWED',
}

export const ActionAndStatusEnum = { ...ActionEnum, ...StatusEnum };

export type TActionOrStatus = {
  action: ActionEnum | StatusEnum;
  role: UserRoleEnum;
  includeTaskRole: UserRoleEnum | null;
  excludeTaskRole: UserRoleEnum | null;
  usersEncounterStatus: (
    | EncounterClinicianStatusEnum
    | EncounterResponderStatusEnum
  )[];
  taskStatus: EncounterTaskStatusEnum[] | null;
  shared?: boolean;
};
