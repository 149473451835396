import { Tokens } from 'config';
import { useToastMessage, useForm, useUserContext } from 'hooks';
import UI from 'ui';
import { TModalState, TSetModalState } from './types';
import Input from 'ui/Input';
import { Logger } from 'utils';
import { useCreateAddendumMutation } from 'generated/graphql';

const AddAddendumModal = ({
  encounterId,
  setModalState,
  modalState,
}: {
  encounterId: number;
  setModalState: TSetModalState;
  modalState: TModalState;
}): JSX.Element => {
  const { setToastMessage } = useToastMessage();
  const [createAddendum] = useCreateAddendumMutation();
  const userContext = useUserContext();

  const { formState, getErrors, getData, setData, setErrors } = useForm({
    addendumText: {
      errorMessage: 'Please provide a valid addendum',
      field: 'addendumText',
      required: true,
      value: null,
    },
  });

  const addAddendumModalContent = (
    <>
      <h4>Addendum</h4>
      <Input
        id="addAdemdumModal"
        label="Addendums are added to a patient's signed chart. This means that you will not be able to edit your addendum after it has been added."
        name="addAddendumModal"
        errorText={
          formState.addendumText.hasError
            ? formState.addendumText.errorMessage
            : undefined
        }
        fullWidth
        multiline
        onChange={(event) => {
          setData('addendumText', event.target.value);
        }}
        style={{ minHeight: `calc(${Tokens.rhythm} * 18)` }}
      />
    </>
  );

  const handleClick = async () => {
    const errors = getErrors();
    const data = getData<{ addendumText: string }>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
    }
    if (data?.addendumText) {
      try {
        await createAddendum({
          variables: {
            encounterId,
            note: data.addendumText,
            user: userContext?.user?.userId ?? 0,
          },
        });
        setToastMessage(
          'Successfully added addendum',
          UI.Toast.SeverityLevel.Success,
        );
      } catch (error) {
        Logger.error(error);
        setToastMessage(
          'An error occurred while adding the addendum',
          UI.Toast.SeverityLevel.Error,
        );
      }
      setModalState({
        selectedType: null,
        isOpen: false,
      });
    }
  };

  return (
    <UI.Modal
      body={addAddendumModalContent}
      closeButtonLabel="Cancel"
      onClose={() => {
        setModalState({
          selectedType: null,
          isOpen: false,
        });
      }}
      title={'Add an addendum'}
      size="small"
      open={modalState.isOpen}
      footerButtons={[
        {
          name: 'addAddendumButton',
          children: 'Add Addendum',
          variant: 'primary',
          size: 'small',
          onClick: () => handleClick(),
        },
      ]}
    />
  );
};

export default AddAddendumModal;
