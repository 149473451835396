import { TypePolicy } from '@apollo/client';

import ResponderAppointments from './ResponderAppointments';
import { TaskDrawer } from '../../reactiveVars';

const Query: TypePolicy = {
  fields: {
    ...ResponderAppointments,
    ...TaskDrawer.policy,
  },
};

export default Query;
