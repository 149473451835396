import { FieldValues } from 'react-hook-form';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  UpsertEncounterTaskInput,
  VitalLookupTypeEnum,
} from 'generated/graphql';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export const heightToInches = (
  heightStr = '',
  heightInchesStr = '',
): string => {
  const height = parseInt(heightStr) || 0;
  const inches = parseInt(heightInchesStr) || 0;
  const combinedHeight = height * 12 + inches;
  return combinedHeight !== 0 ? `${combinedHeight}` : '';
};

const getPatchValues = (
  data: FieldValues,
): Partial<UpsertEncounterTaskInput> => {
  const patch = {
    vital: [
      {
        vitalLookupType: VitalLookupTypeEnum.MEASURED_AT,
        result: data.measuredAt ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.BODY_POSITION,
        result: data.bodyPosition ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.SYSTOLIC_BLOOD_PRESSURE,
        result: data.systolicBloodPressure ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.DIASTOLIC_BLOOD_PRESSURE,
        result: data.diastolicBloodPressure ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.BLOOD_PRESSURE_LOCATION,
        result: data.bloodPressureLocation ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.HEART_RATE,
        result: data.heartRate ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.OXYGEN_SATURATION,
        result: data.oxygenSaturation ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.BODY_TEMPERATURE,
        result: data.bodyTemperature ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.RESPIRATORY_RATE,
        result: data.respiratoryRate ?? '',
      },
      {
        vitalLookupType: VitalLookupTypeEnum.NOTE,
        result: data.note ?? '',
      },
    ],
  };

  if (data.isFirstSet) {
    if (data.measuredHeight === undefined) {
      patch.vital.push(
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT_MEASURED,
          result: '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT_STATED,
          result: '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT,
          result: heightToInches(data.height, data.heightInches),
        },
      );
    } else {
      patch.vital.push(
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT_MEASURED,
          result: data.measuredHeight
            ? heightToInches(data.height, data.heightInches)
            : '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT_STATED,
          result: !data.measuredHeight
            ? heightToInches(data.height, data.heightInches)
            : '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_HEIGHT,
          result: '',
        },
      );
    }

    if (data.measuredWeight === undefined) {
      patch.vital.push(
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT_MEASURED,
          result: '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT_STATED,
          result: '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT,
          result: data.weight ?? '',
        },
      );
    } else {
      patch.vital.push(
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT_MEASURED,
          result: data.measuredWeight ? data.weight ?? '' : '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT_STATED,
          result: !data.measuredWeight ? data.weight ?? '' : '',
        },
        {
          vitalLookupType: VitalLookupTypeEnum.BODY_WEIGHT,
          result: '',
        },
      );
    } // Get stated vs. measured height values and add to patch
  } // If it's not the first set we don't record weight and height

  return patch;
};

export default getPatchValues;
