import { useContext } from 'react';

import { SidebarContext, Context } from 'providers/SidebarContextProvider';

const useSidebarContext = (): Readonly<Context> => {
  const sidebarContextState = useContext(SidebarContext);

  if (sidebarContextState === null) {
    throw new Error(
      'useSidebarContext must be a child of SidebarContextProvider',
    );
  }

  return sidebarContextState;
};

export default useSidebarContext;
