import styled from 'styled-components';
import UI from 'ui';
import { useState } from 'react';
import { Tokens } from 'config';
import { InfoSectionsProps } from '../../types';
import { StyledSection } from './StyledSection';
import EditAccounts from './EditAccounts/EditAccounts';

const StyledAccounts = styled(StyledSection)`
  margin-bottom: calc(${Tokens.rhythm} * 2);
  .fieldValuePass {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .fieldValueButton {
    margin-left: calc(${Tokens.rhythm} * 3);
  }
`;

const Accounts = ({ patient }: InfoSectionsProps): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false);
  const [showTeladocPass, setShowTeladocPass] = useState(false);

  return !isEditable ? (
    <StyledAccounts>
      <div className="heading">
        <p>Accounts</p>
        <UI.Button
          variant="link"
          as="button"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </UI.Button>
      </div>
      <div className="body">
        <p>
          <span className="fieldName">Teladoc Login:</span>
          <span className="fieldValue">{patient?.teladocUser ?? '-'}</span>
        </p>
        <p>
          <span className="fieldName">Teladoc Password:</span>
          {!patient?.teladocPass && '-'}
          {patient?.teladocPass &&
            (!showTeladocPass ? (
              <>
                <span className="fieldValuePass">
                  {patient?.teladocPass.replace(/./g, '*') ?? '-'}
                </span>
              </>
            ) : (
              <span className="fieldValuePass">
                {patient?.teladocPass ?? '-'}
              </span>
            ))}
          {patient?.teladocPass && (
            <span className="fieldValueButton">
              {
                <UI.Button
                  variant="secondary"
                  as="button"
                  onClick={() => setShowTeladocPass(!showTeladocPass)}
                >
                  {showTeladocPass ? 'hide' : 'view'}
                </UI.Button>
              }
            </span>
          )}
        </p>
        <p>
          <span className="fieldName">Patient ID:</span>
          <span className="fieldValue">
            {patient?.humanizedGlobalHumanId ?? '-'}
          </span>
        </p>
      </div>
    </StyledAccounts>
  ) : (
    <EditAccounts patient={patient} setIsEditable={setIsEditable} />
  );
};

export default Accounts;
