import { useAuth0 } from '@auth0/auth0-react';
import mime from 'mime-types';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Config } from 'config';
import { Logger } from 'utils';
import { getMD5Hash, createLocalUrl } from './utils';

type Props = {
  /**
   * The file's URL to fetch from the server
   */
  fileUrl?: string | null;

  /**
   * The folder to use in the file upload
   */
  folder: string;
};

type ReturnValues = {
  /**
   * Clears the failed upload state
   * @throws Will throw an error if not in a failed state
   */
  clearFailedUpload: () => void;

  /**
   * Download the file
   * @throws Will throw an error if file URL is not set
   */
  downloadFile: () => void;

  /**
   * If `true`, the download failed.
   */
  downloadFailed: boolean;

  /**
   * If `true`, the file failed to upload
   */
  uploadFailed: boolean;

  /**
   * The downloaded file
   */
  file: Blob | null;

  /**
   * The file URL
   */
  fileUrl: string | null;

  isDeleted: boolean;

  /**
   * If `true`, the file is downloading
   */
  isDownloading: boolean;

  /**
   * If `true`, the file is uploading
   */
  isUploading: boolean;

  /**
   * The in-memory URL
   */
  localUrl: string | null;

  /**
   * Removes the file
   * @throws Will throw an error if the file does not exist
   */
  removeFile: () => void;

  /**
   * Retry the upload if the file failed to upload
   * @throws Will throw an error if not in a failed state
   */
  retryUpload: () => void;

  /**
   * Upload the file
   * @param {File} file - A file to upload to the server
   */
  uploadFile: (file: File) => Promise<{ success: boolean; url?: string }>;
};

/**
 * Provides functions and stateful objects for authenticated file uploads and downloads
 * to Amazon S3 via Ready's media service. Designed for use with the `<ImageInput />`
 * component.
 * @param {string} bucketName - The Amazon S3 bucket name
 * @param {fileUrl|null} [fileUrl] - The URL of the file to download from the server
 * @param {folder} folder - A folder name used in the upload URL
 */
const useFileUpload = ({
  fileUrl: initialFileUrl = null,
  folder,
}: Props): ReturnValues => {
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(initialFileUrl);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [localUrl, setObjectUrl] = useState<string | null>(null);
  const [uploadFailed, setUploadFailed] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  // Internal state
  const [failedFile, setFailedFile] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      const url = createLocalUrl(file);
      setObjectUrl(url);
    }
  }, [file, setObjectUrl]);

  const downloadFile = async () => {
    if (!fileUrl) {
      throw new Error('Cannot download a file without setting the `fileUrl`');
    }

    setIsDownloading(true);
    setDownloadFailed(false);

    try {
      const accessToken = await getAccessTokenSilently({ ignoreCache: true });
      const resp = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (resp.ok) {
        const data = await resp.blob();
        setFile(data);
        return;
      }

      setDownloadFailed(true);

      // On fetch failure
      Logger.error(
        `An error occurred while retrieving a file. Response Status: ${
          resp.status
        } ${resp.statusText}. Response Body: ${resp.body?.toString()}`,
      );
    } catch (err) {
      setDownloadFailed(true);
      Logger.error(err);
    } finally {
      setIsDownloading(false);
    }
  };

  const uploadFile = async (
    originalFile: File,
  ): Promise<{ success: boolean; url?: string }> => {
    setIsUploading(true);

    const getFileUrl = async (): Promise<string> => {
      const baseUrl = Config.fileUploadUrl;
      const bucket = Config.fileUploadBucket;
      const filename = uuid();
      const extension = mime.extension(originalFile.type);
      const url = `${baseUrl}/${bucket}/${folder}/${filename}.${extension}`;

      if (Config.fileUploadRequireMd5) {
        const hash = await getMD5Hash(originalFile);
        return `${url}?md5=${encodeURIComponent(hash)}`;
      }

      return url;
    };

    try {
      const accessToken = await getAccessTokenSilently({ ignoreCache: true });
      const url = await getFileUrl();

      const resp = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': originalFile.type,
          'Content-Length': originalFile.size.toString(),
        },
        body: originalFile,
      });

      if (resp.ok) {
        // The media upload service currently returns a 200 response
        // even if the upload fails on S3. If the upload is successful,
        // an empty response body is returned. Otherwise, an XML error
        // message will be returned which we'll throw and log to Sentry.
        const contentLength = resp.headers.get('Content-Length');
        if (contentLength && `${contentLength}` !== '0') {
          const body = await resp.text();
          throw new Error(body);
        }

        setFileUrl(url);
        setFile(originalFile);

        return {
          success: true,
          url,
        };
      }

      // On upload failure
      setUploadFailed(true);
      setFailedFile(originalFile);

      Logger.error(
        `An error occurred while uploading a file. Response Status: ${
          resp.status
        } ${
          resp.statusText
        }. Response Body: ${resp.body?.toString()}. File Size: ${
          originalFile.size
        } bytes.`,
      );
    } catch (err) {
      Logger.error(err);
      setUploadFailed(true);
      setFailedFile(originalFile);
    } finally {
      setIsUploading(false);
    }

    return {
      success: false,
    };
  };

  const removeFile = () => {
    if (!file && !fileUrl) {
      throw new Error('Cannot remove a file when it does not exist yet');
    }
    setFile(null);
    setFileUrl(null);
  };

  const retryUpload = async () => {
    if (!uploadFailed) {
      throw new Error('Cannot retry before a failed call to `uploadFile`');
    }
    setUploadFailed(false);
    await uploadFile(failedFile!);
  };

  const clearFailedUpload = () => {
    if (!uploadFailed) {
      throw new Error(
        'Cannot clear failed before a failed call to `uploadFile`',
      );
    }
    setUploadFailed(false);
    setFailedFile(null);
  };

  return {
    clearFailedUpload,
    downloadFailed,
    downloadFile,
    uploadFailed,
    file,
    fileUrl,
    isDeleted: false,
    isDownloading,
    isUploading,
    localUrl,
    removeFile,
    retryUpload,
    uploadFile,
  };
};

export default useFileUpload;
