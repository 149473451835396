import * as yup from 'yup';

import {
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
  ReviewOfSystemEvaluationPayload,
  UpsertEncounterTaskInput,
  ReviewOfSystemSystemEnum,
  ReviewOfSystemTemplateEnum,
} from 'generated/graphql';

import { evaluations as evaluationsFromTemplate } from './evaluations';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TEvaluation = Partial<ReviewOfSystemEvaluationPayload>;

export type TSystem = {
  system?: ReviewOfSystemSystemEnum;
  note?: string | null;
  evaluations?: Partial<ReviewOfSystemEvaluationPayload>[];
};

export type TInputs = {
  chiefComplaint?: Maybe<string>;
  historyOfPresentIllness?: Maybe<string>;
  reviewChangesCounter?: number;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  chiefComplaint: yup.string().required('Please enter the chief complaint'),
  historyOfPresentIllness: yup
    .string()
    .required('Please enter the history of present illness'),
  reviewChangesCounter: yup.number(),
});

export const getDefaultValues = (task: TTask): TInputs => ({
  chiefComplaint: task?.chiefComplaintAndHpi?.chiefComplaint ?? undefined,
  historyOfPresentIllness:
    task?.chiefComplaintAndHpi?.historyOfPresentIllness ?? undefined,
  reviewChangesCounter: 0,
});

export const getSystemsOnFile = (): TSystem[] => {
  const systems: TSystem[] = [];

  // Pull systems and evaluations from file and iterate
  evaluationsFromTemplate.forEach((evaluation) => {
    const foundSystem = systems.find(
      (system) => system.system === evaluation.system,
    );

    // Build an array of all systems
    if (!foundSystem) {
      systems.push({
        system: evaluation.system,
        note: undefined,
        evaluations: [],
      });
    }

    // Push all evaluations onto the appropriate system
    systems
      ?.find((system) => system.system === evaluation.system)
      ?.evaluations?.push({
        key: evaluation.evaluation,
        value: undefined,
        // covid19: evaluation.covid19,
      });
  });

  // Sort and return all systems with values
  return (
    systems.sort(({ system: systemA }, { system: systemB }) => {
      if (systemA && systemB) {
        return systemA.localeCompare(systemB);
      } else {
        return 1;
      }
    }) ?? []
  );
};

export const getSystemValuesFromTask = (task: TTask): TSystem[] => {
  const copyOfSystemsOnFile = [...getSystemsOnFile()];
  const review = task?.reviewOfSystems?.[0];

  review?.systems?.forEach((reviewSystem) => {
    // Find the index of the system the evaluation is in
    const foundSystemIndex = copyOfSystemsOnFile?.findIndex(
      (system) => system.system === reviewSystem?.system,
    );
    // Create a pointer to the found system
    const foundSystem = copyOfSystemsOnFile[foundSystemIndex];

    // Set the incoming note on the found system
    if (foundSystem) {
      foundSystem.note = reviewSystem?.note;
    }

    reviewSystem?.evaluations.forEach((reviewEvaluation) => {
      // Find the index of the evaluation itself
      const foundEvaluationIndex = foundSystem?.evaluations?.findIndex(
        (evaluation) => evaluation.key === reviewEvaluation?.key,
      );
      // Create a pointer to the found evaluation
      const foundEvaluation = foundSystem?.evaluations?.[foundEvaluationIndex!];

      // Set the incoming evaluation on the found evaluation
      if (
        foundSystem &&
        foundEvaluation &&
        typeof foundEvaluationIndex !== 'undefined'
      ) {
        foundEvaluation.value = reviewEvaluation?.value;
      }
    });
  });

  return copyOfSystemsOnFile;
};

export const getPatch = (
  formData: TInputs,
  reviewOfSystems: TSystem[],
): Partial<UpsertEncounterTaskInput> => {
  const { chiefComplaint, historyOfPresentIllness } = formData;

  const patch: Partial<UpsertEncounterTaskInput> = {};

  const reviewOfSystemsPatch =
    reviewOfSystems?.map((system) => ({
      system: system.system!,
      note: system.note,
      evaluations:
        system.evaluations
          ?.filter((evaluation) => typeof evaluation.value === 'boolean')
          .map((evaluation) => {
            // Don't include evaluation if we don't have a value
            // Return only evaluation fields necessary for input
            return {
              key: evaluation.key!,
              value: evaluation.value ?? null!,
            };
          }) ?? [],
    })) ?? [];

  patch.chiefComplaintAndHpi = {
    chiefComplaint: chiefComplaint ?? null,
    historyOfPresentIllness: historyOfPresentIllness ?? null,
    reviewOfSystems: [
      {
        systems: reviewOfSystemsPatch,
        template: ReviewOfSystemTemplateEnum.ALL,
      },
    ],
  };

  return patch;
};
