import styled from 'styled-components';
import { Tokens } from 'config';

const StyledEncounterSearchView = styled.div`
  background-color: ${Tokens.color.neutral.white.base};

  .header {
    background-color: ${Tokens.color.ui.steel.base};
    position: sticky;
    top: 0;
    z-index: ${Tokens.zIndex.header};

    h1 {
      border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.heading[1]};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin: 0;
      padding: calc(${Tokens.rhythm} * 2);
    }

    .searchInputs {
      display: flex;
      padding: calc(${Tokens.rhythm} * 2);
      padding-bottom: ${Tokens.rhythm};

      .typeAheadWrapper {
        width: 419px;
        margin-right: ${Tokens.rhythm};
      }

      .datepickerWrapper {
        width: 140px;
        margin-left: ${Tokens.rhythm};
      }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      padding: 0 calc(${Tokens.rhythm} * 2);
      padding-bottom: ${Tokens.rhythm};

      .filterPill {
        margin-right: ${Tokens.rhythm};
        margin-bottom: ${Tokens.rhythm};
      }
    }
  }

  .tableContainer {
    overflow-x: scroll;
    margin: 0 calc(${Tokens.rhythm} * 2);
  }

  .footerPadding {
    height: 80px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: 115px; /* 80 + 35px */
    } /* iPad */
  }
`;

export default StyledEncounterSearchView;
