import { IconContext } from 'phosphor-react';

import { Tokens } from 'config';

type Props = {
  children: React.ReactNode;
};

const IconProvider = ({ children }: Readonly<Props>): JSX.Element => (
  <IconContext.Provider
    value={{
      color: Tokens.color.ui.slate.base,
      size: Tokens.icon.size.small,
      weight: 'bold',
      mirrored: false,
    }}
  >
    {children}
  </IconContext.Provider>
);

export default IconProvider;
