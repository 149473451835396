import {
  VitalsLookupFieldsFragment,
  VitalLookupTypeEnum,
} from 'generated/graphql';
import { TInputs } from './schema';

type HeightReturnValues = {
  height: string;
  heightInches: string;
  measuredHeight: boolean | undefined;
};

type WeightReturnValues = {
  weight: string;
  measuredWeight: boolean | undefined;
};

export const inchesToHeight = (
  inchesStr: string,
): { height: string; heightInches: string } => {
  const inches = parseInt(inchesStr);
  const height = Math.floor(inches / 12);
  const heightInches = inches % 12;
  return {
    height: isNaN(height) ? '' : `${height}`,
    heightInches: isNaN(heightInches) ? '' : `${heightInches}`,
  };
};

export const getHeightValues = (
  heightVitalsList: VitalsLookupFieldsFragment[],
): HeightReturnValues => {
  const isMeasured = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_HEIGHT_MEASURED &&
    value.result;

  const isStated = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_HEIGHT_STATED &&
    value.result;

  const isDeprecated = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_HEIGHT &&
    value.result;

  let vital;
  if ((vital = heightVitalsList.find(isMeasured))) {
    return {
      ...inchesToHeight(vital.result),
      measuredHeight: true,
    };
  }
  if ((vital = heightVitalsList.find(isStated))) {
    return {
      ...inchesToHeight(vital.result),
      measuredHeight: false,
    };
  }
  if ((vital = heightVitalsList.find(isDeprecated))) {
    return {
      ...inchesToHeight(vital.result),
      measuredHeight: undefined,
    };
  }

  return {
    height: '',
    heightInches: '',
    measuredHeight: undefined,
  };
};

export const getWeightValues = (
  weightVitalsList: VitalsLookupFieldsFragment[],
): WeightReturnValues => {
  const isMeasured = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_WEIGHT_MEASURED &&
    value.result;

  const isStated = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_WEIGHT_STATED &&
    value.result;

  const isDeprecated = (value: VitalsLookupFieldsFragment) =>
    value.vitalLookup?.vitalType === VitalLookupTypeEnum.BODY_WEIGHT &&
    value.result;

  let vital;

  if ((vital = weightVitalsList.find(isMeasured))) {
    return {
      weight: vital.result,
      measuredWeight: true,
    };
  }

  if ((vital = weightVitalsList.find(isStated))) {
    return {
      weight: vital.result,
      measuredWeight: false,
    };
  }

  if ((vital = weightVitalsList.find(isDeprecated))) {
    return {
      weight: vital.result,
      measuredWeight: undefined,
    };
  }

  return {
    weight: '',
    measuredWeight: undefined,
  };
};

const isHeightVital = (value: VitalsLookupFieldsFragment) =>
  value?.vitalLookup?.vitalType &&
  [
    VitalLookupTypeEnum.BODY_HEIGHT_MEASURED,
    VitalLookupTypeEnum.BODY_HEIGHT_STATED,
    VitalLookupTypeEnum.BODY_HEIGHT,
  ].includes(value?.vitalLookup?.vitalType);

const isWeightVital = (value: VitalsLookupFieldsFragment) =>
  value?.vitalLookup?.vitalType &&
  [
    VitalLookupTypeEnum.BODY_WEIGHT_MEASURED,
    VitalLookupTypeEnum.BODY_WEIGHT_STATED,
    VitalLookupTypeEnum.BODY_WEIGHT,
  ].includes(value?.vitalLookup?.vitalType);

const getDefaultValues = (
  vitalsList: VitalsLookupFieldsFragment[],
  isFirstSet: boolean,
): TInputs => {
  const defaultValues: TInputs = {
    isFirstSet,
    height: '',
    heightInches: '',
    measuredHeight: undefined,
    weight: '',
    measuredWeight: undefined,
    measuredAt: '',
    bodyPosition: '',
    systolicBloodPressure: '',
    diastolicBloodPressure: '',
    bloodPressureLocation: '',
    heartRate: '',
    oxygenSaturation: '',
    bodyTemperature: '',
    respiratoryRate: '',
    note: '',
  };

  const heightVitalsList: VitalsLookupFieldsFragment[] = [];
  const weightVitalsList: VitalsLookupFieldsFragment[] = [];

  vitalsList?.forEach((vital) => {
    if (isHeightVital(vital)) {
      heightVitalsList.push(vital);
      return;
    }

    if (isWeightVital(vital)) {
      weightVitalsList.push(vital);
      return;
    }

    switch (vital.vitalLookup?.vitalType) {
      case VitalLookupTypeEnum.MEASURED_AT:
        defaultValues.measuredAt = vital.result;
        return;

      case VitalLookupTypeEnum.BODY_POSITION:
        defaultValues.bodyPosition = vital.result;
        return;

      case VitalLookupTypeEnum.SYSTOLIC_BLOOD_PRESSURE:
        defaultValues.systolicBloodPressure = vital.result;
        return;

      case VitalLookupTypeEnum.DIASTOLIC_BLOOD_PRESSURE:
        defaultValues.diastolicBloodPressure = vital.result;
        return;

      case VitalLookupTypeEnum.BLOOD_PRESSURE_LOCATION:
        defaultValues.bloodPressureLocation = vital.result;
        return;

      case VitalLookupTypeEnum.HEART_RATE:
        defaultValues.heartRate = vital.result;
        return;

      case VitalLookupTypeEnum.OXYGEN_SATURATION:
        defaultValues.oxygenSaturation = vital.result;
        return;

      case VitalLookupTypeEnum.BODY_TEMPERATURE:
        defaultValues.bodyTemperature = vital.result;
        return;

      case VitalLookupTypeEnum.RESPIRATORY_RATE:
        defaultValues.respiratoryRate = vital.result;
        return;

      case VitalLookupTypeEnum.NOTE:
        defaultValues.note = vital.result;
        return;
    }
  });

  const { height, heightInches, measuredHeight } =
    getHeightValues(heightVitalsList);
  const { weight, measuredWeight } = getWeightValues(weightVitalsList);

  return {
    ...defaultValues,
    height,
    heightInches,
    measuredHeight,
    weight,
    measuredWeight,
  };
};

export default getDefaultValues;
