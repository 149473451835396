import { Control, useWatch } from 'react-hook-form';

import UI from 'ui';
import { TInputs } from '../utils';
import AllergyTableRow from './AllergyTableRow';

type Props = {
  control: Control<TInputs>;
  handleDeleteAllergy: (index: number) => void;
  inputsAreDisabled: boolean;
};

const AllergyTable = ({
  control,
  handleDeleteAllergy,
  inputsAreDisabled,
}: Props): JSX.Element => {
  const allergies = useWatch({ name: 'allergies', control });

  return (
    <UI.Table fullWidth textwrap>
      <UI.TableHead>
        <UI.TableRow>
          <UI.TableCell>Non-Drug Allergy</UI.TableCell>
          <UI.TableCell>Reaction</UI.TableCell>
          <UI.TableCell></UI.TableCell>
        </UI.TableRow>
      </UI.TableHead>
      <UI.TableBody>
        {allergies.map((allergy, index) => (
          <AllergyTableRow
            key={allergy.allergy.allergyId}
            allergyName={allergy.allergy.name}
            inputsAreDisabled={inputsAreDisabled}
            reactionName={
              allergy.allergicReaction ? allergy.allergicReaction.name : '-'
            }
            onDelete={() => {
              handleDeleteAllergy(index);
            }}
          />
        ))}
      </UI.TableBody>
    </UI.Table>
  );
};

export default AllergyTable;
