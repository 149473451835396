import { useState } from 'react';
import UI from 'ui';
import { InfoSectionsProps } from '../../types';
import EditAddress from './EditAddress';
import { StyledSection } from './StyledSection';

const Address = ({ patient }: InfoSectionsProps): JSX.Element => {
  const [isEditable, setIsEditable] = useState(false);

  return !isEditable ? (
    <StyledSection>
      <div className="heading">
        <p>Address</p>
        <UI.Button
          variant="link"
          as="button"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </UI.Button>
      </div>

      <div className="body">
        <p>
          <span className="fieldName">Home Address:</span>
          {patient.address1 ? (
            <span className="fieldValue">
              {patient?.addressLine1}
              <br />
              {patient?.addressLine2}
              {patient.specialInstructions ? (
                <span>
                  <br />
                  --
                  <br />
                  {patient?.specialInstructions}
                </span>
              ) : null}
            </span>
          ) : (
            '-'
          )}
        </p>
      </div>
    </StyledSection>
  ) : (
    <EditAddress patient={patient} setIsEditable={setIsEditable} />
  );
};
export default Address;
