import { X } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';

import { Controlled } from 'forms';
import { useGetCPTCodesBySearchTermLazyQuery } from 'generated/graphql';
import UI from 'ui';
import { Option } from 'ui/TypeAhead/TypeAhead';
import { FormInputs, CPTCodeInput } from './schema';

type Props = {
  appointmentDate: string;
  control: Control<FormInputs>;
  cptCodes: CPTCodeInput[];
  disabled?: boolean;
  index: number;
  removeItem: (index: number) => void;
};

const CPTCodeTypeAhead = ({
  appointmentDate,
  control,
  cptCodes,
  disabled,
  index,
  removeItem,
}: Props): JSX.Element => {
  const [options, setOptions] = useState<Option[]>([]);
  const [getCPTCodes, { data, loading }] = useGetCPTCodesBySearchTermLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleInputChange = (searchTerm?: string) => {
    if (!searchTerm) return;
    setOptions([]);
    getCPTCodes({ variables: { activeDate: appointmentDate, searchTerm } });
  };

  const codes = (cptCodes ?? []).map((cptCode) => cptCode?.code);

  useEffect(() => {
    if (data) {
      setOptions(
        (data.searchCPTCodes ?? [])
          .filter((cptCode) => !codes.includes(cptCode.code))
          .map((cptCode) => ({
            label: `${cptCode.code} - ${cptCode.name}`,
            value: cptCode,
          })),
      );
    }
  }, [data]);

  const transformInput = (value: CPTCodeInput) =>
    value?.procedureCodeReferenceId ? `${value.code} - ${value.name}` : '';

  return (
    <div className="cptTypeAheadWrapper">
      <Controlled.TypeAhead
        name={`cptCodes.${index}`}
        control={control}
        label="CPT or CPT Category II Code"
        debounceRate={2}
        defaultValue={null}
        disabled={disabled}
        loading={loading}
        options={options}
        onInputChange={handleInputChange}
        threshold={1}
        transformInput={transformInput}
        className="cptTypeAhead"
      />
      {index > 0 && !disabled && (
        <UI.Button
          size="icon"
          onClick={() => removeItem(index)}
          variant="tertiary"
        >
          <X />
        </UI.Button>
      )}
    </div>
  );
};

export default CPTCodeTypeAhead;
