import { forwardRef, useState } from 'react';
import { CheckSquare, Square } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens } from 'config';
import { useDidMountEffect } from 'hooks';

export type CheckboxProps = {
  /**
   * The class name
   */
  className?: string;
  /**
   * The checked state
   */
  checked?: boolean;
  /**
   * If `true`, the checkbox is disabled
   */
  disabled?: boolean;
  /**
   * ID attribute
   */
  id?: string;
  /**
   * The checkbox's label
   */
  label?: string;
  /**
   * The form field name
   */
  name?: string;
  /**
   * onChange event callback
   */
  onChange: (arg: boolean) => void | Promise<void> | NodeJS.Timeout | undefined;
};

const StyledCheckbox = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  position: relative;
  align-items: center;

  input {
    position: absolute;
    width: ${Tokens.icon.size.base};
    height: ${Tokens.icon.size.base};
    opacity: 0;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .label {
    margin-left: ${Tokens.rhythm};
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: ${Tokens.lineHeight.paragraph.base};
    color: ${({ disabled }) =>
      disabled
        ? Tokens.color.ui.charcoal.transparent[50]
        : Tokens.color.ui.charcoal.base};
  }

  rect + rect {
    stroke-width: 11;
  }
`;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      checked: initialChecked = false,
      disabled = false,
      label,
      id,
      name,
      onChange,
    },
    inputRef,
  ): JSX.Element => {
    const [checked, setChecked] = useState(initialChecked);

    useDidMountEffect(() => {
      onChange(checked);
    }, [checked]);

    const handleClick = () => {
      if (!disabled) {
        setChecked((prev) => !prev);
      }
    };

    return (
      <StyledCheckbox
        onClick={handleClick}
        disabled={disabled}
        className={className}
      >
        <input
          id={id}
          ref={inputRef}
          name={name}
          type="checkbox"
          onChange={(event) => setChecked(event.target.checked)}
          checked={checked}
          disabled={disabled}
        />
        {checked ? (
          <CheckSquare
            size={Tokens.icon.size.base}
            color={
              disabled
                ? Tokens.color.ui.charcoal.transparent[50]
                : Tokens.color.ui.charcoal.base
            }
            weight="fill"
            id={id}
          />
        ) : (
          <Square
            size={Tokens.icon.size.base}
            color={
              disabled
                ? Tokens.color.ui.charcoal.transparent[50]
                : Tokens.color.ui.charcoal.base
            }
            weight="regular"
          />
        )}
        {label && <span className="label">{label}</span>}
      </StyledCheckbox>
    );
  },
);

export default Checkbox;
