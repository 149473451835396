import styled from 'styled-components';

import { Tokens } from 'config';

const StyledTaskForm = styled.form`
  padding: calc(${Tokens.rhythm} * 2) calc(${Tokens.rhythm} * 2) 0;
  height: calc(
    100vh - 214px
  ); // Subtract height of nav header and patient header
  overflow: scroll;
  --webkit-overflow-scrolling: touch;

  header {
    padding-bottom: ${Tokens.rhythm};

    & > h1 {
      font-size: ${Tokens.font.size.heading[1]};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin-bottom: calc(${Tokens.rhythm} * 4);
    }

    & > h3 {
      font-size: ${Tokens.font.size.heading[1]};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.heading[1]};
      margin-bottom: calc(${Tokens.rhythm} * 4);
    }

    p {
      font-size: ${Tokens.font.size.paragraph.base};
      line-height: ${Tokens.lineHeight.paragraph.base};
      margin: ${Tokens.rhythm} 0;
      color: ${Tokens.color.ui.charcoal.base};
    }

    .formGeneralNote {
      font-size: ${Tokens.font.size.paragraph.base};
      margin-bottom: calc(${Tokens.rhythm} * 5);
    }

    .formInstructionsLabel {
      margin-bottom: 0;
    }
  }

  .formInstructionsLabel {
    font-size: ${Tokens.font.size.paragraph.base};
    font-weight: ${Tokens.font.weight.semiBold};
    line-height: ${Tokens.lineHeight.paragraph.small};
    margin-bottom: ${Tokens.rhythm};
  }

  .formStep {
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: ${Tokens.lineHeight.paragraph.base};
    padding-bottom: ${Tokens.rhythm};
    font-weight: ${Tokens.font.weight.bold};
    border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  }

  section {
    border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};
    padding: calc(${Tokens.rhythm} * 2) 0;

    .addbutton {
      margin-top: calc(${Tokens.rhythm} * 2);
    }

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;

      legend {
        font-size: ${Tokens.font.size.heading[2]};
        font-weight: ${Tokens.font.weight.normal};
        line-height: ${Tokens.lineHeight.heading[2]};
      }
    }

    .fieldGroup {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      & > * {
        width: calc(50% - ${Tokens.rhythm});
      }

      &.padTop {
        padding-top: calc(${Tokens.rhythm} * 2);
      }
    }

    div + .fieldGroup {
      padding-top: calc(${Tokens.rhythm} * 2);
    }

    & > label,
    & > * > label,
    & > * .react-datepicker-wrapper label {
      margin-top: calc(${Tokens.rhythm} * 2);
    }

    .embellishment {
      align-items: flex-end;
      display: flex;
      height: calc(${Tokens.rhythm} * 8);

      span {
        padding-bottom: calc(${Tokens.rhythm} * 1.15);
      }
    }
  }

  & > *:last-child::after {
    content: '';
    display: block;
    height: calc(${Tokens.rhythm} * 30); // 240px
    width: 100%;
  }
`;

export default StyledTaskForm;
