import { Plus } from 'phosphor-react';
import { useRef, useState } from 'react';
import { useFieldArray, Control } from 'react-hook-form';
import shortid from 'shortid';
import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { StyledTaskForm } from '../TaskForm';
import CPTCodeTypeAhead from './CPTCodeTypeAhead';
import { FormInputs } from './schema';

type Props = {
  appointmentDate: string;
  control: Control<FormInputs>;
  formState: FormInputs;
  initialKeys: string[];
  inputsAreDisabled: boolean;
  taskHeaderProps: TaskHeaderProps;
};

const StyledTrademark = styled.span`
  font-weight: normal;
`;

const StyledClinicianBillingForm = styled(StyledTaskForm)`
  .fields {
    margin-bottom: calc(${Tokens.rhythm} * 4);

    .formStep {
      margin-bottom: 0;
    }

    .field {
      padding: calc(${Tokens.rhythm} * 2) 0;
      border-bottom: 1px solid ${Tokens.color.neutral.black.transparent[10]};
    }
  }

  .disclaimer {
    font-size: ${Tokens.font.size.paragraph.small};
    line-height: ${Tokens.lineHeight.paragraph.small};

    a {
      color: ${Tokens.color.ui.link.base};
      cursor: pointer;
      font-family: ${Tokens.font.family};
      text-decoration: none;
    }
  }

  .cptTypeAheadWrapper {
    display: flex;

    button {
      margin-left: calc(${Tokens.rhythm} * 0.5);
      margin-top: calc(${Tokens.rhythm} * 3.5);
    }

    .cptTypeAhead {
      flex-grow: 1;
    }
  }
`;

const ClinicianBillingForm = ({
  appointmentDate,
  control,
  formState,
  initialKeys,
  inputsAreDisabled,
  taskHeaderProps,
}: Props): JSX.Element => {
  const { task } = taskHeaderProps;
  const keysRef = useRef<string[]>(initialKeys);
  const [modalOpen, setModalOpen] = useState(false);

  const modalTitle = (
    <span>
      CPT
      <StyledTrademark>®</StyledTrademark> copyright and trademark notice
    </span>
  );

  const modalBody = (
    <span>
      CPT copyright 2020 American Medical Association. All rights reserved.{' '}
      <br />
      <br />
      Fee schedules, relative value units, conversion factors and/or related
      components are not assigned by the AMA, are not part of CPT, and the AMA
      is not recommending their use. The AMA does not directly or indirectly
      practice medicine or dispense medical services. The AMA assumes no
      liability for data contained or not contained herein.
      <br />
      <br />
      CPT is a registered trademark of the American Medical Association.
    </span>
  );

  const { cptCodes } = formState;

  const {
    fields: cptCodeFields,
    remove: removeCPTCode,
    append: appendCPTCode,
  } = useFieldArray({
    control,
    name: 'cptCodes',
  });

  const deleteCPTCode = (index: number) => {
    keysRef.current = keysRef.current.filter((item, idx) => index !== idx);
    removeCPTCode(index);
  };

  const addCPTCode = () => {
    keysRef.current = [...keysRef.current, shortid.generate()];
    appendCPTCode(null);
  };

  const isAddButtonDisabled = () => {
    if ((cptCodes ?? []).length > 0) {
      const lastItem = cptCodes[cptCodes.length - 1];
      return lastItem === null || !lastItem?.procedureCodeReferenceId;
    }
    return true;
  };

  return (
    <>
      <TaskHeader {...taskHeaderProps} />

      <StyledClinicianBillingForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>{task?.taskName}</h3>
          <p>All fields below, unless otherwise noted, are required.</p>
        </header>
        <div className="fields">
          <p className="formStep">
            1. Enter CPT<StyledTrademark>®</StyledTrademark> and CPT Category II
            Codes
          </p>
          <p className="disclaimer">
            CPT copyright 2020 American Medical Association. All rights
            reserved.{' '}
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setModalOpen(true);
              }}
            >
              View full copyright and trademark notice
            </a>
          </p>
          {cptCodeFields.map((cptCode, index) => (
            <div key={keysRef.current[index]} className="field">
              <CPTCodeTypeAhead
                appointmentDate={appointmentDate}
                control={control}
                cptCodes={cptCodes}
                index={index}
                disabled={inputsAreDisabled}
                removeItem={deleteCPTCode}
              />
            </div>
          ))}
        </div>
        <div>
          <UI.Button
            disabled={inputsAreDisabled || isAddButtonDisabled()}
            leftIcon={<Plus />}
            onClick={addCPTCode}
            variant="secondary"
          >
            Add CPT Code
          </UI.Button>
        </div>

        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}

        <UI.Modal
          body={modalBody}
          closeButtonLabel="Cancel"
          onClose={() => setModalOpen(false)}
          title={modalTitle}
          size="small"
          open={modalOpen}
          footerButtons={[
            {
              children: 'Okay',
              variant: 'primary',
              size: 'small',
              onClick: () => setModalOpen(false),
            },
          ]}
        />
      </StyledClinicianBillingForm>
    </>
  );
};

export default ClinicianBillingForm;
