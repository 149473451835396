import * as yup from 'yup';

const CPTCodeSchema = yup
  .object({
    procedureCodeReferenceId: yup
      .number()
      .required('Please enter a valid CPT code'),
    name: yup.string(),
    code: yup.string(),
  })
  .nullable()
  .when('$overrideRequired', (overrideRequired, schema) =>
    schema
      .nullable(overrideRequired)
      .typeError('Please enter a valid CPT code'),
  );

const FormSchema = yup.object({
  cptCodes: yup.array().of(CPTCodeSchema).required(),
});

export declare type CPTCodeInput = yup.InferType<typeof CPTCodeSchema>;
export declare type FormInputs = yup.InferType<typeof FormSchema>;

export default FormSchema;
