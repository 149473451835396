import { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { FieldValues } from 'react-hook-form';
import type { UseFormHandleSubmit } from 'forms/types';

import {
  SystemOfOriginEnum,
  UpsertEncounterTaskInput,
  GetEncounterTaskByEncounterTaskIdQuery,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';

import UI from 'ui';
import { Logger } from 'utils';
import { useTask, useToastMessage, useUserContext } from 'hooks';
import { TaskDrawer } from 'cache/reactiveVars';
import { FormFields } from './useForm';

type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

/**
 * getFormattedPatch will call a function with the current data returned
 * from formState on submit or save. This can be used in conjunction with
 * a more sophisticated function to merge other data structures necessary
 * to create the full patch. Refer to it's implementation in
 * useAllergiesForm.tsx for an example of sophisticated usage.
 *
 * @param {FormFields} data - Form state at time function is called
 * @returns {Partial<UpsertEncounterTaskInput> | undefined}
 */
type TGetFormattedPatch = (
  data: FieldValues,
) => Partial<UpsertEncounterTaskInput> | undefined;

/**
 * onPersistTask is called after the upsert mutation runs and it returns
 * the new task data in order to update the form state
 *
 * @param {TTask} task - Updated task
 * @returns {void}
 */
type TOnPersistTask = (task?: TTask) => void;

type TPersistTask = (options: {
  data?: FieldValues;
  workflow?: 'complete' | 'correct' | 'save' | 'skip';
  correctionReason?: string;
}) => void;

export type TUsePersistTaskOptions = {
  cancelAutosave?: boolean;
  formState?: FieldValues;
  isDirty?: boolean;
  task: TTask;
  handleSave?: UseFormHandleSubmit<FieldValues>;
  handleSubmit?: UseFormHandleSubmit<FieldValues>;
  getFormattedPatch?: TGetFormattedPatch;
  onPersistTask?: TOnPersistTask;
};

export type TUsePersistTaskReturn = {
  handleCompleteTask: () => void;
  handleCorrectTask: (reason: string) => void;
  handleSaveTask: () => void;
  handleSkipTask: () => void;
  persistTask: TPersistTask;
};

export type TUsePersistTask = (
  options: TUsePersistTaskOptions,
) => TUsePersistTaskReturn;

/**
 * This hook accepts methods that are exported from useForm,
 * form state and a patch function that is passed in to format
 * the mutation variables sent to upsertEncounterTask
 *
 * usePersistTask exports 4 functions that are meant to use in
 * each form's header to submit, save or skip the tast.
 *
 * @param {TUsePersistTaskOptions} options - Options object required for hook
 * @returns {TUsePersistTaskReturn}
 */
export const usePersistTask: TUsePersistTask = ({
  cancelAutosave = false,
  formState,
  isDirty = false,
  task,
  getFormattedPatch,
  handleSave,
  handleSubmit,
  onPersistTask,
}) => {
  const isDirtyRef = useRef(isDirty);
  const formStateRef = useRef(formState);
  const userContext = useUserContext();
  const { setToastMessage } = useToastMessage();
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { encounterIsComplete, taskIsComplete, taskIsSkipped } = useTask(task);

  const {
    isAllergiesTask,
    isChiefComplaintAndHpiTask,
    isClinicianBillingTask,
    isDispositionTask,
    isMedicalConditionTask,
    isMedicalDecisionMakingTask,
    isPatientFormTask,
    isPaymentMethodTask,
    isPhysicalExamTask,
    isPointOfCareTestTask,
    isProgressNoteTask,
    isTelehealthTask,
    isVaccineTask,
    isVitalTask,
  } = useReactiveVar(TaskDrawer.state);

  const persistTask: TPersistTask = async (options) => {
    if (!task?.encounterTaskId) {
      Logger.warn(`No task id exists`);
      return;
    }

    const input = {
      ...getFormattedPatch?.(options.data ?? {}),
      encounterTaskId: task?.encounterTaskId as number,
      inProgressAt: task?.inProgressAt ?? new Date(),
      systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
    };

    input.correctionReason =
      options.workflow === 'correct' ? options.correctionReason : null;

    const timestamp = options.workflow === 'complete' ? new Date() : null;

    if (userContext?.operatingAsClinician) {
      input.clinicianReviewedAt = timestamp;
    } else {
      input.responderCompletedAt = timestamp;
    }

    // Skipping is allowed in a Vaccine task if contraindication is present
    if (options.workflow === 'skip') {
      input.skippedReason = isVaccineTask ? 'Contraindication' : null;
    } else {
      input.skippedReason = null;
    }

    try {
      const resp = await upsertEncounterTask({
        variables: {
          input,
          isAllergiesTask,
          isChiefComplaintAndHpiTask,
          isClinicianBillingTask,
          isDispositionTask,
          isMedicalConditionTask,
          isMedicalDecisionMakingTask,
          isPatientFormTask,
          isPaymentMethodTask,
          isPhysicalExamTask,
          isPointOfCareTestTask,
          isProgressNoteTask,
          isTelehealthTask,
          isVaccineTask,
          isVitalTask,
        },
      });

      let action: 'updated' | 'corrected' | 'skipped' | 'completed';

      switch (options.workflow) {
        case 'complete':
          action = 'completed';
          break;
        case 'skip':
          action = 'skipped';
          break;
        case 'correct':
          action = 'corrected';
          break;
        default:
          action = 'updated';
      }

      setToastMessage(
        `Successfully ${action} task '${task?.taskName}'`,
        UI.Toast.SeverityLevel.Success,
      );

      onPersistTask?.(resp.data?.upsertEncounterTask?.encounterTask ?? null);
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `An error occurred while saving task '${task?.taskName}'`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  const handleCompleteTask = handleSubmit
    ? handleSubmit(async (data: FormFields) => {
        persistTask({ data, workflow: 'complete' });
      })
    : () => {};

  const handleSaveTask = handleSave
    ? handleSave(async (data: FormFields) => {
        persistTask({ data, workflow: 'save' });
      })
    : () => {};

  const handleSkipTask = handleSave
    ? handleSave(async (data: FormFields) => {
        persistTask({ data, workflow: 'skip' });
      })
    : () => {};

  const handleCorrectTask = (correctionReason: string) => {
    persistTask({
      data: formStateRef?.current,
      workflow: 'correct',
      correctionReason,
    });
  };

  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  useEffect(
    () => () => {
      const formCanAutosave =
        isDirtyRef.current &&
        !encounterIsComplete &&
        !taskIsComplete &&
        !taskIsSkipped;

      if (formCanAutosave && !cancelAutosave) {
        persistTask({ data: formStateRef.current });
      }
    },
    [],
  );

  return {
    handleCompleteTask,
    handleCorrectTask,
    handleSaveTask,
    handleSkipTask,
    persistTask,
  };
};

export default usePersistTask;
