import styled from 'styled-components';

import { Tokens } from 'config';
import { IconWrapperProps } from './types';

const IconWrapper = styled.div<IconWrapperProps>(
  ({ disabled, multiline, position }) => {
    const overrideVars = disabled
      ? `
    --color-override: ${Tokens.color.ui.charcoal.transparent[50]};
    --cursor-override: not-allowed;
  `
      : '';
    return `
    ${overrideVars}

    align-items: ${multiline ? 'baseline' : 'center'};
    color: var(--color-override, ${Tokens.color.ui.charcoal.base});
    cursor: default;
    display: inline-flex;
    font-size: ${Tokens.font.size.paragraph.large};
    right: ${position === 'right' ? `10.5px` : `auto`};
    left: ${position === 'left' ? `10.5px` : `auto`};
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  `;
  },
);

export default IconWrapper;
