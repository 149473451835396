import { useEffect, useRef, useState } from 'react';
import UI from 'ui';
import { Tokens } from 'config';
import {
  CheckCircle,
  Warning as WarningTriangle,
  WarningOctagon,
} from 'phosphor-react';
import { useToastMessage } from 'hooks';

export enum SeverityLevel {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type ToastProps = {
  severity?: SeverityLevel;
  message?: string;
  actionLabel?: string;
  onActionClick?: () => void;
  testId?: string;
};

const Toast = ({
  severity,
  message,
  actionLabel,
  onActionClick,
  testId,
}: ToastProps): JSX.Element | null => {
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [show, setShow] = useState(false);
  const [internalMessage, setInternalMessage] = useState(message);
  const [className, setClassName] = useState('show');
  const { clearToastMessage } = useToastMessage();

  const showToast = () => {
    clearTimeout(showTimeoutRef.current as NodeJS.Timeout);
    clearTimeout(hideTimeoutRef.current as NodeJS.Timeout);

    setShow(true);
    setClassName('show');

    // Hide automatically after 5 seconds
    showTimeoutRef.current = setTimeout(() => {
      clearToastMessage();
    }, 5000);
  };

  const hideToast = () => {
    clearTimeout(showTimeoutRef.current as NodeJS.Timeout);
    clearTimeout(hideTimeoutRef.current as NodeJS.Timeout);

    setClassName('hide');
    clearToastMessage();

    // Remove from DOM after animation fadeout
    hideTimeoutRef.current = setTimeout(() => {
      setShow(false);
      setInternalMessage(undefined);
    }, parseInt(Tokens.transition.duration[3].replace('ms', ''), 10));
  };

  useEffect(() => {
    if (message) {
      setInternalMessage(message);
      showToast();
    } else {
      hideToast();
    }

    // Cleanup running timers
    return () => {
      clearTimeout(showTimeoutRef.current as NodeJS.Timeout);
      clearTimeout(hideTimeoutRef.current as NodeJS.Timeout);
    };
  }, [message]);

  const iconProps = (() => {
    switch (severity) {
      case SeverityLevel.Error:
        return {
          icon: WarningOctagon,
          iconColor: Tokens.color.ui.error.base,
        };
      case SeverityLevel.Success:
        return {
          icon: CheckCircle,
          iconColor: Tokens.color.ui.success.base,
        };
      case SeverityLevel.Warning:
      default:
        return {
          icon: WarningTriangle,
          iconColor: Tokens.color.ui.warning.base,
        };
    }
  })();

  return show ? (
    <UI.ToastLayout
      testId={testId}
      className={className}
      onHide={hideToast}
      {...iconProps}
      message={internalMessage}
      actionLabel={actionLabel}
      onActionClick={onActionClick}
    />
  ) : null;
};

Toast.SeverityLevel = SeverityLevel;

export default Toast;
