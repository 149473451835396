import { Controlled } from 'forms';
import {
  EncounterTask,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader from '../TaskHeader';
import { StyledTaskForm } from '../TaskForm';
import {
  bodyPositionOptions,
  bloodPressureLocationOptions,
} from './utils/selectOptions';
import { useVitalsForm } from './hooks';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';

type Props = {
  task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>;
};

const VitalsForm = ({ task }: Props): JSX.Element => {
  const { getValues, control, inputsAreDisabled, isFirstSet, taskHeaderProps } =
    useVitalsForm(task);
  const kgConversionFactor = 0.45359237;
  const lbWeight: string = getValues().weight || '0';
  const kgWeight = Math.round(parseFloat(lbWeight) * kgConversionFactor);

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>Collect Vitals Set</h3>
          <p className="formGeneralNote">
            All fields below, unless otherwise noted, are required.
          </p>
          <p className="formInstructionsLabel">
            1. Collect and record all of the necessary vitals for the patient.
          </p>
        </header>

        <section>
          <fieldset>
            {isFirstSet ? (
              <>
                <div className="fieldGroup">
                  <div>
                    <Controlled.Input
                      control={control}
                      name="height"
                      label="Height"
                      rightLabel="ft"
                      inputMode="decimal"
                      disabled={inputsAreDisabled}
                      fullWidth
                    />
                  </div>

                  <div>
                    <Controlled.Input
                      control={control}
                      name="heightInches"
                      label="&nbsp;"
                      rightLabel="in"
                      inputMode="decimal"
                      disabled={inputsAreDisabled}
                      fullWidth
                    />
                  </div>
                </div>

                <Controlled.RadioButtonGroup
                  control={control}
                  name="measuredHeight"
                  disabled={inputsAreDisabled}
                  label="Is the patient’s height measured or patient-stated?"
                  options={['Measured', 'Patient-Stated']}
                  fullWidth
                />

                <div className="fieldGroup">
                  <div>
                    <Controlled.Input
                      control={control}
                      name="weight"
                      label="Weight"
                      rightLabel="lbs"
                      inputMode="decimal"
                      disabled={inputsAreDisabled}
                      fullWidth
                    />
                  </div>
                  <div className="embellishment">
                    {kgWeight > 0 ? <span>= {kgWeight} kgs</span> : null}
                  </div>
                </div>

                <Controlled.RadioButtonGroup
                  control={control}
                  name="measuredWeight"
                  disabled={inputsAreDisabled}
                  label="Is the patient’s weight measured or patient-stated?"
                  options={['Measured', 'Patient-Stated']}
                  fullWidth
                />
              </>
            ) : null}

            <Controlled.Datepicker
              control={control}
              name="measuredAt"
              label="Vitals Taken At"
              filterFutureDateAndTime
              fullWidth
              format="MM/dd/yyyy h:mm aa"
              showTime
              disabled={inputsAreDisabled}
            />

            <Controlled.Select
              control={control}
              name="bodyPosition"
              label="Body Position"
              options={bodyPositionOptions}
              disabled={inputsAreDisabled}
            />

            <Controlled.Input
              control={control}
              name="systolicBloodPressure"
              label="Systolic Blood Pressure"
              rightLabel="mmHg"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Input
              control={control}
              name="diastolicBloodPressure"
              label="Diastolic Blood Pressure"
              rightLabel="mmHg"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Select
              control={control}
              name="bloodPressureLocation"
              label="Blood Pressure Location"
              options={bloodPressureLocationOptions}
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
            />

            <Controlled.Input
              control={control}
              name="heartRate"
              label="Heart Rate"
              rightLabel="bpm"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Input
              control={control}
              name="oxygenSaturation"
              label="Oxygen Saturation"
              rightLabel="%"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Input
              control={control}
              name="bodyTemperature"
              label="Temperature"
              rightLabel="&deg;F"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Input
              control={control}
              name="respiratoryRate"
              label="Respiratory Rate"
              rightLabel="breath/min"
              inputMode="decimal"
              disabled={inputsAreDisabled}
              optional={!isFirstSet}
              fullWidth
            />

            <Controlled.Input
              control={control}
              name="note"
              label="Notes"
              disabled={inputsAreDisabled}
              fullWidth
              multiline
              optional
            />
          </fieldset>
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default VitalsForm;
