import * as yup from 'yup';

export const AllergySchema = yup.object({
  patientAllergyId: yup.number(),
  isDeleted: yup.bool().defined(),
  allergy: yup
    .object({
      allergyId: yup.number().defined(),
      name: yup.string().defined(),
    })
    .defined(),
  allergicReaction: yup
    .object({
      allergicReactionId: yup.number().defined(),
      name: yup.string().defined(),
    })
    .nullable(),
});

export const FormSchema = yup.object({
  allergies: yup.array().of(AllergySchema).defined().default([]),
  deletedAllergies: yup.array().of(AllergySchema).defined().default([]),
});

export type TInputs = yup.InferType<typeof FormSchema>;

export type AllergyInputs = {
  allergy: {
    allergyId: number;
    code: string;
    name: string;
  } | null;
  allergicReaction: {
    allergicReactionId: number;
    code: string;
    name: string;
  } | null;
};
