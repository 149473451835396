import { useEffect, useState } from 'react';

import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import ChiefComplaintAndHpiForm from './ChiefComplaintAndHpiForm';
import {
  TInputs,
  TSystem,
  TTask,
  Schema,
  getDefaultValues,
  getSystemValuesFromTask,
  getPatch,
} from './utils';

const ChiefComplaintAndHpiFormContainer = ({
  task,
}: {
  task: TTask;
}): JSX.Element => {
  const [systems, setSystems] = useState<TSystem[]>(
    getSystemValuesFromTask(task),
  );

  const defaultValues = getDefaultValues(task);
  const {
    control,
    handleSave,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatch(data, systems),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  useEffect(() => {
    register('reviewChangesCounter');
  }, [register]);

  return (
    <ChiefComplaintAndHpiForm
      control={control}
      formState={formState}
      inputsAreDisabled={inputsAreDisabled}
      setValue={setValue}
      systems={systems}
      setSystems={setSystems}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default ChiefComplaintAndHpiFormContainer;
