import React from 'react';
import styled from 'styled-components';

import { TabsProps } from './types';

const StyledTabs = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const InvalidSelectedIndexError = Error;

const Tabs = ({ children, selectedIndex = 0 }: TabsProps): JSX.Element => {
  if (
    (children instanceof Array && selectedIndex > children.length - 1) ||
    selectedIndex < 0
  ) {
    throw InvalidSelectedIndexError('selectedIndex is invalid');
  }
  const [controlledIndex, setControlledIndex] = React.useState(selectedIndex);
  const handleTabClick = (index: number, onClick: () => void) => {
    setControlledIndex(index);
    onClick();
  };

  return (
    <StyledTabs>
      {React.Children.map(children, (child, idx) =>
        React.cloneElement(child, {
          selected: controlledIndex === idx,
          onClick: () => handleTabClick(idx, child.props.onClick),
        }),
      )}
    </StyledTabs>
  );
};

export default Tabs;
