import { useRef } from 'react';

import UI from 'ui';
import { TooltipRefProps } from 'ui/Tooltip/Tooltip';

type ColumnProps = {
  index: number;
  label: string;
  showTooltip: boolean;
};

const Column = ({ index, label, showTooltip }: ColumnProps): JSX.Element => {
  const tooltipRef = useRef<TooltipRefProps>(null);

  const onMouseOver = () => {
    tooltipRef.current?.show();
  };

  const onMouseOut = () => {
    tooltipRef.current?.hide();
  };

  return (
    <>
      <span
        onMouseOver={showTooltip ? onMouseOver : undefined}
        onMouseOut={showTooltip ? onMouseOut : undefined}
        className={`col col${index + 1}`}
        style={{ cursor: showTooltip ? 'default' : 'inherit' }}
      >
        {label}
      </span>
      {showTooltip && <UI.Tooltip ref={tooltipRef} label={label} />}
    </>
  );
};

export default Column;
