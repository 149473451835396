import imageCompression from 'browser-image-compression';
import { forwardRef } from 'react';

import { TFile } from 'hooks/useFileUpload/types';
import { Analytics } from 'utils';
import BaseImageInput, {
  defaultCompressOptions,
} from './components/BaseImageInput';
import { CompressionOptions } from './types';

export type ImageInputProps = {
  /**
   * A description for the image
   */
  description: string;

  /**
   * The class name
   */
  className?: string;

  /**
   * If `true`, compress the image
   */
  compress?: boolean;

  /**
   * Overrides default compression options
   */
  compressOptions?: CompressionOptions;

  /**
   * If `true`, the input is disabled
   */
  disabled?: boolean;

  /**
   * If `true`, the download failed
   */
  downloadFailed: boolean;

  /**
   * An error message
   */
  errorText?: string;

  /**
   * The input's id
   */
  id: string;

  /**
   * An array buffer of image file
   */
  imageFile: Blob | null;

  /**
   * If `true`, the image is downloading
   */
  isDownloading: boolean;

  /**
   * If `true`, the image is currently uploading
   */
  isUploading: boolean;

  /**
   * If `true`, the image failed to upload.
   */
  uploadFailed: boolean;

  /**
   * If `true`, the input's width expands to 100% of its container width
   */
  fullWidth?: boolean;

  /**
   * An input label
   */
  label?: string;

  /**
   * The local browser URL for the image
   */
  localUrl: string | null;

  /**
   * Handles the clear failed action
   */
  onClearFailed: () => void;

  /**
   * Handles the delete action
   */
  onDelete: () => void;

  /**
   * Trigger the image download.
   */
  onDownload: () => void;

  /**
   * Handles the input change event
   */
  onInputChange: (file: File) => Promise<{ success: boolean; url?: string }>;

  /**
   * Handles the retry upload action
   */
  onRetry: () => void;

  /**
   * If `true` the field is not required
   */
  optional?: boolean;

  /**
   * A `data-testid` string
   */
  testId?: string;

  /**
   * The image URL
   */
  value: string | null;
};

const ImageInput = forwardRef<HTMLInputElement, ImageInputProps>(
  (
    {
      className = undefined,
      compress = true,
      compressOptions = {},
      description,
      disabled = false,
      downloadFailed = false,
      errorText,
      id,
      imageFile,
      isDownloading,
      isUploading,
      uploadFailed = false,
      fullWidth = false,
      label,
      localUrl,
      onClearFailed,
      onDelete,
      onDownload,
      onInputChange,
      onRetry,
      optional = false,
      testId,
      value,
    },
    inputRef,
  ): JSX.Element => {
    const image: TFile = {
      downloadFailed,
      downloadFile: onDownload,
      isDownloading,
      file: imageFile,
      fileUrl: value,
      isUploading: isUploading,
      localUrl,
      removeFile: onDelete,
      retryUpload: onRetry,
      uploadFailed,
      uploadFile: onInputChange,
    };

    const handleInputChange = async (
      event: React.FormEvent<HTMLInputElement>,
    ) => {
      if (event.currentTarget.files) {
        const file = event.currentTarget.files[0];
        const preparedFile = compress
          ? await imageCompression(file, {
              ...defaultCompressOptions,
              ...compressOptions,
            })
          : file;

        Analytics.timeEvent('Upload Image');
        const success = await onInputChange(preparedFile);
        Analytics.track('Upload Image', {
          'File Compressed': compress,
          'Original File Size': file.size,
          'Upload File Size': preparedFile.size,
          'Upload Success': success?.success,
        });
      }
    };

    return (
      <BaseImageInput
        className={className}
        description={description}
        disabled={disabled}
        errorText={errorText}
        fullWidth={fullWidth}
        id={id}
        inputRef={inputRef}
        images={[image]}
        isUploading={isUploading}
        label={label}
        onClearFailed={onClearFailed}
        onDelete={onDelete}
        onRetry={onRetry}
        onSingleInputChange={handleInputChange}
        optional={optional}
        testId={testId}
        type="single"
        uploadFailed={uploadFailed}
        value={value}
      />
    );
  },
);

export default ImageInput;
