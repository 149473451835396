import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  Calendar,
  Clock,
  FirstAidKit,
  UserGear,
  CheckSquare,
} from 'phosphor-react';

import { RoutePaths, Tokens } from 'config';
import { UserRoleEnum } from 'generated/graphql';

type TSidebarProps = {
  operatingAs?: UserRoleEnum;
};

const StyledNavigation = styled.ul`
  list-style: none;
  padding: ${Tokens.rhythm};

  a {
    align-items: center;
    border-radius: ${Tokens.border.radius};
    color: ${Tokens.color.neutral.white.base};
    display: flex;
    font-weight: ${Tokens.font.weight.normal};
    line-height: 1;
    padding: calc(${Tokens.rhythm} * 2);
    text-decoration: none;

    svg {
      margin-right: calc(${Tokens.rhythm} * 2);
    }

    &:hover {
      background-color: ${Tokens.color.neutral.black.transparent[10]};
    }

    &.active {
      background-color: ${Tokens.color.neutral.black.transparent[25]};
      font-weight: ${Tokens.font.weight.semiBold};
    }
  }
`;

const Navigation = ({
  operatingAs = undefined,
}: Readonly<TSidebarProps>): JSX.Element => (
  <StyledNavigation>
    {operatingAs ? (
      <>
        {operatingAs && [UserRoleEnum.RESPONDER].includes(operatingAs) ? (
          <>
            <li>
              <NavLink to={RoutePaths.appointments}>
                <Calendar
                  weight="fill"
                  color={Tokens.color.neutral.white.base}
                />
                My Appointments
              </NavLink>
            </li>
          </>
        ) : null}

        {operatingAs && [UserRoleEnum.CLINICIAN].includes(operatingAs) ? (
          <>
            <li>
              <NavLink to={RoutePaths.queue}>
                <Clock weight="fill" color={Tokens.color.neutral.white.base} />
                Patient Queue
              </NavLink>
            </li>
          </>
        ) : null}

        {operatingAs &&
        [
          UserRoleEnum.SUPERVISOR,
          UserRoleEnum.CLINICIAN,
          UserRoleEnum.RESPONDER,
        ].includes(operatingAs) ? (
          <>
            <li>
              <NavLink to={RoutePaths.encounters}>
                <FirstAidKit
                  weight="fill"
                  color={Tokens.color.neutral.white.base}
                />
                All Encounters
              </NavLink>
            </li>
          </>
        ) : null}

        {operatingAs &&
        [UserRoleEnum.RESPONDER, UserRoleEnum.CLINICIAN].includes(
          operatingAs,
        ) ? (
          <li>
            <NavLink to={RoutePaths.tasks}>
              <CheckSquare
                weight="fill"
                color={Tokens.color.neutral.white.base}
              />
              My Tasks
            </NavLink>
          </li>
        ) : null}

        {operatingAs && [UserRoleEnum.IT].includes(operatingAs) ? (
          <li>
            <NavLink to={RoutePaths.users}>
              <UserGear weight="fill" color={Tokens.color.neutral.white.base} />
              Users &amp; Roles
            </NavLink>
          </li>
        ) : null}
      </>
    ) : null}
  </StyledNavigation>
);

export default memo(Navigation);
