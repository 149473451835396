import styled from 'styled-components';

import { ReviewOfSystemSystemEnum } from 'generated/graphql';

import { Tokens } from 'config';
import { Enums } from 'utils';
import UI from 'ui';
import ReviewOfSystemsRadio from './ReviewOfSystemsRadio';
import type { TSystem } from './utils';

const Card = styled.div`
  border: 1px solid ${Tokens.color.neutral.grey[191]};
  padding: calc(${Tokens.rhythm} * 2);

  h4 {
    border-bottom: 1px solid ${Tokens.color.neutral.grey[191]};
    font-weight: ${Tokens.font.weight.bold};
    line-height: 1;
    margin: 0 0 calc(${Tokens.rhythm} * 2) 0;
    padding: 0 0 calc(${Tokens.rhythm} * 2);
  }

  .markNegativeButton {
    margin-top: ${Tokens.rhythm};
  }

  .note {
    margin-top: calc(${Tokens.rhythm} * 2);
  }
`;

export type TProps = {
  disabled?: boolean;
  system: TSystem;
  onSystemUpdate: (updatedSystem: TSystem) => void;
};

const ReviewOfSystemsCard = ({
  disabled = false,
  system,
  onSystemUpdate,
}: TProps): JSX.Element => {
  const normalizeTitle = (title?: ReviewOfSystemSystemEnum) => {
    if (!title) return '';

    if (
      [
        ReviewOfSystemSystemEnum.HEME_LYMPH,
        ReviewOfSystemSystemEnum.OB_GYN,
      ].includes(title)
    ) {
      return Enums.addForwardSlash(title);
    } else {
      return Enums.humanize(title);
    }
  };

  return (
    <Card>
      <h4>{normalizeTitle(system.system)}</h4>

      {system?.evaluations?.map((evaluation) => (
        <ReviewOfSystemsRadio
          disabled={disabled}
          key={evaluation.key}
          systemName={system.system ?? ''}
          label={evaluation.key ?? ''}
          value={evaluation.value}
          onValueChange={(value) => {
            const evaluationsCopy = [...(system?.evaluations ?? [])];
            const updatedEvaluationIndex = evaluationsCopy?.findIndex(
              (evaluationInState) => evaluationInState.key === evaluation.key,
            ); // Find evaluation being updated and assign new value

            // Update or clear value
            if (evaluationsCopy[updatedEvaluationIndex].value === value) {
              evaluationsCopy[updatedEvaluationIndex].value = undefined;
            } else {
              evaluationsCopy[updatedEvaluationIndex].value = value;
            }

            onSystemUpdate({ ...system, evaluations: evaluationsCopy });
          }}
        />
      ))}

      <UI.Button
        className="markNegativeButton"
        disabled={disabled}
        fullWidth
        variant="secondary"
        onClick={() => {
          const updatedSystem = { ...system };
          updatedSystem?.evaluations?.forEach((evaluation) => {
            if (typeof evaluation.value !== 'boolean') {
              evaluation.value = false;
            }
          });

          onSystemUpdate(updatedSystem);
        }}
      >
        Mark unselected as negative
      </UI.Button>

      <UI.Input
        className="note"
        disabled={disabled}
        fullWidth
        id={system.system + '_note'}
        multiline
        placeholder="Add note"
        value={system.note ?? ''}
        onChange={(event) =>
          onSystemUpdate({ ...system, note: event.currentTarget.value })
        }
      />
    </Card>
  );
};

export default ReviewOfSystemsCard;
