import { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import ROSSummary from './ROSSummary';

const ChiefComplaintAndHpiSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const chiefComplaintAndHpi = task?.chiefComplaintAndHpi;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isChiefComplaintAndHpiTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // ChiefComplaint may not exist on this encounter task (defensive)
  if (chiefComplaintAndHpi === null || chiefComplaintAndHpi === undefined)
    return null;

  return (
    <>
      <Grid>
        <div>
          <strong>Chief Complaint:</strong>
        </div>
        <div style={{ gridColumnStart: 2, gridColumnEnd: 5 }}>
          {chiefComplaintAndHpi.chiefComplaint ?? '-'}
        </div>
        <div style={{ gridColumn: 1 }}>
          <strong>HPI:</strong>
        </div>
        <div style={{ gridColumnStart: 2, gridColumnEnd: 5 }}>
          {chiefComplaintAndHpi.historyOfPresentIllness ?? '-'}
        </div>
      </Grid>

      <ROSSummary task={task} />
    </>
  );
};

export default ChiefComplaintAndHpiSummary;
