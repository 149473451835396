import { TypePolicy, FieldPolicy } from '@apollo/client';
import { Encounter, ModuleNameEnum } from 'generated/graphql';

import { Enums } from 'utils';

const EncounterTypePolicy: TypePolicy = {
  keyFields: ['encounterId'],
  fields: {
    humanizedClinicianStatus: {
      read(value, { readField }) {
        const clinicianStatus =
          readField<Encounter['clinicianStatus']>('clinicianStatus');

        if (clinicianStatus) {
          return Enums.humanize(clinicianStatus);
        }

        return null;
      },
    } as FieldPolicy,
    humanizedResponderStatus: {
      read(value, { readField }) {
        const responderStatus =
          readField<Encounter['responderStatus']>('responderStatus');

        if (responderStatus) {
          return Enums.humanize(responderStatus);
        }

        return null;
      },
    } as FieldPolicy,
    visitId: {
      read(value, { readField }) {
        const migrationSource =
          readField<Encounter['migrationSource']>('migrationSource');

        if (migrationSource) {
          const regex = /^visit:([\w|-]+)$/;
          const match = migrationSource?.match(regex);
          return match && match.length > 1 ? match[1] : '';
        }

        return null;
      },
    } as FieldPolicy,

    // Adds new @client field `humanizedModuleEncounterTags` and humanizes existing field `modules`
    humanizedModuleEncounterTags: {
      read(value, { readField }) {
        const modules = readField<Encounter['modules']>('modules');

        const humanize = (module: ModuleNameEnum) => {
          switch (module) {
            case ModuleNameEnum.TEST_A1C:
              return 'Test A1C';
            case ModuleNameEnum.TEST_A1C_ORDER:
              return 'Test A1C Order';
            case ModuleNameEnum.TEST_RSV:
              return 'Test RSV';
            case ModuleNameEnum.TEST_RSV_ORDER:
              return 'Test RSV Order';
            case ModuleNameEnum.EMS_ACTIVATION:
              return 'EMS Activation';
            case ModuleNameEnum.FORM_AMA:
              return 'Form - AMA';
            case ModuleNameEnum.FORM_ABN_NONCOVERAGE:
              return 'Form - ABN Noncoverage';
            case ModuleNameEnum.FORM_RELEASE_MEDICAL:
              return 'Form - Medical Record Release';
            default:
              return Enums.humanize(module);
          }
        };

        return (modules ?? [])
          .filter((module) => !!module) // filter out null values
          .map((module) => humanize(module!));
      },
    } as FieldPolicy,
  },
};

export default EncounterTypePolicy;
