import { Fragment, useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import ImageView from 'ui/ImageView';

const PaymentMethodFormSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const attachments = [
    {
      type: 'primaryInsuranceCardFront',
      url: task?.encounter?.primaryInsuranceRecord?.attachmentFrontUrl,
    },
    {
      type: 'primaryInsuranceCardBack',
      url: task?.encounter?.primaryInsuranceRecord?.attachmentBackUrl,
    },
    {
      type: 'secondaryInsuranceCardFront',
      url: task?.encounter?.secondaryInsuranceRecord?.attachmentFrontUrl,
    },
    {
      type: 'secondaryInsuranceCardBack',
      url: task?.encounter?.secondaryInsuranceRecord?.attachmentBackUrl,
    },
  ];

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          // isPaymentMethodTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Insurance images may not exist on this encounter task (defensive)
  if (attachments) {
    attachments.map((attachment) => {
      if (typeof attachment.url !== 'string') {
        return null;
      }
    });
  }

  return (
    <div>
      <h3>Insurance Card Images</h3>

      <Grid>
        {attachments &&
          attachments.map((attachment) => {
            if (typeof attachment.url === 'string') {
              return (
                <Fragment key={attachment.type}>
                  <ImageView url={attachment.url as string} />
                </Fragment>
              );
            }
          })}
      </Grid>
    </div>
  );
};

export default PaymentMethodFormSummary;
