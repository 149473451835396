import styled from 'styled-components';

import { Tokens } from 'config';

const StyledImageInput = styled.div<{
  fullWidth: boolean;
  hasValue: boolean;
  hasError: boolean;
  disabled: boolean;
}>`
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled
      ? Tokens.color.neutral.grey[240]
      : Tokens.color.neutral.white.base};
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ hasError }) =>
    hasError
      ? Tokens.color.ui.error.base
      : Tokens.color.neutral.black.transparent[25]};
  display: flex;
  height: 40px;
  padding: 0 10px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  .description {
    color: ${({ disabled }) =>
      disabled
        ? Tokens.color.ui.charcoal.transparent[50]
        : Tokens.color.ui.charcoal.base};
    font-size: ${Tokens.font.size.paragraph.base};
    line-height: ${Tokens.lineHeight.paragraph.small};
    margin: 0 calc(${Tokens.rhythm} * 2 - 2px);
  }

  .spacer {
    flex-grow: 1;
  }

  .failed {
    display: inline-flex;
    align-items: center;

    span {
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.paragraph.base};
      font-style: italic;
      line-height: ${Tokens.lineHeight.paragraph.base};
    }

    .retryButton {
      margin: 0 calc(${Tokens.rhythm} * 2);
    }
  }

  ${({ disabled }) =>
    disabled
      ? `
    .uploadButton {
      border: 0;
      color: ${Tokens.color.ui.charcoal.transparent[50]};
    }
  `
      : ''}
`;

export default StyledImageInput;
