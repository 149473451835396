import _capitalize from 'lodash/capitalize';

export const humanize = (enumString: string): string =>
  enumString.length < 3
    ? enumString
    : enumString
        .split(/_/)
        .map((val) => _capitalize(val))
        .join(' ');

export const addForwardSlash = (enumString: string): string =>
  enumString
    .split(/_/)
    .map((val) => _capitalize(val))
    .join('/');

export const addDash = (enumString: string): string => {
  const lowerCaseEnum = enumString.toLowerCase();
  const capitalize =
    lowerCaseEnum.charAt(0).toUpperCase() + lowerCaseEnum.slice(1);
  return capitalize.split(/_/).join('-');
};

export const titleCase = (enumString: string): string => {
  const lowerCaseEnum = enumString.toLowerCase();
  return _capitalize(lowerCaseEnum);
};
