import { MagnifyingGlass } from 'phosphor-react';
import { MutableRefObject } from 'react';

import { Encounter, UserFieldsFragment } from 'generated/graphql';
import UI from 'ui';
import EncounterTableRow from './EncounterTableRow';
import FilterMenu, {
  MarketOption,
  ServiceLineOption,
  ListItemOption,
} from './FilterMenu';
import FilterPill from './FilterPill';
import ResponderSearchBox from './ResponderSearchBox';
import StyledEncounterSearchView from './StyledEncounterSearchView';
import { TPatient } from '../types';

export type Filter = {
  value: number | string;
  type:
    | 'appointmentStatus'
    | 'chartStatus'
    | 'market'
    | 'paymentMode'
    | 'serviceLine';
  name: string;
};

export type EncounterSearchViewProps = {
  /**
   * The current page index.
   */
  currentPage: number;

  /**
   * An array of Encounters
   */
  encounters: Partial<Encounter>[] | undefined;

  /**
   * If `true`, the encounters are loading.
   */
  encountersLoading: boolean;

  /**
   * The total encounters count
   */
  totalResults: number;

  /**
   * The Responder.
   */
  responder: UserFieldsFragment | null;

  /**
   * Handles the encounter rock click.
   */
  onEncounterClick: (encounter: Partial<Encounter>) => void;

  /**
   * Handles clearing the responder name
   */
  onResponderClear: () => void;

  /**
   * Handles the start date input change
   */
  onStartDateChange: (arg: string | undefined) => void;

  /**
   * Handles the end date input change
   */
  onEndDateChange: (arg: string | undefined) => void;

  /**
   * The query start date.
   */
  startDate: Date | undefined;

  /**
   * The query start date.
   */
  endDate: Date | undefined;

  /**
   * Handles the responder search result selection.
   */
  onResponderOptionClick: (value: Record<string, unknown>) => void;

  /**
   * The number of items showing on the page.
   */
  pageSize: number;

  /**
   * The total number of pages.
   */
  pageCount: number;

  /**
   * The filters to display
   */
  filters: Filter[];

  /**
   * If `true`, the filters are loading
   */
  filtersLoading: boolean;

  /**
   * Fetches the next page of results
   */
  handleNextPage: () => void;

  /**
   * Fetches the previous page of results
   */
  handlePrevPage: () => void;

  /**
   * Set the current page
   */
  handlePageChange: (page: number) => void;

  /**
   * If `true`, has next page of results
   */
  hasNextPage: boolean;

  /**
   * If `true`, has previous page of results
   */
  hasPrevPage: boolean;

  /**
   * The chart status options
   */
  chartStatusOptions: ListItemOption[];

  /**
   * Handle chart status click
   */
  onChartStatusClick: (chartStatus: ListItemOption) => void;

  /**
   * The appointment status options
   */
  appointmentStatusOptions: ListItemOption[];

  /**
   * Handle appointment status click
   */
  onAppointmentStatusClick: (appointmentStatus: ListItemOption) => void;

  /**
   * The Market options
   */
  marketOptions: MarketOption[];

  /**
   * Handle market option click
   */
  onMarketClick: (market: MarketOption) => void;

  /**
   * The payment mode options
   */
  paymentModeOptions: ListItemOption[];

  /**
   * Handle appointment status click
   */
  onPaymentModeClick: (paymentMode: ListItemOption) => void;

  /**
   * The Service Line options
   */
  serviceLineOptions: ServiceLineOption[];

  /**
   * Handle Service service line option click
   */
  onServiceLineClick: (serviceLine: ServiceLineOption) => void;

  /**
   * Handle filter close click
   */
  onFilterClose: (filter: Filter) => void;

  /**
   * Handle clear all filters click
   */
  onClearAllFilters: () => void;

  /**
   * The selected patient's id
   */
  selectedPatient: TPatient;

  /**
   * Handles the patient selection from type ahead results
   */
  onPatientOptionClick: (patient: TPatient) => void;

  /**
   * The Patient Filter ref
   */
  patientFilterRef: MutableRefObject<{ reset: () => void }>;
};

const EncounterSearchView = ({
  currentPage,
  encounters,
  encountersLoading,
  handleNextPage,
  handlePrevPage,
  handlePageChange,
  hasNextPage,
  hasPrevPage,
  responder,
  onEncounterClick,
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  totalResults,
  onResponderOptionClick,
  onResponderClear,
  pageSize,
  pageCount,
  filtersLoading,
  filters,
  chartStatusOptions,
  onChartStatusClick,
  appointmentStatusOptions,
  onAppointmentStatusClick,
  marketOptions,
  onMarketClick,
  paymentModeOptions,
  onPaymentModeClick,
  serviceLineOptions,
  onServiceLineClick,
  onFilterClose,
  onClearAllFilters,
  selectedPatient,
  onPatientOptionClick,
  patientFilterRef,
}: EncounterSearchViewProps): JSX.Element => (
  <StyledEncounterSearchView>
    <div className="header">
      <h1>Encounters</h1>
      <div className="searchInputs">
        <div className="typeAheadWrapper">
          <ResponderSearchBox
            initialValue={
              responder ? `${responder?.firstName} ${responder?.lastName}` : ''
            }
            onOptionClick={(value) => {
              onResponderOptionClick(value as Record<string, unknown>);
            }}
            onReset={onResponderClear}
          />
        </div>
        <div className="datepickerWrapper">
          <UI.Datepicker
            id="start-date"
            name="start-date"
            format="MM/dd/yyyy"
            value={startDate}
            onChange={onStartDateChange}
          />
        </div>
        <div className="datepickerWrapper">
          <UI.Datepicker
            id="end-date"
            name="end-date"
            format="MM/dd/yyyy"
            value={endDate}
            onChange={onEndDateChange}
          />
        </div>
      </div>
      <div className="filters">
        <FilterMenu
          isLoading={filtersLoading}
          chartStatusOptions={chartStatusOptions}
          onChartStatusClick={onChartStatusClick}
          appointmentStatusOptions={appointmentStatusOptions}
          onAppointmentStatusClick={onAppointmentStatusClick}
          marketOptions={marketOptions}
          onMarketClick={onMarketClick}
          paymentModeOptions={paymentModeOptions}
          onPaymentModeClick={onPaymentModeClick}
          serviceLineOptions={serviceLineOptions}
          onServiceLineClick={onServiceLineClick}
          selectedPatient={selectedPatient}
          onPatientOptionClick={onPatientOptionClick}
          patientFilterRef={patientFilterRef}
        />
        {filters.map((filter) => (
          <FilterPill
            key={`${filter.name}__${filter.value}`}
            value={filter.value}
            label={filter.name}
            type={filter.type}
            onClose={() => onFilterClose(filter)}
            className="filterPill"
          />
        ))}
        {selectedPatient && (
          <FilterPill
            value={selectedPatient?.patientId ?? -1}
            label={selectedPatient?.globalHumanId ?? ''}
            type="patient"
            onClose={() => onPatientOptionClick(null)}
            className="filterPill"
          />
        )}
        {(filters.length > 0 || !!selectedPatient) && (
          <UI.Button
            variant="tertiary"
            size="small"
            onClick={onClearAllFilters}
          >
            Clear all filters
          </UI.Button>
        )}
      </div>
    </div>
    <div className="tableContainer">
      {encountersLoading ? (
        <UI.Spinner centered />
      ) : (
        <>
          {encounters && (
            <>
              {totalResults === 0 ? (
                <UI.EmptyState
                  icon={MagnifyingGlass}
                  iconWeight="regular"
                  title="No results found."
                />
              ) : (
                <UI.Table fullWidth>
                  <UI.TableHead>
                    <UI.TableRow>
                      <UI.TableCell>Scheduled Date</UI.TableCell>
                      <UI.TableCell>Service Line/Sub-type</UI.TableCell>
                      <UI.TableCell>Patient</UI.TableCell>
                      <UI.TableCell>Responder(s)</UI.TableCell>
                      <UI.TableCell>Clinician</UI.TableCell>
                      <UI.TableCell>Market</UI.TableCell>
                      <UI.TableCell>Appointment Status</UI.TableCell>
                      <UI.TableCell>Responder Status</UI.TableCell>
                      <UI.TableCell>Clinician Status</UI.TableCell>
                    </UI.TableRow>
                  </UI.TableHead>
                  <UI.TableBody>
                    {(encounters ?? []).map((encounter) => (
                      <EncounterTableRow
                        key={`encounterrow__${encounter.encounterId}`}
                        encounter={encounter}
                        responder={responder}
                        onClick={() => onEncounterClick(encounter)}
                      />
                    ))}
                  </UI.TableBody>
                </UI.Table>
              )}
            </>
          )}
        </>
      )}
      {encounters && (
        <>
          <div className="footerPadding" />
          <UI.TableFooter
            currentPage={currentPage}
            onPageChange={handlePageChange}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            itemLabel="Encounters"
            itemCount={totalResults}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            pageCount={pageCount}
            pageSize={pageSize}
          />
        </>
      )}
    </div>
  </StyledEncounterSearchView>
);

export default EncounterSearchView;
