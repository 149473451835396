import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Tokens } from 'config';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader, { TaskHeaderProps } from '../TaskHeader';
import { TInputs } from './utils';
import Row from './Row';
import Datepicker from 'ui/Datepicker';
import Button from 'ui/Button';
import Input from 'ui/Input';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';
import { EncounterTask } from 'generated/graphql';

const StyledMedicalConditionsForm = styled(StyledTaskForm)`
  .formStep {
    border-bottom: 0;
    margin-top: 0;
  }

  .inputWrapper {
    background-color: ${Tokens.color.ui.steel.base};
    border-top: 0px;
    padding: calc(${Tokens.rhythm} * 2);
  }

  .addButton {
    margin-top: calc(${Tokens.rhythm} * 2);
  }

  label {
    font-weight: ${Tokens.font.weight.bold};
    padding-bottom: calc(${Tokens.rhythm});
  }
`;

const StyledTable = styled.table`
  color: ${Tokens.color.ui.charcoal.base};
  margin: calc(${Tokens.rhythm} * 5) auto;
  min-width: 100%;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;

  th,
  td {
    padding: ${Tokens.rhythm};
    text-align: left;
  }
`;

const MedicalConditionsForm = ({
  titleIsEmpty,
  formState,
  dateValue,
  inputsAreDisabled,
  problemTextValue,
  taskHeaderProps,
  addMedicalCondition,
  deleteMedicalCondition,
  setDateValue,
  setProblemTextValue,
}: {
  titleIsEmpty: boolean;
  formState: TInputs;
  dateValue: Date | undefined;
  inputsAreDisabled: boolean;
  problemTextValue: string;
  addMedicalCondition: () => void;
  deleteMedicalCondition: (key?: string) => void;
  setDateValue: Dispatch<SetStateAction<Date | undefined>>;
  setProblemTextValue: Dispatch<SetStateAction<string>>;
  taskHeaderProps: TaskHeaderProps;
}): JSX.Element => {
  const { task } = taskHeaderProps;
  const filteredProblemList = formState?.patientProblemList?.filter(
    (item) => !item.isDeleted,
  );

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledMedicalConditionsForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <header>
          <h3>{task?.taskName}</h3>
        </header>
        <div>
          <p className="formStep">Medical Conditions</p>

          <section className="inputWrapper">
            <Input
              disabled={inputsAreDisabled}
              fullWidth
              id="problemText"
              label="Add Medical Condition"
              name="problemText"
              onChange={(event) => setProblemTextValue(event.target.value)}
              placeholder="Enter medical condition"
              value={problemTextValue}
            />

            {titleIsEmpty ? (
              <p style={{ color: Tokens.color.ui.error.base }}>
                Condition is required.
              </p>
            ) : null}

            <Datepicker
              disabled={inputsAreDisabled}
              filterFutureDateAndTime
              format="MM/dd/yyyy"
              fullWidth
              label="Start Date"
              optional
              name="startedAt"
              onChange={(dateString) => setDateValue(new Date(dateString))}
              placeholder="Enter start date"
              value={dateValue}
            />

            <Button
              className="addbutton"
              disabled={inputsAreDisabled}
              onClick={addMedicalCondition}
              variant="primary"
            >
              Add
            </Button>
          </section>

          {filteredProblemList && filteredProblemList.length > 0 ? (
            <StyledTable>
              <thead>
                <tr>
                  <th>Medical Condition</th>
                  <th>Start Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredProblemList.map((item) => (
                  <Row
                    key={item.key}
                    deleteMedicalCondition={deleteMedicalCondition}
                    generatedKey={item.key}
                    inputsAreDisabled={inputsAreDisabled}
                    item={item}
                  />
                ))}
              </tbody>
            </StyledTable>
          ) : null}
        </div>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledMedicalConditionsForm>
    </>
  );
};

export default MedicalConditionsForm;
