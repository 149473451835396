import { Control } from 'react-hook-form';

import { useForm } from 'forms';
import { usePersistTask, useTask } from 'hooks';
import {
  getDefaultValues,
  getPatch,
  FormSchema,
  TInputs,
  TTask,
} from '../utils';
import { TaskHeaderProps } from '../../TaskHeader';

type Props = {
  task: TTask;
};

type ReturnValues = {
  control: Control<TInputs>;
  inputsAreDisabled: boolean;
  taskHeaderProps: TaskHeaderProps;
};

const usePhysicalExamForm = ({ task }: Props): ReturnValues => {
  const defaultValues = getDefaultValues(task);

  const {
    control,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
    handleSave,
    handleSubmit,
    reset,
    watch,
  } = useForm<TInputs>(FormSchema, { defaultValues });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => getPatch(data, task),
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  return {
    control,
    inputsAreDisabled,
    taskHeaderProps: {
      task,
      isDirty,
      isSaving,
      isValidating,
      onTaskSave: handleSaveTask,
      onTaskSkip: handleSkipTask,
      onTaskComplete: handleCompleteTask,
    },
  };
};

export default usePhysicalExamForm;
