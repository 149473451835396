import { useState, createContext } from 'react';
import { SeverityLevel } from 'ui/Toast/Toast';

export type ToastParams = {
  message: string | undefined;
  severity?: SeverityLevel;
};

export type SetToastMessage = (
  message?: string,
  severity?: SeverityLevel,
) => void;

export type ToastContextType = ToastParams & {
  setToastMessage: SetToastMessage;
};

export const ToastContext = createContext<ToastContextType>({
  message: undefined,
  severity: undefined,
  setToastMessage: () => {},
});

export const ToastContextProvider = ({
  children,
}: Readonly<{ children: React.ReactNode }>): JSX.Element => {
  const [alertParams, setAlertParams] = useState<ToastParams>({
    message: undefined,
    severity: undefined,
  });

  const setToastMessage: SetToastMessage = (message, severity?) => {
    setAlertParams({
      message,
      severity: severity ?? alertParams.severity,
    });
  };

  const values: ToastContextType = {
    ...alertParams,
    setToastMessage,
  };

  return (
    <ToastContext.Provider value={values}>{children}</ToastContext.Provider>
  );
};

export const ToastConsumer = ToastContext.Consumer;
