import {
  useFieldArray,
  Control,
  FieldArrayWithId,
  useWatch,
} from 'react-hook-form';

import { useForm } from 'forms';
import {
  EncounterTaskAttachmentTypeEnum,
  UpsertEncounterTaskInput,
  SystemOfOriginEnum,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';
import { useToastMessage } from 'hooks';
import UI from 'ui';
import { Logger } from 'utils';
import { FormSchema, TInputs, typeOptions, TypeOption } from '../utils';
import useWatchForChanges from './useWatchForChanges';

type Props = {
  encounterTaskId: number;
  defaultValues: TInputs;
  fetchAttachments: () => void;
  setAttachmentsSaving: (isSaving: boolean) => void;
};

type ReturnValues = {
  buttonIsDisabled: boolean;
  appendAttachment: () => void;
  attachmentFields: FieldArrayWithId<TInputs, 'attachments', 'key'>[];
  getAvailableTypeOptions: (currentType: string | undefined) => TypeOption[];
  control: Control<TInputs>;
};

const useAttachmentsForm = ({
  defaultValues,
  encounterTaskId,
  fetchAttachments,
  setAttachmentsSaving,
}: Props): ReturnValues => {
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();

  const { control } = useForm<TInputs>(FormSchema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { fields: attachmentFields, append: appendAttachment } = useFieldArray({
    control,
    name: 'attachments',
    keyName: 'key',
  });

  const handleAppendAttachment = () => {
    appendAttachment({ openOnRender: true });
  };

  const attachments = useWatch({ control, name: 'attachments' });

  const buttonIsDisabled =
    attachments.length > 0 && !attachments[attachments.length - 1];

  const onSubmit = async (values: TInputs['attachments']) => {
    setAttachmentsSaving(true);

    const input: UpsertEncounterTaskInput = {
      systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
      encounterTaskId,
      attachments: values.map(({ description, type, images }) => ({
        description,
        type: type as EncounterTaskAttachmentTypeEnum,
        images: images.map(
          ({ id: encounterTaskAttachmentId, url, isDeleted }) => ({
            encounterTaskAttachmentId,
            url,
            isDeleted,
          }),
        ),
      })),
    };

    try {
      await upsertEncounterTask({
        variables: { input },
      });
      fetchAttachments();
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        'An error occurred while saving attachments. Please try again.',
        UI.Toast.SeverityLevel.Error,
      );
    } finally {
      setAttachmentsSaving(false);
    }
  };

  useWatchForChanges({
    control,
    defaultAttachmentsValue: defaultValues.attachments,
    onSubmit,
  });

  const getAvailableTypeOptions = (
    currentType: string | undefined,
  ): TypeOption[] => {
    return typeOptions.filter((option) => {
      const selectedOptions = attachmentFields.reduce(
        (prev, attachment) =>
          attachment?.type ? [...prev, attachment.type] : prev,
        [] as string[],
      );
      return (
        currentType === option.value || !selectedOptions.includes(option.value)
      );
    });
  };

  return {
    appendAttachment: handleAppendAttachment,
    buttonIsDisabled,
    attachmentFields,
    getAvailableTypeOptions,
    control,
  };
};

export default useAttachmentsForm;
