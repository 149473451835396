import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';

export const ToggleButton = styled(UI.Button)<{ disabled: boolean }>`
  --background-color-override: ${Tokens.color.neutral.white.base};
  --border-color-override: ${Tokens.color.ui.charcoal.base};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
`;

export const StyledEncounter = styled.div`
  max-width: 100%;

  & + & {
    border-top: 1px solid ${Tokens.color.neutral.black.transparent[10]};
  }

  p {
    padding: ${Tokens.rhythm} 0 0;
  }

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      line-height: 1;
      margin: 0;
    }

    .date {
      font-weight: ${Tokens.font.weight.semiBold};
    }

    .time {
      opacity: 0.5;
    }

    .serviceLine {
      font-size: ${Tokens.font.size.paragraph.large};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.paragraph.large};
      padding-top: 2px;
    }

    .actions {
      display: flex;
      align-items: center;

      & > * {
        margin: 0 ${Tokens.rhythm};
      }
    }
  }

  .channelAttribution {
    align-items: center;
    display: flex;
    font-size: ${Tokens.font.size.paragraph.small};
    font-weight: ${Tokens.font.weight.semiBold};
    padding-top: 0;

    span {
      color: ${Tokens.color.ui.charcoal.base};
      margin-left: ${Tokens.rhythm};
    }
  }

  .reason {
    margin-top: 0;
  }

  .modules {
    padding: calc(${Tokens.rhythm} * 2) 0;
  }

  .startButton {
    margin-top: calc(${Tokens.rhythm} * 2);
  }

  .claimButton {
    margin: calc(${Tokens.rhythm} * 2) 0;
  }

  .status > * + * {
    margin-left: ${Tokens.rhythm};
  }

  .status.portrait {
    display: none;
  }

  @media (max-width: ${Tokens.dimensions.iPad.portrait}) {
    .status.portrait {
      display: block;
      padding-top: ${Tokens.rhythm};
    }

    .status.landscape {
      display: none;
    }
  }
`;
