import { Dispatch, SetStateAction } from 'react';
import * as yup from 'yup';

import { Tokens } from 'config';
import { Controlled, useForm } from 'forms';
import UI from 'ui';
import { Maybe } from 'generated/graphql';

type TInputs = {
  correctionReason?: Maybe<string>;
};

const Schema: yup.SchemaOf<TInputs> = yup.object({
  correctionReason: yup.string().required('Please provide a valid answer'),
});

const TaskCorrectionsModal = ({
  onTaskCorrect,
  modalOpen,
  setModalOpen,
}: {
  onTaskCorrect: (correctionsReason: string) => void;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { control, handleSubmit, watch } = useForm<TInputs>(Schema, {
    reValidateMode: 'onChange',
  });

  const correctionsFormState = watch();

  const handleCompleteTask = handleSubmit(() => {
    if (correctionsFormState?.correctionReason) {
      onTaskCorrect(correctionsFormState?.correctionReason);
    }
  });

  const modalBody = (
    <>
      <p
        style={{
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
          marginTop: '0px',
        }}
      >
        Please describe the reason for the correction below. You will not be
        able to make any edits to this correction after submission.
      </p>
      <Controlled.Input
        control={control}
        defaultValue=""
        fullWidth
        multiline
        label="Reason for correction"
        name="correctionReason"
        style={{ minHeight: `calc(${Tokens.rhythm} * 18)` }}
      />
    </>
  );

  return (
    <UI.Modal
      body={modalBody}
      closeButtonLabel="Cancel"
      onClose={() => setModalOpen(false)}
      title="Submit this correction?"
      size="small"
      open={modalOpen}
      footerButtons={[
        {
          children: 'Submit',
          variant: 'primary',
          size: 'small',
          onClick: () => handleCompleteTask(),
        },
      ]}
    />
  );
};

export default TaskCorrectionsModal;
