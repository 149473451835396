import './wdyr';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import 'index.css';

import { Config, Environment } from 'config';
import { App } from 'modules';
// import reportWebVitals from 'reportWebVitals';

const sentryEnvironments = [
  Environment.Development,
  Environment.Staging,
  Environment.Production,
];

if (sentryEnvironments.includes(Config.environment)) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
    integrations: [],
    release: Config.version,
  });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
