import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import FormstackForm from './FormstackForm';
import { TInputs, Schema } from './utils';

const FormstackFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const defaultValues: TInputs = {
    patientConsentForm: task?.patientForm?.formstackId ? true : undefined,
  };

  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    reValidateMode: 'onChange',
    defaultValues,
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: () => undefined,
      onPersistTask: () => reset({}, { keepValues: true }),
    },
  );

  return (
    <FormstackForm
      control={control}
      errors={errors}
      formState={formState}
      inputsAreDisabled={inputsAreDisabled}
      setValue={setValue}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default FormstackFormContainer;
