import { useState } from 'react';

export type UsePaginationProps = {
  /**
   * Initial page number if using React state.
   */
  initialPage?: number;

  /**
   * A URL Search Params object. If provided, it will use
   * querystring params instead of state.
   */
  queryParams?: URLSearchParams;

  /**
   * The number of results to display per page. Used for
   * calculating a results offset.
   */
  resultsPerPage: number;

  /**
   * The number number of items in the paginated collection.
   */
  totalResults?: number;
};

type ReturnValue = {
  /**
   * The current page. Defaults to 1.
   */
  currentPage: number;

  /**
   * Sets the next page.
   */
  nextPage: () => void;

  /**
   * Sets the previous page.
   */
  prevPage: () => void;

  /**
   * If `true`, there is a next page in the results.
   */
  hasNextPage: boolean;

  /**
   * If `true`, there is a previous page in the results.
   */
  hasPrevPage: boolean;

  /**
   * The total number of pages.
   */
  pageCount: number;

  /**
   * A results offset to use with querying new pages.
   */
  resultsOffset: number;

  /**
   * Sets the current page.
   */
  setPage: (page: number) => void;
};

const getCleanedPage = (page: number, pageCount: number) => {
  if (page < 1) return 1;
  if (page > pageCount) return pageCount;
  return page;
};

const usePagination = ({
  initialPage = 1,
  queryParams,
  resultsPerPage,
  totalResults = 0,
}: UsePaginationProps): ReturnValue => {
  const [statefulPage, setStatefulPage] = useState(initialPage);

  const pageCount = totalResults ? Math.ceil(totalResults / resultsPerPage) : 0;

  const getCurrentPage = () => {
    if (queryParams) {
      const page = parseInt(queryParams.get('page') ?? '1', 10);
      return page || 1;
    }
    return statefulPage;
  };

  const currentPage = getCurrentPage();

  const hasNextPage = currentPage < pageCount;
  const hasPrevPage = totalResults > 0 && currentPage > 1;

  const pageIndex = currentPage - 1;
  const resultsOffset = pageIndex * resultsPerPage;

  const setPage = (page: number) => {
    const cleanedPage = getCleanedPage(page, pageCount);
    if (queryParams) {
      queryParams.set('page', `${cleanedPage}`);
      return;
    }
    setStatefulPage(cleanedPage);
  };

  const nextPage = () => {
    setPage(currentPage + 1);
  };

  const prevPage = () => {
    setPage(currentPage - 1);
  };

  return {
    currentPage,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    pageCount,
    resultsOffset,
    setPage,
  };
};

export default usePagination;
