import * as yup from 'yup';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  Maybe,
  UpsertEncounterTaskInput,
  UserRoleEnum,
} from 'generated/graphql';

import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import { getDrugIsExpired } from 'utils';

export const POCTestCategoryToLookupIdEnum = {
  Covid: 1,
  A1c: 2,
  Pregnancy: 3,
  Glucose: 4,
  FluAntigen: 5,
  CovidAntibody: 6,
  Strep: 7,
  Rsv: 8,
  A1cPercent: 9,
};

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export type TInputs = {
  clinicianFirstName?: Maybe<string>;
  clinicianLastName?: Maybe<string>;
  criticalValueConfirmation?: Maybe<boolean>;
  expirationDate?: Maybe<string>;
  humidity?: Maybe<string>;
  internalControlAcceptable?: Maybe<boolean>;
  lotNumber?: Maybe<string>;
  result?: Maybe<string>;
  standingOrderAddedAt?: Maybe<string>;
  standingOrderId?: Maybe<number>;
  temperature?: Maybe<string>;
  testResultAttachment?: Maybe<string>;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  clinicianFirstName: yup
    .string()
    .nullable()
    .required('Ordering clinician first name is required'),
  clinicianLastName: yup
    .string()
    .nullable()
    .required('Ordering clinician last name is required'),
  criticalValueConfirmation: yup
    .boolean()
    .test(
      'test-based-on-critical-value',
      'Confirmation is required',
      (value, context) => {
        if (context?.options?.context?.overrideRequired) return true;
        return context?.options?.context?.resultIsCritical
          ? Boolean(value)
          : true;
      },
    ),
  expirationDate: yup
    .string()
    .nullable()
    .test(
      'test-expirationDate',
      'Please record the reagent expiration date',
      (value, context) =>
        (value &&
          Boolean(!getDrugIsExpired(context?.options?.context?.task, value))) ||
        Boolean(context?.options?.context?.overrideRequired),
    ),
  humidity: yup
    .string()
    .nullable()
    .test(
      'test-humidity',
      'Please record the room/ambient humidity',
      (value, context) =>
        Boolean(context?.options?.context?.overrideRequired) ||
        Boolean(value && Number(value) >= 20 && Number(value) <= 80),
    ),
  internalControlAcceptable: yup
    .boolean()
    .nullable()
    .test(
      'test-internalControls',
      'Please select a valid answer',
      (value, context) =>
        (value && Boolean(value)) ||
        Boolean(context?.options?.context?.overrideRequired),
    ),
  lotNumber: yup.string().nullable().required('Please provide a valid lot #'),
  result: yup
    .string()
    .nullable()
    .required('Please select or enter a valid test result'),
  standingOrderAddedAt: yup.string().nullable(),
  standingOrderId: yup.number().when(['$isOrderedModule'], {
    is: false,
    then: yup
      .number()
      .nullable()
      .required('Please select a valid standing order'),
    otherwise: yup.number().nullable(),
  }),
  temperature: yup
    .string()
    .nullable()
    .test(
      'test-temperature',
      'Please record the room/ambient temperature',
      (value, context) =>
        Boolean(context?.options?.context?.overrideRequired) ||
        Boolean(value && Number(value) >= 59 && Number(value) <= 86),
    ),
  testResultAttachment: yup
    .string()
    .nullable()
    .required('Please upload a valid test result'),
});

export const getDefaultValues = (
  task: TTask,
  operatingAs?: UserRoleEnum,
): TInputs => ({
  clinicianFirstName: task?.pointOfCareTest?.clinicianFirstName,
  clinicianLastName: task?.pointOfCareTest?.clinicianLastName,
  criticalValueConfirmation:
    operatingAs === UserRoleEnum.SUPERVISOR
      ? task?.pointOfCareTest?.criticalValueConfirmations?.some(
          (item) => item?.operatingAsRole === UserRoleEnum.RESPONDER,
        )
      : task?.pointOfCareTest?.criticalValueConfirmations?.some(
          (item) => item?.operatingAsRole === operatingAs,
        ),
  expirationDate: task?.pointOfCareTest?.expirationDate,
  humidity: task?.pointOfCareTest?.humidity?.toString() ?? undefined,
  internalControlAcceptable: task?.pointOfCareTest?.internalControlAcceptable,
  lotNumber: task?.pointOfCareTest?.lotNumber,
  result: task?.pointOfCareTest?.result,
  standingOrderAddedAt: task?.pointOfCareTest?.standingOrderAddedAt,
  standingOrderId: task?.pointOfCareTest?.standingOrder?.standingOrderId,
  temperature: task?.pointOfCareTest?.temperature?.toString() ?? undefined,
  testResultAttachment: getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.TEST_RESULT,
  ).attachmentUrl,
});

export const getPatchValues = (
  formData: TInputs,
  operatingAs?: UserRoleEnum,
): Partial<UpsertEncounterTaskInput> => {
  const {
    clinicianFirstName,
    clinicianLastName,
    criticalValueConfirmation,
    expirationDate,
    humidity,
    internalControlAcceptable,
    lotNumber,
    result,
    standingOrderAddedAt,
    standingOrderId,
    temperature,
  } = formData;

  const patch: {
    pointOfCareTest: NonNullable<UpsertEncounterTaskInput['pointOfCareTest']>;
  } = {
    pointOfCareTest: {
      attestedAt: null,
      attestedByRole:
        operatingAs === UserRoleEnum.CLINICIAN
          ? operatingAs
          : UserRoleEnum.RESPONDER,
      clinicianFirstName,
      clinicianLastName,
      expirationDate: expirationDate ? expirationDate : undefined,
      humidity: humidity ? Number(humidity) : undefined,
      internalControlAcceptable,
      lotNumber: lotNumber ? lotNumber : undefined,
      result: result ? result : undefined,
      standingOrderAddedAt,
      standingOrderId,
      temperature: temperature ? Number(temperature) : undefined,
    },
  };

  if (criticalValueConfirmation) {
    patch.pointOfCareTest.attestedAt = new Date();
  }

  return patch;
};
