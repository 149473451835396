import { Dispatch, SetStateAction } from 'react';

import { useUpdateEncounterTaskMutation } from 'generated/graphql';

import { Tokens } from 'config';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import UI from 'ui';
import Input from 'ui/Input';

const SkipTaskModal = ({
  encounterTaskId,
  inProgressAt,
  isDirty,
  modalOpen,
  onTaskSkip,
  setModalOpen,
}: {
  encounterTaskId?: number;
  inProgressAt?: string;
  isDirty?: boolean;
  onTaskSkip?: () => void;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [updateEncounterTask] = useUpdateEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();

  const { formState, getErrors, getData, setData, setErrors } = useForm({
    skippedReason: {
      errorMessage: 'Please provide a valid answer',
      field: 'skippedReason',
      required: true,
      value: null,
    },
  });

  const skipTask = async () => {
    if (!formState.skippedReason.value) return;

    try {
      if (isDirty) {
        if (onTaskSkip) {
          await onTaskSkip();
        }
      }

      setTimeout(async () => {
        await updateEncounterTask({
          variables: {
            input: {
              patch: {
                inProgressAt: inProgressAt ?? new Date(),
                responderCompletedAt: null,
                skippedAt: new Date(),
                skippedReason: formState.skippedReason.value as string,
                updatedAt: new Date(),
              },
              encounterTaskId: encounterTaskId!,
            },
          },
        });
      }, 500);
    } catch (error) {
      Logger.error(error);
    }

    setData('skippedReason', null);
  };

  const handleClick = async () => {
    const errors = getErrors();
    const data = getData<{ skippedReason: string }>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
    }
    if (data?.skippedReason) {
      await skipTask();

      setModalOpen(false);
      setToastMessage(
        `Successfully skipped task`,
        UI.Toast.SeverityLevel.Success,
      );
    }
  };

  const modalBody = (
    <>
      <p
        style={{
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
          marginTop: '0px',
        }}
      >
        Please provide a reason for skipping this task.
      </p>
      <Input
        id="skipTaskModal"
        label="Reason for skip"
        name="skipTaskModal"
        errorText={
          formState.skippedReason.hasError
            ? formState.skippedReason.errorMessage
            : undefined
        }
        fullWidth
        multiline
        onChange={(event) => {
          setData('skippedReason', event.target.value);
        }}
        style={{ minHeight: `calc(${Tokens.rhythm} * 18)` }}
      />
    </>
  );

  return (
    <UI.Modal
      body={modalBody}
      closeButtonLabel="Cancel"
      onClose={() => setModalOpen(false)}
      title="Skip this task?"
      size="small"
      open={modalOpen}
      footerButtons={[
        {
          children: 'Submit',
          variant: 'primary',
          size: 'small',
          onClick: () => handleClick(),
        },
      ]}
    />
  );
};

export default SkipTaskModal;
