import {
  UserRoleEnum,
  EncounterClinicianStatusEnum,
  EncounterResponderStatusEnum,
  EncounterTaskStatusEnum,
} from 'generated/graphql';

import { ActionAndStatusEnum } from './utils';
import type { TActionOrStatus } from './utils';

export const statusData: TActionOrStatus[] = [
  // STATUS: ENCOUNTER_CAN_BE_CLAIMED
  {
    action: ActionAndStatusEnum.ENCOUNTER_CAN_BE_CLAIMED,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_QUEUE],
    taskStatus: null,
  },

  // STATUS: ENCOUNTER_CAN_BE_UNCLAIMED
  {
    action: ActionAndStatusEnum.ENCOUNTER_CAN_BE_UNCLAIMED,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.IN_PROGRESS,
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
      EncounterTaskStatusEnum.SKIPPED,
    ],
  },

  // STATUS: ENCOUNTER_COMPLETE
  {
    action: ActionAndStatusEnum.ENCOUNTER_COMPLETE,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.COMPLETED],
    taskStatus: null,
  },
  {
    action: ActionAndStatusEnum.ENCOUNTER_COMPLETE,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.COMPLETED],
    taskStatus: null,
  },
  {
    action: ActionAndStatusEnum.ENCOUNTER_COMPLETE,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.COMPLETED],
    taskStatus: null,
  },

  // STATUS: ENCOUNTER_IN_PROGRESS
  {
    action: ActionAndStatusEnum.ENCOUNTER_IN_PROGRESS,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: null,
  },
  {
    action: ActionAndStatusEnum.ENCOUNTER_IN_PROGRESS,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: null,
  },
  {
    action: ActionAndStatusEnum.ENCOUNTER_IN_PROGRESS,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_PROGRESS],
    taskStatus: null,
  },

  // STATUS: TASK_EDITABLE
  {
    action: ActionAndStatusEnum.TASK_EDITABLE,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },
  {
    action: ActionAndStatusEnum.TASK_EDITABLE,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
    ],
  },
  {
    action: ActionAndStatusEnum.TASK_EDITABLE,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterClinicianStatusEnum.IN_PROGRESS],
    taskStatus: [
      EncounterTaskStatusEnum.NOT_STARTED,
      EncounterTaskStatusEnum.IN_PROGRESS,
      EncounterTaskStatusEnum.RESPONDER_COMPLETED,
    ],
  },

  // STATUS: TASK_NEEDS_REVIEW
  {
    action: ActionAndStatusEnum.TASK_NEEDS_REVIEW,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.RESPONDER_COMPLETED],
    shared: true,
  },
  {
    action: ActionAndStatusEnum.TASK_NEEDS_REVIEW,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.RESPONDER_COMPLETED],
    shared: true,
  },
  {
    action: ActionAndStatusEnum.TASK_NEEDS_REVIEW,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.RESPONDER_COMPLETED],
    shared: true,
  },

  // STATUS: TASK_REVIEWED
  {
    action: ActionAndStatusEnum.TASK_REVIEWED,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.CLINICIAN_REVIEWED],
    shared: true,
  },
  {
    action: ActionAndStatusEnum.TASK_REVIEWED,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.CLINICIAN_REVIEWED],
    shared: true,
  },
  {
    action: ActionAndStatusEnum.TASK_REVIEWED,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: UserRoleEnum.CLINICIAN,
    excludeTaskRole: null,
    usersEncounterStatus: [EncounterResponderStatusEnum.IN_PROGRESS],
    taskStatus: [EncounterTaskStatusEnum.CLINICIAN_REVIEWED],
    shared: true,
  },

  // STATUS: TASK_VISIBLE
  {
    action: ActionAndStatusEnum.TASK_VISIBLE,
    role: UserRoleEnum.RESPONDER,
    includeTaskRole: UserRoleEnum.RESPONDER,
    excludeTaskRole: null,
    usersEncounterStatus: [],
    taskStatus: [],
  },
  {
    action: ActionAndStatusEnum.TASK_VISIBLE,
    role: UserRoleEnum.SUPERVISOR,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [],
    taskStatus: [],
  },
  {
    action: ActionAndStatusEnum.TASK_VISIBLE,
    role: UserRoleEnum.CLINICIAN,
    includeTaskRole: null,
    excludeTaskRole: null,
    usersEncounterStatus: [],
    taskStatus: [],
  },
];
