import { ArrowSquareOut, Copy } from 'phosphor-react';
import styled from 'styled-components';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import {
  EncounterTask,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader from '../TaskHeader';
import { StyledTaskForm } from '../TaskForm';
import { useTelehealthForm } from './hooks';
import { AlternateConnectionOptions } from './utils';
import { useToastMessage } from 'hooks';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';

type Props = {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
};

const StyledTelehealthForm = styled(StyledTaskForm)`
  .field,
  .field p {
    margin-bottom: calc(${Tokens.rhythm} * 2);
  }

  .error {
    margin-top: 0;
    margin-bottom: calc(${Tokens.rhythm} * 2);
    color: ${Tokens.color.ui.error.base};
  }
`;

const TelehealthForm = ({ task }: Props): JSX.Element => {
  const {
    control,
    inputsAreDisabled,
    isAlternateConnectMethod,
    isCompleteErrorText,
    isInQueue,
    handleJoinQueue,
    hasInQueueAt,
    meetingDetails,
    taskHeaderProps,
  } = useTelehealthForm({
    task,
  });
  const { setToastMessage } = useToastMessage();

  const copyText = (copyData: string) => {
    navigator.clipboard.writeText(copyData);
    setToastMessage(
      'Meeting link copied to clipboard',
      UI.Toast.SeverityLevel.Success,
    );
  };

  const handleOpenMeeting = () => {
    if (meetingDetails && meetingDetails?.url) {
      window.open(meetingDetails.url);
    }
  };

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledTelehealthForm noValidate autoComplete="off">
        <header>
          <h3>{task?.taskName}</h3>
        </header>
        <p className="formInstructionsLabel">
          1. Complete all prior tasks before the video encounter
        </p>
        <section>
          <p className="formGeneralNote">
            The clinician will review these tasks as part of the video
            encounter.
          </p>
        </section>
        <p className="formInstructionsLabel">
          2. Join the queue to speak with a clinician
        </p>
        <section>
          {isCompleteErrorText && (
            <p className="error">{isCompleteErrorText}</p>
          )}
          {meetingDetails ? (
            <>
              <UI.Button
                variant="primary"
                disabled={inputsAreDisabled}
                className="field"
                onClick={handleOpenMeeting}
                leftIcon={
                  <ArrowSquareOut color={Tokens.color.neutral.white.base} />
                }
              >
                Join Meeting (Host: {meetingDetails.host})
              </UI.Button>

              {!inputsAreDisabled ? (
                <Copy
                  data-testid="url-copy"
                  weight="fill"
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyText(meetingDetails.url)}
                />
              ) : null}

              <div className="field">
                <p>
                  <b>Meeting ID:</b>
                  <br />
                  {meetingDetails.meetingId ?? '-'}
                </p>
                <p>
                  <b>Start Time:</b>
                  <br />
                  {meetingDetails.startTime ?? '-'}
                </p>
                <p>
                  <b>End Time:</b>
                  <br />
                  {meetingDetails.endTime ?? '-'}
                </p>
              </div>
            </>
          ) : (
            <UI.Button
              variant="primary"
              disabled={inputsAreDisabled}
              className="field"
              onClick={handleJoinQueue}
              isLoading={isInQueue}
            >
              {isInQueue ? 'Waiting for a clinician' : 'Join Queue'}
            </UI.Button>
          )}

          <div className="field">
            <Controlled.Checkbox
              control={control}
              name="isAlternateConnectMethod"
              label="Connected with clinician via different method"
              disabled={inputsAreDisabled || !hasInQueueAt}
            />
          </div>

          {isAlternateConnectMethod && (
            <>
              <div className="field">
                <Controlled.Select
                  control={control}
                  name="alternateConnectionMethod"
                  label="Connection method"
                  options={AlternateConnectionOptions}
                  disabled={inputsAreDisabled}
                />
              </div>

              <div className="field">
                <Controlled.Input
                  control={control}
                  name="alternateConnectionReason"
                  label="Reason for alternate connection"
                  disabled={inputsAreDisabled}
                  fullWidth
                />
              </div>
            </>
          )}
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTelehealthForm>
    </>
  );
};

export default TelehealthForm;
