import styled from 'styled-components';
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from 'phosphor-react';

import { Tokens } from 'config';

export declare interface TableFooterProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The current page number.
   */
  currentPage: number;

  /**
   * If `true`, the next page button is enabled.
   */
  hasNextPage: boolean;

  /**
   * If `true`, the previous page button is enabled.
   */
  hasPrevPage: boolean;

  /**
   * The total number of items.
   */
  itemCount: number;

  /**
   * The label to display next to the total item count.
   */
  itemLabel: string;

  /**
   * When called, will increase the page by one.
   */
  onNextPage: () => void;

  /**
   * When called, will decrease the page by one.
   */
  onPrevPage: () => void;

  /**
   * Handles the page change
   */
  onPageChange: (page: number) => void;

  /**
   * The total number of pages.
   */
  pageCount: number;

  /**
   * The number of rows per page.
   */
  pageSize: number;
}

const localize = (n: number) => n.toLocaleString();

const StyledTableFooter = styled.div<
  React.HTMLAttributes<HTMLDivElement> & {
    hasNextPage: boolean;
    hasPrevPage: boolean;
  }
>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: ${Tokens.lineHeight.paragraph.small};
  box-shadow: 0px -1px 3px ${Tokens.color.neutral.black.transparent[8]};
  padding: 0 calc(${Tokens.rhythm} * 4);
  background-color: ${Tokens.color.neutral.white.base};

  .pages {
    span {
      margin: 0 calc(${Tokens.rhythm} * 2);
    }

    .pageButton {
      cursor: pointer;
      position: relative;
      top: 2px;

      &:first-child {
        margin-right: calc(${Tokens.rhythm} * 2);
      }

      &:last-child {
        margin-left: calc(${Tokens.rhythm} * 2);
      }

      &.disabled {
        cursor: default;
        opacity: 25%;
      }
    }
  }
`;

const Table = ({
  currentPage,
  hasNextPage,
  hasPrevPage,
  itemLabel,
  itemCount,
  onNextPage,
  onPrevPage,
  onPageChange,
  pageCount,
  pageSize,
  ...props
}: TableFooterProps): JSX.Element => {
  const pageIndex = currentPage - 1;
  const itemStart = pageIndex * pageSize + 1;
  const itemEnd =
    currentPage === pageCount && itemCount % pageSize > 0
      ? pageIndex * pageSize + (itemCount % pageSize)
      : currentPage * pageSize;

  const getItemCountDisplay = () => {
    const items = `${localize(itemCount)} ${itemLabel}`;
    return itemCount > 0
      ? `${localize(itemStart)} - ${localize(itemEnd)} of ${items}`
      : items;
  };

  const getPageDisplay = () => {
    const page = pageCount > 0 ? currentPage : 0;
    return `Page ${localize(page)} of ${localize(pageCount)}`;
  };

  return (
    <StyledTableFooter
      hasNextPage={hasNextPage}
      hasPrevPage={hasPrevPage}
      {...props}
    >
      <div className="items">{getItemCountDisplay()}</div>
      <div className="pages">
        <CaretDoubleLeft
          onClick={
            currentPage > 1
              ? () => {
                  onPageChange(1);
                }
              : undefined
          }
          className={`pageButton ${currentPage === 1 ? 'disabled' : ''}`}
        />
        <CaretLeft
          onClick={hasPrevPage ? onPrevPage : undefined}
          className={`pageButton ${!hasPrevPage ? 'disabled' : ''}`}
        />
        <span>{getPageDisplay()}</span>
        <CaretRight
          onClick={hasNextPage ? onNextPage : undefined}
          className={`pageButton ${!hasNextPage ? 'disabled' : ''}`}
        />
        <CaretDoubleRight
          onClick={
            currentPage < pageCount
              ? () => {
                  onPageChange(pageCount);
                }
              : undefined
          }
          className={`pageButton ${
            currentPage === pageCount ? 'disabled' : ''
          }`}
        />
      </div>
    </StyledTableFooter>
  );
};

export default Table;
