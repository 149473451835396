import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { Control, useWatch, UseFormSetValue } from 'react-hook-form';

import { Tokens } from 'config';
import { Controlled } from 'forms';
import { useDidMountEffect } from 'hooks';
import { Option } from 'ui/TypeAhead/TypeAhead';
import { FormInputs, InsuranceInputs } from './schema';
import companies from './insurance-companies.json';

type Props = {
  control: Control<FormInputs>;
  disabled?: boolean;
  index: number;
  setValue: UseFormSetValue<FormInputs>;
};

const InsuranceTypeAhead = ({
  control,
  disabled,
  setValue,
  index,
}: Props): JSX.Element => {
  const [options, setOptions] = useState<Option[]>([]);
  const [company, companyNotFound] = useWatch({
    name: [
      `insurances.${index}.company`,
      `insurances.${index}.companyNotFound`,
    ],
    control,
  });

  useDidMountEffect(() => {
    setValue(`insurances.${index}.company.name`, '');
    setValue(`insurances.${index}.company.packageId`, null);
  }, [companyNotFound]);

  const handleInputChange = (query?: string) => {
    if (!query) return;
    setOptions([]);
    const matched = matchSorter(companies, query, {
      keys: [
        {
          key: 'name',
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        },
      ],
    });
    setOptions(
      matched.slice(0, 5).map((comp) => ({
        label: comp.name,
        value: comp,
      })),
    );
  };

  const transformInput = (value: InsuranceInputs['company']) =>
    !value.packageId ? '' : value?.name ?? '';

  return (
    <>
      <Controlled.TypeAhead
        name={`insurances.${index}.company`}
        control={control}
        label="Insurance Company"
        debounceRate={0}
        defaultValue={{
          name: '',
          packageId: null,
        }}
        disabled={companyNotFound || disabled}
        onInputChange={handleInputChange}
        options={options}
        threshold={1}
        transformInput={transformInput}
      />
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          marginTop: Tokens.rhythm,
          marginBottom: `calc(${Tokens.rhythm} * 2)`,
        }}
      >
        <Controlled.Checkbox
          name={`insurances.${index}.companyNotFound`}
          defaultValue={false}
          control={control}
          label="Cannot find insurance company"
          disabled={disabled || (!!company?.name && !!company?.packageId)}
        />
      </div>
      {companyNotFound && (
        <Controlled.Input
          name={`insurances.${index}.unlistedCompany`}
          control={control}
          defaultValue=""
          disabled={disabled}
          label="Non-listed Insurance Company"
          instructionText="Please provide the non-listed insurance company. Non-listed entries should only be added if you cannot find the patient’s insurance company through the search above."
          fullWidth
        />
      )}
    </>
  );
};

export default InsuranceTypeAhead;
