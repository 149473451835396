import { TTask } from 'hooks/useTask';
import { format as formatDate } from 'date-fns';

const getDrugIsExpired = (task: TTask, expiration: Date | string): boolean => {
  const scheduledForDate = formatDate(
    new Date(task?.encounter?.appointment?.scheduledFor),
    'yyyy-MM-dd',
  );
  const expirationDate = new Date(expiration).toISOString().split('T')[0];
  const drugIsExpired = Boolean(scheduledForDate > expirationDate);

  return drugIsExpired;
};

export default getDrugIsExpired;
