import {
  EncounterTaskStatusEnum,
  GetEncounterTasksByEncounterIdQuery,
  UserRoleEnum,
} from 'generated/graphql';

type TTaskList = GetEncounterTasksByEncounterIdQuery['encounterTasksList'];

export const getRoleSpecificTaskList = ({
  role = UserRoleEnum.RESPONDER,
  list = [],
}: {
  role?: UserRoleEnum;
  list?: TTaskList;
}): TTaskList => {
  // TODO, is is where the task get's filtered for RCO-3430
  //   return role === UserRoleEnum.RESPONDER
  //     ? list?.filter((task) => task?.taskRoles?.includes(role))
  //     : list;
  return role ? list : list;
};

export const getTaskGroups = ({
  list = [],
}: {
  list?: TTaskList;
}): string[] | undefined =>
  list?.reduce((groups: string[], task) => {
    if (!groups.includes(task.group)) {
      groups.push(task.group);
    }
    return groups;
  }, []);

type TGetTaskMetrics = {
  allTasksAreComplete: boolean;
  completeTasks: TTaskList;
  incompleteTasks: TTaskList;
  roleSpecificTaskCount: number;
  skippedTasks: TTaskList;
};

export const getTaskMetrics = ({
  role = UserRoleEnum.RESPONDER,
  list = [],
}: {
  role?: UserRoleEnum;
  list?: TTaskList;
}): TGetTaskMetrics => {
  const roleSpecificTasks =
    list?.filter((task) =>
      task?.taskRoles.includes(
        role === UserRoleEnum.SUPERVISOR ? UserRoleEnum.RESPONDER : role,
      ),
    ) ?? [];

  const roleSpecificTaskCount = roleSpecificTasks.length;

  const skippedTasks = roleSpecificTasks.filter(
    (task) => task.status === EncounterTaskStatusEnum.SKIPPED,
  );

  const completeTasks = roleSpecificTasks.filter((task) => {
    if (!task?.status) return 0;

    return role === UserRoleEnum.CLINICIAN
      ? [EncounterTaskStatusEnum.CLINICIAN_REVIEWED].includes(task.status)
      : [
          EncounterTaskStatusEnum.RESPONDER_COMPLETED,
          EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
        ].includes(task.status);
  });

  const incompleteTasks = roleSpecificTasks.filter((task) => {
    if (!task?.status) return 0;

    return role === UserRoleEnum.CLINICIAN
      ? task?.taskRoles.includes(UserRoleEnum.CLINICIAN) &&
          task.status !== EncounterTaskStatusEnum.CLINICIAN_REVIEWED
      : ![
          EncounterTaskStatusEnum.RESPONDER_COMPLETED,
          EncounterTaskStatusEnum.CLINICIAN_REVIEWED,
        ].includes(task.status);
  });

  const allTasksAreComplete =
    roleSpecificTasks.length ===
    (completeTasks?.length ?? 0) + (skippedTasks?.length ?? 0);

  return {
    allTasksAreComplete,
    completeTasks,
    incompleteTasks,
    roleSpecificTaskCount,
    skippedTasks,
  };
};
