import { forwardRef } from 'react';
import { Tokens } from 'config';
import UI from 'ui';
import Logger from 'utils/logger/logger';

import { InputProps } from './types';
import IconWrapper from './IconWrapper';
import InputWrapper from './InputWrapper';
import InstructionText from './InstructionText';
import StyledInput from './StyledInput';

export const InputPropsError = Error;

const validateProps = (props: InputProps): void => {
  if (props.rows && !props.multiline) {
    throw InputPropsError(
      'Must include "multiline" in order to use "rows" prop.',
    );
  }
  if (props.rightIcon && props.rightLabel) {
    throw InputPropsError(
      'Cannot include both a rightIcon and rightLabel. Choose only one.',
    );
  }
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, inputRef): JSX.Element => {
    validateProps(props);

    const {
      id,
      disabled = false,
      errorText,
      fullWidth = false,
      helpText,
      instructionText,
      label,
      leftIcon,
      multiline = false,
      onClick,
      optional = false,
      required = false,
      rows = undefined,
      rightIcon,
      rightLabel,
      successText,
      ...rest
    } = props;

    if (errorText && successText) {
      Logger.warn(
        'successText and errorText props were both supplied to the Input component',
      );
    }

    return (
      <>
        {label && (
          <UI.Label
            style={{
              marginBottom: instructionText ? '4px' : Tokens.rhythm,
            }}
            htmlFor={id}
            required={required}
          >
            {optional ? (
              <span>
                {label} <i>(optional)</i>
              </span>
            ) : (
              label
            )}
          </UI.Label>
        )}

        {instructionText && (
          <InstructionText>{instructionText}</InstructionText>
        )}

        <InputWrapper fullWidth={fullWidth}>
          {leftIcon && (
            <IconWrapper
              multiline={multiline}
              disabled={disabled}
              onClick={onClick}
              position="left"
            >
              {leftIcon}
            </IconWrapper>
          )}

          <StyledInput
            id={id}
            as={multiline ? 'textarea' : 'input'}
            rows={rows}
            disabled={disabled}
            errorText={errorText}
            fullWidth={fullWidth}
            onClick={onClick}
            required={required}
            rightIcon={rightIcon}
            rightLabel={rightLabel}
            leftIcon={leftIcon}
            multiline={multiline}
            ref={inputRef}
            {...rest}
          />

          {rightIcon && (
            <IconWrapper
              multiline={multiline}
              disabled={disabled}
              onClick={onClick}
              position="right"
            >
              {rightIcon}
            </IconWrapper>
          )}

          {rightLabel && <span className="rightLabel">{rightLabel}</span>}
        </InputWrapper>

        {helpText && !errorText && !successText && (
          <UI.HelpText>{helpText}</UI.HelpText>
        )}
        {errorText && <UI.HelpText error>{errorText}</UI.HelpText>}
        {successText && <UI.HelpText success>{successText}</UI.HelpText>}
      </>
    );
  },
);

export default Input;
