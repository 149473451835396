import React from 'react';

import { Tokens } from 'config';
import UI from 'ui';
import { ButtonProps, ButtonHTMLTags, ButtonVariants } from './types';
import StyledButton from './StyledButton';
import StyledLinkButton from './StyledLinkButton';

/**
 * Use `as` if explicitly provided. Otherwise, if `variant` is "link" return `<a>` tag.
 * @param variant the button variant
 * @param as "a" or "button" (optional)
 */
const getHtmlTag = (
  variant: ButtonVariants,
  as?: ButtonHTMLTags,
): ButtonHTMLTags => {
  let htmlTag;
  if (as !== undefined) {
    htmlTag = as;
  } else {
    htmlTag = variant === 'link' ? 'a' : 'button';
  }
  return htmlTag as ButtonHTMLTags;
};

export const ButtonPropError = Error;

/**
 * Validate the button props.
 * @param {ButtonProps} props `ButtonProps`
 * @throws {ButtonPropError}
 */
const validateProps = (props: ButtonProps, htmlTag: ButtonHTMLTags): void => {
  if (htmlTag === 'a' && !props.href) {
    throw new ButtonPropError(
      'Buttons rendered as <a> tag must include "href" prop.',
    );
  }
  if (htmlTag === 'label' && !props.htmlFor) {
    throw new ButtonPropError(
      'Buttons rendered as <label> tag must include "htmlFor" prop.',
    );
  }
  if (htmlTag !== 'a' && !!props.href) {
    throw new ButtonPropError(
      'Only buttons rendered as <a> can include "href" prop.',
    );
  }
  if (htmlTag !== 'label' && !!props.htmlFor) {
    throw new ButtonPropError(
      'Only buttons rendered as <label> can include "htmlFor" prop.',
    );
  }
};

/**
 * Return a Spinner styled for loading state.
 */
const renderSpinner = () => (
  <UI.Spinner
    testId="loading-spinner"
    size="small"
    foregroundColor={Tokens.color.ui.charcoal.base}
    backgroundColor={Tokens.color.neutral.grey[219]}
  />
);

/**
 * UI Button Component
 * @param {ButtonProps} props
 */
const Button = (props: ButtonProps): JSX.Element => {
  const {
    as,
    children,
    disabled,
    href,
    htmlFor,
    isLoading,
    leftIcon,
    rightIcon,
    onClick,
    size,
    type,
    variant,
    ...rest
  } = props;

  const renderContent = () => {
    if (isLoading && (size === 'icon' || size === 'smallIcon')) {
      return renderSpinner();
    }
    return (
      <>
        {(isLoading || leftIcon) && (
          <div className="sideIcon left">
            {isLoading ? renderSpinner() : leftIcon}
          </div>
        )}
        {children}
        {rightIcon && <div className="sideIcon right">{rightIcon}</div>}
      </>
    );
  };

  const htmlTag = getHtmlTag(variant, as);
  validateProps(props, htmlTag);

  const ButtonComponent = variant === 'link' ? StyledLinkButton : StyledButton;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    (event.currentTarget as HTMLElement).blur();
    if (onClick && !isLoading) {
      onClick(event);
    }
  };

  return (
    <ButtonComponent
      as={htmlTag}
      disabled={disabled}
      href={htmlTag === 'a' ? href : undefined}
      htmlFor={htmlTag === 'label' ? htmlFor : undefined}
      isLoading={isLoading}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={handleClick}
      role={htmlTag === 'a' ? 'button' : undefined}
      size={size}
      type={htmlTag === 'button' ? type : undefined}
      variant={variant}
      {...rest}
    >
      {renderContent()}
    </ButtonComponent>
  );
};

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  href: null,
  isLoading: false,
  size: 'default',
  type: 'button',
  variant: 'primary',
};

export default Button;
