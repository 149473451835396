import styled from 'styled-components';

import { Tokens } from 'config';
import { ButtonProps } from './types';

const StyledButton = styled.button<ButtonProps>(
  ({ variant, disabled, size, isLoading }) => {
    const sizeVars = (() => {
      switch (size) {
        case 'small':
          return `
            --font-size: ${Tokens.font.size.paragraph.small};
            --height: 24px;
            --padding-left-right: ${Tokens.rhythm};
            --width: auto;
          `;
        case 'icon':
          return `
            --font-size: ${Tokens.icon.size.base};
            --height: 32px;
            --padding-left-right: 0;
            --width: 32px;

            & > * {
              margin: 0 auto;
            }
          `;
        case 'smallIcon':
          return `
            --font-size: ${Tokens.icon.size.small};
            --height: 24px;
            --padding-left-right: 0;
            --width: 24px;

            & > * {
              margin: 0 auto;
            }
          `;
        case 'default':
        default:
          return `
            --font-size: ${Tokens.font.size.paragraph.base};
            --height: 32px;
            --padding-left-right: ${Tokens.rhythm};
            --width: auto;
          `;
      }
    })();

    const variantVars = (() => {
      switch (variant) {
        case 'secondary':
          return `
            --background-color: ${Tokens.color.neutral.white.base};
            --background-color-active: ${Tokens.color.neutral.white.base};
            --border-color: ${Tokens.color.ui.charcoal.base};
            --color: ${Tokens.color.ui.charcoal.base};
          `;
        case 'tertiary':
          return `
            --background-color: transparent;
            --border-color: transparent;
            --color: ${Tokens.color.ui.charcoal.base};
          `;
        case 'danger':
          return `
            --background-color: ${Tokens.color.ui.error.base};
            --background-color-active: ${Tokens.color.ui.error.transparent[90]};
            --border-color: ${Tokens.color.ui.error.base};
            --color: ${Tokens.color.neutral.white.base};
          `;
        case 'success':
          return `
            --background-color: ${Tokens.color.ui.success.base};
            --background-color-active: ${Tokens.color.ui.success.transparent[90]};
            --border-color: ${Tokens.color.ui.success.base};
            --color: ${Tokens.color.neutral.white.base};
          `;
        case 'basic':
          return `
            --background-color: ${Tokens.color.neutral.white.base};
            --background-color-active: ${Tokens.color.neutral.white.base};
            --border-color: ${Tokens.color.ui.charcoal.transparent[25]};
            --color: ${Tokens.color.ui.charcoal.base};
          `;
        case 'primary':
        default:
          return `
            --background-color: ${Tokens.color.ui.charcoal.base};
            --background-color-active: ${Tokens.color.ui.charcoal.transparent[90]};
            --border-color: ${Tokens.color.ui.charcoal.base};
            --color: ${Tokens.color.neutral.white.base};
          `;
      }
    })();

    const overrideVars = (() => {
      if (isLoading) {
        return `
          --background-color-override: ${
            variant === 'tertiary'
              ? 'transparent'
              : Tokens.color.neutral.grey[250]
          };
          --border-color-override: ${
            variant === 'tertiary'
              ? 'transparent'
              : Tokens.color.neutral.grey[250]
          };
          --color-override: ${Tokens.color.ui.charcoal.base};
          --cursor-override: default;
        `;
      }
      if (disabled) {
        if (variant === 'basic') {
          return `
            --opacity-override: 0.25;
            --cursor-override: not-allowed;
          `;
        }
        return `
          --background-color-override: ${Tokens.color.neutral.grey[240]};
          --border-color-override: ${Tokens.color.neutral.grey[219]};
          --color: ${Tokens.color.neutral.grey[82]};
          --cursor-override: not-allowed;
        `;
      }
      return '';
    })();

    return `
      ${sizeVars}
      ${variantVars}
      ${overrideVars}

      align-items: center;
      background-color: var(--background-color-override, var(--background-color));
      border: 1px;
      border-color: var(--border-color-override, var(--border-color));
      border-style: solid;
      border-radius: 2px;
      color: var(--color-override, var(--color));
      cursor: var(--cursor-override, pointer);
      display: flex;
      font-family: ${Tokens.font.family};
      font-size: var(--font-size);
      height: var(--height);
      line-height: 1;
      opacity: var(--opacity-override, 1);
      padding: 0 var(--padding-left-right);
      width: var(--width);

      &:focus,
      &:active,
      &.active {
        outline: ${disabled ? 0 : Tokens.outline};
      }

      &:focus,
      &:active,
      &.active,
      &:hover,
      &.hover {
        background-color: var(--background-color-override, var(--background-color-active));
      }

      .sideIcon {
        display: flex;
        font-size: 16px;
        alignItems: center;

        &.left {
          margin-right: ${
            size !== 'icon' && size !== 'smallIcon' ? Tokens.rhythm : 0
          };
        }

        &.right {
          margin-left: ${Tokens.rhythm};
        }
      }
    }
  `;
  },
);

export default StyledButton;
