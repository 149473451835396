import UI from 'ui';
import AlertBox from 'ui/AlertBox';
import { useDoseSpotModal } from './hooks';

type TQueryArgs = {
  encounterId?: number;
  marketId: number;
  patientId: number;
};

type DoseSpotModalProps = {
  urlQueryArgs?: TQueryArgs;
  onClose: () => void;
  open: boolean;
};

const DoseSpotModal = ({
  onClose,
  open,
  urlQueryArgs,
}: DoseSpotModalProps): JSX.Element => {
  const { errorMessage, doseSpotUrl, loading } = useDoseSpotModal({
    open,
    urlQueryArgs,
  });

  return (
    <UI.Modal
      open={open}
      onClose={onClose}
      size="fullscreen"
      title="DoseSpot"
      body={
        loading ? (
          <UI.Spinner centered />
        ) : (
          <>
            {errorMessage && (
              <div style={{ display: 'block', margin: '0 auto' }}>
                <UI.AlertBox
                  severity={AlertBox.SeverityLevel.Danger}
                  label="Unable to load DoseSpot"
                  message={errorMessage}
                />
              </div>
            )}
            {doseSpotUrl && (
              <iframe src={doseSpotUrl} style={{ flexGrow: 1, border: 0 }} />
            )}
          </>
        )
      }
    />
  );
};

export default DoseSpotModal;
