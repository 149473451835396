import {
  Controller,
  Control,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';

import Input from 'ui/Input/Input';
import { InputProps } from 'ui/Input/types';

/**
 * Controlled Input Props
 * @extends {UI.Input}
 */
type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = Omit<InputProps, 'name' | 'errorText' | 'id' | 'onChange' | 'value'> & {
  /**
   * `control` object provided by invoking `useForm`
   */
  control: Control<TFieldValues>;

  /**
   * The same as an uncontrolled component's `defaultValue`.
   */
  defaultValue?: UnpackNestedValue<PathValue<TFieldValues, TName>>;

  /**
   * Unique name of your input.
   */
  name: TName;
};

const ControlledInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  defaultValue,
  name,
  ...props
}: Props<TFieldValues, TName>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { value, ...field }, fieldState: { error } }) => (
      <Input
        id={name}
        value={value}
        errorText={error?.message}
        {...field}
        {...props}
      />
    )}
  />
);

export default ControlledInput;
