import { ReviewOfSystemSystemEnum } from 'generated/graphql';

export const evaluations = [
  {
    id: 1,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'COVID-19 Symptoms per CDC',
    covid19: true,
  },
  {
    id: 2,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'Contact with COVID-19 Person',
    covid19: true,
  },
  {
    id: 3,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'Patient with chronic conditions',
    covid19: true,
  },
  {
    id: 4,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'High Risk Exposure / Occupation',
    covid19: true,
  },
  {
    id: 5,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'Recent travel or large gatherings',
    covid19: true,
  },
  {
    id: 6,
    system: ReviewOfSystemSystemEnum.COVID_19_SCREENING,
    evaluation: 'COVID-19 Vaccinated',
    covid19: true,
  },
  {
    id: 7,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Malaise',
    covid19: false,
  },
  {
    id: 8,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Decreased Appetite',
    covid19: false,
  },
  {
    id: 9,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Fever',
    covid19: true,
  },
  {
    id: 10,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Diaphoresis',
    covid19: false,
  },
  {
    id: 11,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Fatigue',
    covid19: false,
  },
  {
    id: 12,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Decreased Fluid Intake',
    covid19: false,
  },
  {
    id: 13,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Chills',
    covid19: true,
  },
  {
    id: 14,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Unintentional weight change',
    covid19: false,
  },
  {
    id: 15,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Dizziness/Vertigo',
    covid19: true,
  },
  {
    id: 16,
    system: ReviewOfSystemSystemEnum.CONSTITUTIONAL,
    evaluation: 'Weight Changes',
    covid19: false,
  },
  {
    id: 17,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Discharge',
    covid19: false,
  },
  {
    id: 18,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Redness',
    covid19: false,
  },
  {
    id: 19,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Pain',
    covid19: false,
  },
  {
    id: 20,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Contact Lens Use',
    covid19: false,
  },
  {
    id: 21,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Double Vision',
    covid19: false,
  },
  {
    id: 22,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Swelling',
    covid19: false,
  },
  {
    id: 23,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Sensitivity to Light',
    covid19: false,
  },
  {
    id: 24,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Vision Changes',
    covid19: false,
  },
  {
    id: 25,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Foreign Body Sensation',
    covid19: false,
  },
  {
    id: 26,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Itching',
    covid19: false,
  },
  {
    id: 27,
    system: ReviewOfSystemSystemEnum.EYES,
    evaluation: 'Watery',
    covid19: false,
  },
  {
    id: 28,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Sore Throat',
    covid19: false,
  },
  {
    id: 29,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Painful Swallowing',
    covid19: false,
  },
  {
    id: 30,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Ear Pain',
    covid19: false,
  },
  {
    id: 31,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Tinnitus',
    covid19: false,
  },
  {
    id: 32,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Runny Nose',
    covid19: false,
  },
  {
    id: 33,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Sinus Pain/Pressure',
    covid19: false,
  },
  {
    id: 34,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Facial Swelling',
    covid19: false,
  },
  {
    id: 35,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Gum Swelling',
    covid19: false,
  },
  {
    id: 36,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Difficulty Swallowing',
    covid19: false,
  },
  {
    id: 37,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Ear Discharge',
    covid19: false,
  },
  {
    id: 38,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Hearing loss',
    covid19: false,
  },
  {
    id: 39,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Nosebleeds',
    covid19: false,
  },
  {
    id: 40,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Sneezing',
    covid19: false,
  },
  {
    id: 41,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Post Nasal Drip',
    covid19: false,
  },
  {
    id: 42,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Dental Pain',
    covid19: false,
  },
  {
    id: 43,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Gum Drainage',
    covid19: false,
  },
  {
    id: 44,
    system: ReviewOfSystemSystemEnum.ENT,
    evaluation: 'Nasal Congestion',
    covid19: false,
  },
  {
    id: 45,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'Chest Pain',
    covid19: true,
  },
  {
    id: 46,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'Peripheral Edema',
    covid19: false,
  },
  {
    id: 47,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'PND',
    covid19: false,
  },
  {
    id: 48,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'Palpitations',
    covid19: true,
  },
  {
    id: 49,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'Fainting/Near Fainting',
    covid19: true,
  },
  {
    id: 50,
    system: ReviewOfSystemSystemEnum.CARDIOVASCULAR,
    evaluation: 'Orthopnea',
    covid19: false,
  },
  {
    id: 51,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Shortness of Breath',
    covid19: true,
  },
  {
    id: 52,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Sputum',
    covid19: true,
  },
  {
    id: 53,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Dyspnea on Exertion',
    covid19: true,
  },
  {
    id: 54,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Pleuritic Chest Pain',
    covid19: false,
  },
  {
    id: 55,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Cough',
    covid19: true,
  },
  {
    id: 56,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Hemoptysis',
    covid19: false,
  },
  {
    id: 57,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Wheezing',
    covid19: false,
  },
  {
    id: 58,
    system: ReviewOfSystemSystemEnum.RESPIRATORY,
    evaluation: 'Stridor/Airway Noises',
    covid19: false,
  },
  {
    id: 59,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Abdominal Pain',
    covid19: false,
  },
  {
    id: 60,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Bloating',
    covid19: false,
  },
  {
    id: 61,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Vomiting',
    covid19: true,
  },
  {
    id: 62,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Diarrhea',
    covid19: true,
  },
  {
    id: 63,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Bloody Stool',
    covid19: false,
  },
  {
    id: 64,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Heartburn',
    covid19: false,
  },
  {
    id: 65,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Abdominal Cramping',
    covid19: false,
  },
  {
    id: 66,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Nausea',
    covid19: true,
  },
  {
    id: 67,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Blood in Vomit',
    covid19: false,
  },
  {
    id: 68,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Constipation',
    covid19: false,
  },
  {
    id: 69,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Excessive Gas',
    covid19: false,
  },
  {
    id: 70,
    system: ReviewOfSystemSystemEnum.GASTROINTESTINAL,
    evaluation: 'Dark Stool',
    covid19: false,
  },
  {
    id: 71,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Dysuria',
    covid19: false,
  },
  {
    id: 72,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Urgency',
    covid19: false,
  },
  {
    id: 73,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Supra Pubic Abdominal Pain',
    covid19: false,
  },
  {
    id: 74,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Incontinence',
    covid19: false,
  },
  {
    id: 75,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Penile Discharge',
    covid19: false,
  },
  {
    id: 76,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Frequency',
    covid19: false,
  },
  {
    id: 77,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Hematuria',
    covid19: false,
  },
  {
    id: 78,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Flank pain',
    covid19: false,
  },
  {
    id: 79,
    system: ReviewOfSystemSystemEnum.GENITOURINARY,
    evaluation: 'Testicular Pain',
    covid19: false,
  },
  {
    id: 80,
    system: ReviewOfSystemSystemEnum.OB_GYN,
    evaluation: 'LMP (date)',
    covid19: false,
  },
  {
    id: 81,
    system: ReviewOfSystemSystemEnum.OB_GYN,
    evaluation: 'Vaginal Bleeding',
    covid19: false,
  },
  {
    id: 82,
    system: ReviewOfSystemSystemEnum.OB_GYN,
    evaluation: 'Hot flashes',
    covid19: false,
  },
  {
    id: 83,
    system: ReviewOfSystemSystemEnum.OB_GYN,
    evaluation: 'Vaginal Discharge',
    covid19: false,
  },
  {
    id: 84,
    system: ReviewOfSystemSystemEnum.OB_GYN,
    evaluation: 'Pelvic Pain',
    covid19: false,
  },
  {
    id: 85,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Rash',
    covid19: false,
  },
  {
    id: 86,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Hives',
    covid19: false,
  },
  {
    id: 87,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Abrasion',
    covid19: false,
  },
  {
    id: 88,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Erythema',
    covid19: false,
  },
  {
    id: 89,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Contusion',
    covid19: false,
  },
  {
    id: 90,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Drainage',
    covid19: false,
  },
  {
    id: 91,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Itching',
    covid19: false,
  },
  {
    id: 92,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Swelling',
    covid19: false,
  },
  {
    id: 93,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Laceration',
    covid19: false,
  },
  {
    id: 94,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Warmth',
    covid19: false,
  },
  {
    id: 95,
    system: ReviewOfSystemSystemEnum.SKIN,
    evaluation: 'Abscess',
    covid19: false,
  },
  {
    id: 96,
    system: ReviewOfSystemSystemEnum.MUSCULOSKELETAL,
    evaluation: 'Muscle Pain',
    covid19: true,
  },
  {
    id: 97,
    system: ReviewOfSystemSystemEnum.MUSCULOSKELETAL,
    evaluation: 'Neck Pain',
    covid19: false,
  },
  {
    id: 98,
    system: ReviewOfSystemSystemEnum.MUSCULOSKELETAL,
    evaluation: 'Chest Pain',
    covid19: false,
  },
  {
    id: 99,
    system: ReviewOfSystemSystemEnum.MUSCULOSKELETAL,
    evaluation: 'Joint Pain',
    covid19: false,
  },
  {
    id: 100,
    system: ReviewOfSystemSystemEnum.MUSCULOSKELETAL,
    evaluation: 'Back Pain',
    covid19: false,
  },
  {
    id: 101,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Headache',
    covid19: true,
  },
  {
    id: 102,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Vertigo',
    covid19: false,
  },
  {
    id: 103,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Ataxia',
    covid19: false,
  },
  {
    id: 104,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Saddle Anesthesia',
    covid19: false,
  },
  {
    id: 105,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Bladder Dysfunction',
    covid19: false,
  },
  {
    id: 106,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Slurred Speach',
    covid19: true,
  },
  {
    id: 107,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Focal Weakness',
    covid19: true,
  },
  {
    id: 108,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Seizure',
    covid19: false,
  },
  {
    id: 109,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Balance Disturbance',
    covid19: false,
  },
  {
    id: 110,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Numbness/Tingling',
    covid19: false,
  },
  {
    id: 111,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Bowel Dysfunction',
    covid19: false,
  },
  {
    id: 112,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Radicular Symptoms',
    covid19: false,
  },
  {
    id: 113,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Vision Disturbance',
    covid19: false,
  },
  {
    id: 114,
    system: ReviewOfSystemSystemEnum.NEUROLOGICAL,
    evaluation: 'Sensory Changes',
    covid19: true,
  },
  {
    id: 115,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Depression',
    covid19: false,
  },
  {
    id: 116,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Anxiety',
    covid19: false,
  },
  {
    id: 117,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Homicidal Ideations',
    covid19: false,
  },
  {
    id: 118,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Stress',
    covid19: false,
  },
  {
    id: 119,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Suicidal Ideations',
    covid19: false,
  },
  {
    id: 120,
    system: ReviewOfSystemSystemEnum.PSYCH,
    evaluation: 'Hallucinations',
    covid19: false,
  },
  {
    id: 121,
    system: ReviewOfSystemSystemEnum.HEME_LYMPH,
    evaluation: 'Lymphadenopathy',
    covid19: false,
  },
  {
    id: 122,
    system: ReviewOfSystemSystemEnum.HEME_LYMPH,
    evaluation: 'Easy Bruising',
    covid19: false,
  },
  {
    id: 123,
    system: ReviewOfSystemSystemEnum.HEME_LYMPH,
    evaluation: 'Anticoagulant Use',
    covid19: false,
  },
  {
    id: 124,
    system: ReviewOfSystemSystemEnum.HEME_LYMPH,
    evaluation: 'Edema',
    covid19: false,
  },
  {
    id: 125,
    system: ReviewOfSystemSystemEnum.HEME_LYMPH,
    evaluation: 'Easy Bleeding',
    covid19: false,
  },
  {
    id: 126,
    system: ReviewOfSystemSystemEnum.ENDOCRINE,
    evaluation: 'Polydipsia',
    covid19: false,
  },
  {
    id: 127,
    system: ReviewOfSystemSystemEnum.ENDOCRINE,
    evaluation: 'Temperature Intolerance',
    covid19: false,
  },
  {
    id: 128,
    system: ReviewOfSystemSystemEnum.ENDOCRINE,
    evaluation: 'Polyphagia',
    covid19: false,
  },
];
