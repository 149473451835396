import { useEffect, useState } from 'react';
import { List } from 'phosphor-react';
import shortid from 'shortid';
import { useIdleTimer } from 'react-idle-timer';

import { Config } from 'config';
import { useLogout, useSidebarContext } from 'hooks';
import { DoseSpotNotifications, Sidebar } from 'modules';
import { LinkTo } from 'ui/Breadcrumbs/types';
import UI from 'ui';
import Toast, { SeverityLevel } from 'ui/Toast/Toast';
import { ToastConsumer } from 'providers/ToastContextProvider';
import StyledLayout from './StyledLayout';

type BreadcrumbsProps = {
  title: string;
  href?: string;
  to?: LinkTo;
};

type Props = {
  children?: React.ReactNode;
  title?: string;
  breadcrumbsProps?: BreadcrumbsProps[];
};

const Layout = ({
  children,
  title = 'Page Title',
  breadcrumbsProps,
}: Props): JSX.Element => {
  const COUNTDOWN = 60;
  const sidebarContext = useSidebarContext();
  const { logout } = useLogout();
  const [modalOpen, setModalOpen] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(COUNTDOWN);
  const { reset } = useIdleTimer({
    timeout: Config.inactivityTimeout,
    onIdle: () => {
      if (document.hasFocus()) {
        setModalOpen(true);
      } else {
        logout(true);
      } // Only launch timeout modal if ready health is in focus
    },
  });
  const asideClasses = ['sidebar'];
  const overlayClasses = ['overlay'];

  document.title = `${title} | Ready Health`;

  if (sidebarContext?.sidebarIsOpen) {
    asideClasses.push('visible');
    overlayClasses.push('visible');
  }

  useEffect(() => {
    if (sidebarContext?.sidebarIsOpen) {
      sidebarContext?.setSidebarIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (modalOpen) {
      setTimeout(
        () => setLogoutTimer((count) => (count > 0 ? count - 1 : count)),
        1000,
      );
    }
    if (logoutTimer === 0) logout(true); // Is idle timeout
  }, [modalOpen, logoutTimer]);

  return (
    <StyledLayout>
      <div
        className={overlayClasses.join(' ')}
        onClick={() => sidebarContext?.setSidebarIsOpen(false)}
      />

      <aside className={asideClasses.join(' ')}>
        <Sidebar />
      </aside>

      <main>
        <header>
          <UI.Button
            onClick={() => sidebarContext?.setSidebarIsOpen(true)}
            size="icon"
            variant="secondary"
          >
            <List size={22} />
          </UI.Button>

          {breadcrumbsProps && (
            <UI.Breadcrumbs data-testid="breadcrumbs">
              {breadcrumbsProps.map(
                (
                  { title: breadcrumbTitle, href, to }: BreadcrumbsProps,
                  index: number,
                ) => (
                  <UI.Breadcrumb
                    key={shortid.generate()}
                    href={href}
                    to={to}
                    active={index === breadcrumbsProps.length - 1}
                  >
                    {breadcrumbTitle}
                  </UI.Breadcrumb>
                ),
              )}
            </UI.Breadcrumbs>
          )}
          <div className="grow" />
          <DoseSpotNotifications />
        </header>

        <div className="content">{children}</div>

        <ToastConsumer>
          {(value: { message?: string; severity?: SeverityLevel }) => (
            <Toast
              testId="toast"
              severity={value?.severity}
              message={value?.message}
            />
          )}
        </ToastConsumer>

        <UI.Modal
          body={
            <>
              <div>
                Your current session will expire in {logoutTimer} seconds. In
                order to keep your account secure, we periodically check if you
                are still here. You can stay logged in by clicking below.
              </div>
            </>
          }
          closeButtonLabel="Log out"
          onClose={() => logout()}
          title="Are you still here?"
          size="small"
          open={modalOpen}
          footerButtons={[
            {
              children: 'Keep me logged in',
              variant: 'primary',
              size: 'small',
              onClick: () => {
                reset();
                setLogoutTimer(COUNTDOWN);
                setModalOpen(false);
              },
            },
          ]}
        />
      </main>
    </StyledLayout>
  );
};

export default Layout;
