import { useEffect, useRef } from 'react';
import { FieldValues } from 'react-hook-form';

import type { FormFields } from './useForm';

type FormState<TFieldValues extends FieldValues = FormFields> =
  | Readonly<FormFields>
  | TFieldValues;

export type TOptions<
  TFieldValues extends FieldValues,
  TSubmitReturnValues extends Record<string, unknown> | undefined | null,
> = {
  formState: FormState<TFieldValues>;
  skipped: boolean;
  handleSubmit?: (shouldValidate: boolean, formState: FormState) => void;
  handleHookFormSubmit?: (
    formData: TFieldValues,
  ) => Promise<TSubmitReturnValues> | Promise<void>;
};

const useAutosave = <
  TFieldValues extends FieldValues,
  TSubmitReturnValues extends Record<string, unknown> | undefined | null,
>(
  options: TOptions<TFieldValues, TSubmitReturnValues>,
): void => {
  const formStateRef = useRef(options.formState);
  const skippedRef = useRef(options.skipped);

  useEffect(() => {
    formStateRef.current = options.formState;
  }, [options.formState]);

  useEffect(() => {
    skippedRef.current = options.skipped;
  }, [options.skipped]);

  useEffect(
    () => () => {
      if (!skippedRef.current) {
        if (options.handleHookFormSubmit) {
          options.handleHookFormSubmit(formStateRef.current as TFieldValues);
          return;
        }
        if (options.handleSubmit) {
          options.handleSubmit(false, formStateRef.current);
        }
      }
    },
    [],
  ); // If not skipped, then save data before tearing down
};

export default useAutosave;
