import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  SystemOfOriginEnum,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';

import {
  usePersistTask,
  useDidMountEffect,
  useTask,
  useToastMessage,
} from 'hooks';
import { useForm } from 'forms';
import UI from 'ui';
import { Logger } from 'utils';
import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import AttachmentsForm from './AttachmentsForm';
import {
  TransferOfCareInputs,
  TransferOfCareSchema,
  LeaveBehindInputs,
  LeaveBehindSchema,
  getDefaultValues,
} from './utils';

const AttachmentsFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const defaultValues = getDefaultValues(task);
  const Schema =
    task?.dataCategory === 'leave_behind'
      ? LeaveBehindSchema
      : TransferOfCareSchema;
  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<LeaveBehindInputs & TransferOfCareInputs>(Schema, {
    defaultValues,
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      onPersistTask: (updatedTask) => reset(getDefaultValues(updatedTask)),
    },
  );

  const leaveBehindAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.LEAVE_BEHIND,
  )?.attachmentId;
  const transferOfCareAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.TRANSFER_OF_CARE,
  )?.attachmentId;
  const workOrSchoolNoteAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.WORK_SCHOOL_NOTE,
  )?.attachmentId;

  const saveAttachment = async ({
    description,
    type,
    url,
  }: {
    description: string;
    type: EncounterTaskAttachmentTypeEnum;
    url: string;
  }) => {
    if (!type || !url) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              { description, type, images: [{ url }], isRequired: true },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);

      switch (type) {
        case EncounterTaskAttachmentTypeEnum.LEAVE_BEHIND:
          setValue('leaveBehindAttachment', null);
          break;
        case EncounterTaskAttachmentTypeEnum.TRANSFER_OF_CARE:
          setValue('transferOfCareAttachment', null);
          break;
        case EncounterTaskAttachmentTypeEnum.WORK_SCHOOL_NOTE:
          setValue('workOrSchoolNoteAttachment', null);
          break;
        default:
          Logger.warn(`Attachment type ${type} not handled`);
      }

      setToastMessage(
        `An error occurred while uploading the attachment. Please try again.`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  const deleteAttachment = async ({ id }: { id?: number }) => {
    if (!id) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              {
                images: [
                  {
                    encounterTaskAttachmentId: id,
                    isDeleted: true,
                  },
                ],
              },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  useDidMountEffect(() => {
    if (formState.leaveBehindAttachment) {
      saveAttachment({
        description: 'Leave Behind',
        type: EncounterTaskAttachmentTypeEnum.LEAVE_BEHIND,
        url: formState.leaveBehindAttachment,
      });
    } else {
      deleteAttachment({
        id: leaveBehindAttachmentId,
      });
    }
  }, [formState.leaveBehindAttachment]);

  useDidMountEffect(() => {
    if (formState.transferOfCareAttachment) {
      saveAttachment({
        description: 'Transfer of Care',
        type: EncounterTaskAttachmentTypeEnum.TRANSFER_OF_CARE,
        url: formState.transferOfCareAttachment,
      });
    } else {
      deleteAttachment({
        id: transferOfCareAttachmentId,
      });
    }
  }, [formState.transferOfCareAttachment]);

  useDidMountEffect(() => {
    if (formState.workOrSchoolNoteAttachment) {
      saveAttachment({
        description: 'Work School Note',
        type: EncounterTaskAttachmentTypeEnum.WORK_SCHOOL_NOTE,
        url: formState.workOrSchoolNoteAttachment,
      });
    } else {
      deleteAttachment({
        id: workOrSchoolNoteAttachmentId,
      });
    }
  }, [formState.workOrSchoolNoteAttachment]);

  return (
    <AttachmentsForm
      control={control}
      errors={errors}
      inputsAreDisabled={inputsAreDisabled}
      onCompleteTask={handleCompleteTask}
      onSaveTask={handleSaveTask}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default AttachmentsFormContainer;
