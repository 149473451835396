import React, { useEffect } from 'react';
import { format as formatDate } from 'date-fns';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Tokens } from 'config';

const AllergiesSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const patientAllergies = task?.patientAllergyList;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isAllergiesTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Patient Allergies may not exist on this encounter task (defensive)
  if (
    patientAllergies === null ||
    patientAllergies === undefined ||
    patientAllergies.length === 0
  )
    return null;

  return (
    <>
      <h2>Non-Drug allergies</h2>

      <Grid style={{ marginBottom: Tokens.rhythm }}>
        <div>
          <strong>Name:</strong>
        </div>
        <div>
          <strong>Reaction:</strong>
        </div>
        <div>
          <strong>Reaction Type:</strong>
        </div>
        <div>
          <strong>Added Date:</strong>
        </div>
      </Grid>

      {patientAllergies.map((patientAllergy) => {
        return (
          <Grid key={patientAllergy?.patientAllergyId}>
            <div>{patientAllergy?.allergy?.name ?? '-'}</div>
            <div>{patientAllergy?.allergicReaction?.name ?? '-'}</div>
            <div>{patientAllergy?.allergy?.allergyType?.name ?? '-'}</div>
            <div>
              {formatDate(new Date(patientAllergy?.startedAt), 'M/dd/yyyy') ??
                '-'}
            </div>
          </Grid>
        );
      })}
    </>
  );
};

export default AllergiesSummary;
