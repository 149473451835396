import { useGetDoseSpotNotificationDetailsQuery } from 'generated/graphql';
import { useUserContext } from 'hooks';
import { useEffect } from 'react';
import { Logger } from 'utils';

type ReturnValues = {
  errorCount: number;
  handleButtonClick: () => void;
  showNotifications: boolean;
};

const useDoseSpotNotifications = (): ReturnValues => {
  const userContext = useUserContext();
  const { data, error } = useGetDoseSpotNotificationDetailsQuery({
    skip: !userContext?.operatingAsClinician,
    pollInterval: 1000 * 60 * 10, // 10 mins
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Logger.error(`DoseSpot Notifications Error: ${error?.message}`);
    }
  }, [error]);

  const errorCount =
    data?.getDoseSpotNotificationDetails?.notificationCount ?? 0;

  const handleButtonClick = () => {
    const url = data?.getDoseSpotNotificationDetails?.notificationsUrl;
    if (errorCount > 0 && url) {
      window.open(url, '_blank');
    }
  };

  return {
    errorCount,
    handleButtonClick,
    showNotifications: userContext?.operatingAsClinician ?? false,
  };
};

export default useDoseSpotNotifications;
