import { useEffect, useRef } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { useUserContext } from 'hooks';
import {
  useGetAppointmentDispatchesByResponderIdQuery,
  useGetAppointmentsByResponderIdQuery,
  ResponderAppointmentFilter,
} from 'generated/graphql';
import { RoutePaths } from 'config';
import { Layout } from 'modules';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import UI from 'ui';
import { AppointmentsView } from './components';
import AppointmentsContext from './AppointmentsContext';

const AppointmentsContainer = (): JSX.Element => {
  const userContext = useUserContext();
  const userId = userContext?.user?.userId as number;

  const history = useHistory();
  const location = useLocation();

  // Filters
  const queryParams = new URLSearchParams(location.search);
  const filter: ResponderAppointmentFilter =
    (queryParams.get('filter') as ResponderAppointmentFilter) ??
    ResponderAppointmentFilter.TODAY;

  // Expanded Items
  const expandedItemsRef = useRef(location.state?.expandedItems ?? []);

  const {
    loading: appointmentDispatchesLoading,
    error: appointmentDispatchesError,
    startPolling,
    stopPolling,
  } = useGetAppointmentDispatchesByResponderIdQuery({
    variables: { responderId: userId },
  });

  const {
    loading: appointmentsLoading,
    error: appointmentsError,
    data: appointmentsData,
  } = useGetAppointmentsByResponderIdQuery({
    variables: { responderId: userId, filter },
  });

  const getEmptyMessage = () => {
    switch (filter) {
      case 'CANCELLED':
        return 'You have no cancelled appointments from the last 7 days!';
      case 'FUTURE':
        return 'You have no future appointments scheduled yet!';
      case 'PAST':
        return 'You have no past appointments from the last 7 days!';
      case 'TODAY':
      default:
        return 'You have no appointments for today!';
    }
  };

  const handleTabClick = (_filter: ResponderAppointmentFilter) => {
    const search = _filter === 'TODAY' ? undefined : `?filter=${_filter}`;
    history.replace(
      {
        pathname: RoutePaths.appointments,
        search,
      },
      {
        expandedItems: expandedItemsRef.current,
      },
    );
  };

  const updateExpandedItems = (appointmentId: number, expanded: boolean) => {
    expandedItemsRef.current = {
      ...expandedItemsRef.current,
      [appointmentId]: expanded,
    };
  };

  const handleEncounterClick = (globalPatientId: string) => {
    history.replace(
      {
        pathname: RoutePaths.appointments,
        search: location.search,
      },
      {
        expandedItems: expandedItemsRef.current,
      },
    );
    history.push(
      {
        pathname: generatePath(PatientRoutePaths.encounters, {
          id: globalPatientId,
        }),
      },
      {
        referrer: {
          pageTitle: 'My Appointments',
          pathname: RoutePaths.appointments,
          expandedItems: expandedItemsRef.current,
          search: location.search,
          scrollPosition: document.documentElement.scrollTop,
        },
      },
    );
  };

  useEffect(() => {
    if (location.state?.scrollPosition && location.state?.scrollPosition > 0) {
      window.scrollTo(0, location.state?.scrollPosition);
    }
  }, []);

  useEffect(() => {
    startPolling(5000);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (appointmentDispatchesError || appointmentsError) {
    return (
      <UI.ErrorFallback
        error={appointmentDispatchesError || appointmentsError}
      />
    );
  }

  return (
    <Layout
      title="My Appointments"
      breadcrumbsProps={[
        {
          title: 'My Appointments',
          to: {
            pathname: RoutePaths.appointments,
            search:
              filter === ResponderAppointmentFilter.TODAY
                ? undefined
                : `?filter=${filter}`,
          },
        },
      ]}
    >
      {appointmentDispatchesLoading || appointmentsLoading ? (
        <UI.Spinner data-testid="spinner" size="large" centered />
      ) : (
        <AppointmentsContext.Provider
          value={{ onEncounterClick: handleEncounterClick }}
        >
          <AppointmentsView
            appointments={appointmentsData?.readResponderAppointments ?? []}
            emptyMessage={getEmptyMessage()}
            expandedItems={expandedItemsRef.current}
            filter={filter}
            onTabClick={handleTabClick}
            updateExpandedItems={updateExpandedItems}
          />
        </AppointmentsContext.Provider>
      )}
    </Layout>
  );
};

export default AppointmentsContainer;
