import { useEffect, useState } from 'react';

import {
  EncounterTaskAttachmentTypeEnum,
  GetEncounterTaskByEncounterTaskIdQuery,
  SystemOfOriginEnum,
  useUpsertEncounterTaskMutation,
} from 'generated/graphql';

import {
  useDidMountEffect,
  useTask,
  useToastMessage,
  usePersistTask,
  useUserContext,
} from 'hooks';
import UI from 'ui';
import { useForm } from 'forms';
import { Logger } from 'utils';
import { getAttachment } from 'modules/Patient/components/EncounterTaskAttachment/EncounterTaskAttachment';
import PointOfCareTestForm from './PointOfCareTestForm';
import {
  TInputs,
  Schema,
  getDefaultValues,
  getPatchValues,
  POCTestCategoryToLookupIdEnum,
} from './utils';

const PointOfCareTestFormContainer = ({
  task,
}: {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
}): JSX.Element => {
  const { operatingAs } = useUserContext() ?? {};
  const [upsertEncounterTask] = useUpsertEncounterTaskMutation();
  const { setToastMessage } = useToastMessage();
  const [correctionsModalOpen, setCorrectionsModalOpen] = useState(false);
  const [resultIsCritical, setResultIsCritical] = useState(false);
  const isOrderedModule = task?.group === 'Ordered Modules';
  const lookupId = task?.pointOfCareTestLookup?.pointOfCareTestLookupId;

  const defaultValues = getDefaultValues(task, operatingAs);
  const {
    control,
    handleSave,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting: isSaving, isValidating },
  } = useForm<TInputs>(Schema, {
    context: {
      isOrderedModule,
      resultIsCritical,
      task,
    },
    defaultValues,
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const {
    handleCompleteTask,
    handleCorrectTask,
    handleSaveTask,
    handleSkipTask,
  } = usePersistTask({
    task,
    isDirty,
    formState,
    handleSave,
    handleSubmit,
    getFormattedPatch: (data) => getPatchValues(data, operatingAs),
    onPersistTask: (updatedTask) =>
      reset(getDefaultValues(updatedTask, operatingAs)),
  });

  const handleCorrectionsModal = handleSubmit(async () => {
    setCorrectionsModalOpen(true);
  });

  const testResultAttachmentId = getAttachment(
    task,
    EncounterTaskAttachmentTypeEnum.TEST_RESULT,
  )?.attachmentId;

  const saveAttachment = async ({
    description,
    type,
    url,
  }: {
    description: string;
    type: EncounterTaskAttachmentTypeEnum;
    url: string;
  }) => {
    if (!type || !url) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              { description, type, images: [{ url }], isRequired: true },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
      setValue('testResultAttachment', null);
      setToastMessage(
        `An error occurred while uploading the attachment. Please try again.`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  const deleteAttachment = async ({ id }: { id?: number }) => {
    if (!id) return;
    try {
      await upsertEncounterTask({
        variables: {
          input: {
            encounterTaskId: task?.encounterTaskId ?? 0,
            systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            attachments: [
              {
                images: [
                  {
                    encounterTaskAttachmentId: id,
                    isDeleted: true,
                  },
                ],
                isRequired: true,
              },
            ],
          },
        },
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  const handleCriticalValue = (isCritical: boolean) => {
    if (isCritical) {
      setResultIsCritical(true);
    } else {
      setResultIsCritical(false);
      setValue('criticalValueConfirmation', false);
    }
  };

  useEffect(() => {
    register('standingOrderAddedAt');
    register('standingOrderId');
  }, []);

  useEffect(() => {
    if (!formState.result) {
      handleCriticalValue(false);
      return;
    }

    // If result value is critical, set validation rules in context
    switch (lookupId) {
      case POCTestCategoryToLookupIdEnum.Covid:
        if (formState.result === 'positive') {
          handleCriticalValue(true);
        } else {
          handleCriticalValue(false);
        }
        break;
      case POCTestCategoryToLookupIdEnum.Glucose:
        if (Number(formState.result) < 50 || Number(formState.result) > 400) {
          handleCriticalValue(true);
        } else {
          handleCriticalValue(false);
        }
        break;
      default:
        handleCriticalValue(false);
    }
  }, [formState.result]);

  useDidMountEffect(() => {
    if (formState.testResultAttachment) {
      saveAttachment({
        description: 'Test Result',
        type: EncounterTaskAttachmentTypeEnum.TEST_RESULT,
        url: formState.testResultAttachment,
      });
    } else {
      deleteAttachment({
        id: testResultAttachmentId,
      });
    }
  }, [formState.testResultAttachment]);

  return (
    <PointOfCareTestForm
      control={control}
      errors={errors}
      formState={formState}
      inputsAreDisabled={inputsAreDisabled}
      isOrderedModule={isOrderedModule}
      resultIsCritical={resultIsCritical}
      setValue={setValue}
      taskHeaderProps={{
        task,
        correctionsModalOpen,
        setCorrectionsModalOpen,
        handleCorrectionsModal,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
        onTaskCorrect: handleCorrectTask,
      }}
    />
  );
};

export default PointOfCareTestFormContainer;
