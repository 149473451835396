import {
  EncounterTask,
  GetEncounterTaskByEncounterTaskIdQuery,
} from 'generated/graphql';
import { DoseSpotModal } from 'modules';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import TaskHeader from '../TaskHeader';
import { StyledTaskForm } from '../TaskForm';
import { useDoseSpotForm } from './hooks';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';

type Props = {
  task: GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];
};

const DoseSpotForm = ({ task }: Props): JSX.Element => {
  const {
    closeModal,
    inputsAreDisabled,
    modalOpen,
    openModal,
    taskHeaderProps,
    urlQueryArgs,
  } = useDoseSpotForm({ task });

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledTaskForm noValidate autoComplete="off">
        <header>
          <h3>{task?.taskName}</h3>
        </header>
        <p className="formInstructionsLabel">1. Confirm patient demographics</p>
        <section>
          <p className="formGeneralNote">
            Confirm these are in the patient profile:
          </p>
          <ul>
            <li>Home address</li>
            <li>Preferred phone number</li>
          </ul>
        </section>
        <p className="formInstructionsLabel">
          2. Collect patient medications and drug allergies
        </p>
        <section>
          <p className="formGeneralNote">Ask for:</p>
          <ul>
            <li>
              Current medication bottles (or a medication list with dosages)
            </li>
            <li>Drug allergies</li>
          </ul>
          <p className="formGeneralNote">
            The clinician will then add these during the video encounter.
          </p>
        </section>
        <p className="formInstructionsLabel">
          3. Document and review medications and drug allergies in DoseSpot
        </p>
        <section>
          <UI.Button
            variant="primary"
            disabled={inputsAreDisabled}
            onClick={openModal}
          >
            Launch DoseSpot
          </UI.Button>
          <DoseSpotModal
            onClose={closeModal}
            open={modalOpen}
            urlQueryArgs={urlQueryArgs}
          />
        </section>
        {task?.encounterTaskId && (
          <AdditionalPhotoDocSection
            encounterTaskId={task?.encounterTaskId}
            disabled={inputsAreDisabled}
          />
        )}
      </StyledTaskForm>
    </>
  );
};

export default DoseSpotForm;
