import styled from 'styled-components';

const StyledModalBody = styled.div`
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    max-height: 450px;
    overflow: scroll;
  }
`;

export const modalBody = (
  <StyledModalBody>
    <p>
      Below are disposition definitions based on the encounter type and
      situation. ReadyHealth will display the disposition options based on the
      encounter type.
    </p>

    <u>Responder + Clinician Encounters:</u>
    <ul>
      <li>
        <b>Discharged</b> - Patient has been evaluated by a Clinician, and the
        Clinician has provided a diagnosis, management recommendation, and/or
        plan.
      </li>
      <li>
        <b>Discharged with activation</b> - Patient has been evaluated by a
        Clinician, and the Clinician has provided a diagnosis, management
        recommendation, and/or plan. The Clinician has recommended EMS/Police
        activation.
      </li>
      <li>
        <b>Discharged with service refusal</b> - Patient has been evaluated by a
        Clinician, and the Clinician has provided a diagnosis, management
        recommendation, and/or plan, but the patient has refused one or more
        services. This status should also be used if the patient refuses to
        speak with the clinician after the teleconference has started.
      </li>
      <li>
        <b>Patient declined services</b> - Patient contact has been made, but
        the patient declined services. No Clinician was included in this
        encounter.
      </li>
      <li>
        <b>Responder-initiated activation</b> - Patient contact has been made,
        but there has been no Clinician contact because an EMS/Police activation
        occurred immediately.
      </li>
    </ul>

    <u>Responder Only Encounters:</u>
    <ul>
      <li>
        <b>Completed</b> - Patient has finished their encounter with the
        Responder. No Clinician was included in this encounter.
      </li>
      <li>
        <b>Responder-initiated activation</b> - Patient contact has been made,
        but there has been no Clinician contact because an EMS/Police activation
        occurred immediately.
      </li>
      <li>
        <b>Patient declined services</b> - Patient contact has been made, but
        the patient declined services. No Clinician was included in this
        encounter.
      </li>
    </ul>
  </StyledModalBody>
);
