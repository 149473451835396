const RoutePaths = {
  appointments: '/appointments',
  default: '/',
  encounters: '/encounters',
  encounterPrint: '/encounters/print/:encounterId',
  logout: '/logout',
  noRole: '/new-user',
  patients: '/patients/:id',
  queue: '/queue',
  tasks: '/tasks',
  users: '/users',
};

export default RoutePaths;
