import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import AdditionalPhotoDocSection from '../AdditionalPhotoDocSection';
import { StyledTaskForm } from '../TaskForm';
import TaskHeader from '../TaskHeader';
import { AllergyTable } from './components';
import { useAllergiesForm } from './hooks';
import { TTask, AllergyInputs } from './utils';
import { useWatch } from 'react-hook-form';
import {
  renderClinicianAttestation,
  StyledClinicianAttestation,
} from 'modules/Patient/utils';
import { EncounterTask } from 'generated/graphql';

type Props = {
  task: TTask;
};

const StyledAllergiesExamForm = styled.div`
  header {
    padding-bottom: 0;
  }

  .allergyForm {
    background-color: ${Tokens.color.ui.steel.base};
    padding: calc(${Tokens.rhythm} * 2);

    .description {
      display: block;
      font-weight: bold;
      font-size: ${Tokens.font.size.heading[3]};
      margin-bottom: calc(${Tokens.rhythm} * 2);
    }

    .field {
      margin-bottom: calc(${Tokens.rhythm} * 2);
    }

    button {
      margin-top: ${Tokens.rhythm};
    }
  }

  section {
    margin-top: calc(${Tokens.rhythm} * 2);

    .empty {
      font-size: ${Tokens.font.size.paragraph.base};
    }
  }
`;

const AllergiesForm = ({ task }: Props): JSX.Element => {
  const {
    addButtonDisabled,
    allergyOptions,
    allergyOptionsLoading,
    allergyTypeAheadRef,
    control,
    clearAllergyInput,
    clearReactionInput,
    handleAddClick,
    handleAllergyInputChange,
    handleDeleteAllergy,
    handleReactionInputChange,
    inputsAreDisabled,
    reactionOptions,
    reactionOptionsLoading,
    reactionTypeAheadRef,
    setAllergyInput,
    setReactionInput,
    taskHeaderProps,
  } = useAllergiesForm({
    task,
  });
  const allergies = useWatch({ name: 'allergies', control });

  return (
    <>
      <TaskHeader {...taskHeaderProps} />
      <StyledClinicianAttestation>
        {renderClinicianAttestation(task as EncounterTask)}
      </StyledClinicianAttestation>
      <StyledTaskForm
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <StyledAllergiesExamForm>
          <header>
            <h3>{task?.taskName}</h3>
          </header>
          <div className="allergyForm">
            <span className="description">Add Non-Drug Allergy</span>
            <div className="field">
              <UI.TypeAhead
                disabled={inputsAreDisabled}
                label="Non-drug allergy"
                loading={allergyOptionsLoading}
                onInputChange={handleAllergyInputChange}
                onOptionClick={(value) => {
                  setAllergyInput(value as AllergyInputs['allergy']);
                }}
                options={allergyOptions}
                onReset={clearAllergyInput}
                placeholder="Search for non-drug allergy"
                ref={allergyTypeAheadRef}
                threshold={2}
              />
            </div>
            <div className="field">
              <UI.TypeAhead
                disabled={inputsAreDisabled}
                label="Reaction"
                loading={reactionOptionsLoading}
                onInputChange={handleReactionInputChange}
                onOptionClick={(value) => {
                  setReactionInput(value as AllergyInputs['allergicReaction']);
                }}
                optional
                options={reactionOptions}
                onReset={clearReactionInput}
                placeholder="Search for reaction"
                ref={reactionTypeAheadRef}
                threshold={2}
              />
            </div>
            <UI.Button
              disabled={addButtonDisabled || inputsAreDisabled}
              onClick={handleAddClick}
            >
              Add
            </UI.Button>
          </div>
          <section>
            {allergies.length > 0 ? (
              <AllergyTable
                control={control}
                handleDeleteAllergy={handleDeleteAllergy}
                inputsAreDisabled={inputsAreDisabled}
              />
            ) : null}
          </section>
          {task?.encounterTaskId && (
            <AdditionalPhotoDocSection
              encounterTaskId={task?.encounterTaskId}
              disabled={inputsAreDisabled}
            />
          )}
        </StyledAllergiesExamForm>
      </StyledTaskForm>
    </>
  );
};

export default AllergiesForm;
