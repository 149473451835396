import UI from 'ui';
import { useState } from 'react';
import {
  InfoSectionsProps,
  PatientType,
  GenderAndNameQuestionsProps,
} from '../../types';
import { StyledSection } from './StyledSection';
import EditNameAndDemographics from './EditNameAndDemographics/EditNameAndDemographics';

const NameAndDemographics = ({
  patient,
}: InfoSectionsProps): JSX.Element | null => {
  const [isEditable, setIsEditable] = useState(false);

  const renderQuestion = (
    fieldName: keyof GenderAndNameQuestionsProps,
  ): JSX.Element => {
    const questions: GenderAndNameQuestionsProps = {
      hasPreferredName: 'Does the patient have a preferred first name?',
      hasDifferentGenderIdentity:
        'Does the patient’s gender identity differ from their sex at birth?',
    };

    return <span className="fieldName">{questions[fieldName]}</span>;
  };

  const nameAndGenderCheck = (fieldName: keyof PatientType) =>
    patient[fieldName] === null ? (
      <p>
        {renderQuestion(fieldName as keyof GenderAndNameQuestionsProps)}
        <span className="fieldValue">Unknown</span>
      </p>
    ) : (
      <p>
        {renderQuestion(fieldName as keyof GenderAndNameQuestionsProps)}
        <span className="fieldValue">No</span>
      </p>
    );

  return !isEditable ? (
    <StyledSection>
      <div className="heading">
        <p>Name &amp; Demographics</p>
        <UI.Button
          variant="link"
          as="button"
          onClick={() => setIsEditable(true)}
        >
          Edit
        </UI.Button>
      </div>

      <div className="body">
        <p>
          <span className="fieldName">Legal Full Name:</span>
          <span className="fieldValue">{patient?.fullName ?? '-'}</span>
        </p>
        {patient?.preferredName || patient?.hasPreferredName ? (
          <>
            <p>
              {renderQuestion('hasPreferredName')}
              <span className="fieldValue">Yes</span>
            </p>
            <p>
              <span className="fieldName">Preferred First Name:</span>
              <span className="fieldValue">
                {patient?.preferredName || '-'}
              </span>
            </p>
          </>
        ) : (
          nameAndGenderCheck('hasPreferredName')
        )}
        <p>
          <span className="fieldName">Date of Birth:</span>
          <span className="fieldValue">
            {patient?.dob ? `${patient?.dob} (${patient?.age} years old)` : '-'}
          </span>
        </p>
        <p>
          <span className="fieldName">Sex at Birth:</span>
          <span className="fieldValue">
            {patient?.humanizedSex !== 'Other' ? patient?.humanizedSex : '-'}
          </span>
        </p>

        {(patient?.genderIdentity &&
          patient?.sex &&
          (patient?.genderIdentity as string) !== (patient?.sex as string)) ||
        (patient?.sex === 'OTHER' && patient.genderIdentity === 'OTHER') ||
        patient?.hasDifferentGenderIdentity ? (
          <>
            <p>
              {renderQuestion('hasDifferentGenderIdentity')}
              <span className="fieldValue">Yes</span>
            </p>
            <p>
              <span className="fieldName">Preferred Gender Identity:</span>
              <span className="fieldValue">
                {patient?.humanizedGenderIdentity ?? '-'}
              </span>
            </p>
            <p>
              <span className="fieldName">Preferred Gender Pronouns:</span>
              <span className="fieldValue">
                {patient?.humanizedGenderPronoun ?? '-'}
              </span>
            </p>
          </>
        ) : (
          nameAndGenderCheck('hasDifferentGenderIdentity')
        )}

        <p>
          <span className="fieldName">Race &amp; Ethnicity:</span>
          <span className="fieldValue">
            {patient?.humanizedRaces ? patient?.humanizedRaces.join(', ') : '-'}
          </span>
        </p>
        {!patient?.cannotProvideSsn ? (
          <p>
            <span className="fieldName">SSN (last 4 digits):</span>
            <span className="fieldValue">{patient?.ssnSerial ?? '-'}</span>
          </p>
        ) : (
          <p>
            <span className="fieldName">SSN (last 4 digits):</span>
            <span className="fieldValue">
              {(patient?.humanizedNoSsnReason &&
                patient?.humanizedNoSsnReason[0]) ??
                '-'}
            </span>
          </p>
        )}
      </div>
    </StyledSection>
  ) : (
    <EditNameAndDemographics patient={patient} setIsEditable={setIsEditable} />
  );
};

export default NameAndDemographics;
