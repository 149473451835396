import { Route, Redirect, Switch } from 'react-router-dom';

import { RoutePaths, Config } from 'config';
import { UserRoleEnum } from 'generated/graphql';
import { useUserContext } from 'hooks';
import {
  Appointments,
  EncounterSearch,
  Patient,
  PatientQueue,
  NoRole,
  MyTasks,
  Users,
} from 'modules';
import UI from 'ui';

import EncounterPrint from 'modules/Patient/components/Encounters/EncounterPrint';
import UploadImageTest from '../UploadImageTest';
import ProtectedRoute from './ProtectedRoute';

const getRedirectRoute = (role: string | undefined): string => {
  if (role === UserRoleEnum.RESPONDER) return RoutePaths.appointments;
  if (role === UserRoleEnum.CLINICIAN) return RoutePaths.queue;
  if (role === UserRoleEnum.SUPERVISOR) return RoutePaths.encounters;
  if (role === UserRoleEnum.IT) return RoutePaths.users;

  return RoutePaths.noRole;
};

const AppRoutes = (): JSX.Element => {
  const userContext = useUserContext();
  const redirectRoute = getRedirectRoute(userContext?.operatingAs);

  return userContext?.user?.userId ? (
    <Switch>
      {/* Temporary test image upload route */}
      {Config.enableImageUploadTest && (
        <Route exact path="/image-upload-test">
          <UploadImageTest />
        </Route>
      )}

      {/* No Role */}
      <Route exact path={RoutePaths.noRole}>
        <NoRole />
      </Route>

      {/* Appointments */}
      <ProtectedRoute
        exact
        path={RoutePaths.appointments}
        roles={[UserRoleEnum.RESPONDER]}
        redirectTo={redirectRoute}
      >
        <Appointments />
      </ProtectedRoute>

      {/* Encounter Search */}
      <ProtectedRoute
        exact
        path={RoutePaths.encounters}
        roles={[
          UserRoleEnum.CLINICIAN,
          UserRoleEnum.SUPERVISOR,
          UserRoleEnum.RESPONDER,
        ]}
        redirectTo={redirectRoute}
      >
        <EncounterSearch />
      </ProtectedRoute>

      {/* Patient Queue */}
      <ProtectedRoute
        exact
        path={RoutePaths.queue}
        roles={[UserRoleEnum.CLINICIAN]}
        redirectTo={redirectRoute}
      >
        <PatientQueue />
      </ProtectedRoute>

      {/* My Tasks */}
      <Route exact path={RoutePaths.tasks}>
        <MyTasks />
      </Route>

      {/* Users */}
      <ProtectedRoute
        exact
        path={RoutePaths.users}
        roles={[UserRoleEnum.IT]}
        redirectTo={redirectRoute}
      >
        <Users />
      </ProtectedRoute>

      {/* Patient */}
      <Route path={RoutePaths.patients} component={Patient} />

      {/* EncounterPrint */}
      <Route
        exact
        path={RoutePaths.encounterPrint}
        component={EncounterPrint}
      />

      {/* Default */}
      <Route exact path={RoutePaths.default}>
        <Redirect to={redirectRoute} />
      </Route>
    </Switch>
  ) : (
    <UI.Spinner centered />
  );
};

export default AppRoutes;
