import styled from 'styled-components';

import { Tokens } from 'config';
import StyledButton from './StyledButton';

const StyledLinkButton = styled(StyledButton)<{ disabled: boolean }>`
  text-decoration: none;
  background-color: transparent;
  border: 0;
  color: ${({ disabled }) =>
    disabled ? Tokens.color.neutral.grey[82] : Tokens.color.ui.link.base};

  &:hover,
  &.hover,
  &:focus,
  &:active,
  &.active {
    background-color: transparent;
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`;

export default StyledLinkButton;
