import styled from 'styled-components';

import { Tokens } from 'config';
import { CardProps } from './types';

const StyledCard = styled.div<CardProps>`
  background-color: ${Tokens.color.neutral.white.base};
  border-radius: 2px;
  box-shadow: ${Tokens.boxShadow.light};
  cursor: pointer;
  padding: calc(${Tokens.rhythm} * 2);
  transition-duration: ${Tokens.transition.duration[1]};
  transition-property: all;
  transition-timing-function: ${Tokens.transition.timing.easeIn};

  &:hover {
    box-shadow: ${Tokens.boxShadow.dark};
  }
`;

const Card = ({ children, ...props }: Readonly<CardProps>): JSX.Element => (
  <StyledCard {...props}>{children}</StyledCard>
);

export default Card;
