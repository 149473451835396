import { useEffect } from 'react';

import {
  useUpsertEncounterTaskMutation,
  GetEncounterTaskByEncounterTaskIdQuery,
  EncounterTaskAttachmentTypeEnum,
  SystemOfOriginEnum,
} from 'generated/graphql';

import { Logger } from 'utils';
import { useFileUpload } from 'hooks';
import UI from 'ui';

type AttachmentUrlAndId = {
  attachmentUrl?: string;
  attachmentId?: number;
};

export const getAttachment = (
  task: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>,
  attachmentTypeEnum: EncounterTaskAttachmentTypeEnum,
): AttachmentUrlAndId => {
  const foundAttachment = task?.encounterTaskAttachmentsList?.find(
    (attachment) =>
      attachment.type === attachmentTypeEnum && attachment.isRequired,
  );

  return {
    attachmentUrl: foundAttachment?.url ?? undefined,
    attachmentId: foundAttachment?.encounterTaskAttachmentId ?? undefined,
  };
};

const EncounterTaskAttachment = ({
  encounterTaskAttachmentId,
  additionalMutationInput,
  className,
  description = 'Upload',
  disabled = false,
  errorText = 'Error',
  hasError = false,
  id,
  label = ' ',
  task,
  type,
  onDeleteFile,
}: {
  encounterTaskAttachmentId?: string;
  additionalMutationInput?: { isPointOfCareTestTask?: boolean };
  className?: string;
  description?: string;
  disabled?: boolean;
  errorText?: string;
  hasError?: boolean;
  id: string;
  label?: string;
  task?: Readonly<GetEncounterTaskByEncounterTaskIdQuery['encounterTask']>;
  type: EncounterTaskAttachmentTypeEnum;
  onDeleteFile?: () => void;
}): JSX.Element => {
  const [upsertEncounterTaskMutation] = useUpsertEncounterTaskMutation();
  const existingAttachmentUrl = getAttachment(task, type).attachmentUrl;

  const {
    clearFailedUpload,
    downloadFile,
    downloadFailed,
    uploadFailed,
    file,
    fileUrl,
    isDownloading,
    isUploading,
    localUrl,
    retryUpload,
    removeFile,
    uploadFile,
  } = useFileUpload({
    folder: task?.encounter?.encounterId?.toString() ?? 'unknown',
    fileUrl: existingAttachmentUrl ?? null,
  });

  const handleDeleteAttachment = () => {
    try {
      if (encounterTaskAttachmentId) {
        upsertEncounterTaskMutation({
          variables: {
            input: {
              encounterTaskId: task?.encounterTaskId as number,
              attachments: [
                {
                  images: [
                    {
                      encounterTaskAttachmentId: Number(
                        encounterTaskAttachmentId,
                      ),
                      isDeleted: true,
                    },
                  ],
                  isRequired: true,
                },
              ],
              systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
            },
            ...additionalMutationInput,
          },
        }); // Set delete on Attachment File
      }

      removeFile(); // Remove file from hook state
      if (onDeleteFile) onDeleteFile(); // Set file to null on Form Task
    } catch (error) {
      Logger.error(error);
    }
  };

  useEffect(() => {
    if (!existingAttachmentUrl && fileUrl) {
      const create = async () => {
        try {
          await upsertEncounterTaskMutation({
            variables: {
              input: {
                encounterTaskId: task?.encounterTaskId as number,
                attachments: [
                  {
                    images: [
                      {
                        encounterTaskAttachmentId: Number(
                          encounterTaskAttachmentId,
                        ),
                        url: fileUrl,
                      },
                    ],
                    description,
                    type,
                    isRequired: true,
                  },
                ],
                systemLastUpdatedBy: SystemOfOriginEnum.READY_HEALTH_2,
              },
              ...additionalMutationInput,
            },
          }); // Attach file to Encounter Task
        } catch (e) {
          Logger.error(e);
        }
      };

      create();
    }
  }, [existingAttachmentUrl, fileUrl]);

  return (
    <UI.ImageInput
      id={id}
      className={className}
      description={description}
      errorText={hasError ? errorText : undefined}
      disabled={disabled}
      downloadFailed={downloadFailed}
      fullWidth
      imageFile={file}
      isDownloading={isDownloading}
      isUploading={isUploading}
      label={label}
      localUrl={localUrl}
      onInputChange={uploadFile}
      onDelete={handleDeleteAttachment}
      onDownload={downloadFile}
      uploadFailed={uploadFailed}
      onClearFailed={clearFailedUpload}
      onRetry={retryUpload}
      value={fileUrl}
    />
  );
};

export default EncounterTaskAttachment;
