import * as yup from 'yup';

import {
  CredentialsEnum,
  GetUserQuery,
  GrantRolePatchRecordInput,
  UserRoleEnum,
} from 'generated/graphql';
import { Enums } from 'utils';

export type TInputs = {
  clinicianMarkets: number[];
  clinicianNpi?: string;
  credentials?: CredentialsEnum[] | null;
  dob?: Date;
  registeredFirstName: string;
  registeredLastName: string;
};

export type TRoleState = {
  userRole: UserRoleEnum | null;
  addRole: boolean;
};

export const Schema: yup.SchemaOf<TInputs> = yup.object({
  clinicianMarkets: yup
    .array()
    .min(1, 'Please select at least one market')
    .of(yup.number().required('Please select at least one market')),
  clinicianNpi: yup
    .string()
    .test(
      'test-clinicianNpi',
      'Please enter a valid 10-digit Clinician NPI',
      (value, context) => {
        const isValid =
          Boolean(value !== '0000000000') && Boolean(value?.length === 10);
        const isClinician =
          context?.options?.context?.roleState.userRole ===
          UserRoleEnum.CLINICIAN;

        if (!isValid && isClinician) {
          return false;
        }
        return true;
      },
    ),
  credentials: yup
    .array()
    .nullable()
    .test('test-credentials', 'Please enter credentials', (value, context) => {
      const isValid = value && value.length > 0;
      const isClinician =
        context?.options?.context?.roleState.userRole ===
        UserRoleEnum.CLINICIAN;

      if (!isValid && isClinician) {
        return false;
      }
      return true;
    }),
  dob: yup.date().required('Please enter date of birth').nullable(),
  registeredFirstName: yup
    .string()
    .required('Please enter registered first name')
    .nullable(),
  registeredLastName: yup
    .string()
    .required('Please enter registered last name'),
});

export const getDefaultValues = (user: GetUserQuery['user']): TInputs => {
  const npiType = user?.userIdentifiersList?.filter(
    (type) => type?.identifierType === 'NPI',
  )[0];

  return {
    clinicianMarkets:
      user?.userMarketsList
        ?.filter((market) => !!market?.marketId)
        .map((market) => market?.marketId as number) ?? [],
    clinicianNpi: npiType?.identifier,
    credentials: user?.credentials as CredentialsEnum[],
    dob: user?.dob,
    registeredFirstName: user?.registeredFirstName ?? '',
    registeredLastName: user?.registeredLastName ?? '',
  };
};

export const getPatch = (formData: TInputs): GrantRolePatchRecordInput => {
  const {
    clinicianMarkets,
    clinicianNpi,
    credentials,
    dob,
    registeredFirstName,
    registeredLastName,
  } = formData;

  const patch: GrantRolePatchRecordInput = {
    markets: clinicianMarkets,
    ...(clinicianNpi && { npi: clinicianNpi }),
    ...(credentials && credentials.length > 0 && { credentials }),
    dob,
    registeredFirstName,
    registeredLastName,
  };

  return patch;
};

export const CredentialsOptions: { label: string; value: CredentialsEnum }[] =
  Object.values(CredentialsEnum).map((option) => ({
    label:
      option === CredentialsEnum.RESPONDER ? Enums.humanize(option) : option,
    value: option,
  }));
