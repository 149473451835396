import { Dispatch, SetStateAction } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { Tokens } from 'config';
import type { TInputs, TSystem } from './utils';
import ReviewOfSystemsCard from './ReviewOfSystemsCard';

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: ${Tokens.rhythm};
    width: calc(50% - (${Tokens.rhythm} / 2));
  }

  & > *:nth-child(odd) {
    margin-right: ${Tokens.rhythm};
  }

  @media only screen and (max-width: 1000px) {
    & > * {
      width: 100%;
    }

    & > *:nth-child(odd) {
      margin-right: 0;
    }
  } // iPad
`;

const ReviewOfSystems = ({
  disabled = false,
  formState,
  setValue,
  systems,
  setSystems,
}: {
  disabled?: boolean;
  formState: TInputs;
  setValue: UseFormSetValue<TInputs>;
  systems: TSystem[];
  setSystems: Dispatch<SetStateAction<TSystem[]>>;
}): JSX.Element => {
  const onSystemUpdate = (updatedSystem: TSystem) => {
    setSystems((systemsInState) => {
      const systemsInStateCopy = [...systemsInState];
      // Find system being updated and assign new value
      const indexOfSystem = systemsInStateCopy.findIndex(
        (systemInState) => systemInState.system === updatedSystem.system,
      );
      // Overwrite system with new pointer
      systemsInStateCopy[indexOfSystem] = updatedSystem;

      return systemsInStateCopy;
    });

    if (typeof formState?.reviewChangesCounter !== 'undefined') {
      setValue(`reviewChangesCounter`, formState.reviewChangesCounter + 1, {
        shouldDirty: true,
      });
    }
  };

  return (
    <>
      <h3>Review of Systems</h3>

      {systems ? (
        <Cards>
          {systems.map((system) => (
            <ReviewOfSystemsCard
              disabled={disabled}
              key={system.system}
              system={system}
              onSystemUpdate={onSystemUpdate}
            />
          ))}
        </Cards>
      ) : (
        <p>There are no systems to review.</p>
      )}
    </>
  );
};

export default ReviewOfSystems;
