import { Auth0Provider } from '@auth0/auth0-react';

import { Config } from 'config';

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Readonly<Props>): JSX.Element => (
  <Auth0Provider
    domain={Config.auth0.domain}
    clientId={Config.auth0.clientId}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    audience={Config.auth0.audience}
  >
    {children}
  </Auth0Provider>
);

export default AuthProvider;
