import React, { useEffect } from 'react';

import {
  EncounterTaskStatusEnum,
  useGetEncounterTaskByEncounterTaskIdLazyQuery,
} from 'generated/graphql';

import UI from 'ui';
import { Grid } from '../StyledEncounterPrint';
import { Enums } from 'utils';

const DispositionSummary = ({
  taskId,
}: {
  taskId?: number;
}): JSX.Element | null => {
  // Task may not exist on this encounter
  if (taskId === null || taskId === undefined) return null;

  const [getEncounterTask, { data, loading }] =
    useGetEncounterTaskByEncounterTaskIdLazyQuery();

  const task = data?.encounterTask;
  const disposition = task?.encounter?.disposition;
  const patientInstructions = task?.patientInstructions;

  useEffect(() => {
    if (taskId) {
      getEncounterTask({
        variables: {
          id: taskId,
          isDispositionTask: true,
        },
      });
    }
  }, [taskId]);

  if (loading) return <UI.Spinner centered />;

  // ASSUMPTION: SKIPPED TASKS SHOULD DISPLAY NOTHING
  if (task?.status === EncounterTaskStatusEnum.SKIPPED) return null;

  // Disposition may not exist on this encounter task (defensive)
  if (disposition === null || disposition === undefined) return null;

  return (
    <>
      <h2>Assessment</h2>

      <Grid>
        <div>
          <strong>Disposition:</strong>
        </div>
        <div>{Enums.humanize(disposition)}</div>

        <div>
          <strong>Patient Instructions:</strong>
        </div>
        {patientInstructions &&
          patientInstructions.length > 0 &&
          patientInstructions.map((instruction) => (
            <div key={instruction?.patientInstructionId}>
              {instruction?.careInstructions ?? '-'}
            </div>
          ))}
      </Grid>
    </>
  );
};

export default DispositionSummary;
