import styled from 'styled-components';

import { Tokens } from 'config';

export const StyledSection = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  margin-top: calc(${Tokens.rhythm} * 2);
  margin-right: calc(${Tokens.rhythm} * 2);

  .body {
    padding-left: calc(${Tokens.rhythm} * 2);
    padding-right: calc(${Tokens.rhythm} * 2);
    padding-bottom: ${Tokens.rhythm};

    p {
      display: flex;

      .fieldName {
        color: ${Tokens.color.neutral.grey[102]};
        width: 40%;
        margin-right: calc(${Tokens.rhythm} * 2);
      }

      .fieldValue {
        width: 60%;
      }
    }
  }

  button {
    &:hover {
      text-decoration: none;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    border-bottom: ${Tokens.border.size} solid
      ${Tokens.color.neutral.black.transparent[2]};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding-left: calc(${Tokens.rhythm} * 2);
    padding-right: calc(${Tokens.rhythm} * 2);
    align-items: center;

    p {
      font-weight: ${Tokens.font.weight.semiBold};
    }
  }
`;
