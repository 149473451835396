import { CredentialsEnum, Encounter, Maybe } from 'generated/graphql';

import { Grid } from '../Patient/components/StyledEncounterPrint';

const AppointmentsSummary = ({
  encounter,
}: {
  encounter: Partial<Encounter>;
}): JSX.Element | null => {
  if (!encounter || !encounter.appointment) return null;

  const getAddressLine1 = () =>
    `${encounter?.appointment?.address1 ?? '-'}${
      encounter?.appointment?.address2
        ? `, ${encounter?.appointment?.address2}`
        : ''
    }
  `;

  const getAddressLine2 = () =>
    `${
      encounter?.appointment?.city ? `${encounter?.appointment?.city},` : ''
    } ${encounter?.appointment?.state ?? ''} ${
      encounter?.appointment?.zipCode ?? ''
    }
  `;

  const getScheduledFor = () =>
    encounter?.appointment?.scheduledForDate
      ? `${encounter?.appointment?.scheduledForDate}`
      : '-';

  const getCredentials = (credentials: Maybe<CredentialsEnum>[]) =>
    credentials && credentials.length > 0 ? `, ${credentials.join(', ')}` : '';

  const getRespondersOnScene = () => {
    if (
      encounter?.appointment?.appointmentDispatchesList &&
      encounter?.appointment?.appointmentDispatchesList?.length > 0
    ) {
      return encounter.appointment?.appointmentDispatchesList.map(
        (appointment) => {
          if (appointment.onSceneAt) {
            return (
              <div key={appointment.responder?.user?.userId}>
                {appointment.responder?.registeredFirstName ??
                  appointment?.responder?.user?.registeredFirstName}{' '}
                {appointment.responder?.registeredLastName ??
                  appointment?.responder?.user?.registeredLastName}
                {getCredentials(
                  appointment?.responder?.user?.credentials ?? [],
                )}
              </div>
            );
          }
        },
      );
    } else return 'UNKNOWN';
  };

  const getClinicianName = () => {
    const credentials =
      encounter?.clinician &&
      encounter.clinician.credentials &&
      encounter.clinician.credentials.length > 0
        ? `, ${encounter.clinician.credentials.join(', ')}`
        : '';

    return encounter.clinician && encounter.claimedAt
      ? `${encounter.clinician.firstName} ${encounter.clinician.lastName}${credentials}`
      : 'UNKNOWN';
  };

  return (
    <div>
      <h2>Visit Note</h2>

      <Grid>
        <div>
          <strong>Date of Service:</strong>
        </div>
        <div>{getScheduledFor()}</div>
        <div>
          <strong>Place of Service:</strong>
        </div>
        <div>
          <span>
            {getAddressLine1()}
            <br />
            {getAddressLine2()}
            <br />
          </span>
        </div>
      </Grid>

      <Grid>
        <div>
          <strong>Responder:</strong>
        </div>
        <div>{getRespondersOnScene()}</div>
        <div>
          <strong>Clinician:</strong>
        </div>
        <div>{getClinicianName()}</div>
      </Grid>
    </div>
  );
};

export default AppointmentsSummary;
