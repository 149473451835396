import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { useState, useEffect } from 'react';

import { TFile } from 'hooks/useFileUpload/types';
import Button from 'ui/Button/Button';
import Modal, { ModalSize } from 'ui/Modal/Modal';
import Spinner from 'ui/Spinner/Spinner';

export type ImageModalProps = {
  images: TFile[];
  onClose: () => void;
  open: boolean;
  size?: ModalSize;
  testId?: string;
  title: string;
  type: 'single' | 'multi';
};

type ModalBodyProps = {
  downloadFailed: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  imageFile: Blob | null;
  imageUrl: string;
  isDownloading: boolean;
  showArrows: boolean;
};

const ModalBody = ({
  downloadFailed,
  handleNext,
  handlePrevious,
  hasNext,
  hasPrevious,
  imageFile,
  imageUrl,
  isDownloading,
  showArrows,
}: ModalBodyProps): JSX.Element => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '600px',
      overflow: 'scroll',
    }}
  >
    {showArrows && (
      <div style={{ paddingRight: '16px' }}>
        <Button
          disabled={!hasPrevious}
          onClick={handlePrevious}
          size="icon"
          variant="secondary"
        >
          <ArrowLeft />
        </Button>
      </div>
    )}

    <div style={{ flex: '1 1 auto', textAlign: 'center', height: '100%' }}>
      {imageFile && (
        <img
          id="modal-image"
          src={imageUrl ?? undefined}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      )}
      {isDownloading && (
        <Spinner testId="imageModal__downloadingSpinner" centered />
      )}
      {downloadFailed &&
        'The image failed to download. Please close the window and try again.'}
    </div>

    {showArrows && (
      <div style={{ paddingLeft: '16px' }}>
        <Button
          disabled={!hasNext}
          onClick={handleNext}
          size="icon"
          variant="secondary"
        >
          <ArrowRight />
        </Button>
      </div>
    )}
  </div>
);

const ImageModal = ({
  images,
  onClose,
  open,
  size = 'large',
  testId,
  title,
  type,
}: ImageModalProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const image = images[currentIndex];

  useEffect(() => {
    if (open && image) {
      const { file, isDownloading, downloadFile } = image;
      if (!file && !isDownloading) downloadFile();
    }
  }, [open, image]);

  const hasNext = currentIndex < images.length - 1;
  const hasPrevious = currentIndex > 0;

  const handleNext = () => {
    if (hasNext) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const handlePrevious = () => {
    if (hasPrevious) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleClose = () => {
    setCurrentIndex(0);
    onClose();
  };

  const modalTitle =
    type === 'multi'
      ? `${title} (Image ${currentIndex + 1} of ${images.length})`
      : title;

  return (
    <Modal
      body={
        <ModalBody
          downloadFailed={image?.downloadFailed}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          imageFile={image?.file}
          imageUrl={image?.localUrl ?? ''}
          isDownloading={image?.isDownloading}
          showArrows={type === 'multi'}
        />
      }
      size={size}
      onClose={handleClose}
      open={open}
      testId={testId}
      title={modalTitle}
    />
  );
};

export default ImageModal;
