import { CaretRight } from 'phosphor-react';
import { format as formatDate } from 'date-fns';
import { generatePath, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  Encounter,
  EncounterTaskStatusEnum,
  UserRoleEnum,
} from 'generated/graphql';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import { Tokens } from 'config';
import UI from 'ui';

export type EncounterTableRowProps = {
  /**
   * The Encounter.
   */
  encounter: Partial<Encounter>;

  /**
   * The `data-testid`
   */
  testId?: string;

  /**
   * handles the onClick event
   */
  onClick: () => void;

  /**
   * The user role
   */
  operatingAs: UserRoleEnum | undefined;
};

const StyledEncounterTableRow = styled(UI.TableRow)`
  .dateTimeCell {
    .date {
      display: block;
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: ${Tokens.lineHeight.paragraph.large};
    }

    .time {
      display: block;
      color: ${Tokens.color.neutral.grey[94]};
    }
  }

  .serviceLineCell {
    color: ${Tokens.color.neutral.grey[94]};
  }
`;

const EncounterTableRow = ({
  encounter,
  testId = undefined,
  onClick,
  operatingAs,
}: EncounterTableRowProps): JSX.Element => {
  const location = useLocation();

  const scheduledFor = encounter.appointment?.scheduledFor;

  const date = encounter.appointment
    ? formatDate(new Date(scheduledFor), 'M/dd/yyyy')
    : null;

  const time = encounter.appointment
    ? formatDate(new Date(scheduledFor), 'h:mmaaa')
    : '';

  const getTasksRemaining = () =>
    (encounter.encounterTasksList ?? []).filter((task) => {
      const hasMyRole =
        operatingAs && (task.taskRoles ?? []).includes(operatingAs);

      const isIncomplete =
        task.status &&
        [
          EncounterTaskStatusEnum.IN_PROGRESS,
          EncounterTaskStatusEnum.NOT_STARTED,
        ].includes(task.status);

      const isClinicianIncomplete =
        operatingAs === UserRoleEnum.CLINICIAN &&
        (task.taskRoles ?? []).includes(UserRoleEnum.RESPONDER) &&
        task.status === EncounterTaskStatusEnum.RESPONDER_COMPLETED;

      return hasMyRole && (isIncomplete || isClinicianIncomplete);
    }).length;

  const getChartStatus = () =>
    operatingAs === UserRoleEnum.CLINICIAN
      ? encounter.humanizedClinicianStatus
      : encounter.humanizedResponderStatus;

  return (
    <StyledEncounterTableRow data-testid={testId} onClick={onClick}>
      <UI.TableCell className="dateTimeCell">
        <span className="date">{date}</span>
        <span className="time">{time}</span>
      </UI.TableCell>
      <UI.TableCell className="serviceLineCell">
        {encounter.serviceLine?.name} - {encounter.serviceLine?.subType}
      </UI.TableCell>
      <UI.TableCell>
        <Link
          to={{
            pathname: generatePath(PatientRoutePaths.profile, {
              id: `${encounter.patient?.globalPatientId}`,
            }),
            state: {
              referrer: {
                pageTitle: 'My Tasks',
                pathname: location.pathname,
                search: location.search,
              },
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {encounter.patient?.preferredName
            ? `${encounter.patient?.firstName} "${encounter.patient?.preferredName}" ${encounter.patient?.lastName}`
            : encounter.patient?.fullName}
        </Link>
      </UI.TableCell>
      <UI.TableCell>{encounter.appointment?.humanizedStatus}</UI.TableCell>
      <UI.TableCell>
        <UI.EncounterStatusPill status={getChartStatus() ?? 'N/A'} />
      </UI.TableCell>
      <UI.TableCell>{getTasksRemaining()}</UI.TableCell>
      <UI.TableCell>
        <UI.Button size="icon" variant="tertiary" onClick={onClick}>
          <CaretRight />
        </UI.Button>
      </UI.TableCell>
    </StyledEncounterTableRow>
  );
};

export default EncounterTableRow;
