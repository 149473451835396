import shortid from 'shortid';

import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';
import { usePersistTask, useTask } from 'hooks';
import { useForm } from 'forms';
import ClinicianBillingForm from './ClinicianBillingForm';
import FormSchema, { FormInputs, CPTCodeInput } from './schema';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

type Props = {
  task: TTask;
};

const cleanProcedureCodeRefIds = (cptCodes: Array<CPTCodeInput | null>) =>
  (cptCodes ?? [])
    .filter((cptCode) => !!cptCode && !!cptCode?.procedureCodeReferenceId)
    .map((cptCode) => cptCode?.procedureCodeReferenceId ?? 0);

const ClinicianBillingContainer = ({ task }: Props): JSX.Element => {
  const cptCodes: CPTCodeInput[] = (task?.billingDiagnosticsList ?? [])
    .filter((item) => !item?.deletedAt)
    .map((item) => ({
      code: item.procedureCodeReference?.code ?? '',
      name: item.procedureCodeReference?.name ?? '',
      procedureCodeReferenceId:
        item.procedureCodeReference?.procedureCodeReferenceId ?? 0,
    }));

  const initialFormValues: FormInputs = {
    cptCodes: cptCodes.length > 0 ? cptCodes : [null],
  };

  const initialKeys = initialFormValues.cptCodes.map(() => shortid.generate());

  const {
    control,
    formState: { isDirty, isSubmitting: isSaving, isValidating },
    handleSubmit,
    handleSave,
    reset,
    watch,
  } = useForm<FormInputs>(FormSchema, {
    defaultValues: initialFormValues,
    reValidateMode: 'onChange',
  });

  const formState = watch();
  const { inputsAreDisabled } = useTask(task);

  const { handleCompleteTask, handleSaveTask, handleSkipTask } = usePersistTask(
    {
      task,
      isDirty,
      formState,
      handleSave,
      handleSubmit,
      getFormattedPatch: (data) => {
        const procedureCodeReferenceIds = cleanProcedureCodeRefIds(
          data.cptCodes,
        );
        return {
          billingDiagnostics: {
            procedureCodeReferenceIds,
          },
        };
      },
      onPersistTask: () => reset({}, { keepValues: true }),
    },
  );

  const appointmentDate = task?.encounter?.appointment?.scheduledFor;

  return (
    <ClinicianBillingForm
      appointmentDate={appointmentDate}
      control={control}
      formState={formState}
      initialKeys={initialKeys}
      inputsAreDisabled={inputsAreDisabled}
      taskHeaderProps={{
        task,
        isDirty,
        isSaving,
        isValidating,
        onTaskSave: handleSaveTask,
        onTaskSkip: handleSkipTask,
        onTaskComplete: handleCompleteTask,
      }}
    />
  );
};

export default ClinicianBillingContainer;
