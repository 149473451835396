import styled from 'styled-components';
import { Minus, Plus } from 'phosphor-react';

import { Tokens } from 'config';

type TProps = {
  disabled?: boolean;
  label: string;
  systemName: string;
  value?: boolean | null;
  onValueChange: (value: boolean) => void;
};

const StyledRadioButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: calc(${Tokens.rhythm} * 2);
  position: relative;

  input {
    left: -99999px;
    position: absolute;
  }

  label {
    align-items: center;
    background-color: ${Tokens.color.neutral.grey[219]};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    line-height: 1;
    margin: 0 ${Tokens.rhythm} 0 0;
    padding: 0;
    width: 40px;

    &:hover {
      background-color: ${Tokens.color.neutral.grey[191]};
    }

    &.disabled {
      background-color: ${Tokens.color.neutral.grey[240]};
      cursor: not-allowed;
    }
  }

  input:checked + label {
    background-color: ${Tokens.color.ui.charcoal.base};
  }

  span {
    line-height: 1;
    margin: 0;
    padding: 0;
    flex: 3;
  }
`;

const ReviewOfSystemsRadio = ({
  disabled,
  systemName,
  label,
  value,
  onValueChange,
}: TProps): JSX.Element => {
  const inputName = systemName + ' ' + label;

  return (
    <StyledRadioButtonWrapper>
      <input
        checked={value === true}
        disabled={disabled}
        name={inputName}
        id={inputName + ' true'}
        onClick={() => onValueChange(true)}
        onChange={() => {}}
        type="radio"
        tabIndex={-1}
      />
      <label
        htmlFor={inputName + ' true'}
        className={`plusIcon ${disabled ? 'disabled' : ''}`}
      >
        <Plus color={Tokens.color.neutral.white.base} size={14} />
      </label>

      <input
        checked={value === false}
        disabled={disabled}
        name={inputName}
        id={inputName + ' false'}
        onClick={() => onValueChange(false)}
        onChange={() => {}}
        type="radio"
        tabIndex={-1}
      />
      <label
        htmlFor={inputName + ' false'}
        className={`minusIcon ${disabled ? 'disabled' : ''}`}
      >
        <Minus color={Tokens.color.neutral.white.base} size={14} />
      </label>

      <span>{label}</span>
    </StyledRadioButtonWrapper>
  );
};

export default ReviewOfSystemsRadio;
