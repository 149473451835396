import { DotsThree } from 'phosphor-react';
import { useState, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { Tokens } from 'config';
import UI from 'ui';
import Column from './Column';
import Rib from './Rib';

type ColumnProp = {
  label: string;
  showTooltip: boolean;
};

export type RowProps = {
  columns: ColumnProp[];
  disabled: boolean;
  id: string;
  index: number;
  onRemove: () => void;
  rank?: number;
};

const StyledRow = styled.div<{ isDragging: boolean; disabled: boolean }>(
  ({ isDragging, disabled }) => {
    const getBackgroundColor = () => {
      if (disabled) {
        return Tokens.color.neutral.grey[240];
      }

      return isDragging
        ? Tokens.color.neutral.grey[250]
        : Tokens.color.neutral.white.base;
    };

    const getBorderColor = () =>
      isDragging
        ? Tokens.color.ui.charcoal.base
        : Tokens.color.neutral.black.transparent[25];

    const getTextColor = () =>
      disabled
        ? Tokens.color.ui.charcoal.transparent[50]
        : Tokens.color.ui.charcoal.base;

    return `
    align-items: center;
    background-color: ${getBackgroundColor()};
    border: 1px solid ${getBorderColor()};
    border-radius: 2px;
    color: ${getTextColor()};
    cursor: ${disabled ? 'not-allowed' : 'grab'};
    display: flex;
    font-size: ${Tokens.font.size.paragraph.base};
    height: 40px;
    margin-bottom: ${Tokens.rhythm};
    padding: 0 ${Tokens.rhythm};

    .rank {
      flex: 0 calc(${Tokens.rhythm} * 6);
    }

    .rib {
      position: relative;
      top: 1px;
      margin-right: calc(${Tokens.rhythm} * 2);
    }

    .col {
      flex-grow: 1;
      flex-basis: calc(${Tokens.rhythm} * 2);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ${disabled ? 'button { border: 0; }' : ''}
    `;
  },
);

const Row = ({
  columns,
  disabled,
  id,
  index,
  onRemove,
  rank,
}: RowProps): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  return (
    <Draggable isDragDisabled={disabled} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <StyledRow
          disabled={disabled}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Rib className="rib" />
          {rank && <span className="rank">{rank}</span>}
          {columns.map((col, idx) => (
            <Column
              key={`col-${id}-${idx}`}
              index={idx}
              label={col.label}
              showTooltip={col.showTooltip}
            />
          ))}
          <div ref={menuAnchorRef}>
            <UI.Button
              className="actionsButton"
              disabled={disabled}
              variant="tertiary"
              size="icon"
              onClick={() => setMenuOpen((state) => !state)}
            >
              <DotsThree size={34} weight="fill" />
            </UI.Button>
            <UI.Dropdown
              anchorRef={menuAnchorRef}
              open={menuOpen}
              setOpen={setMenuOpen}
              position="bottomLeft"
            >
              <UI.ListItem label="Remove" onClick={onRemove} />
            </UI.Dropdown>
          </div>
        </StyledRow>
      )}
    </Draggable>
  );
};

export default Row;
