import { ReactNode } from 'react';
import styled from 'styled-components';
import { Tokens } from 'config';
import {
  Lightbulb,
  CheckCircle,
  Info,
  Warning as WarningTriangle,
  WarningCircle,
} from 'phosphor-react';

export enum SeverityLevel {
  Tip = 'TIP',
  Warning = 'WARNING',
  Information = 'INFO',
  Success = 'SUCCESS',
  Danger = 'DANGER',
}

export type AlertBoxTypes = {
  /**
   * Renders a label with the provided text.
   */
  label: string;
  /**
   * Renders an alert message with the provided text.
   */
  message?: string | ReactNode;
  /**
   * Renders props based on degree of severity
   */
  severity: SeverityLevel;
};

const StyledAlertBox = styled.div<{ backgroundColor?: string }>(
  ({ backgroundColor }) => `
  background: ${backgroundColor};
  border-radius: 2px;
  color: ${Tokens.color.ui.charcoal.base};
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  font-weight: ${Tokens.font.weight.normal};
  padding: calc(${Tokens.rhythm} * 2);

  .icon {
    display: flex;
    align-items: center;
    margin-bottom: ${Tokens.rhythm};
    span {
      font-weight: ${Tokens.font.weight.semiBold};
      margin-left: ${Tokens.rhythm};
    };
  };


`,
);

const AlertBox = ({ severity, message, label }: AlertBoxTypes): JSX.Element => {
  const AlertProps = (() => {
    switch (severity) {
      case SeverityLevel.Warning:
        return {
          icon: WarningTriangle,
          iconColor: Tokens.color.ui.warning.base,
          backgroundColor: Tokens.color.ui.warning.transparent[10],
        };
      case SeverityLevel.Information:
        return {
          icon: Info,
          iconColor: Tokens.color.ui.information.base,
          backgroundColor: Tokens.color.ui.information.transparent[10],
        };
      case SeverityLevel.Success:
        return {
          icon: CheckCircle,
          iconColor: Tokens.color.ui.success.base,
          backgroundColor: Tokens.color.ui.success.transparent[10],
        };
      case SeverityLevel.Danger:
        return {
          icon: WarningCircle,
          iconColor: Tokens.color.ui.error.base,
          backgroundColor: Tokens.color.ui.error.transparent[10],
        };
      case SeverityLevel.Tip:
      default:
        return {
          icon: Lightbulb,
          iconColor: Tokens.color.ui.charcoal.base,
          backgroundColor: Tokens.color.ui.charcoal.transparent[10],
        };
    }
  })();

  return (
    <StyledAlertBox backgroundColor={AlertProps.backgroundColor}>
      <div className="icon">
        <AlertProps.icon color={AlertProps.iconColor} weight="fill" />
        <span>{label}</span>
      </div>
      <div className="message">{message}</div>
    </StyledAlertBox>
  );
};

AlertBox.SeverityLevel = SeverityLevel;

export default AlertBox;
