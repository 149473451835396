import { GetEncounterTaskByEncounterTaskIdQuery } from 'generated/graphql';

import { TInputs } from './schema';

export type TTask = GetEncounterTaskByEncounterTaskIdQuery['encounterTask'];

export const getDefaultValues = (task: TTask): TInputs => {
  return {
    note: task?.physicalExam?.note ?? '',
  };
};

export default getDefaultValues;
