import PhoneUtils from 'utils/phone';
import { Encounter } from 'generated/graphql';

import { Grid } from '../StyledEncounterPrint';

const PatientSummary = ({
  encounter,
}: {
  encounter: Partial<Encounter>;
}): JSX.Element | null => {
  if (!encounter || !encounter.appointment) return null;

  const getPatientName = () =>
    encounter?.patient?.preferredName
      ? `${encounter?.patient?.firstName} "${encounter?.patient?.preferredName}" ${encounter?.patient?.middleName} ${encounter?.patient?.lastName}`
      : encounter?.patient?.fullName;

  return (
    <div>
      <h2>Patient Information</h2>

      <Grid>
        <div>
          <strong>Name:</strong>
        </div>
        <div>{getPatientName()}</div>
        <div>
          <strong>Date of Birth:</strong>
        </div>
        <div>
          {encounter?.patient?.dob
            ? `${encounter?.patient?.dob} (${encounter?.patient?.age} years old)`
            : '-'}
        </div>
        <div>
          <strong>Sex at Birth:</strong>
        </div>
        <div>
          {encounter?.patient?.humanizedSex !== 'Other'
            ? encounter?.patient?.humanizedSex
            : '-'}
        </div>
        <div>
          <strong>Preferred Gender Identity:</strong>
        </div>
        <div>{encounter?.patient?.humanizedGenderIdentity ?? '-'}</div>
        <div>
          <strong>Preferred Gender Pronouns:</strong>
        </div>
        <div>{encounter?.patient?.humanizedGenderPronoun ?? '-'}</div>
        <div>
          <strong>Race &amp; Ethnicity:</strong>
        </div>
        <div>
          {encounter?.patient?.humanizedRaces
            ? encounter?.patient?.humanizedRaces.join(', ')
            : '-'}
        </div>
        <div>
          <strong>Home Address:</strong>
        </div>
        <div>
          {encounter?.patient?.address1 ? (
            <span className="fieldValue">
              {encounter?.patient?.addressLine1}
              <br />
              {encounter?.patient?.addressLine2 ?? ''}
            </span>
          ) : (
            '-'
          )}
        </div>
        <div>
          <strong>Email Address</strong>
        </div>
        <div>{encounter?.patient?.email ?? '-'}</div>
        <div>
          <strong>Preferred Phone Number</strong>
        </div>
        <div>
          {encounter?.patient?.preferredPhoneNumber
            ? PhoneUtils.formatE164ToUSPhoneNumber(
                encounter?.patient?.preferredPhoneNumber,
              )
            : '-'}
        </div>
        <div>
          <strong>Preferred Language:</strong>
        </div>
        <div>{encounter?.patient?.humanizedLanguages?.join(', ') ?? '-'}</div>
        <div>
          <strong>Special Communications Needs:</strong>
        </div>
        <div>
          {encounter?.patient?.humanizedCommunicationNeed?.join(', ') ?? '-'}
        </div>
      </Grid>
    </div>
  );
};

export default PatientSummary;
