import { FormEvent, useState } from 'react';
import { Flag } from 'phosphor-react';
import { Tokens } from 'config';

import {
  PatientNoteInputRecordInput,
  usePatientCreatePatientNoteMutation,
} from 'generated/graphql';

import UI from 'ui';
import { Logger } from 'utils';
import { useForm, useToastMessage } from 'hooks';
import { EditInfoSectionsProps } from '../../../types';
import { StyledNotes } from '../StyledPatientNotes';

const AddPatientNote = ({
  patient,
  setNoteInputArea,
}: EditInfoSectionsProps): JSX.Element => {
  const [createPatientNoteMutation] = usePatientCreatePatientNoteMutation();
  const { setToastMessage } = useToastMessage();

  const [highPriority, setHighPriority] = useState(false);

  const { formState, getData, setData, getErrors, setErrors } = useForm({
    note: {
      field: 'note',
      required: true,
      value: '',
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errors = getErrors();
    const formData = getData<PatientNoteInputRecordInput>();

    if (errors.length > 0) {
      errors.forEach((errorField) => {
        setErrors(errorField.field, true);
      });
      if (setNoteInputArea) setNoteInputArea(false);
      return;
    }

    if (!patient?.patientId) return;

    const patientNote = {
      isHighPriority: highPriority,
      note: formData?.note as string,
      patientId: patient?.patientId,
    };

    try {
      const createdNote = await createPatientNoteMutation({
        variables: {
          input: {
            patientNote,
          },
        },
      });
      if (createdNote) {
        setToastMessage(
          `Successfully updated the patient's note`,
          UI.Toast.SeverityLevel.Success,
        );
        if (setNoteInputArea) setNoteInputArea(false);
      }
    } catch (error) {
      Logger.error(error);
      setToastMessage(
        `Failed to update the patient's note`,
        UI.Toast.SeverityLevel.Error,
      );
    }
  };

  return (
    <StyledNotes>
      <form className="body" onSubmit={handleSubmit} autoComplete="off">
        <div className="fields">
          <UI.Input
            id="note"
            name="note"
            fullWidth
            multiline
            optional
            onChange={(event) => setData('note', event.target.value)}
            placeholder="Write a patient note..."
            style={{ border: 'none', resize: 'none' }}
            value={(formState.note.value as string) ?? ''}
          />
        </div>

        <footer className="buttonContainer">
          {highPriority ? (
            <Flag
              color={Tokens.color.ui.error.base}
              cursor="pointer"
              data-testid="flagIconRedButton"
              onClick={() => setHighPriority(!highPriority)}
              weight="fill"
            />
          ) : (
            <Flag
              cursor="pointer"
              data-testid="flagIconButton"
              onClick={() => setHighPriority(!highPriority)}
            />
          )}
          <div className="buttonsRight">
            <UI.Button
              variant="tertiary"
              className="closeButton"
              onClick={() => {
                if (setNoteInputArea) setNoteInputArea(false);
              }}
            >
              Close
            </UI.Button>
            <UI.Button variant="primary" className="saveButton" type="submit">
              Done
            </UI.Button>
          </div>
        </footer>
      </form>
    </StyledNotes>
  );
};

export default AddPatientNote;
