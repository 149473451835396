import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { format as formatDate } from 'date-fns';
import { Plus, DotsThree, Check, File } from 'phosphor-react';

import { Maybe, StandingOrderFieldsFragment } from 'generated/graphql';

import UI from 'ui';
import { Tokens } from 'config';

const StyledStaticStandingOrders = styled.div`
  & > * + * {
    margin-top: ${Tokens.rhythm};
  }
`;

const StyledTaskBox = styled.div`
  align-items: center;
  border: 1px solid;
  border-color: rgba(48, 53, 70, 0.25);
  border-radius: ${Tokens.border.radius};
  display: flex;
  margin: ${Tokens.rhythm} 0;
  min-height: 83px;
  padding: calc(${Tokens.rhythm} * 2) calc(${Tokens.rhythm} * 2);

  &.hasError {
    border-color: ${Tokens.color.ui.error.base};
  }

  .fileIcon {
    justify-self: flex-start;
    flex-shrink: 1;
    width: 24px;
  }

  .actions {
    justify-self: flex-end;
    margin-left: auto;
  }

  .taskBoxInfo {
    justify-self: flex-start;
    max-width: 250px;
    padding: 0 calc(${Tokens.rhythm} * 2);

    h4 {
      font-size: ${Tokens.font.size.heading[3]};
      line-height: ${Tokens.lineHeight.heading[4]};
      margin: 0;
      padding: 0;

      a {
        color: ${Tokens.color.ui.link.base};
        text-decoration: none;
      }
    }
  }

  .signedInfo {
    align-items: center;
    display: flex;

    small {
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.paragraph.small};
      line-height: ${Tokens.lineHeight.paragraph.small};
      padding-right: ${Tokens.rhythm};
    }
  }
`;

export type StaticStandingOrdersProps = {
  disabled?: boolean;
  hasError?: boolean;
  standingOrderId?: Maybe<StandingOrderFieldsFragment['standingOrderId']>;
  standingOrderOptions?:
    | Maybe<StandingOrderFieldsFragment | undefined>[]
    | null;
  standingOrderAddedAt?: Maybe<string>;
  onAddStandingOrder: (order: StandingOrderFieldsFragment) => void;
  onDeleteStandingOrder: () => void;
};

const StaticStandingOrders = ({
  disabled = false,
  hasError = false,
  standingOrderId,
  standingOrderOptions,
  standingOrderAddedAt,
  onAddStandingOrder,
  onDeleteStandingOrder,
}: StaticStandingOrdersProps): JSX.Element => {
  const [actionsOpen, setActionsOpen] = useState<boolean>(false);
  const actionsAnchorRef = useRef(null);
  const selectedStandingOrder = standingOrderOptions?.find(
    (order) => order?.standingOrderId === standingOrderId,
  );

  useEffect(() => {
    setActionsOpen(false);
  }, []); // Close menu when component changes state

  if (
    !selectedStandingOrder &&
    (!standingOrderOptions || standingOrderOptions.length < 1)
  ) {
    return <p>No active standing orders exist in this market.</p>;
  }

  return (
    <StyledStaticStandingOrders>
      {selectedStandingOrder ? (
        <StyledTaskBox className={hasError ? 'hasError' : ''}>
          <File size={24} weight="fill" className="fileIcon" />

          <div className="taskBoxInfo">
            <h4>
              <a href={selectedStandingOrder.orderUrl ?? '#0'} target="_blank">
                {selectedStandingOrder.name}
              </a>
            </h4>

            <span className="signedInfo">
              <small>
                Added on:{' '}
                {formatDate(
                  standingOrderAddedAt
                    ? new Date(standingOrderAddedAt)
                    : new Date(),
                  'M/dd/yyyy @ h:mmaaa',
                )}
              </small>

              <Check color={Tokens.color.ui.success.base} size={14} />
            </span>
          </div>

          <div ref={actionsAnchorRef} className="actions">
            <UI.Button
              data-testid="actionsButton"
              className="actionsButton"
              variant="tertiary"
              size="icon"
              onClick={() => {
                setActionsOpen((currentState) => !currentState);
              }}
            >
              <DotsThree size={34} weight="fill" />
            </UI.Button>

            <UI.Dropdown
              data-testid="dropdown"
              anchorRef={actionsAnchorRef}
              open={actionsOpen}
              position="bottomLeft"
              setOpen={setActionsOpen}
            >
              <UI.ListItem
                label="View"
                onClick={() =>
                  window.open(selectedStandingOrder.orderUrl ?? '#0', '_blank')
                }
              />
              {!disabled ? (
                <UI.ListItem
                  color={Tokens.color.ui.error.base}
                  label="Delete"
                  onClick={() => onDeleteStandingOrder()}
                />
              ) : null}
            </UI.Dropdown>
          </div>
        </StyledTaskBox>
      ) : (
        standingOrderOptions?.map((option) => (
          <StyledTaskBox
            key={option?.standingOrderId}
            className={hasError ? 'hasError' : ''}
          >
            <File size={24} weight="fill" className="fileIcon" />

            <div className="taskBoxInfo">
              <h4>
                <a href={option?.orderUrl ?? '#0'} target="_blank">
                  {option?.name}
                </a>
              </h4>
            </div>

            <UI.Button
              data-testid="addStandingOrder"
              variant="secondary"
              leftIcon={<Plus />}
              onClick={() => {
                if (!disabled && option) onAddStandingOrder(option);
                setActionsOpen(false);
              }}
              className="actions"
              disabled={disabled}
            >
              Add
            </UI.Button>
          </StyledTaskBox>
        ))
      )}
    </StyledStaticStandingOrders>
  );
};

export default StaticStandingOrders;
