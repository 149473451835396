import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, generatePath } from 'react-router-dom';
import { endOfToday, startOfToday, sub } from 'date-fns';

import {
  useGetPatientByPatientIdLazyQuery,
  useGetEncountersByUserIdQuery,
  useGetEncounterSearchFiltersQuery,
  useGetUserLazyQuery,
  UserFieldsFragment,
  Encounter,
  EncounterAppointmentStatusConditionEnum,
  EncounterResponderStatusConditionEnum,
  EncounterPaymentModeConditionEnum,
} from 'generated/graphql';
import { RoutePaths } from 'config';
import { usePagination } from 'hooks';
import { Layout } from 'modules';
import { PatientRoutePaths } from 'modules/Patient/PatientRoutes';
import { Enums, Logger } from 'utils';
import {
  EncounterSearchView,
  ListItemOption,
  MarketOption,
  ServiceLineOption,
  Filter,
} from './components';
import { TPatient } from './types';

const RESULTS_PER_PAGE = 25;
const LOCAL_STORAGE_STARTDATE_KEY = 'encounters__startdate';
const LOCAL_STORAGE_ENDDATE_KEY = 'encounters__enddate';

type EncountersListState = {
  encounters: Encounter[] | undefined;
  loading: boolean;
  totalResults: number;
};

const EncountersContainer = (): JSX.Element => {
  const [responder, setResponder] = useState<UserFieldsFragment | null>(null);
  const [offset, setOffset] = useState<number>();
  const [encountersListState, setEncountersListState] =
    useState<EncountersListState>({
      encounters: undefined,
      loading: true,
      totalResults: 0,
    });
  const [patient, setPatient] = useState<TPatient>(null);
  const [filters, setFilters] = useState<Filter[] | null>(null);
  const filterPillsSetRef = useRef(false);
  const patientFilterRef = useRef({ reset: () => {} });

  const history = useHistory();
  const location = useLocation();

  const { encounters, loading, totalResults } = encountersListState;

  /**
   * Retrieve Search Params
   */
  const queryParams = new URLSearchParams(location.search);

  // Responder ID
  const responderId =
    parseInt(queryParams.get('responderId') ?? '', 10) ?? undefined;

  // Patient ID
  const patientId =
    parseInt(queryParams.get('patientId') ?? '', 10) ?? undefined;

  // Chart Status Options
  const chartStatusParams = (queryParams.get('chartStatuses') ?? '')
    .split(',')
    .filter((val) => val !== '');

  // Appointment Status Options
  const appointmentStatusParams = (queryParams.get('appointmentStatuses') ?? '')
    .split(',')
    .filter((val) => val !== '');

  // Market IDs
  const marketParams = (queryParams.get('markets') ?? '')
    .split(',')
    .filter((val) => val !== '')
    .map((val) => parseInt(val, 10));

  // Service Line IDs
  const serviceLineParams = (queryParams.get('serviceLines') ?? '')
    .split(',')
    .filter((val) => val !== '')
    .map((val) => parseInt(val, 10));

  // Payment Mode Options
  const paymentModeParams = (queryParams.get('paymentModes') ?? '')
    .split(',')
    .filter((val) => val !== '');

  // Set default filter dates
  if (!queryParams.get('startDate')) {
    queryParams.set(
      'startDate',
      localStorage.getItem(LOCAL_STORAGE_STARTDATE_KEY) ??
        sub(startOfToday(), { days: 7 }).toISOString(),
    );
  }
  if (!queryParams.get('endDate')) {
    queryParams.set(
      'endDate',
      localStorage.getItem(LOCAL_STORAGE_ENDDATE_KEY) ??
        endOfToday().toISOString(),
    );
  }
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');

  /**
   * Perform Encounter Search
   */
  const { data: encounterData, loading: encountersLoading } =
    useGetEncountersByUserIdQuery({
      variables: {
        ...(responderId && { responderId }),
        ...(patientId && { patientId }),
        includeResponderNames: !responderId, // Retrieve responder names if there is no responderId supplied
        scheduledForAfter: startDate,
        scheduledForBefore: endDate,
        ...(marketParams.length > 0 && { marketId: marketParams }),
        ...(serviceLineParams.length > 0 && { serviceLine: serviceLineParams }),
        ...(chartStatusParams.length > 0 && {
          responderStatus:
            chartStatusParams as EncounterResponderStatusConditionEnum[],
        }),
        ...(appointmentStatusParams.length > 0 && {
          appointmentStatus:
            appointmentStatusParams as EncounterAppointmentStatusConditionEnum[],
        }),
        ...(paymentModeParams.length > 0 && {
          paymentMode: paymentModeParams as EncounterPaymentModeConditionEnum[],
        }),
        offset: offset ?? 0,
        limit: RESULTS_PER_PAGE,
      },
      skip: offset === undefined,
    });

  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    pageCount,
    resultsOffset,
    setPage,
  } = usePagination({
    queryParams,
    totalResults,
    resultsPerPage: RESULTS_PER_PAGE,
  });

  useEffect(() => {
    const items = encounterData?.encounters?.nodes as Encounter[];
    if (items) {
      setEncountersListState((state) => ({
        ...state,
        encounters: items,
        totalResults: encounterData?.encountersCount?.totalCount ?? 0,
      }));
    }
  }, [encounterData]);

  useEffect(() => {
    setEncountersListState((state) => ({
      ...state,
      loading: encountersLoading,
    }));
  }, [encountersLoading]);

  useEffect(() => {
    setOffset(resultsOffset);
  }, [resultsOffset]);

  /**
   * Fetch the filters
   */
  const { data: filtersData, loading: filtersLoading } =
    useGetEncounterSearchFiltersQuery();

  // Set the market filter options
  const marketOptions = (filtersData?.marketsList ?? []).map((market) => ({
    ...market,
    selected:
      (filters ?? []).findIndex(
        (filter) =>
          filter.type === 'market' && filter.value === market.marketId,
      ) > -1,
  }));

  // Set the chart status filter options
  const chartStatusOptions = Object.values(
    EncounterResponderStatusConditionEnum,
  ).map((value) => ({
    label: Enums.humanize(value),
    value,
    selected:
      (filters ?? []).findIndex(
        (filter) => filter.type === 'chartStatus' && filter.value === value,
      ) > -1,
  }));

  // Set the appointment status filter options
  const appointmentStatusOptions = Object.values(
    EncounterAppointmentStatusConditionEnum,
  ).map((value) => ({
    label: Enums.humanize(value),
    value,
    selected:
      (filters ?? []).findIndex(
        (filter) =>
          filter.type === 'appointmentStatus' && filter.value === value,
      ) > -1,
  }));

  // Set the service line filter options
  const serviceLineOptions = (filtersData?.serviceLinesList ?? []).map(
    (serviceLine) => ({
      ...serviceLine,
      selected:
        (filters ?? []).findIndex(
          (filter) =>
            filter.type === 'serviceLine' &&
            filter.value === serviceLine.serviceLineId,
        ) > -1,
    }),
  );

  // Set the payment mode filter options
  const paymentModeOptions = Object.values(
    EncounterPaymentModeConditionEnum,
  ).map((value) => ({
    label: Enums.humanize(value),
    value,
    selected:
      (filters ?? []).findIndex(
        (filter) => filter.type === 'paymentMode' && filter.value === value,
      ) > -1,
  }));

  // Add the filter pills if it's an initial page load,
  // and after filter options have been loaded
  useEffect(() => {
    if (
      !filterPillsSetRef.current &&
      filters === null &&
      !filtersLoading &&
      filtersData
    ) {
      setFilters([
        ...(filtersData.marketsList ?? [])
          .filter((market) => marketParams.includes(market.marketId))
          .map(
            (market) =>
              ({
                name: market.name ?? '',
                type: 'market',
                value: market.marketId,
                selected: true,
              } as Filter),
          ),
        ...(filtersData.serviceLinesList ?? [])
          .filter((serviceLine) =>
            serviceLineParams.includes(serviceLine.serviceLineId),
          )
          .map(
            (serviceLine) =>
              ({
                name: `${serviceLine.name} - ${serviceLine.subType}`,
                type: 'serviceLine',
                value: serviceLine.serviceLineId,
                selected: true,
              } as Filter),
          ),
        ...appointmentStatusParams.map(
          (appointmentStatus) =>
            ({
              name: Enums.humanize(appointmentStatus),
              type: 'appointmentStatus',
              value: appointmentStatus,
              selected: true,
            } as Filter),
        ),
        ...chartStatusParams.map(
          (chartStatus) =>
            ({
              name: Enums.humanize(chartStatus),
              type: 'chartStatus',
              value: chartStatus,
              selected: true,
            } as Filter),
        ),
        ...paymentModeParams.map(
          (paymentMode) =>
            ({
              name: Enums.humanize(paymentMode),
              type: 'paymentMode',
              value: paymentMode,
              selected: true,
            } as Filter),
        ),
      ]);
      filterPillsSetRef.current = true;
    }
  }, [
    appointmentStatusParams,
    chartStatusParams,
    marketParams,
    paymentModeParams,
    serviceLineParams,
    filterPillsSetRef.current,
    filters,
    filtersData,
    filtersLoading,
  ]);

  // Get the responder user if passed into QueryParams
  const [getResponderUser, { data: responderUser }] = useGetUserLazyQuery();

  // Get the patient if passed into QueryParams
  const [getPatient, { data: patientData }] =
    useGetPatientByPatientIdLazyQuery();

  useEffect(() => {
    if (responderId && !responder) {
      getResponderUser({
        variables: {
          userId: responderId,
        },
      });
    }
    if (patientId && !patient) {
      getPatient({
        variables: {
          id: patientId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (responderUser?.user) {
      setResponder(responderUser?.user);
    }
  }, [responderUser]);

  useEffect(() => {
    if (patientData?.patient) {
      setPatient(patientData?.patient);
    }
  }, [patientData]);

  // Trigger a search by updating the window's location with search params
  const performSearch = (push = false) => {
    if (push) {
      history.push({
        pathname: RoutePaths.encounters,
        search: queryParams.toString(),
      });
      return;
    }
    history.replace({
      pathname: RoutePaths.encounters,
      search: queryParams.toString(),
    });
  };

  // Apply filter changes to query params
  useEffect(() => {
    if (filters) {
      const marketIds: number[] = [];
      const serviceLineIds: number[] = [];
      const chartStatuses: string[] = [];
      const appointmentStatuses: string[] = [];
      const paymentModes: string[] = [];
      filters.forEach((filter) => {
        if (filter.type === 'market') {
          marketIds.push(filter.value as number);
        } else if (filter.type === 'serviceLine') {
          serviceLineIds.push(filter.value as number);
        } else if (filter.type === 'chartStatus') {
          chartStatuses.push(filter.value as string);
        } else if (filter.type === 'appointmentStatus') {
          appointmentStatuses.push(filter.value as string);
        } else if (filter.type === 'paymentMode') {
          paymentModes.push(filter.value as string);
        }
      });
      queryParams.set('markets', marketIds.join(','));
      queryParams.set('serviceLines', serviceLineIds.join(','));
      queryParams.set('chartStatuses', chartStatuses.join(','));
      queryParams.set('appointmentStatuses', appointmentStatuses.join(','));
      queryParams.set('paymentModes', paymentModes.join(','));
      setPage(1);
      performSearch(true);
    }
  }, [filters]);

  const handleEncounterClick = (encounter: Partial<Encounter>) => {
    const { patient: thisPatient, encounterId } = encounter;
    const id = thisPatient?.globalPatientId;

    if (!id) {
      Logger.warn(
        `globalPatientId does not exist for patient id ${thisPatient?.patientId}`,
      );
    }

    const url = generatePath(PatientRoutePaths.encounters, {
      id: `${id}`,
      encounterId,
    });

    history.push(url, {
      referrer: {
        pageTitle: 'Encounters',
        pathname: location.pathname,
        search: location.search,
      },
    });
  };

  const handleStartDateChange = (date?: string) => {
    if (date) {
      localStorage.setItem(LOCAL_STORAGE_STARTDATE_KEY, date);
      queryParams.set('startDate', date as string);
      setPage(1);
      performSearch();
    }
  };

  const handleEndDateChange = (date?: string) => {
    if (date) {
      localStorage.setItem(LOCAL_STORAGE_ENDDATE_KEY, date);
      queryParams.set('endDate', date as string);
      setPage(1);
      performSearch();
    }
  };

  const handleNextPage = () => {
    nextPage();
    performSearch(true);
  };

  const handlePrevPage = () => {
    prevPage();
    performSearch(true);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    performSearch(true);
  };

  const handleResponderOptionClick = (value: Record<string, unknown>) => {
    setResponder(value as UserFieldsFragment);
    if (value) {
      queryParams.set('responderId', value.userId as string);
      setPage(1);
      performSearch();
    }
  };

  const handlePatientOptionClick = (_patient: TPatient | null) => {
    setPatient(_patient);
    if (_patient) {
      queryParams.set('patientId', _patient?.patientId?.toString() ?? '');
    } else {
      queryParams.delete('patientId');
    }
    setPage(1);
    performSearch();
  };

  const handleResponderClear = () => {
    setResponder(null);
    queryParams.delete('responderId');
    setPage(1);
    performSearch();
  };

  const handleMarketFilterOptionClick = (market: MarketOption) => {
    if (market.selected) {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []).filter(
          (filter) =>
            filter.type !== 'market' ||
            (filter.type === 'market' && filter.value !== market.marketId),
        ),
      ]);
    } else {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []),
        {
          value: market.marketId,
          type: 'market',
          name: market.name ?? '',
        },
      ]);
    }
  };

  const handleServiceLineFilterOptionClick = (
    serviceLine: ServiceLineOption,
  ) => {
    if (serviceLine.selected) {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []).filter(
          (filter) =>
            filter.type !== 'serviceLine' ||
            (filter.type === 'serviceLine' &&
              filter.value !== serviceLine.serviceLineId),
        ),
      ]);
    } else {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []),
        {
          value: serviceLine.serviceLineId,
          type: 'serviceLine',
          name: `${serviceLine.name} - ${serviceLine.subType}`,
        },
      ]);
    }
  };

  const handleChartStatusOptionClick = (chartStatus: ListItemOption) => {
    if (chartStatus.selected) {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []).filter(
          (filter) =>
            filter.type !== 'chartStatus' ||
            (filter.type === 'chartStatus' &&
              filter.value !== chartStatus.value),
        ),
      ]);
    } else {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []),
        {
          value: chartStatus.value,
          type: 'chartStatus',
          name: `${chartStatus.label}`,
        },
      ]);
    }
  };

  const handleAppointmentStatusOptionClick = (
    appointmentStatus: ListItemOption,
  ) => {
    if (appointmentStatus.selected) {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []).filter(
          (filter) =>
            filter.type !== 'appointmentStatus' ||
            (filter.type === 'appointmentStatus' &&
              filter.value !== appointmentStatus.value),
        ),
      ]);
    } else {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []),
        {
          value: appointmentStatus.value,
          type: 'appointmentStatus',
          name: appointmentStatus.label,
        },
      ]);
    }
  };

  const handlePaymentModeOptionClick = (paymentMode: ListItemOption) => {
    if (paymentMode.selected) {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []).filter(
          (filter) =>
            filter.type !== 'paymentMode' ||
            (filter.type === 'paymentMode' &&
              filter.value !== paymentMode.value),
        ),
      ]);
    } else {
      setFilters((prevFilters) => [
        ...(prevFilters ?? []),
        {
          value: paymentMode.value,
          type: 'paymentMode',
          name: paymentMode.label,
        },
      ]);
    }
  };

  const handleFilterClose = (filter: Filter) => {
    setFilters((prevFilters) => [
      ...(prevFilters ?? []).filter(
        (_filter) =>
          _filter.type !== filter.type ||
          (_filter.type === filter.type && _filter.value !== filter.value),
      ),
    ]);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    setPatient(null);
    queryParams.delete('patientId');
    setPage(1);
    performSearch();
  };

  return (
    <Layout
      title="Encounters"
      breadcrumbsProps={[
        {
          title: 'Encounters',
          href: RoutePaths.encounters,
        },
      ]}
    >
      <EncounterSearchView
        encounters={encounters}
        encountersLoading={loading}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handlePageChange={handlePageChange}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        onEncounterClick={handleEncounterClick}
        onStartDateChange={handleStartDateChange}
        onEndDateChange={handleEndDateChange}
        startDate={new Date(startDate!)}
        endDate={new Date(endDate!)}
        responder={responder}
        onResponderOptionClick={handleResponderOptionClick}
        onResponderClear={handleResponderClear}
        totalResults={totalResults}
        currentPage={currentPage}
        pageSize={RESULTS_PER_PAGE}
        pageCount={pageCount}
        chartStatusOptions={chartStatusOptions}
        onChartStatusClick={handleChartStatusOptionClick}
        appointmentStatusOptions={appointmentStatusOptions}
        onAppointmentStatusClick={handleAppointmentStatusOptionClick}
        paymentModeOptions={paymentModeOptions}
        onPaymentModeClick={handlePaymentModeOptionClick}
        marketOptions={marketOptions}
        serviceLineOptions={serviceLineOptions}
        filters={filters ?? []}
        filtersLoading={filtersLoading}
        onMarketClick={handleMarketFilterOptionClick}
        onServiceLineClick={handleServiceLineFilterOptionClick}
        onFilterClose={handleFilterClose}
        onClearAllFilters={handleClearAllFilters}
        selectedPatient={patient}
        onPatientOptionClick={handlePatientOptionClick}
        patientFilterRef={patientFilterRef}
      />
    </Layout>
  );
};

export default EncountersContainer;
